<template>
  <b-field
    label="Campaign"
    custom-class="is-small"
  >
    <b-autocomplete
      icon-pack="fas"
      icon="search"
      placeholder="Campaign Details"
      field="name"
      :keep-first="true"
      :data="items"
      v-model="queryStr"
      :loading="isLoading"
      @input="loadOptionsSuggestion"
      @select="onOptionSelection"
    >
      <template slot="empty">No campaign found</template>
      <template slot-scope="props">
        <div class="media">
          <div class="media-content">
            {{ props.option.name }}
          </div>
        </div>
      </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
import { mapActions } from 'vuex'
import searchInputMixins from './search-input-mixin'
import { CAMPAIGN_LIST } from '@/store/actions/campaignActions'

export default {
  name: 'CampaignSearchInput',
  mixins: [searchInputMixins],
  methods: {
    ...mapActions('Campaigns', { loadCampaignOptions: CAMPAIGN_LIST }),
    loadOptions (data) {
      return this.loadCampaignOptions(data)
    }
  }
}
</script>
