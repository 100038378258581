<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="card card-section">
      <div class="card-content">
        <form
          @submit.prevent="onStart"
          v-if="form"
        >
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Title</label>
                <div class="control">
                  <input
                    required
                    class="input"
                    type="text"
                    placeholder="Enter Media Title"
                    v-model="form.title"
                    :class="{'is-danger' : errors.title}"
                  />
                </div>
                <p
                  v-if="errors.title"
                  class="help is-danger"
                >{{ errors.title | implode }}</p>
              </div>
              <div class="field">
                <label class="label">Description</label>
                <div class="control">
                  <textarea
                    v-model="form.description"
                    class="textarea"
                    :class="{'is-danger' : errors.description}"
                    placeholder="Enter Media Description"
                  />
                </div>
                <p
                  v-if="errors.description"
                  class="help is-danger"
                >{{ errors.description | implode }}</p>
              </div>
              <div class="field">
                <label class="label">Anchor Link</label>
                <div class="control">
                  <input
                    v-model="form.href"
                    class="input"
                    :class="{'is-danger' : errors.href}"
                    type="text"
                    placeholder="Enter Media Anchor Link"
                  />
                </div>
                <p
                  v-if="errors.href"
                  class="help is-danger"
                >{{ errors.href | implode }}</p>
              </div>
              <div class="field">
                <label class="label">Status</label>
                <div class="control">
                  <!-- <div class="control is-expanded has-icons-left"> -->
                  <v-select
                    v-model="form.status"
                    :options="status_options"
                    :class="{'is-danger' : errors.status}"
                    placeholder="Pick status for media"
                    class="is-small"
                  ></v-select>
                </div>
                <p
                  v-if="errors.status"
                  class="help is-danger"
                >{{ errors.status | implode }}</p>
              </div>
            </div>
            <div class="column is-two-fifths">
              <div class="card card-section">
                <div class="card-content">
                  <div class="card">
                    <div class="card-image">
                      <figure class="image is-4by3">
                        <img
                          v-if="!form.thumbnail"
                          src="/img/placeholders/image-placeholder.png"
                          alt="Media Thumbnail"
                        >
                        <img
                          v-if="form.thumbnail"
                          :src="form.thumbnail"
                          alt="Media Thumbnail"
                        >
                      </figure>
                    </div>
                    <div class="card-content">
                      <div class="file has-name is-fullwidth is-primary">
                        <label class="file-label">
                          <input
                            class="file-input"
                            type="file"
                            name="mediaFile"
                            ref="mediaFile"
                            @change="onMediaChange"
                          />
                          <span class="file-cta">
                            <span class="file-icon">
                              <i class="fas fa-upload"></i>
                            </span>
                            <span class="file-label">
                              Choose a media file to upload…
                            </span>
                          </span>
                          <span class="file-name">
                            {{ mediaFileName }}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-grouped action-buttons">
            <div class="control">
              <button
                type="submit"
                :disabled="isLoading"
                class="button is-primary is-rounded"
                :class="{ 'is-loading': isLoading }"
              >Submit</button>
            </div>
            <div class="control">
              <button
                type="button"
                @click="onReset"
                class="button is-rounded"
              >Reset</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { MEDIA_CREATE } from '@/store/actions/mediaActions'

export default {
  name: 'MediaCreate',
  components: {
    Breadcrumb
  },
  data () {
    return {
      pageTitle: 'New Media Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-images', routeName: 'MediaList', name: 'Manage Media Library' }
        ],
        current: { icon: 'fas fa-plus', name: 'New Media' },
        actions: [{ icon: 'fas fa-images', routeName: 'MediaList', name: 'Manage Media Library' }]
      },
      status_options: ['ENABLE', 'DISABLE'],
      form: null,
      errors: {},
      mediaFileName: null
    }
  },
  computed: {
    ...mapGetters('Media', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Media', { createMedia: MEDIA_CREATE }),
    onMediaChange () {
      const mediaFiles = this.$refs.mediaFile
      if (!mediaFiles || !mediaFiles.files.length) {
        return
      }
      this.mediaFileName = mediaFiles.files[0].name;
      [this.form.mediaFile] = mediaFiles.files
      this.createImage(mediaFiles.files[0])
    },
    createImage (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.form.thumbnail = e.target.result
      }
      reader.readAsDataURL(file)
    },
    onStart () {
      this.errors = {}
      if (this.form.mediaFile === null) {
        this.$notify({
          title: 'Media File Required',
          text: 'Select media file to upload...',
          type: 'is-danger'
        })
      } else {
        const formData = new FormData()
        formData.set('title', this.form.title)
        formData.set('description', this.form.description)
        formData.set('href', this.form.href)
        formData.set('mediaFile', this.form.mediaFile)
        formData.set('status', this.form.status)
        this.createMedia(formData)
          .then(() => {
            this.$buefy.snackbar.open({
              duration: 4000,
              message: 'Media is created successfully',
              type: 'is-success',
              position: 'is-top',
              actionText: 'Go to Manage Media Library',
              onAction: () => {
                this.$router.push({
                  name: 'MediaList'
                })
              }
            })
            this.onReset()
          })
          .catch((error) => {
            this.errors = error.errors
            this.$notify({
              title: 'Invalid Media Form',
              text: error.message,
              type: 'is-danger'
            })
          })
      }
    },
    onReset () {
      this.errors = {}
      this.form = {
        title: '',
        description: '',
        href: '',
        thumbnail: '',
        mediaFile: '',
        status: 'ENABLE'
      }
      this.$notify({
        title: 'Reset Form',
        text: 'Media form is reset with default value, please continue...',
        type: 'is-info'
      })
    }
  },
  created () {
    this.onReset()
  }
}
</script>
