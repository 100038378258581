<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="user"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ user.name }}</p>
                    <!--                     <p class="subtitle is-6">
                      <i class="fas fa-user-secret"></i> Administrator
                      <i class="fas fa-user-tie"></i>  Customer
                    </p> -->
                  </div>
                </div>

                <div class="content">
                  <table class="table is-striped">
                    <tbody>
                      <tr>
                        <th>Role</th>
                        <td>{{ user.role ? user.role.name : '' }}</td>
                      </tr>
                      <tr>
                        <th>E-Mail</th>
                        <td>{{ user.email }}</td>
                      </tr>
                      <tr>
                        <th>Mobile</th>
                        <td>{{ user.mobile }}</td>
                      </tr>
                      <tr>
                        <th>Gender</th>
                        <td>{{ user.profiles ? user.profiles.gender : '' }}</td>
                      </tr>
                      <tr>
                        <th>Status</th>
                        <td>{{ user.status }}</td>
                      </tr>
                      <tr>
                        <th>Types</th>
                        <td>{{ user.types | implode }}</td>
                      </tr>
                      <tr>
                        <th>Logins</th>
                        <td>{{ user.logins | implode }}</td>
                      </tr>
                      <tr>
                        <th>Expire Date</th>
                        <td>{{ user.expire_date | moment("dddd, MMMM Do YYYY") }}</td>
                      </tr>
                      <tr>
                        <th>Address</th>
                        <td>{{ user.profiles ? user.profiles.address : '' }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="field is-grouped">
                    <p
                      class="control"
                      v-if="hasAnyScope('USER-LIST')"
                    >
                      <router-link
                        :to="{ name: 'UserList'}"
                        class="button is-primary"
                      >
                        <span class="icon">
                          <i class="fas fa-th-list"></i>
                        </span>
                        <span>LIST</span>
                      </router-link>
                    </p>
                    <p
                      class="control"
                      v-if="hasAnyScope('USER-WALLET-SHOW')"
                    >
                      <router-link
                        :to="{ name: 'UserWallet', params: { userId: user.id }}"
                        class="button is-link"
                      >
                        <span class="icon">
                          <i class="fas fa-wallet"></i>
                        </span>
                        <span>WALLET</span>
                      </router-link>
                    </p>
                    <p
                      class="control"
                      v-if="hasAnyScope('USER-UPDATE')"
                    >
                      <router-link
                        :to="{ name: 'UserEdit', params: { userId: user.id }}"
                        class="button is-warning"
                      >
                        <span class="icon">
                          <i class="fas fa-edit"></i>
                        </span>
                        <span>EDIT</span>
                      </router-link>
                    </p>
                    <p
                      class="control"
                      v-if="hasAnyScope('USER-DELETE')"
                    >
                      <a
                        class="button is-danger"
                        @click.prevent="confirmDeleteUser"
                      >
                        <span class="icon">
                          <i class="fas fa-trash"></i>
                        </span>
                        <span>DELETE</span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">Financial Details</p>
                  </div>
                </div>
                <div class="content">
                  <table class="table is-striped">
                    <tbody>
                      <tr>
                        <th>Bank Name</th>
                        <td>{{ user.profiles ? user.profiles.bank_name : '' }}</td>
                      </tr>
                      <tr>
                        <th>Name (As per bank a/c)</th>
                        <td>{{ user.profiles ? user.profiles.account_name : '' }}</td>
                      </tr>
                      <tr>
                        <th>Account Number</th>
                        <td>{{ user.profiles ? user.profiles.account_number : '' }}</td>
                      </tr>
                      <tr>
                        <th>Account Type</th>
                        <td>{{ user.profiles ? user.profiles.account_type : '' }}</td>
                      </tr>
                      <tr>
                        <th>Account IFSC</th>
                        <td>{{ user.profiles ? user.profiles.account_ifsc : '' }}</td>
                      </tr>
                      <tr>
                        <th>Paytm Number</th>
                        <td>{{ user.profiles ? user.profiles.paytm_number : '' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-image">
                <figure class="image is-4by3">
                  <img
                    src="/img/placeholders/image-placeholder.png"
                    alt="User Image"
                  >
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { USER_SHOW, USER_DELETE } from '@/store/actions/userActions'

export default {
  name: 'UserShow',
  components: {
    Breadcrumb
  },
  props: {
    userId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncUser'
  },
  data () {
    return {
      pageTitle: 'User Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-user-shield', routeName: 'UserList', name: 'Manage Users' }
        ],
        current: { icon: 'fas fa-user-tie', name: 'User Details' },
        actions: [
          {
            icon: 'fas fa-user-shield', routeName: 'UserList', name: 'Manage Users', scope: 'USER-LIST'
          }
        ]
      },
      user: null
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    ...mapGetters('Users', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Users', { loadUser: USER_SHOW, sendDeleteUserRequest: USER_DELETE }),
    confirmDeleteUser () {
      this.$buefy.dialog.confirm({
        title: 'Deleting User',
        message: 'Are you sure you want to <b>delete</b> this user? This action cannot be undone.',
        confirmText: 'Delete User',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deleteUser()
      })
    },
    deleteUser () {
      this.sendDeleteUserRequest(this.user.id)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.$router.replace({ name: 'UserList' })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncUser () {
      this.user = null
      this.loadUser(this.userId)
        .then((response) => {
          this.user = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (User Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'UserList' }),
            onConfirm: () => this.$router.replace({ name: 'UserList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncUser()
  }
}
</script>
