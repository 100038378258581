<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="slider"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ slider.name }}</p>
                  </div>
                </div>
                <div class="content">
                  <table class="table is-striped">
                    <tbody>
                      <tr>
                        <th>Status</th>
                        <td>{{ slider.status }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="card">
                    <div class="card-content">
                      <div class="field is-grouped">
                        <p class="control">
                          <router-link
                            :to="{ name: 'SliderList'}"
                            class="button is-primary"
                          >
                            <span class="icon">
                              <i class="fas fa-th-list"></i>
                            </span>
                            <span>LIST</span>
                          </router-link>
                        </p>
                        <p class="control">
                          <router-link
                            :to="{ name: 'SliderEdit', params: { sliderId: slider.id }}"
                            class="button is-warning"
                          >
                            <span class="icon">
                              <i class="fas fa-edit"></i>
                            </span>
                            <span>EDIT</span>
                          </router-link>
                        </p>
                        <p class="control">
                          <a
                            class="button is-danger"
                            @click.prevent="confirmDeleteSlider"
                          >
                            <span class="icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span>DELETE</span>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <picture-gallery
                    title="Slides Gallery"
                    :gallery="slider.slides"
                    :properties="['title','subtitle','link']"
                  ></picture-gallery>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import PictureGallery from '@/pages/layouts/secure/components/PictureGallery'
import { mapGetters, mapActions } from 'vuex'
import { SLIDER_SHOW, SLIDER_DELETE } from '@/store/actions/sliderActions'

export default {
  name: 'SliderShow',
  components: { Breadcrumb, PictureGallery },
  props: {
    sliderId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncSlider'
  },
  data () {
    return {
      pageTitle: 'Slider Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-clone', routeName: 'SliderList', name: 'Manage Sliders' }
        ],
        current: { icon: 'fas fa-eye', name: 'Slider Details' },
        actions: [{ icon: 'fas fa-clone', routeName: 'SliderList', name: 'Manage Sliders' }]
      },
      slider: null
    }
  },
  computed: {
    ...mapGetters('Sliders', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Sliders', { loadSlider: SLIDER_SHOW, sendDeleteSliderRequest: SLIDER_DELETE }),
    confirmDeleteSlider () {
      this.$buefy.dialog.confirm({
        title: 'Deleting Slider',
        message: 'Are you sure you want to <b>delete</b> this slider? This action cannot be undone.',
        confirmText: 'Delete Slider',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deleteSlider()
      })
    },
    deleteSlider () {
      this.sendDeleteSliderRequest(this.slider.id)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.$router.replace({ name: 'SliderList' })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncSlider () {
      this.slider = null
      this.loadSlider(this.sliderId)
        .then((response) => {
          this.slider = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Slider Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'SliderList' }),
            onConfirm: () => this.$router.replace({ name: 'SliderList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncSlider()
  }
}
</script>
