<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="card card-section">
      <div class="card-content">
        <b-tabs
          v-model="activeTab"
          v-if="form"
        >
          <b-tab-item label="General Properties">
            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                <input
                  v-model="form.name"
                  class="input"
                  type="text"
                  placeholder="Enter Slider Name"
                  :class="{'is-danger' : errors.name}"
                />
              </div>
              <p
                v-if="errors.name"
                class="help is-danger"
              >{{ errors.name | implode }}</p>
            </div>
            <div class="field action-buttons">
              <div class="control">
                <button
                  type="button"
                  :disabled="isLoading"
                  class="button is-primary is-rounded"
                  @click="formSubmit"
                  :class="{ 'is-loading': isLoading }"
                >Create</button>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item
            label="Manage Slides"
            :disabled="true"
          ></b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { SLIDER_CREATE } from '@/store/actions/sliderActions'

export default {
  name: 'SliderCreate',
  components: {
    Breadcrumb
  },
  data () {
    return {
      activeTab: 0,
      isSlugEditable: false,
      pageTitle: 'New Slider Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-clone', routeName: 'SliderList', name: 'Manage Sliders' }
        ],
        current: { icon: 'fas fa-plus', name: 'New Slider' },
        actions: [{ icon: 'fas fa-clone', routeName: 'SliderList', name: 'Manage Sliders' }]
      },
      status_options: ['DRAFT', 'ENABLE', 'DISABLE'],
      form: null,
      errors: {}
    }
  },
  computed: {
    ...mapGetters('Sliders', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Sliders', { createSlider: SLIDER_CREATE }),
    formSubmit () {
      this.errors = {}
      this.createSlider(this.form)
        .then((response) => {
          this.$notify({
            title: 'Slider Saved as Draft',
            text: `(${response.data.name}) Slider is created and saved as draft successfully`,
            type: 'is-success'
          })
          this.$router.push({
            name: 'SliderEdit',
            params: { sliderId: response.data.id }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Slider Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onReset () {
      this.errors = {}
      this.form = {
        name: ''
      }
      this.$notify({
        title: 'Reset Form',
        text: 'Slider form is reset with default value, please continue...',
        type: 'is-info'
      })
    }
  },
  created () {
    this.onReset()
  }
}
</script>
