<template>
  <div class="card filters">
    <header
      class="card-header"
      :class="headerBackgroundColor"
    >
      <p class="card-header-title">
        {{ title }}
      </p>
      <a
        @click.prevent="toggle"
        href="#"
        class="card-header-icon"
        aria-label="toggle"
      >
        <span class="icon">
          <i
            class="fas"
            :class="[isShow ? 'fa-angle-down' : 'fa-angle-up']"
            aria-hidden="true"
          ></i>
        </span>
      </a>
    </header>
    <div
      class="card-content"
      v-show="isShow"
    >
      <form @submit.prevent="goFilter">
        <slot></slot>
        <div class="field is-grouped">
          <p class="control">
            <button
              type="submit"
              class="button is-small is-link"
              :class="{ 'is-loading' : isLoading }"
              :disabled="isLoading"
            >Go Filter</button>
          </p>
          <p
            class="control"
            v-show="isResetNeeded"
          >
            <button
              type="button"
              @click="goReset"
              class="button is-small"
              :disabled="isLoading"
            >Reset</button>
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdvanceFilter',
  props: {
    title: {
      default: 'Advance Filter',
      type: String
    },
    isHide: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isResetNeeded: {
      type: Boolean,
      default: true
    },
    headerBackgroundColor: {
      default: 'has-background-primary',
      type: String
    }
  },
  data () {
    return {
      isShow: !this.isHide
    }
  },
  methods: {
    toggle () {
      this.isShow = !this.isShow
      this.$emit('toggleFilter', this.isShow)
    },
    goFilter () {
      if (!this.isLoading) {
        this.$emit('onStart')
      }
    },
    goReset () {
      this.$emit('onReset')
    }
  }
}
</script>
