import { ROLE_LIST, ROLE_CREATE, ROLE_SHOW, ROLE_UPDATE, ROLE_DELETE, PERMISSION_LIST } from '../../actions/roleActions'

import { LOADING, SUCCESS, ERROR } from '../../actions/requestStatus'
import roleApi from '../../../api/roleApi'

/* , ROLE_CREATE, ROLE_UPDATE, ROLE_SHOW, ROLE_DELETE */

export default {
  namespaced: true,

  state: {
    status: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR
  },

  actions: {
    [ROLE_SHOW]: ({ commit }, roleId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        roleApi
          .show(roleId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [PERMISSION_LIST]: () =>
      new Promise((resolve, reject) => {
        roleApi
          .permissions()
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [ROLE_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        roleApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [ROLE_CREATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        roleApi
          .create(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [ROLE_UPDATE]: ({ commit }, { roleId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        roleApi
          .update(roleId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [ROLE_DELETE]: ({ commit }, roleId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        roleApi
          .delete(roleId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    }
  }
}
