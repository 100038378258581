<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="card card-section"
      v-if="nav"
    >
      <div class="card-content">
        <form @submit.prevent="onStart">
          <div class="field">
            <label class="label">Name</label>
            <div class="control">
              <input
                v-model="nav.name"
                class="input"
                :class="{'is-danger' : errors.name}"
                type="text"
                placeholder="Enter Nav Name"
              />
            </div>
            <p
              v-if="errors.name"
              class="help is-danger"
            >{{ errors.name | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Icon</label>
            <div class="control">
              <input
                v-model="nav.icon"
                class="input"
                :class="{'is-danger' : errors.icon}"
                type="text"
                placeholder="Enter Nav Icon"
              />
            </div>
            <p
              v-if="errors.icon"
              class="help is-danger"
            >{{ errors.icon | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Link</label>
            <div class="control">
              <input
                v-model="nav.link"
                class="input"
                :class="{'is-danger' : errors.link}"
                type="text"
                placeholder="Enter Nav Link"
              />
            </div>
            <p
              v-if="errors.link"
              class="help is-danger"
            >{{ errors.link | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Target</label>
            <div class="control">
              <!-- <div class="control is-expanded has-icons-left"> -->
              <v-select
                v-model="nav.target"
                :options="target_options"
                :class="{'is-danger' : errors.target}"
                placeholder="Pick target for nav"
                class="is-small"
              ></v-select>
            </div>
            <p
              v-if="errors.target"
              class="help is-danger"
            >{{ errors.target | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Parent Nav</label>
            <div class="control">
              <v-select
                v-model="nav.parent"
                :options="parents"
                label="name"
                :class="{'is-danger' : errors.parent}"
                placeholder="Pick parent for nav"
                class="is-small"
              ></v-select>
            </div>
            <p
              v-if="errors.parent"
              class="help is-danger"
            >{{ errors.parent | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Group</label>
            <div class="control">
              <v-select
                v-model="nav.group"
                :options="group_options"
                :class="{'is-danger' : errors.group}"
                placeholder="Pick group for nav"
                class="is-small"
              ></v-select>
            </div>
            <p
              v-if="errors.group"
              class="help is-danger"
            >{{ errors.group | implode }}</p>
          </div>
          <div class="field is-grouped action-buttons">
            <div class="control">
              <button
                type="submit"
                :disabled="isLoading"
                class="button is-primary is-rounded"
                :class="{ 'is-loading': isLoading }"
              >Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { NAV_SHOW, NAV_UPDATE, NAV_LIST } from '@/store/actions/navActions'

export default {
  name: 'NavEdit',
  components: {
    Breadcrumb
  },
  props: {
    navId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncNav'
  },
  data () {
    return {
      pageTitle: 'Edit Nav Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-road', routeName: 'NavList', name: 'Manage Navs' }
        ],
        current: { icon: 'fas fa-edit', name: 'Edit Nav' },
        actions: [
          { icon: 'fas fa-road', routeName: 'NavList', name: 'Manage Navs' },
          {
            icon: 'fas fa-eye', routeName: 'NavShow', params: { navId: this.navId }, name: 'Display Nav'
          }
        ]
      },
      target_options: ['_blank', '_self', '_parent', '_top'],
      group_options: ['Top Nav', 'Left Main Nav', 'Right Main Nav', 'Footer Nav'],
      nav: null,
      errors: {},
      parents: []
    }
  },
  computed: {
    ...mapGetters('Navs', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Navs', { loadNav: NAV_SHOW, updateNav: NAV_UPDATE, loadParents: NAV_LIST }),
    onStart () {
      this.errors = {}
      const data = {
        navId: this.navId,
        formData: this.nav
      }
      this.updateNav(data)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.name}) Nav is updated successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Nav',
            onAction: () => {
              this.$router.push({
                name: 'NavList'
              })
            }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Nav Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncNav () {
      this.nav = null
      this.loadNav(this.navId)
        .then((response) => {
          this.nav = {
            id: response.data.id,
            name: response.data.name,
            icon: response.data.icon,
            link: response.data.link,
            target: response.data.target,
            parent: response.data.parent,
            group: response.data.group
          }
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Nav Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'NavList' }),
            onConfirm: () => this.$router.replace({ name: 'NavList' })
          })
        })
    },
    loadAsyncParents () {
      this.loadParents()
        .then((response) => {
          this.parents = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Parent Navs Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    this.loadAsyncParents()
    this.loadAsyncNav()
  }
}
</script>
