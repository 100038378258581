<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="setting"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ setting.slug }}</p>
                  </div>
                </div>

                <div class="content">
                  <table class="table is-striped">
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td>{{ setting.name }}</td>
                      </tr>
                      <tr>
                        <th>Icon</th>
                        <td>{{ setting.icon }}<span v-if="setting.icon">: <i class="setting.icon"></i></span></td>
                      </tr>
                      <tr>
                        <th>Value</th>
                        <td>{{ setting.value }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="field is-grouped">
                    <p class="control">
                      <router-link
                        :to="{ name: 'SettingList'}"
                        class="button is-primary"
                      >
                        <span class="icon">
                          <i class="fas fa-th-list"></i>
                        </span>
                        <span>LIST</span>
                      </router-link>
                    </p>
                    <p class="control">
                      <router-link
                        :to="{ name: 'SettingEdit', params: { settingId: setting.id }}"
                        class="button is-warning"
                      >
                        <span class="icon">
                          <i class="fas fa-edit"></i>
                        </span>
                        <span>EDIT</span>
                      </router-link>
                    </p>
                    <p class="control">
                      <a
                        class="button is-danger"
                        @click.prevent="confirmDeleteSetting"
                      >
                        <span class="icon">
                          <i class="fas fa-trash"></i>
                        </span>
                        <span>DELETE</span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-image">
                <figure class="image is-4by3">
                  <img
                    src="/img/placeholders/image-placeholder.png"
                    alt="Setting Image"
                  >
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { SETTING_SHOW, SETTING_DELETE } from '@/store/actions/settingActions'

export default {
  name: 'SettingShow',
  components: { Breadcrumb },
  props: {
    settingId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncSetting'
  },
  data () {
    return {
      pageTitle: 'Setting Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-cogs', routeName: 'SettingList', name: 'Manage Settings' }
        ],
        current: { icon: 'fas fa-ribbon', name: 'Setting Details' },
        actions: [{ icon: 'fas fa-cogs', routeName: 'SettingList', name: 'Manage Settings' }]
      },
      setting: null
    }
  },
  computed: {
    ...mapGetters('Settings', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Settings', { loadSetting: SETTING_SHOW, sendDeleteSettingRequest: SETTING_DELETE }),
    confirmDeleteSetting () {
      this.$buefy.dialog.confirm({
        title: 'Deleting Setting',
        message: 'Are you sure you want to <b>delete</b> this setting? This action cannot be undone.',
        confirmText: 'Delete Setting',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deleteSetting()
      })
    },
    deleteSetting () {
      this.sendDeleteSettingRequest(this.setting.id)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.$router.replace({ name: 'SettingList' })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncSetting () {
      this.setting = null
      this.loadSetting(this.settingId)
        .then((response) => {
          this.setting = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Setting Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'SettingList' }),
            onConfirm: () => this.$router.replace({ name: 'SettingList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncSetting()
  }
}
</script>
