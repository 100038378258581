<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="card card-section">
      <div class="card-content">
        <form @submit.prevent="onStart">
          <div class="field">
            <label class="label">Slug</label>
            <div class="control">
              <input
                v-model="form.slug"
                class="input"
                :class="{'is-danger' : errors.slug}"
                type="text"
                placeholder="Enter Setting Slug"
              />
            </div>
            <p
              v-if="errors.slug"
              class="help is-danger"
            >{{ errors.slug | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Name</label>
            <div class="control">
              <input
                v-model="form.name"
                class="input"
                :class="{'is-danger' : errors.name}"
                type="text"
                placeholder="Enter Setting Name"
              />
            </div>
            <p
              v-if="errors.name"
              class="help is-danger"
            >{{ errors.name | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Icon</label>
            <div class="control">
              <input
                v-model="form.icon"
                class="input"
                :class="{'is-danger' : errors.icon}"
                type="text"
                placeholder="Enter Setting Icon"
              />
            </div>
            <p
              v-if="errors.icon"
              class="help is-danger"
            >{{ errors.icon | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Value</label>
            <div class="control">
              <input
                v-model="form.value"
                class="input"
                :class="{'is-danger' : errors.value}"
                type="text"
                placeholder="Enter Setting Value"
              />
            </div>
            <p
              v-if="errors.value"
              class="help is-danger"
            >{{ errors.value | implode }}</p>
          </div>
          <div class="field is-grouped action-buttons">
            <div class="control">
              <button
                type="submit"
                :disabled="isLoading"
                class="button is-primary is-rounded"
                :class="{ 'is-loading': isLoading }"
              >Submit</button>
            </div>
            <div class="control">
              <button
                type="button"
                @click="onReset"
                class="button is-rounded"
              >Reset</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { SETTING_CREATE } from '@/store/actions/settingActions'

export default {
  name: 'SettingCreate',
  components: { Breadcrumb },
  data () {
    return {
      pageTitle: 'New Setting Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-cogs', routeName: 'SettingList', name: 'Manage Settings' }
        ],
        current: { icon: 'fas fa-ribbon', name: 'New Setting' },
        actions: [{ icon: 'fas fa-cogs', routeName: 'SettingList', name: 'Manage Settings' }]
      },
      form: {
        slug: null,
        name: null,
        icon: null,
        value: null
      },
      errors: {}
    }
  },
  computed: {
    ...mapGetters('Settings', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Settings', { createSetting: SETTING_CREATE }),
    onStart () {
      this.errors = {}
      this.createSetting(this.form)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.slug}) Setting is created successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Setting',
            onAction: () => {
              this.$router.push({
                name: 'SettingList'
              })
            }
          })
          this.onReset()
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Setting Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onReset () {
      this.errors = {}
      this.form = {
        slug: null,
        name: null,
        icon: null,
        value: null
      }
      this.$notify({
        title: 'Reset Form',
        text: 'Setting form is reset with default value, please continue...',
        type: 'is-info'
      })
    }
  },
  created () {
    this.onReset()
  }
}
</script>
