<template>
  <div>
    <!-- <loading v-show="isLoading"></loading> -->
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="order"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">Order #{{ order.id }}</p>
                  </div>
                </div>
                <div class="content">
                  <div class="columns">
                    <div class="column">
                      <table class="table is-striped">
                        <tbody>
                          <tr>
                            <th>Booked At</th>
                            <td>{{ order.booked_at }}</td>
                          </tr>
                          <tr>
                            <th>Total Slots Booked</th>
                            <td>{{ order.slots }}</td>
                          </tr>
                          <tr>
                            <th>Status</th>
                            <td>{{ order.status }}</td>
                          </tr>
                          <tr>
                            <th>Intervals Selected</th>
                            <td>{{ order.intervals }}</td>
                          </tr>
                          <tr>
                            <th>Budget Alloted</th>
                            <td>{{ order.budget_alloted }}</td>
                          </tr>
                          <tr>
                            <th>Has Limit</th>
                            <td>{{ order.has_limit | yesOrNo }}</td>
                          </tr>
                          <tr v-if="order.has_limit">
                            <th>Repeat Ads Suggested</th>
                            <td>{{ order.run_times }}</td>
                          </tr>
                          <tr>
                            <th>Amount</th>
                            <td>{{ order.amount }}</td>
                          </tr>
                          <tr>
                            <th>Refund</th>
                            <td>{{ order.refund }}</td>
                          </tr>
                          <tr>
                            <th>Advertiser</th>
                            <td>
                              <div v-if="order.advertiser">
                                <div v-if="order.advertiser.name">{{ order.advertiser.name }}</div>
                                <div v-if="order.advertiser.email">{{ order.advertiser.email }}</div>
                                <div v-if="order.advertiser.mobile">{{ order.advertiser.mobile }}</div>
                                <div v-if="order.advertiser.address">{{ order.advertiser.address }}</div>
                                <div v-if="order.advertiser.status">{{ order.advertiser.status }}</div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>Booked By</th>
                            <td>
                              <div v-if="order.bookedBy">
                                <div v-if="order.bookedBy.name">{{ order.bookedBy.name }}</div>
                                <div v-if="order.bookedBy.email">{{ order.bookedBy.email }}</div>
                                <div v-if="order.bookedBy.mobile">{{ order.bookedBy.mobile }}</div>
                                <div v-if="order.bookedBy.address">{{ order.bookedBy.address }}</div>
                                <div v-if="order.bookedBy.status">{{ order.bookedBy.status }}</div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="card">
                        <div class="card-content">
                          <div class="field is-grouped">
                            <p
                              class="control"
                              v-if="hasAnyScope('ORDER-LIST')"
                            >
                              <router-link
                                :to="{ name: 'OrderList'}"
                                class="button is-primary"
                              >
                                <span class="icon">
                                  <i class="fas fa-th-list"></i>
                                </span>
                                <span>LIST</span>
                              </router-link>
                            </p>
                            <p
                              class="control"
                              v-if="hasAnyScope('ORDER-ADS-CHANGE')"
                            >
                              <router-link
                                :to="{ name: 'OrderAdsChange', params: { orderId: order.id }}"
                                class="button is-info"
                              >
                                <span class="icon">
                                  <i class="fas fa-heartbeat"></i>
                                </span>
                                <span>CHANGE ADS</span>
                              </router-link>
                            </p>
                            <p
                              class="control"
                              v-if="hasAnyScope('BOOKING-SEARCH')"
                            >
                              <router-link
                                :to="{ name: 'BookingSearch', query: { order: order.id }}"
                                class="button is-warning"
                              >
                                <span class="icon">
                                  <i class="fab fa-cloudversify"></i>
                                </span>
                                <span>REPEAT ORDER</span>
                              </router-link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-two-fifths">
                      <div class="card card-section">
                        <header class="card-header">
                          <p class="card-header-title has-background-primary has-text-white">
                            Advertisement
                          </p>
                        </header>
                        <div class="card-image">
                          <figure class="image is-4by3">
                            <img
                              v-if="!order.advertisement.thumbnails"
                              src="/img/placeholders/image-placeholder.png"
                              alt="Order Thumbnail"
                            >
                            <img
                              v-if="order.advertisement.thumbnails"
                              :src="order.advertisement.thumbnails.large"
                              alt="Order Thumbnail"
                            >
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <order-item-list
                    :order-id="orderId"
                    v-if="hasAnyScope('ORDER-SHOW')" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import OrderItemList from './OrderItemList.vue'
import { mapGetters, mapActions } from 'vuex'
import { ORDER_SHOW } from '@/store/actions/orderActions'

export default {
  name: 'OrderShow',
  components: { Breadcrumb, OrderItemList },
  props: {
    orderId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncOrder'
  },
  data () {
    return {
      pageTitle: 'Order Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-file-invoice-dollar', routeName: 'OrderList', name: 'Manage Orders' }
        ],
        current: { icon: 'fas fa-eye', name: 'Order Details' }
      },
      order: null,
      items: null
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    ...mapGetters('Orders', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Orders', { loadOrder: ORDER_SHOW }),
    loadAsyncOrder () {
      this.order = null
      this.items = null
      this.loadOrder(this.orderId)
        .then((response) => {
          this.order = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Order Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'OrderList' }),
            onConfirm: () => this.$router.replace({ name: 'OrderList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncOrder()
  }
}
</script>
