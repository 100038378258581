export const USER_LIST = 'USER_LIST'
export const USER_CREATE = 'USER_CREATE'
export const USER_UPDATE = 'USER_UPDATE'
export const USER_SHOW = 'USER_SHOW'
export const USER_DELETE = 'USER_DELETE'

export const CHANNEL_LIST = 'CHANNEL_LIST'
export const AGENT_LIST = 'AGENT_LIST'
export const DISTRIBUTOR_LIST = 'DISTRIBUTOR_LIST'
export const ADVERTISER_LIST = 'ADVERTISER_LIST'
export const USER_SUGGENTIONS = 'USER_SUGGENTIONS'
