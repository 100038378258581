<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="card card-section">
      <div class="card-content">
        <form
          @submit.prevent="onStart"
          v-if="form"
        >
          <div class="field">
            <label class="label">Name</label>
            <div class="control">
              <input
                v-model="form.name"
                class="input"
                :class="{'is-danger' : errors.name}"
                type="text"
                placeholder="Enter Place Category Name"
              />
            </div>
            <p
              v-if="errors.name"
              class="help is-danger"
            >{{ errors.name | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Description</label>
            <div class="control">
              <textarea
                v-model="form.description"
                rows="2"
                class="textarea"
                :class="{'is-danger' : errors.description}"
                placeholder="Enter Place Category Description"
              ></textarea>
            </div>
            <p
              v-if="errors.description"
              class="help is-danger"
            >{{ errors.description | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Parent Place Category</label>
            <div class="control">
              <v-select
                v-model="form.parent"
                :options="parents"
                label="name"
                :class="{'is-danger' : errors.parent}"
                placeholder="Pick parent for category"
                class="is-small"
              ></v-select>
            </div>
            <p
              v-if="errors.parent"
              class="help is-danger"
            >{{ errors.parent | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Status</label>
            <div class="control">
              <!-- <div class="control is-expanded has-icons-left"> -->
              <v-select
                v-model="form.status"
                :options="status_options"
                :class="{'is-danger' : errors.status}"
                placeholder="Pick status for category"
                class="is-small"
              ></v-select>
            </div>
            <p
              v-if="errors.status"
              class="help is-danger"
            >{{ errors.status | implode }}</p>
          </div>
          <div class="field is-grouped action-buttons">
            <div class="control">
              <button
                type="submit"
                :disabled="isLoading"
                class="button is-primary is-rounded"
                :class="{ 'is-loading': isLoading }"
              >Submit</button>
            </div>
            <div class="control">
              <button
                type="button"
                @click="onReset"
                class="button is-rounded"
              >Reset</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { CATEGORY_CREATE, CATEGORY_LIST } from '@/store/actions/categoryActions'

export default {
  name: 'CategoryCreate',
  components: {
    Breadcrumb
  },
  data () {
    return {
      pageTitle: 'NewPlace Category Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-location-arrow', routeName: 'CategoryList', name: 'ManagePlace Categories' }
        ],
        current: { icon: 'fas fa-plus', name: 'NewPlace Category' },
        actions: [{ icon: 'fas fa-location-arrow', routeName: 'CategoryList', name: 'ManagePlace Categories' }]
      },
      status_options: ['ENABLE', 'DISABLE'],
      form: null,
      errors: {},
      parents: []
    }
  },
  computed: {
    ...mapGetters('Categories', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Categories', { createCategory: CATEGORY_CREATE, loadParents: CATEGORY_LIST }),
    onStart () {
      this.errors = {}
      this.createCategory(this.form)
        .then((response) => {
          this.$router.push({
            name: 'CategoryEdit',
            params: { categoryId: response.data.id }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'InvalidPlace Category Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onReset () {
      this.errors = {}
      this.form = {
        name: null,
        description: null,
        status: 'ENABLE',
        parent: null,
        groups: []
      }
      this.$notify({
        title: 'Reset Form',
        text: 'Place Category form is reset with default value, please continue...',
        type: 'is-info'
      })
    },
    loadAsyncParents () {
      this.loadParents()
        .then((response) => {
          this.parents = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'ParentPlace Categories Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    this.onReset()
    this.loadAsyncParents()
  }
}
</script>
