import _axios from 'axios'
import Vue from 'vue'
/* import moment from 'moment'; */
import store from '@/store'
import router from '../router'

const API_BASE = process.env.VUE_APP_BASE_URL

const axios = _axios.create({
  baseURL: API_BASE,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

/*
axios.interceptors.request.use((config) => {
  const tempConfig = config;
  if (tempConfig.data !== undefined && tempConfig.data !== null) {
    const cloneData = tempConfig.data;
    Object.keys(cloneData).forEach((key) => {
      const value = cloneData[key];
      if (value instanceof Date) {
        cloneData[key] = moment(value).format('YYYY-MM-DD HH:mm:ss ZZ');
      }
    });
    tempConfig.data = cloneData;
  }
  return tempConfig;
}, (error) => {
  Promise.reject(error);
}); */

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        if (!error.response.config.url.includes('/oauth/token') && !error.response.config.url.includes('/api/me/logout')) {
          Vue.prototype.$notify({
            title: 'Authentication',
            text: 'You are not authenticated for this session, please login to continue...',
            type: 'is-danger'
          })
          store.dispatch('Auth/AUTH_LOGOUT').then(() => {
            router.replace('/auth/login')
          })
        }
      } else if (error.response.status === 403) {
        Vue.prototype.$notify({
          title: 'Authentication',
          text: 'You are not authorized to view this component, redirecting you to dashboard...',
          type: 'is-danger'
        })
        router.replace({
          name: 'Dashboard'
        })
      } else if (error.response.status === 500) {
        Vue.prototype.$notify({
          title: 'Server Error',
          text: 'Something went wrong to server, please try again after sometimes...',
          type: 'is-danger'
        })
      }
    }
    const tempError = {
      response: {
        message: error.message,
        errors: {}
      }
    }
    if (error.response !== undefined) {
      tempError.response = {
        ...tempError.response,
        ...error.response
      }
    }
    return Promise.reject(tempError.response)
  }
)

const setAuthorizationToken = (token) => {
  if (token !== undefined && token !== null && token !== '') {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common.Authorization
  }
}

export {
  axios,
  setAuthorizationToken
}
