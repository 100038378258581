<template>
  <form @submit.prevent="saveGeneralInformation">
    <b-field label="Name">
      <b-input v-model="form.name"></b-input>
    </b-field>
    <b-field>
      <b-radio
        v-model="form.gender"
        native-value="MALE"
      >MALE</b-radio>
      <b-radio
        v-model="form.gender"
        native-value="FEMALE"
      >FEMALE</b-radio>
      <b-radio
        v-model="form.gender"
        native-value="TRANSGENDER"
      >TRANSGENDER</b-radio>
    </b-field>
    <b-field label="Address">
      <b-input
        v-model="form.address"
        maxlength="200"
        type="textarea"
      ></b-input>
    </b-field>
    <div class="field is-grouped action-buttons">
      <div class="control"><button
          type="submit"
          class="button is-primary is-rounded"
        >Save Information</button></div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { PROFILE_REQUEST, STORE_GENERAL_PROFILE } from '@/store/actions/profileActions'
// STORE_GENERAL_PROFILE, STORE_SECURE_PROFILE, STORE_FINANCE_PROFILE

export default {
  name: 'GeneralInformation',
  data () {
    return {
      form: {
        name: '',
        address: '',
        gender: ''
      }
    }
  },
  computed: {
    ...mapGetters('Profile', { user: 'getProfile' })
  },
  methods: {
    ...mapActions('Profile', { saveGeneralProfile: STORE_GENERAL_PROFILE, reLoadProfile: PROFILE_REQUEST }),
    saveGeneralInformation () {
      const loadingComponent = this.$buefy.loading.open({ container: null, canCancel: false })
      this.saveGeneralProfile(this.form)
        .then(() => this.reLoadProfile())
        .then(() => {
          loadingComponent.close()
          this.$buefy.snackbar.open({
            duration: 4000,
            message: 'General information is saved successfully',
            type: 'is-success',
            position: 'is-top'
          })
        })
        .catch((error) => {
          loadingComponent.close()
          this.errors = error.errors
          this.$notify({
            title: 'Invalid General Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    this.form.name = this.user.name
    if (this.user.profiles) {
      this.form.address = this.user.profiles.address
      this.form.gender = this.user.profiles.gender
    }
  }
}
</script>
