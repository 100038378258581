export const DEVICE_LIST = 'DEVICE_LIST'
export const DEVICE_CREATE = 'DEVICE_CREATE'
export const DEVICE_UPDATE = 'DEVICE_UPDATE'
export const DEVICE_RELATION_UPDATE = 'DEVICE_RELATION_UPDATE'
export const DEVICE_PRICE_UPDATE = 'DEVICE_PRICE_UPDATE'
export const DEVICE_SHOW = 'DEVICE_SHOW'
export const DEVICE_SHOW_DETAILS = 'DEVICE_SHOW_DETAILS'
export const DEVICE_DELETE = 'DEVICE_DELETE'
export const DEVICE_THUMBNAIL = 'DEVICE_THUMBNAIL'
export const DEVICE_ADS = 'DEVICE_ADS'
export const DEVICE_TEXT_TO_ADS = 'DEVICE_TEXT_TO_ADS'
export const GALLERY_CREATE = 'GALLERY_CREATE'
export const GALLERY_DELETE = 'GALLERY_DELETE'
export const DEVICE_WEBSITE_USER_LOG_LIST = 'DEVICE_WEBSITE_USER_LOG_LIST'
export const DEVICE_PING_LIST = 'DEVICE_PING_LIST'
export const DEVICE_PING_CREATE = 'DEVICE_PING_CREATE'
export const DEVICE_REPORT_OPERATION = 'DEVICE_REPORT_OPERATION'
export const DEVICE_REPORT_REVENUE = 'DEVICE_REPORT_REVENUE'
export const DEVICE_REPORT_AGGREGATE_OPERATION = 'DEVICE_REPORT_AGGREGATE_OPERATION'
export const DEVICE_REPORT_AGGREGATE_REVENUE = 'DEVICE_REPORT_AGGREGATE_REVENUE'
