<template>
  <b-field
    label="Locations"
    custom-class="is-small"
  >
    <treeselect
      placeholder="Pick Locations"
      value-consists-of="BRANCH_PRIORITY"
      v-model="values"
      :multiple="true"
      :options="options"
      :normalizer="normalizer"
      @input="onValueChange"
    />
  </b-field>
</template>

<script>
import { mapActions } from 'vuex'
import treekMixins from './tree-mixin'
import { LOCATION_TREE } from '@/store/actions/bookingActions'

export default {
  name: 'LocationTree',
  mixins: [treekMixins],
  data () {
    return {
      type: 'Locations'
    }
  },
  methods: {
    ...mapActions('Bookings', { loadOptions: LOCATION_TREE })
  }
}
</script>
