import { axios } from './axios'

export default {
  list (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/tickets', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  create (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/tickets', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  update (ticketId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/tickets/${ticketId}`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  delete (ticketId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/tickets/${ticketId}/cancelled`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
