export default {
  props: ['value'],
  data () {
    return {
      result: null,
      items: [],
      queryStr: null,
      isLoading: false,
      timeoutInterval: null
    }
  },
  watch: {
    value: 'selectFromProps'
  },
  methods: {
    selectFromProps () {
      if (this.value) {
        const eles = []
        if (Array.isArray(this.value)) {
          this.value.forEach(val => {
            eles.push(val)
          })
        } else {
          eles.push(this.value)
        }
        this.values = eles
      }
    },
    loadOptions (data) {
      return new Promise((resolve) => {
        resolve({ data: [] })
      })
    },
    loadOptionsSuggestion () {
      clearTimeout(this.timeoutInterval)
      this.timeoutInterval = setTimeout(() => {
        const data = {
          query: this.queryStr
        }
        if (data.query) {
          this.isLoading = true
          this.loadOptions(data)
            .then((response) => {
              this.items = response.data
              this.isLoading = false
            })
            .catch((error) => {
              this.isLoading = false
              this.$notify({
                title: 'Option Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    },
    onOptionSelection (option) {
      this.result = option.id
      this.$emit('input', this.result)
    }
  }
}
