<template>
  <div>
    <!-- <loading v-show="isListLoading"></loading> -->
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <advance-filter
      :is-loading="isListLoading"
      @onStart="onFilter"
    >
      <div class="columns is-multiline">
        <div class="column field is-3">
          <label class="label is-small">Query</label>
          <p class="control has-icons-left">
            <input
              class="input"
              type="text"
              placeholder="search"
              v-model="filter.query"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-search"></i>
            </span>
          </p>
        </div>
        <div class="column field is-4">
          <label class="label is-small">User Status</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              multiple
              v-model="filter.statuses"
              :options="status_options"
              placeholder="Pick statuses for filter"
              class="is-small"
            ></v-select>
          </div>
        </div>
      </div>
    </advance-filter>

    <div class="card card-section">
      <div class="card-content">
        <b-field
          grouped
          group-multiline
        >
          <b-select
            v-model="filter.size"
            :disabled="!hasItems"
            @input="onFilter"
          >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </b-select>
        </b-field>
        <b-table
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :mobile-cards="true"
          :loading="isListLoading"
          paginated
          backend-pagination
          :per-page="filter.size"
          :total="meta.total"
          @page-change="onPageChange"
          backend-sorting
          default-sort-direction="desc"
          :default-sort="[filter.sortField, filter.sortOrder]"
          @sort="onSort"
          :data="items"
        >
          <b-table-column
            label="S.No."
            width="40"
            numeric
            v-slot="props"
          >
            {{ meta.from + props.index }}
          </b-table-column>

          <b-table-column
            field="name"
            label="User"
            sortable
            v-slot="props"
          >
            {{ props.row.name }}
          </b-table-column>

          <b-table-column
            field="email"
            label="E-Mail"
            sortable
            v-slot="props"
          >
            {{ props.row.email }}
          </b-table-column>
          <b-table-column
            field="mobile"
            label="Mobile"
            v-slot="props"
          >
            {{ props.row.mobile }}
          </b-table-column>
          <b-table-column
            field="status"
            label="Status"
            sortable
            v-slot="props"
          >
            {{ props.row.status }}
          </b-table-column>
          <b-table-column
            label=""
            v-slot="props"
          >
            <b-dropdown position="is-bottom-left">
              <button
                class="button is-primary"
                slot="trigger"
              >
                <span>Actions</span>
                <b-icon
                  icon="chevron-down"
                  size="is-small"
                ></b-icon>
              </button>
              <b-dropdown-item has-link>
                <router-link :to="{ name: 'MyAgentWallet', params: { agentId: props.row.id }}">
                  <span class="icon">
                    <i class="fas fa-wallet"></i>
                  </span>
                  <span>WALLET</span>
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item has-link>
                <router-link :to="{ name: 'MyAgentShow', params: { agentId: props.row.id }}">
                  <span class="icon">
                    <i class="fas fa-eye"></i>
                  </span>
                  <span>DETAILS</span>
                </router-link>
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="emoticon-sad"
                    size="is-large"
                  >
                  </b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import { mapActions } from 'vuex'
import { AGENT_LIST } from '@/store/actions/myActions'

export default {
  name: 'MyAgentList',
  components: {
    Breadcrumb,
    AdvanceFilter
  },
  data () {
    return {
      pageTitle: 'Manage Agents',
      breadcrumb: {
        list: [{ icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' }],
        current: { icon: 'fas fa-user-shield', name: 'Agents' }
      },
      status_options: ['ACTIVE', 'BANNED', 'EXPIRED'],
      filter: {},
      items: [],
      meta: {},
      fetchListStatus: ''
    }
  },
  computed: {
    isListLoading () {
      return this.fetchListStatus === 'AGENT_LOADING'
    },
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    }
  },
  methods: {
    ...mapActions('MyActions', { loadAgentList: AGENT_LIST }),
    onFilter () {
      this.loadAsyncData()
    },
    onPageChange (page) {
      this.filter.page = page
      this.loadAsyncData()
    },
    onSort (field, order) {
      this.filter.sortField = field
      this.filter.sortOrder = order
      this.loadAsyncData()
    },
    loadAsyncData () {
      if (!this.isListLoading) {
        this.fetchListStatus = 'AGENT_LOADING'
        const data = this.filter
        this.loadAgentList(data)
          .then((response) => {
            this.items = response.data
            this.meta = response.meta
            this.fetchListStatus = 'AGENT_SUCCESS'
          })
          .catch((error) => {
            this.fetchListStatus = 'AGENT_ERROR'
            this.$notify({
              title: 'Agnet Loading Failed',
              text: error.message,
              type: 'is-danger'
            })
          })
      }
    },
    reset () {
      this.filter = {
        statuses: [],
        query: '',
        page: 1,
        size: 10,
        sortField: 'updated_at',
        sortOrder: 'desc'
      }
      this.items = []
      this.meta = {
        total: 0
      }
      this.onFilter()
    }
  },
  created () {
    this.reset()
  }
}
</script>
