import {
  CATEGORY_LIST,
  CATEGORY_CREATE,
  CATEGORY_SHOW,
  CATEGORY_THUMBNAIL,
  CATEGORY_UPDATE,
  CATEGORY_DELETE,
  CATEGORY_TREE_SHOW,
  CATEGORY_TREE_UPDATE
} from '../../actions/categoryActions'

import { LOADING, SUCCESS, ERROR, LOADING_THUMBNAIL, SUCCESS_THUMBNAIL, ERROR_THUMBNAIL } from '../../actions/requestStatus'
import categoryApi from '../../../api/categoryApi'

export default {
  namespaced: true,

  state: {
    status: '',
    thumbnailStatus: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR,
    isThumbnailLoading: (state) => state.thumbnailStatus === LOADING_THUMBNAIL,
    isThumbnailSuccess: (state) => state.thumbnailStatus === SUCCESS_THUMBNAIL,
    isThumbnailError: (state) => state.thumbnailStatus === ERROR_THUMBNAIL
  },

  actions: {
    [CATEGORY_THUMBNAIL]: ({ commit }, { categoryId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING_THUMBNAIL)
        categoryApi
          .uploadThumbnail(categoryId, formData)
          .then((response) => {
            commit(SUCCESS_THUMBNAIL)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR_THUMBNAIL)
            reject(error)
          })
      }),

    [CATEGORY_SHOW]: ({ commit }, categoryId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        categoryApi
          .show(categoryId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [CATEGORY_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        categoryApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [CATEGORY_CREATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        categoryApi
          .create(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [CATEGORY_UPDATE]: ({ commit }, { categoryId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        categoryApi
          .update(categoryId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [CATEGORY_DELETE]: ({ commit }, categoryId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        categoryApi
          .delete(categoryId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [CATEGORY_TREE_SHOW]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        categoryApi
          .tree()
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [CATEGORY_TREE_UPDATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        categoryApi
          .treeUpdate(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    },
    [LOADING_THUMBNAIL]: (state) => {
      state.thumbnailStatus = LOADING
    },
    [SUCCESS_THUMBNAIL]: (state) => {
      state.thumbnailStatus = SUCCESS
    },
    [ERROR_THUMBNAIL]: (state) => {
      state.thumbnailStatus = ERROR
    }
  }
}
