import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import SecureModule from './pages/layouts/secure/SecureModule'
import BasicModule from './pages/layouts/basic/BasicModule'
import HelpDesk from './pages/common/HelpDesk'
import Login from './pages/auth/Login'
import Register from './pages/auth/Register'
import Dashboard from './pages/secure/Dashboard'
import MyProfile from './pages/secure/MyProfile'
import UserModule from './pages/secure/users/UserModule'
import PageNotFound from './pages/errors/PageNotFound'
import UserList from './pages/secure/users/UserList'
import UserShow from './pages/secure/users/UserShow'
import UserWallet from './pages/secure/users/UserWallet'
import UserEdit from './pages/secure/users/UserEdit'
import UserCreate from './pages/secure/users/UserCreate'
import TicketModule from './pages/secure/tickets/TicketModule'
import TicketList from './pages/secure/tickets/TicketList'
import TicketCreate from './pages/secure/tickets/TicketCreate'
import RoleModule from './pages/secure/roles/RoleModule'
import RoleList from './pages/secure/roles/RoleList'
import RoleShow from './pages/secure/roles/RoleShow'
import RoleEdit from './pages/secure/roles/RoleEdit'
import RoleCreate from './pages/secure/roles/RoleCreate'
import SettingModule from './pages/secure/settings/SettingModule'
import SettingList from './pages/secure/settings/SettingList'
import SettingShow from './pages/secure/settings/SettingShow'
import SettingEdit from './pages/secure/settings/SettingEdit'
import SettingCreate from './pages/secure/settings/SettingCreate'
import NavModule from './pages/secure/navs/NavModule'
import NavList from './pages/secure/navs/NavList'
import NavShow from './pages/secure/navs/NavShow'
import NavEdit from './pages/secure/navs/NavEdit'
import NavCreate from './pages/secure/navs/NavCreate'
import NavTree from './pages/secure/navs/NavTree'
import DeviceWindowModule from './pages/secure/devicewindows/DeviceWindowModule'
import DeviceWindowList from './pages/secure/devicewindows/DeviceWindowList'
import DeviceWindowShow from './pages/secure/devicewindows/DeviceWindowShow'
import DeviceWindowEdit from './pages/secure/devicewindows/DeviceWindowEdit'
import DeviceWindowCreate from './pages/secure/devicewindows/DeviceWindowCreate'
import LocationModule from './pages/secure/locations/LocationModule'
import LocationList from './pages/secure/locations/LocationList'
import LocationTree from './pages/secure/locations/LocationTree'
import LocationShow from './pages/secure/locations/LocationShow'
import LocationEdit from './pages/secure/locations/LocationEdit'
import LocationCreate from './pages/secure/locations/LocationCreate'
import CampaignModule from './pages/secure/campaigns/CampaignModule'
import CampaignList from './pages/secure/campaigns/CampaignList'
import CampaignShow from './pages/secure/campaigns/CampaignShow'
import CampaignEdit from './pages/secure/campaigns/CampaignEdit'
import CampaignCreate from './pages/secure/campaigns/CampaignCreate'
import CampaignStart from './pages/secure/campaigns/CampaignStart'
import WebsiteModule from './pages/secure/websites/WebsiteModule'
import WebsiteList from './pages/secure/websites/WebsiteList'
import WebsiteShow from './pages/secure/websites/WebsiteShow'
import WebsiteEdit from './pages/secure/websites/WebsiteEdit'
import WebsiteCreate from './pages/secure/websites/WebsiteCreate'
import TagModule from './pages/secure/tags/TagModule'
import TagList from './pages/secure/tags/TagList'
import TagTree from './pages/secure/tags/TagTree'
import TagShow from './pages/secure/tags/TagShow'
import TagEdit from './pages/secure/tags/TagEdit'
import TagCreate from './pages/secure/tags/TagCreate'
import DeviceModule from './pages/secure/devices/DeviceModule'
import DeviceList from './pages/secure/devices/DeviceList'
import DeviceShow from './pages/secure/devices/DeviceShow'
import DeviceEdit from './pages/secure/devices/DeviceEdit'
import DeviceRevenueReport from './pages/secure/devices/DeviceRevenueReport'
import DeviceOperationReport from './pages/secure/devices/DeviceOperationReport'
import DeviceAds from './pages/secure/devices/DeviceAds'
import DeviceCreate from './pages/secure/devices/DeviceCreate'
import DevicePingList from './pages/secure/devices/DevicePingList'
import DeviceTemperatureList from './pages/secure/devices/DeviceTemperatureList'
import DeviceDiskList from './pages/secure/devices/DeviceDiskList'
import DeviceWebsiteUserLogs from './pages/secure/devices/DeviceWebsiteUserLogs'
import ReportRevenue from './pages/secure/reports/ReportRevenue'
import ReportOperation from './pages/secure/reports/ReportOperation'
import CategoryModule from './pages/secure/categories/CategoryModule'
import CategoryList from './pages/secure/categories/CategoryList'
import CategoryTree from './pages/secure/categories/CategoryTree'
import CategoryShow from './pages/secure/categories/CategoryShow'
import CategoryEdit from './pages/secure/categories/CategoryEdit'
import CategoryCreate from './pages/secure/categories/CategoryCreate'
import SliderModule from './pages/secure/sliders/SliderModule'
import SliderList from './pages/secure/sliders/SliderList'
import SliderShow from './pages/secure/sliders/SliderShow'
import SliderEdit from './pages/secure/sliders/SliderEdit'
import SliderCreate from './pages/secure/sliders/SliderCreate'
import MediaModule from './pages/secure/media/MediaModule'
import MediaList from './pages/secure/media/MediaList'
import MediaShow from './pages/secure/media/MediaShow'
import MediaEdit from './pages/secure/media/MediaEdit'
import MediaCreate from './pages/secure/media/MediaCreate'
import PageModule from './pages/secure/pages/PageModule'
import PageList from './pages/secure/pages/PageList'
import PageShow from './pages/secure/pages/PageShow'
import PageEdit from './pages/secure/pages/PageEdit'
import PageCreate from './pages/secure/pages/PageCreate'
import AdvertisementModule from './pages/secure/advertisements/AdvertisementModule'
import AdvertisementList from './pages/secure/advertisements/AdvertisementList'
import AdvertisementShow from './pages/secure/advertisements/AdvertisementShow'
import AdvertisementEdit from './pages/secure/advertisements/AdvertisementEdit'
import AdvertisementCreate from './pages/secure/advertisements/AdvertisementCreate'
import TextAdvertisementModule from './pages/secure/textadvertisements/TextAdvertisementModule'
import TextAdvertisementList from './pages/secure/textadvertisements/TextAdvertisementList'
import TextAdvertisementShow from './pages/secure/textadvertisements/TextAdvertisementShow'
import TextAdvertisementEdit from './pages/secure/textadvertisements/TextAdvertisementEdit'
import TextAdvertisementCreate from './pages/secure/textadvertisements/TextAdvertisementCreate'

import BookingSearch from './pages/secure/bookings/BookingSearch'

import ReservationModule from './pages/secure/reservations/ReservationModule'
import ReservationList from './pages/secure/reservations/ReservationList'

import OrderModule from './pages/secure/orders/OrderModule'
import OrderList from './pages/secure/orders/OrderList'
import OrderShow from './pages/secure/orders/OrderShow'
import OrderAdsChange from './pages/secure/orders/OrderAdsChange'

import MyTicketList from './pages/myassets/tickets/MyTicketList'
import MyDeviceModule from './pages/myassets/devices/MyDeviceModule'
import MyDeviceList from './pages/myassets/devices/MyDeviceList'
import MyDeviceShow from './pages/myassets/devices/MyDeviceShow'
import MyDeviceAds from './pages/myassets/devices/MyDeviceAds'
import MyDeviceEdit from './pages/myassets/devices/MyDeviceEdit'
import MyDevicePingList from './pages/myassets/devices/MyDevicePingList'
import MyDeviceAdsHistoryList from './pages/myassets/devices/MyDeviceAdsHistoryList'
import MyDeviceRevenueReport from './pages/myassets/devices/MyDeviceRevenueReport'

import MyAgentModule from './pages/myassets/agents/MyAgentModule'
import MyAgentList from './pages/myassets/agents/MyAgentList'
import MyAgentShow from './pages/myassets/agents/MyAgentShow'
import MyAgentWallet from './pages/myassets/agents/MyAgentWallet'

import MyAdvertisementModule from './pages/myassets/advertisements/MyAdvertisementModule'
import MyAdvertisementList from './pages/myassets/advertisements/MyAdvertisementList'
import MyAdvertisementShow from './pages/myassets/advertisements/MyAdvertisementShow'
import MyAdvertisementEdit from './pages/myassets/advertisements/MyAdvertisementEdit'
import MyAdvertisementCreate from './pages/myassets/advertisements/MyAdvertisementCreate'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: 'is-open-active',
  linkExactActiveClass: 'is-active',
  routes: [
    { path: '', redirect: { name: 'Dashboard' }, meta: { requiresAuth: true } },
    {
      path: '/auth',
      component: BasicModule,
      children: [
        {
          path: '', redirect: { name: 'Login' }
        },
        {
          path: 'login', name: 'Login', component: Login, meta: { redirectIfAuthenticated: true }, props: (route) => ({ redirect: route.query.redirect })
        },
        {
          path: 'register', name: 'Register', component: Register, meta: { redirectIfAuthenticated: true }
        },
        {
          path: 'help-desk', name: 'FrontendHelpDesk', component: HelpDesk, meta: { redirectIfAuthenticated: false }
        }
      ]
    },
    {
      path: '/secure',
      component: SecureModule,
      children: [
        { path: '', redirect: { name: 'Dashboard' }, meta: { requiresAuth: true } },
        {
          path: 'help-desk', name: 'SecureHelpDesk', component: HelpDesk, meta: { requiresAuth: true }
        },
        {
          path: 'dashboard', name: 'Dashboard', component: Dashboard, meta: { requiresAuth: true }
        },
        {
          path: 'my-profile', name: 'MyProfile', component: MyProfile, meta: { requiresAuth: true }
        },
        {
          path: 'booking/search', name: 'BookingSearch', component: BookingSearch, meta: { requiresAuth: true, requireScope: 'BOOKING-SEARCH' }
        },
        {
          path: 'reports',
          component: OrderModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: 'revenue', name: 'ReportRevenue', component: ReportRevenue, props: true, meta: { requiresAuth: true, requireScope: 'REPORT-DEVICE-REVENUE' }
            },
            {
              path: 'operation', name: 'ReportOperation', component: ReportOperation, props: true, meta: { requiresAuth: true, requireScope: 'REPORT-DEVICE-OPERATION' }
            }
          ]
        },
        {
          path: 'orders',
          component: OrderModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'OrderList', component: OrderList, meta: { requiresAuth: true, requireScope: 'ORDER-LIST' }
            },
            {
              path: ':orderId/detail', name: 'OrderShow', component: OrderShow, props: true, meta: { requiresAuth: true, requireScope: 'ORDER-SHOW' }
            },
            {
              path: ':orderId/ads-change', name: 'OrderAdsChange', component: OrderAdsChange, props: true, meta: { requiresAuth: true, requireScope: 'ORDER-ADS-CHANGE' }
            }
          ]
        },
        {
          path: 'reservations',
          component: ReservationModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'ReservationList', component: ReservationList, meta: { requiresAuth: true, requireScope: 'RESERVATION-LIST' }
            }
          ]
        },
        {
          path: 'users',
          component: UserModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'UserList', component: UserList, meta: { requiresAuth: true, requireScope: 'USER-LIST' }
            },
            {
              path: 'create', name: 'UserCreate', component: UserCreate, meta: { requiresAuth: true, requireScope: 'USER-CREATE' }
            },
            {
              path: ':userId/detail', name: 'UserShow', component: UserShow, props: true, meta: { requiresAuth: true, requireScope: 'USER-SHOW' }
            },
            {
              path: ':userId/wallet', name: 'UserWallet', component: UserWallet, props: true, meta: { requiresAuth: true, requireScope: 'USER-WALLET-SHOW' }
            },
            {
              path: ':userId/edit', name: 'UserEdit', component: UserEdit, props: true, meta: { requiresAuth: true, requireScope: 'USER-UPDATE' }
            }
          ]
        },
        {
          path: 'roles',
          component: RoleModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'RoleList', component: RoleList, meta: { requiresAuth: true, requireScope: 'ROLE-LIST' }
            },
            {
              path: 'create', name: 'RoleCreate', component: RoleCreate, meta: { requiresAuth: true, requireScope: 'ROLE-CREATE' }
            },
            {
              path: ':roleId/detail', name: 'RoleShow', component: RoleShow, props: true, meta: { requiresAuth: true, requireScope: 'ROLE-SHOW' }
            },
            {
              path: ':roleId/edit', name: 'RoleEdit', component: RoleEdit, props: true, meta: { requiresAuth: true, requireScope: 'ROLE-UPDATE' }
            }
          ]
        },
        {
          path: 'categories',
          component: CategoryModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'CategoryList', component: CategoryList, meta: { requiresAuth: true, requireScope: 'CATEGORY-LIST' }
            },
            {
              path: 'tree', name: 'CategoryTree', component: CategoryTree, meta: { requiresAuth: true, requireScope: 'CATEGORY-UPDATE' }
            },
            {
              path: 'create', name: 'CategoryCreate', component: CategoryCreate, meta: { requiresAuth: true, requireScope: 'CATEGORY-CREATE' }
            },
            {
              path: ':categoryId/detail', name: 'CategoryShow', component: CategoryShow, props: true, meta: { requiresAuth: true, requireScope: 'CATEGORY-SHOW' }
            },
            {
              path: ':categoryId/edit', name: 'CategoryEdit', component: CategoryEdit, props: true, meta: { requiresAuth: true, requireScope: 'CATEGORY-UPDATE' }
            }
          ]
        },
        {
          path: 'device-windows',
          component: DeviceWindowModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'DeviceWindowList', component: DeviceWindowList, meta: { requiresAuth: true, requireScope: 'DEVICE-WINDOW-LIST' }
            },
            {
              path: 'create', name: 'DeviceWindowCreate', component: DeviceWindowCreate, meta: { requiresAuth: true, requireScope: 'DEVICE-WINDOW-CREATE' }
            },
            {
              path: ':deviceWindowId/detail', name: 'DeviceWindowShow', component: DeviceWindowShow, props: true, meta: { requiresAuth: true, requireScope: 'DEVICE-WINDOW-SHOW' }
            },
            {
              path: ':deviceWindowId/edit', name: 'DeviceWindowEdit', component: DeviceWindowEdit, props: true, meta: { requiresAuth: true, requireScope: 'DEVICE-WINDOW-UPDATE' }
            }
          ]
        },
        {
          path: 'locations',
          component: LocationModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'LocationList', component: LocationList, meta: { requiresAuth: true, requireScope: 'LOCATION-LIST' }
            },
            {
              path: 'tree', name: 'LocationTree', component: LocationTree, meta: { requiresAuth: true, requireScope: 'LOCATION-UPDATE' }
            },
            {
              path: 'create', name: 'LocationCreate', component: LocationCreate, meta: { requiresAuth: true, requireScope: 'LOCATION-CREATE' }
            },
            {
              path: ':locationId/detail', name: 'LocationShow', component: LocationShow, props: true, meta: { requiresAuth: true, requireScope: 'LOCATION-SHOW' }
            },
            {
              path: ':locationId/edit', name: 'LocationEdit', component: LocationEdit, props: true, meta: { requiresAuth: true, requireScope: 'LOCATION-UPDATE' }
            }
          ]
        },
        {
          path: 'campaigns',
          component: CampaignModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'CampaignList', component: CampaignList, meta: { requiresAuth: true, requireScope: 'CAMPAIGN-LIST' }
            },
            {
              path: 'create', name: 'CampaignCreate', component: CampaignCreate, meta: { requiresAuth: true, requireScope: 'CAMPAIGN-CREATE' }
            },
            {
              path: ':campaignId/detail', name: 'CampaignShow', component: CampaignShow, props: true, meta: { requiresAuth: true, requireScope: 'CAMPAIGN-SHOW' }
            },
            {
              path: ':campaignId/edit', name: 'CampaignEdit', component: CampaignEdit, props: true, meta: { requiresAuth: true, requireScope: 'CAMPAIGN-UPDATE' }
            },
            {
              path: ':campaignId/apply', name: 'CampaignStart', component: CampaignStart, props: true, meta: { requiresAuth: true, requireScope: 'CAMPAIGN-START' }
            }
          ]
        },
        {
          path: 'websites',
          component: WebsiteModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'WebsiteList', component: WebsiteList, meta: { requiresAuth: true, requireScope: 'WEBSITE-LIST' }
            },
            {
              path: 'create', name: 'WebsiteCreate', component: WebsiteCreate, meta: { requiresAuth: true, requireScope: 'WEBSITE-CREATE' }
            },
            {
              path: ':websiteId/detail', name: 'WebsiteShow', component: WebsiteShow, props: true, meta: { requiresAuth: true, requireScope: 'WEBSITE-SHOW' }
            },
            {
              path: ':websiteId/edit', name: 'WebsiteEdit', component: WebsiteEdit, props: true, meta: { requiresAuth: true, requireScope: 'WEBSITE-UPDATE' }
            }
          ]
        },
        {
          path: 'tags',
          component: TagModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'TagList', component: TagList, meta: { requiresAuth: true, requireScope: 'TAG-LIST' }
            },
            {
              path: 'tree', name: 'TagTree', component: TagTree, meta: { requiresAuth: true, requireScope: 'TAG-UPDATE' }
            },
            {
              path: 'create', name: 'TagCreate', component: TagCreate, meta: { requiresAuth: true, requireScope: 'TAG-CREATE' }
            },
            {
              path: ':tagId/detail', name: 'TagShow', component: TagShow, props: true, meta: { requiresAuth: true, requireScope: 'TAG-SHOW' }
            },
            {
              path: ':tagId/edit', name: 'TagEdit', component: TagEdit, props: true, meta: { requiresAuth: true, requireScope: 'TAG-UPDATE' }
            }
          ]
        },
        {
          path: 'devices',
          component: DeviceModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'DeviceList', component: DeviceList, meta: { requiresAuth: true, requireScope: 'DEVICE-LIST' }
            },
            {
              path: 'create', name: 'DeviceCreate', component: DeviceCreate, meta: { requiresAuth: true, requireScope: 'DEVICE-CREATE' }
            },
            {
              path: ':deviceId/detail', name: 'DeviceShow', component: DeviceShow, props: true, meta: { requiresAuth: true, requireScope: 'DEVICE-SHOW' }
            },
            {
              path: ':deviceId/edit', name: 'DeviceEdit', component: DeviceEdit, props: true, meta: { requiresAuth: true, requireScope: 'DEVICE-UPDATE' }
            },
            {
              path: ':deviceId/ads', name: 'DeviceAds', component: DeviceAds, props: true, meta: { requiresAuth: true, requireScope: 'DEVICE-UPDATE' }
            },
            {
              path: ':deviceId/reports/revenue', name: 'DeviceRevenueReport', component: DeviceRevenueReport, props: true, meta: { requiresAuth: true, requireScope: 'REPORT-DEVICE-REVENUE' }
            },
            {
              path: ':deviceId/reports/operation', name: 'DeviceOperationReport', component: DeviceOperationReport, props: true, meta: { requiresAuth: true, requireScope: 'REPORT-DEVICE-OPERATION' }
            },
            {
              path: ':deviceId/temperatures', name: 'DeviceTemperatureList', component: DeviceTemperatureList, props: true, meta: { requiresAuth: true, requireScope: 'DEVICE-SHOW' }
            },
            {
              path: ':deviceId/disks', name: 'DeviceDiskList', component: DeviceDiskList, props: true, meta: { requiresAuth: true, requireScope: 'DEVICE-SHOW' }
            },
            {
              path: ':deviceId/pings', name: 'DevicePingList', component: DevicePingList, props: true, meta: { requiresAuth: true, requireScope: 'DEVICE-SHOW' }
            },
            {
              path: ':deviceId/website-user-logs', name: 'DeviceWebsiteUserLogs', component: DeviceWebsiteUserLogs, props: true, meta: { requiresAuth: true, requireScope: 'DEVICE-KIOSK-WEBSITE-USER-LOGS-LIST' }
            }
          ]
        },
        {
          path: 'advertisements',
          component: AdvertisementModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'AdvertisementList', component: AdvertisementList, meta: { requiresAuth: true, requireScope: 'ADVERTISEMENT-LIST' }
            },
            {
              path: 'create', name: 'AdvertisementCreate', component: AdvertisementCreate, meta: { requiresAuth: true, requireScope: 'ADVERTISEMENT-CREATE' }
            },
            {
              path: ':advertisementId/detail', name: 'AdvertisementShow', component: AdvertisementShow, props: true, meta: { requiresAuth: true, requireScope: 'ADVERTISEMENT-SHOW' }
            },
            {
              path: ':advertisementId/edit', name: 'AdvertisementEdit', component: AdvertisementEdit, props: true, meta: { requiresAuth: true, requireScope: 'ADVERTISEMENT-UPDATE' }
            }
          ]
        },
        {
          path: 'text-advertisements',
          component: TextAdvertisementModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'TextAdvertisementList', component: TextAdvertisementList, meta: { requiresAuth: true, requireScope: 'TEXT-ADVERTISEMENT-LIST' }
            },
            {
              path: 'create', name: 'TextAdvertisementCreate', component: TextAdvertisementCreate, meta: { requiresAuth: true, requireScope: 'TEXT-ADVERTISEMENT-CREATE' }
            },
            {
              path: ':advertisementId/detail', name: 'TextAdvertisementShow', component: TextAdvertisementShow, props: true, meta: { requiresAuth: true, requireScope: 'TEXT-ADVERTISEMENT-SHOW' }
            },
            {
              path: ':advertisementId/edit', name: 'TextAdvertisementEdit', component: TextAdvertisementEdit, props: true, meta: { requiresAuth: true, requireScope: 'TEXT-ADVERTISEMENT-UPDATE' }
            }
          ]
        },
        {
          path: 'sliders',
          component: SliderModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'SliderList', component: SliderList, meta: { requiresAuth: true, requireScope: 'SLIDER-LIST' }
            },
            {
              path: 'create', name: 'SliderCreate', component: SliderCreate, meta: { requiresAuth: true, requireScope: 'SLIDER-CREATE' }
            },
            {
              path: ':sliderId/detail', name: 'SliderShow', component: SliderShow, props: true, meta: { requiresAuth: true, requireScope: 'SLIDER-SHOW' }
            },
            {
              path: ':sliderId/edit', name: 'SliderEdit', component: SliderEdit, props: true, meta: { requiresAuth: true, requireScope: 'SLIDER-UPDATE' }
            }
          ]
        },
        {
          path: 'media-library',
          component: MediaModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'MediaList', component: MediaList, meta: { requiresAuth: true, requireScope: 'MEDIA-LIST' }
            },
            {
              path: 'create', name: 'MediaCreate', component: MediaCreate, meta: { requiresAuth: true, requireScope: 'MEDIA-CREATE' }
            },
            {
              path: ':mediaId/detail', name: 'MediaShow', component: MediaShow, props: true, meta: { requiresAuth: true, requireScope: 'MEDIA-SHOW' }
            },
            {
              path: ':mediaId/edit', name: 'MediaEdit', component: MediaEdit, props: true, meta: { requiresAuth: true, requireScope: 'MEDIA-UPDATE' }
            }
          ]
        },
        {
          path: 'pages',
          component: PageModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'PageList', component: PageList, meta: { requiresAuth: true, requireScope: 'PAGE-LIST' }
            },
            {
              path: 'create', name: 'PageCreate', component: PageCreate, meta: { requiresAuth: true, requireScope: 'PAGE-CREATE' }
            },
            {
              path: ':pageId/detail', name: 'PageShow', component: PageShow, props: true, meta: { requiresAuth: true, requireScope: 'PAGE-SHOW' }
            },
            {
              path: ':pageId/edit', name: 'PageEdit', component: PageEdit, props: true, meta: { requiresAuth: true, requireScope: 'PAGE-UPDATE' }
            }
          ]
        },
        {
          path: 'tickets',
          component: TicketModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'TicketList', component: TicketList, meta: { requiresAuth: true, requireScope: 'TICKET-LIST' }
            },
            {
              path: 'create', name: 'TicketCreate', component: TicketCreate, meta: { requiresAuth: true, requireScope: 'TICKET-CREATE' }
            }
          ]
        },
        {
          path: 'settings',
          component: SettingModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'SettingList', component: SettingList, meta: { requiresAuth: true, requireScope: 'SETTING-LIST' }
            },
            {
              path: 'create', name: 'SettingCreate', component: SettingCreate, meta: { requiresAuth: true, requireScope: 'SETTING-CREATE' }
            },
            {
              path: ':settingId/detail', name: 'SettingShow', component: SettingShow, props: true, meta: { requiresAuth: true, requireScope: 'SETTING-SHOW' }
            },
            {
              path: ':settingId/edit', name: 'SettingEdit', component: SettingEdit, props: true, meta: { requiresAuth: true, requireScope: 'SETTING-UPDATE' }
            }
          ]
        },
        {
          path: 'navs',
          component: NavModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'NavList', component: NavList, meta: { requiresAuth: true, requireScope: 'NAV-LIST' }
            },
            {
              path: 'create', name: 'NavCreate', component: NavCreate, meta: { requiresAuth: true, requireScope: 'NAV-CREATE' }
            },
            {
              path: ':navId/detail', name: 'NavShow', component: NavShow, props: true, meta: { requiresAuth: true, requireScope: 'NAV-SHOW' }
            },
            {
              path: ':navId/edit', name: 'NavEdit', component: NavEdit, props: true, meta: { requiresAuth: true, requireScope: 'NAV-UPDATE' }
            },
            {
              path: 'tree', name: 'NavTree', component: NavTree, meta: { requiresAuth: true, requireScope: 'NAV-UPDATE' }
            }
          ]
        },
        {
          path: 'my-devices/:userView',
          component: MyDeviceModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'MyDeviceList', component: MyDeviceList, props: true, meta: { requiresAuth: true }
            },
            {
              path: ':deviceId/detail', name: 'MyDeviceShow', component: MyDeviceShow, props: true, meta: { requiresAuth: true }
            },
            {
              path: ':deviceId/ads', name: 'MyDeviceAds', component: MyDeviceAds, props: true, meta: { requiresAuth: true }
            },
            {
              path: ':deviceId/edit', name: 'MyDeviceEdit', component: MyDeviceEdit, props: true, meta: { requiresAuth: true }
            },
            {
              path: ':deviceId/pings', name: 'MyDevicePingList', component: MyDevicePingList, props: true, meta: { requiresAuth: true }
            },
            {
              path: ':deviceId/ads-history', name: 'MyDeviceAdsHistoryList', component: MyDeviceAdsHistoryList, props: true, meta: { requiresAuth: true }
            },
            {
              path: ':deviceId/reports/revenue', name: 'MyDeviceRevenueReport', component: MyDeviceRevenueReport, props: true, meta: { requiresAuth: true, requireScope: 'REPORT-DEVICE-REVENUE' }
            }
          ]
        },
        {
          path: 'my-video-ads',
          component: MyAdvertisementModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'MyAdvertisementList', component: MyAdvertisementList, meta: { requiresAuth: true }
            },
            {
              path: 'create', name: 'MyAdvertisementCreate', component: MyAdvertisementCreate, meta: { requiresAuth: true }
            },
            {
              path: ':advertisementId/detail', name: 'MyAdvertisementShow', component: MyAdvertisementShow, props: true, meta: { requiresAuth: true }
            },
            {
              path: ':advertisementId/edit', name: 'MyAdvertisementEdit', component: MyAdvertisementEdit, props: true, meta: { requiresAuth: true }
            }
          ]
        },
        {
          path: 'my-agents',
          component: MyAgentModule,
          meta: { requiresAuth: true },
          children: [
            {
              path: '', name: 'MyAgentList', component: MyAgentList, meta: { requiresAuth: true }
            },
            {
              path: ':agentId/detail', name: 'MyAgentShow', component: MyAgentShow, props: true, meta: { requiresAuth: true }
            },
            {
              path: ':agentId/wallet', name: 'MyAgentWallet', component: MyAgentWallet, props: true, meta: { requiresAuth: true }
            }
          ]
        },
        {
          path: 'my-tickets', name: 'MyTicketList', component: MyTicketList, meta: { requiresAuth: true }
        }
      ]
    },
    {
      path: '/*',
      component: BasicModule,
      children: [{ path: '', component: PageNotFound }]
    }
  ]
})

router.beforeEach((to, from, next) => {
  Vue.prototype.$eventHub.$emit('next-route')
  let defaultNextChanged = false
  document.documentElement.classList.remove('is-clipped-touch')
  if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters['Auth/isAuthenticated']) {
    Vue.prototype.$notify({
      title: 'Authentication',
      text: 'You are not authenticated for this session, please login to continue...',
      type: 'is-danger'
    })
    defaultNextChanged = true
    next({ path: '/auth/login', query: { redirect: to.fullPath } })
  }
  /* if (to.matched.some((record) => record.meta.requireScope) && !store.getters['Profile/hasScope'](to.meta.requireScope)) {
    Vue.prototype.$notify({
      title: 'Un-Authorized Access',
      text: 'You are not authorized to view this component, redirecting you to dashboard...',
      type: 'is-danger',
    });
    defaultNextChanged = true;
    next({ path: '/secure' });
  } */
  if (to.matched.some((record) => record.meta.redirectIfAuthenticated) && store.getters['Auth/isAuthenticated']) {
    Vue.prototype.$notify({
      title: 'Authentication',
      text: 'You are already authenticated for this session, redirecting you to dashboard...',
      type: 'is-info'
    })
    defaultNextChanged = true
    next({ path: '/secure' })
  }
  if (!defaultNextChanged) {
    next()
  }
})

export default router
