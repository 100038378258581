<template>
  <section class="hero is-primary is-bold">
    <div class="hero-body">
      <div class="container">
        <div class="column is-6 is-offset-3">
          <div class="card white has-card-deep-shadow">
            <header class="card-header">
              <p class="card-header-title">
                <img
                  class="logo-card-header"
                  src="@/assets/logo.png"
                />
              </p>
            </header>
            <div class="card-content">
              <div class="columns">
                <div class="column">
                  <div class="">
                    <form @submit.prevent="login">
                      <div class="field">
                        <label class="label">Mobile/Email</label>
                        <div class="control has-icons-left has-icons-right">
                          <input
                            v-model="email"
                            class="input"
                            type="text"
                            placeholder="Enter your mobile or email address"
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-user"></i>
                          </span>
                          <span class="icon is-small is-right">
                            <!-- <i class="fas fa-check"></i> -->
                          </span>
                        </div>
                        <!-- <p class="help">You can use email or mobile as your username</p> -->
                        <p
                          class="help"
                          :class="{'is-success' : isSuccess, 'is-danger' : isDanger}"
                        >{{ message }}</p>
                      </div>
                      <div class="field">
                        <label class="label">Password
                          <!-- <a class="is-pulled-right has-text-weight-light is-italic has-text-info" href="#">Forgot Password</a> -->
                        </label>
                        <div class="control has-icons-left has-icons-right">
                          <input
                            v-model="password"
                            class="input"
                            type="password"
                            placeholder="Enter your password"
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-user"></i>
                          </span>
                          <span class="icon is-small is-right">
                            <!-- <i class="fas fa-check"></i> -->
                          </span>
                        </div>
                        <!-- <p class="help">You can use email or mobile as your username</p>
                      <p class="help is-success">This username is available</p>
                      <p class="help is-danger">Username can not be empty</p> -->
                      </div>
                      <div class="field">
                        <div class="control">
                          <label class="checkbox">
                            <input
                              type="checkbox"
                              v-model="rememberMe"
                            >
                            Remember Me
                          </label>
                        </div>
                      </div>
                      <div class="field is-grouped">
                        <div class="control">
                          <button
                            :disabled="isLoading"
                            class="button is-link"
                            :class="{ 'is-loading': isLoading }"
                          >Submit</button>
                        </div>
                        <div class="control">
                          <button class="button is-outlined">Cancel</button>
                        </div>
                      </div>
                    </form>
                    <!-- <div class="link-blocks">
                    <p class="has-text-link has-text-centered">
                      <router-link :to="{ name: 'Register'}">
                        Sign Up
                      </router-link>
                      &nbsp;&ndash;&nbsp;
                      <a href="#">Need Help?</a>
                    </p>
                  </div> -->
                  </div>
                </div>
                <!-- <div class="column">
                <div class="button-group">
                  <a class="button is-primary is-fullwidth">
                    <span class="icon">
                      <i class="fab fa-facebook-f"></i>
                    </span>
                    <span>Sign in with Facebook</span>
                  </a>
                  <a class="button is-link is-fullwidth">
                    <span class="icon">
                      <i class="fab fa-google-plus-g"></i>
                    </span>
                    <span>Sign in with Google+</span>
                  </a>
                  <a class="button is-info is-fullwidth">
                    <span class="icon">
                      <i class="fab fa-twitter"></i>
                    </span>
                    <span>Sign in with Twitter</span>
                  </a>
                  <a class="button is-success is-fullwidth">
                    <span class="icon">
                      <i class="fab fa-github"></i>
                    </span>
                    <span>Sign in with Github</span>
                  </a>
                </div>
              </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AUTH_REQUEST } from '@/store/actions/authActions'

export default {
  name: 'Login',
  props: {
    redirect: null
  },
  data () {
    return {
      email: '',
      password: '',
      rememberMe: false,
      message: ''
    }
  },
  computed: {
    ...mapGetters('Auth', ['authStatus']),
    isLoading () {
      return this.authStatus === 'loading'
    },
    isSuccess () {
      return this.authStatus === 'success'
    },
    isDanger () {
      return this.authStatus === 'error'
    }
  },
  methods: {
    ...mapActions('Auth', { loginAction: AUTH_REQUEST }),
    login () {
      const { email, password, rememberMe } = this
      this.loginAction({ email, password, rememberMe })
        .then(() => {
          this.$notify({
            title: 'Authentication',
            text: 'You are successfully login for this session...',
            type: 'is-success'
          })
          if (this.redirect !== undefined) {
            this.$router.push({ path: this.redirect })
          } else {
            this.$router.push('/')
          }
        })
        .catch((error) => {
          this.message = error.message
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
