import { TICKET_LIST, TICKET_CREATE, TICKET_PROCESSED, TICKET_DELETE } from '../../actions/ticketActions'
import { LOADING, SUCCESS, ERROR } from '../../actions/requestStatus'
import ticketApi from '../../../api/ticketApi'

/* , TICKET_CREATE, TICKET_UPDATE, TICKET_SHOW, TICKET_DELETE */

export default {
  namespaced: true,

  state: {
    status: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR
  },

  actions: {
    [TICKET_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        ticketApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [TICKET_CREATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        ticketApi
          .create(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [TICKET_PROCESSED]: ({ commit }, { ticketId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        ticketApi
          .update(ticketId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [TICKET_DELETE]: ({ commit }, { ticketId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        ticketApi
          .delete(ticketId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    }
  }
}
