import {
  NAV_LIST,
  NAV_CREATE,
  NAV_SHOW,
  NAV_UPDATE,
  NAV_DELETE,
  NAV_TREE_SHOW,
  NAV_TREE_UPDATE
} from '../../actions/navActions'

import { LOADING, SUCCESS, ERROR } from '../../actions/requestStatus'
import navApi from '../../../api/navApi'

/* , NAV_CREATE, NAV_UPDATE, NAV_SHOW, NAV_DELETE */

export default {
  namespaced: true,

  state: {
    status: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR
  },

  actions: {
    [NAV_SHOW]: ({ commit }, navId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        navApi
          .show(navId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [NAV_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        navApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [NAV_CREATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        navApi
          .create(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [NAV_UPDATE]: ({ commit }, { navId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        navApi
          .update(navId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [NAV_DELETE]: ({ commit }, navId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        navApi
          .delete(navId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [NAV_TREE_SHOW]: ({ commit }, groupName) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        navApi
          .tree(groupName)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [NAV_TREE_UPDATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        navApi
          .treeUpdate(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    }
  }
}
