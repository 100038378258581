<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="card card-section"
      v-if="campaign"
    >
      <div class="card-content">
        <b-tabs v-model="activeTab">
          <b-tab-item
            label="General Properties"
            v-if="hasAnyScope(['CAMPAIGN-UPDATE', 'CAMPAIGN-UPDATE-GENERAL'])"
          >
            <campaign-general
              :campaign="campaign"
              @on-campaign-update="onCampaignUpdate"
            ></campaign-general>
          </b-tab-item>
          <b-tab-item
            label="Relationships"
            v-if="hasAnyScope(['CAMPAIGN-UPDATE-RELATIONS'])"
          >
            <campaign-links
              :campaign="campaign"
              :categoryTree="categoryTree"
              :locationTree="locationTree"
              :tagTree="tagTree"
            ></campaign-links>
          </b-tab-item>
          <b-tab-item
            label="Edit Display"
            v-if="campaign && hasAnyScope(['CAMPAIGN-UPDATE-DISPLAY'])"
          >
            <campaign-single-screen-kiosk
              v-if="hasSingleScreenKiosk"
              @on-display-update="onDisplayUpdate"
              :campaign="campaign"
            />
            <campaign-screen-with-side-kiosk
              v-else-if="hasScreenWithRightSideKiosk"
              @on-display-update="onDisplayUpdate"
              :campaign="campaign"
            />
            <campaign-screen-with-interactive-kiosk
              v-else-if="hasScreenWithInteractiveKiosk"
              @on-display-update="onDisplayUpdate"
              :campaign="campaign"
            />
            <campaign-screen-with-custom-kiosk
              v-else-if="hasCustomScreenKiosk"
              @on-display-update="onDisplayUpdate"
              :campaign="campaign"
            />
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import CampaignGeneral from './CampaignGeneral'
import CampaignSingleScreenKiosk from './kiosks/CampaignSingleScreenKiosk'
import CampaignScreenWithInteractiveKiosk from './kiosks/CampaignScreenWithInteractiveKiosk'
import CampaignScreenWithSideKiosk from './kiosks/CampaignScreenWithSideKiosk'
import CampaignScreenWithCustomKiosk from './kiosks/CampaignScreenWithCustomKiosk'

import CampaignLinks from './CampaignLinks'
import { CAMPAIGN_SHOW } from '@/store/actions/campaignActions'
import { LOCATION_TREE_SHOW } from '@/store/actions/locationActions'
import { CATEGORY_TREE_SHOW } from '@/store/actions/categoryActions'
import { TAG_TREE_SHOW } from '@/store/actions/tagActions'

export default {
  name: 'CampaignEdit',
  components: {
    Breadcrumb,
    CampaignGeneral,
    CampaignLinks,
    CampaignSingleScreenKiosk,
    CampaignScreenWithSideKiosk,
    CampaignScreenWithInteractiveKiosk,
    CampaignScreenWithCustomKiosk
  },
  props: {
    campaignId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncCampaign'
  },
  data () {
    return {
      activeTab: 0,
      pageTitle: 'Edit Campaign Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-desktop', routeName: 'CampaignList', name: 'Manage Campaigns' }
        ],
        current: { icon: 'fas fa-edit', name: 'Edit Campaign' },
        actions: [
          { icon: 'fas fa-desktop', routeName: 'CampaignList', name: 'Manage Campaigns' },
          {
            icon: 'fas fa-eye', routeName: 'CampaignShow', params: { campaignId: this.campaignId }, name: 'Display Campaign'
          }
        ]
      },
      campaign: null,
      categoryTree: [],
      locationTree: [],
      tagTree: []
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    ...mapGetters('Campaigns', ['isLoading']),
    hasSingleScreenKiosk () {
      return this.campaign.type === 'SINGLE-SCREEN-KIOSK'
    },
    hasScreenWithRightSideKiosk () {
      return this.campaign.type === 'SCREEN-WITH-RIGHT-SIDE-KIOSK'
    },
    hasScreenWithInteractiveKiosk () {
      return this.campaign.type === 'INTERACTIVE-KIOSK'
    },
    hasCustomScreenKiosk () {
      return this.campaign.type === 'CUSTOM-KIOSK'
    }
  },
  methods: {
    ...mapActions('Campaigns', { loadCampaign: CAMPAIGN_SHOW }),
    ...mapActions('Locations', { loadLocationTree: LOCATION_TREE_SHOW }),
    ...mapActions('Categories', { loadCategoryTree: CATEGORY_TREE_SHOW }),
    ...mapActions('Tags', { loadTagTree: TAG_TREE_SHOW }),
    onDisplayUpdate () {
      this.campaign.status = 'IDEAL'
    },
    loadAsyncCategoryTree () {
      this.loadCategoryTree()
        .then((response) => {
          this.categoryTree = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Category Tree Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncLocationTree () {
      this.loadLocationTree()
        .then((response) => {
          this.locationTree = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Place Category Tree Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncTagTree () {
      this.loadTagTree()
        .then((response) => {
          this.tagTree = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Tag Tree Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncCampaign () {
      this.campaign = null
      this.loadCampaign(this.campaignId)
        .then((response) => {
          this.campaign = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Campaign Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'CampaignList' }),
            onConfirm: () => this.$router.replace({ name: 'CampaignList' })
          })
        })
    },
    onCampaignUpdate () {

    }
  },
  created () {
    this.loadAsyncCampaign()
    this.loadAsyncCategoryTree()
    this.loadAsyncLocationTree()
    this.loadAsyncTagTree()
  }
}
</script>
