import { axios } from './axios'

export default {
  uploadThumbnail (categoryId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/categories/${categoryId}/thumbnail`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/categories', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  show (categoryId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/categories/${categoryId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  create (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/categories', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  update (categoryId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/categories/${categoryId}`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  delete (categoryId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/categories/${categoryId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  tree () {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/categories/tree')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  treeUpdate (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/categories/tree', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
