<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="card card-section"
      v-if="slider"
    >
      <div class="card-content">
        <b-tabs v-model="activeTab">
          <b-tab-item label="General Properties">
            <slider-general :slider="slider"></slider-general>
          </b-tab-item>
          <b-tab-item label="Manage Slides">
            <slider-gallery :slider="slider"></slider-gallery>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import SliderGeneral from './SliderGeneral'
import SliderGallery from './SliderGallery'
import { SLIDER_SHOW } from '@/store/actions/sliderActions'

export default {
  name: 'SliderEdit',
  components: {
    Breadcrumb,
    SliderGeneral,
    SliderGallery
  },
  props: {
    sliderId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncSlider'
  },
  data () {
    return {
      activeTab: 0,
      pageTitle: 'Edit Slider Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-clone', routeName: 'SliderList', name: 'Manage Sliders' }
        ],
        current: { icon: 'fas fa-edit', name: 'Edit Slider' },
        actions: [
          { icon: 'fas fa-clone', routeName: 'SliderList', name: 'Manage Sliders' },
          {
            icon: 'fas fa-eye', routeName: 'SliderShow', params: { sliderId: this.sliderId }, name: 'Display Slider'
          }
        ]
      },
      slider: null
    }
  },
  computed: {
    ...mapGetters('Sliders', ['isLoading'])
  },
  methods: {
    ...mapActions('Sliders', { loadSlider: SLIDER_SHOW }),
    loadAsyncSlider () {
      this.slider = null
      this.loadSlider(this.sliderId)
        .then((response) => {
          this.slider = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Slider Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'SliderList' }),
            onConfirm: () => this.$router.replace({ name: 'SliderList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncSlider()
  }
}
</script>
