<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div v-if="device">
      <div class="columns">
        <div class="column">
          <div class="card card-section">
            <header class="card-header has-background-primary">
              <p class="card-header-title">
                Device Ads Form
              </p>
            </header>
            <div class="card-content">
              <div class="field">
                <label class="label">Type</label>
                <div class="control">
                  <v-select
                    v-model="form.ads_type"
                    :options="ads_types"
                    placeholder="Pick ads type for ads"
                    class="is-small"
                  ></v-select>
                </div>
              </div>
              <div v-if="isVideoUploadForm">
                <div class="file has-name is-fullwidth is-primary">
                  <label class="file-label">
                    <input
                      class="file-input"
                      type="file"
                      name="adsFile"
                      ref="adsFile"
                      @change="onAdsChange"
                      :disabled="isAdsLoading"
                    />
                    <span class="file-cta">
                      <span class="file-icon">
                        <i class="fas fa-upload"></i>
                      </span>
                      <span class="file-label">
                        Choose a file…
                      </span>
                    </span>
                  </label>
                </div>
              </div>
              <div v-else>
                <form @submit.prevent="uploadTextToImage">
                  <div class="field">
                    <label class="label">Ads Title</label>
                    <div class="control">
                      <textarea
                        v-model="form.ads_title"
                        class="input"
                        style="min-height: 100px"
                        placeholder="Enter Ads Title"
                      ></textarea>
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Ads Sub-Title</label>
                    <div class="control">
                      <textarea
                        v-model="form.ads_subtitle"
                        class="input"
                        style="min-height: 100px"
                        placeholder="Enter Ads Sub-Title"
                      ></textarea>
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Ads Text</label>
                    <div class="control">
                      <textarea
                        v-model="form.ads_text"
                        class="input"
                        style="min-height: 100px"
                        placeholder="Enter Ads Text"
                      ></textarea>
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Background Color</label>
                    <div class="control">
                      <color-picker
                        :color="form.ads_bg_color"
                        v-model="form.ads_bg_color"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Title Color</label>
                    <div class="control">
                      <color-picker
                        :color="form.ads_title_color"
                        v-model="form.ads_title_color"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Sub-Title Color</label>
                    <div class="control">
                      <color-picker
                        :color="form.ads_subtitle_color"
                        v-model="form.ads_subtitle_color"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Text Color</label>
                    <div class="control">
                      <color-picker
                        :color="form.ads_text_color"
                        v-model="form.ads_text_color"
                      />
                    </div>
                  </div>
                  <div class="field saction-buttons">
                    <div class="control">
                      <button
                        type="submit"
                        class="button is-primary is-rounded"
                        :disabled="isLoading"
                        :class="{ 'is-loading': isLoading }"
                      >Upload Ads</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div
            class="card card-section"
            v-if="hasAnyScope(['DEVICE-UPDATE-ADS'])"
          >
            <header class="card-header">
              <p class="card-header-title has-background-primary has-text-white">
                Ads
              </p>
            </header>
            <div class="card-image">
              <figure
                class="image is-4by3"
                v-if="isVideoUploadForm"
              >
                <img
                  v-if="!device.ads"
                  src="/img/placeholders/image-placeholder.png"
                  alt="Device Thumbnail"
                >
                <img
                  v-if="device.ads"
                  :src="device.ads.thumbnail"
                  alt="Device Ads Thumbnail"
                >
              </figure>
              <div
                class="has-text-centered"
                style="width: 100%; min-height: 200px; padding: 50px;"
                :style="`background-color: ${form.ads_bg_color}`"
                v-else
              >
                <h1 class="is-title is-size-3">{{ form.ads_title }}</h1>
                <h3 class="is-title is-size-5">{{ form.ads_subtitle }}</h3>
                <p class="is-title is-size-7">{{ form.ads_text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import ColorPicker from '@/lib/ColorPicker'
import { DEVICE_SHOW, DEVICE_ADS, DEVICE_TEXT_TO_ADS } from '@/store/actions/deviceActions'

export default {
  name: 'DeviceAds',
  components: {
    Breadcrumb,
    ColorPicker
  },
  props: {
    deviceId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncDevice'
  },
  data () {
    return {
      activeTab: 0,
      pageTitle: 'Edit Device Ads',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-desktop', routeName: 'DeviceList', name: 'Manage Devices' },
          {
            icon: 'fas fa-eye', routeName: 'DeviceShow', params: { deviceId: this.deviceId }, name: 'Display Device'
          }
        ],
        current: { icon: 'fas fa-edit', name: 'Update Device Ads' },
        actions: [
          { icon: 'fas fa-desktop', routeName: 'DeviceList', name: 'Manage Devices' },
          {
            icon: 'fas fa-eye', routeName: 'DeviceShow', params: { deviceId: this.deviceId }, name: 'Display Device'
          }
        ]
      },
      form: {},
      ads_types: ['UPLOAD VIDEO ADS', 'TEXT TO VIDEO ADS'],
      device: null,

      adsStatus: '',
      LOADING_ADS: 'LOADING_ADS',
      SUCCESS_ADS: 'SUCCESS_ADS',
      ERROR_ADS: 'ERROR_ADS',

      show_bg_picker: false,
      show_fg_picker: false
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    ...mapGetters('Devices', ['isLoading']),
    isVideoUploadForm () {
      return this.form && this.form.ads_type === 'UPLOAD VIDEO ADS'
    },
    isAdsLoading () {
      return this.adsStatus === this.LOADING_ADS
    },
    isAdsSuccess () {
      return this.adsStatus === this.SUCCESS_ADS
    },
    isAdsError () {
      return this.adsStatus === this.ERROR_ADS
    }
  },
  methods: {
    ...mapActions('Devices', { loadDevice: DEVICE_SHOW, uploadAds: DEVICE_ADS, uploadTextToImageService: DEVICE_TEXT_TO_ADS }),
    loadAsyncDevice () {
      this.device = null
      this.loadDevice(this.deviceId)
        .then((response) => {
          this.device = {
            id: response.data.id,
            ads: response.data.ads
          }
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Device Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'DeviceList' }),
            onConfirm: () => this.$router.replace({ name: 'DeviceList' })
          })
        })
    },
    uploadTextToImage () {
      const data = {
        deviceId: this.deviceId,
        formData: this.form
      }
      this.uploadTextToImageService(data)
        .then((response) => {
          this.device.ads.thumbnail = response.data.thumbnail
          this.$notify({
            title: 'Ads Success',
            text: 'Ads uploaded successfully',
            type: 'is-success'
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Page Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    reset () {
      this.form = {
        ads_type: 'UPLOAD VIDEO ADS',
        ads_title: null,
        ads_subtitle: null,
        ads_text: null,
        ads_bg_color: '#000000',
        ads_title_color: '#ffffff',
        ads_subtitle_color: '#ffffff',
        ads_text_color: '#ffffff'
      }
    },
    onAdsChange () {
      const adsFiles = this.$refs.adsFile
      if (!adsFiles || !adsFiles.files.length) {
        return
      }
      this.adsName = adsFiles.files[0].name
      this.uploadDeviceAds(adsFiles.files[0])
    },
    uploadDeviceAds (ads) {
      const adsFormData = new FormData()
      adsFormData.set('ads', ads)
      const data = {
        deviceId: this.device.id,
        formData: adsFormData
      }
      this.uploadAds(data)
        .then((response) => {
          this.device.ads = response.data
          this.$notify({
            title: 'Device Ads',
            text: response.message,
            type: 'is-success'
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Device Thumbnail Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  },
  mounted () {
    this.loadAsyncDevice()
    this.reset()
  }
}
</script>
