<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="card card-section"
      v-if="category"
    >
      <div class="card-content">
        <form @submit.prevent="onStart">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Name</label>
                <div class="control">
                  <input
                    v-model="category.name"
                    class="input"
                    :class="{'is-danger' : errors.name}"
                    type="text"
                    placeholder="Enter Place Name"
                  />
                </div>
                <p
                  v-if="errors.name"
                  class="help is-danger"
                >{{ errors.name | implode }}</p>
              </div>
              <div class="field">
                <label class="label">Description</label>
                <div class="control">
                  <textarea
                    v-model="category.description"
                    rows="2"
                    class="textarea"
                    :class="{'is-danger' : errors.description}"
                    placeholder="Enter Place Description"
                  ></textarea>
                </div>
                <p
                  v-if="errors.description"
                  class="help is-danger"
                >{{ errors.description | implode }}</p>
              </div>
              <div class="field">
                <label class="label">Parent Place</label>
                <div class="control">
                  <v-select
                    v-model="category.parent"
                    :options="parents"
                    label="name"
                    :class="{'is-danger' : errors.parent}"
                    placeholder="Pick parent for category"
                    class="is-small"
                  ></v-select>
                </div>
                <p
                  v-if="errors.parent"
                  class="help is-danger"
                >{{ errors.parent | implode }}</p>
              </div>
              <div class="field">
                <label class="label">Status</label>
                <div class="control">
                  <!-- <div class="control is-expanded has-icons-left"> -->
                  <v-select
                    v-model="category.status"
                    :options="status_options"
                    :class="{'is-danger' : errors.status}"
                    placeholder="Pick status for category"
                    class="is-small"
                  ></v-select>
                </div>
                <p
                  v-if="errors.status"
                  class="help is-danger"
                >{{ errors.status | implode }}</p>
              </div>
            </div>
            <div class="column is-two-fifths">
              <div class="card card-section">
                <div class="card-content">
                  <div class="card">
                    <div class="card-image">
                      <figure class="image is-4by3">
                        <img
                          v-if="!category.thumbnails"
                          src="/img/placeholders/image-placeholder.png"
                          alt="Page Thumbnail"
                        >
                        <img
                          v-if="category.thumbnails"
                          :src="category.thumbnails.large"
                          alt="Page Thumbnail"
                        >
                      </figure>
                      <div
                        v-show="isThumbnailLoading"
                        class="card-image-text-ontop has-background-primary"
                      >
                        <i class="fas fa-cloud-upload-alt"></i> Uploading...<i class="fas fa-spinner fa-spin"></i>
                      </div>
                      <div
                        v-show="isThumbnailSuccess"
                        class="card-image-text-ontop has-background-success"
                      >
                        <i class="fas fa-cloud-upload-alt"></i> Uploaded to server...
                      </div>
                      <div
                        v-show="isThumbnailError"
                        class="card-image-text-ontop has-background-danger"
                      >
                        <i class="fas fa-cloud-upload-alt"></i> Failed to upload on server...
                      </div>
                    </div>
                    <div class="card-content">
                      <div class="file has-name is-fullwidth is-primary">
                        <label class="file-label">
                          <input
                            class="file-input"
                            type="file"
                            name="thumbnailFile"
                            ref="thumbnailFile"
                            @change="onThumbnailChange"
                            :disabled="isThumbnailLoading"
                          />
                          <span class="file-cta">
                            <span class="file-icon">
                              <i class="fas fa-upload"></i>
                            </span>
                            <span class="file-label">
                              Choose a file…
                            </span>
                          </span>
                          <span class="file-name">
                            {{ thumbnailName }}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-grouped action-buttons">
            <div class="control">
              <button
                type="submit"
                :disabled="isLoading"
                class="button is-primary is-rounded"
                :class="{ 'is-loading': isLoading }"
              >Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { CATEGORY_SHOW, CATEGORY_UPDATE, CATEGORY_LIST, CATEGORY_THUMBNAIL } from '@/store/actions/categoryActions'

export default {
  name: 'CategoryEdit',
  components: {
    Breadcrumb
  },
  props: {
    categoryId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncCategory'
  },
  data () {
    return {
      pageTitle: 'Edit Place Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-location-arrow', routeName: 'CategoryList', name: 'Manage Place Categories' }
        ],
        current: { icon: 'fas fa-edit', name: 'Edit Place' },
        actions: [
          { icon: 'fas fa-location-arrow', routeName: 'CategoryList', name: 'Manage Place Categories' },
          {
            icon: 'fas fa-eye', routeName: 'CategoryShow', params: { categoryId: this.categoryId }, name: 'Display Place'
          }
        ]
      },
      status_options: ['ENABLE', 'DISABLE'],
      category: null,
      errors: {},
      parents: [],
      thumbnailName: null
    }
  },
  computed: {
    ...mapGetters('Categories', ['isLoading', 'isSuccess', 'isError', 'isThumbnailLoading', 'isThumbnailSuccess', 'isThumbnailError'])
  },
  methods: {
    ...mapActions('Categories', {
      loadCategory: CATEGORY_SHOW,
      updateCategory: CATEGORY_UPDATE,
      uploadThumbnail: CATEGORY_THUMBNAIL,
      loadParents: CATEGORY_LIST
    }),
    onThumbnailChange () {
      const thumbnailFiles = this.$refs.thumbnailFile
      if (!thumbnailFiles || !thumbnailFiles.files.length) {
        return
      }
      this.thumbnailName = thumbnailFiles.files[0].name
      this.createImage(thumbnailFiles.files[0])
      this.uploadPageThumbnail(thumbnailFiles.files[0])
    },
    createImage (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.category.thumbnails = { large: e.target.result }
      }
      reader.readAsDataURL(file)
    },
    uploadPageThumbnail (thumbnail) {
      const thumbnailFormData = new FormData()
      thumbnailFormData.set('thumbnail', thumbnail)
      const data = {
        categoryId: this.category.id,
        formData: thumbnailFormData
      }
      this.uploadThumbnail(data)
        .then((response) => {
          this.$notify({
            title: 'Place Category Thumbnail',
            text: response.message,
            type: 'is-success'
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Place Thumbnail Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onStart () {
      this.errors = {}
      const data = {
        categoryId: this.categoryId,
        formData: this.category
      }
      this.updateCategory(data)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.name}) Place is updated successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Place',
            onAction: () => {
              this.$router.push({
                name: 'CategoryList'
              })
            }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Place Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncCategory () {
      this.category = null
      this.loadCategory(this.categoryId)
        .then((response) => {
          this.category = {
            id: response.data.id,
            name: response.data.name,
            description: response.data.description,
            status: response.data.status,
            parent: response.data.parent,
            groups: response.data.groups,
            thumbnails: response.data.thumbnails
          }
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Place Category Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'CategoryList' }),
            onConfirm: () => this.$router.replace({ name: 'CategoryList' })
          })
        })
    },
    loadAsyncParents () {
      this.loadParents()
        .then((response) => {
          this.parents = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Parent Place Categories Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    this.loadAsyncParents()
    this.loadAsyncCategory()
  }
}
</script>
