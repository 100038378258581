<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="card card-section">
      <div class="card-content">
        <div class="field">
          <label class="label">Name</label>
          <div class="control">
            <input
              v-model="form.name"
              class="input"
              type="text"
              placeholder="Enter Advertisement Name"
              :class="{'is-danger' : errors.name}"
            />
          </div>
          <p
            v-if="errors.name"
            class="help is-danger"
          >{{ errors.name | implode }}</p>
        </div>
        <div class="field">
          <label class="label">Ads Duration (Secs)</label>
          <div class="control">
            <input
              v-model="form.duration"
              class="input"
              type="text"
              placeholder="Enter Advertisement Duration"
              :class="{'is-danger' : errors.duration}"
            />
          </div>
          <p
            v-if="errors.duration"
            class="help is-danger"
          >{{ errors.duration | implode }}</p>
        </div>
        <div class="field">
          <label class="label">Desc</label>
          <div class="control">
            <textarea
              v-model="form.desc"
              class="textarea"
              placeholder="Enter Advertisement Desc"
              :class="{'is-danger' : errors.desc}"
            ></textarea>
          </div>
          <p
            v-if="errors.desc"
            class="help is-danger"
          >{{ errors.desc | implode }}</p>
        </div>
        <b-field label="Advertiser">
          <b-autocomplete
            placeholder="Type advertiser to search and select"
            field="name"
            :keep-first="true"
            :data="autocomplete.advertiser.items"
            v-model="autocomplete.advertiser.value"
            :loading="autocomplete.advertiser.isLoading"
            @input="loadAdvertisersSuggestion"
            @select="option => form.advertiser = option"
          >
            <template slot="empty">No advertiser found</template>
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  {{ props.option.name }}
                  <br>
                  <small>
                    {{ props.option.email }},
                  </small>
                </div>
              </div>
            </template>
          </b-autocomplete>
          <p
            v-if="errors.advertiser"
            class="help is-danger"
          >{{ errors.advertiser | implode }}</p>
        </b-field>
        <div class="field">
          <label class="label">Product Tags</label>
          <div class="control">
            <treeselect
              placeholder="Select product tags for the device..."
              value-consists-of="ALL_WITH_INDETERMINATE"
              v-model="form.tags"
              :multiple="true"
              :options="tagTree"
              :normalizer="normalizer"
            ></treeselect>
          </div>
        </div>
        <div class="field">
          <label class="label">Status</label>
          <div class="control">
            <v-select
              v-model="form.status"
              :options="status_options"
              :class="{'is-danger' : errors.status}"
              placeholder="Pick status for advertisement"
              class="is-small"
            ></v-select>
          </div>
          <p
            v-if="errors.status"
            class="help is-danger"
          >{{ errors.status | implode }}</p>
        </div>
        <div class="field is-grouped action-buttons">
          <div class="control">
            <button
              type="button"
              :disabled="isLoading"
              class="button is-primary is-rounded"
              @click="formSubmit"
              :class="{ 'is-loading': isLoading }"
            >Create</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { ADVERTISEMENT_CREATE } from '@/store/actions/advertisementActions'
import { ADVERTISER_LIST } from '@/store/actions/userActions'
import { TAG_TREE_SHOW } from '@/store/actions/tagActions'

export default {
  name: 'AdvertisementCreate',
  components: {
    Breadcrumb, Treeselect
  },
  data () {
    return {
      pageTitle: 'New Advertisement Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fab fa-adversal', routeName: 'AdvertisementList', name: 'Manage Advertisements' }
        ],
        current: { icon: 'fas fa-plus', name: 'New Advertisement' },
        actions: [{ icon: 'fab fa-adversal', routeName: 'AdvertisementList', name: 'Manage Advertisements' }]
      },
      status_options: ['IDEAL', 'ENABLE', 'DISABLE'],
      form: null,
      errors: {},
      autocomplete: {
        advertiser: {
          value: null,
          items: [],
          isLoading: false,
          timeout: null
        }
      },
      tagTree: []
    }
  },
  computed: {
    ...mapGetters('Advertisements', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Tags', { loadTagTree: TAG_TREE_SHOW }),
    ...mapActions('Advertisements', { createAdvertisement: ADVERTISEMENT_CREATE }),
    ...mapActions('Users', { loadAdvertisers: ADVERTISER_LIST }),
    normalizer (node) {
      return {
        id: node.id,
        label: node.name,
        children: node.children
      }
    },
    formSubmit () {
      this.errors = {}
      this.createAdvertisement(this.form)
        .then((response) => {
          this.$notify({
            title: 'Advertisement Saved as Draft',
            text: `(${response.data.name}) Advertisement is created and saved as ${response.data.status} successfully`,
            type: 'is-success'
          })
          this.$router.push({
            name: 'AdvertisementEdit',
            params: { advertisementId: response.data.id }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Advertisement Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAdvertisersSuggestion () {
      clearTimeout(this.autocomplete.advertiser.timeout)
      this.autocomplete.advertiser.timeout = setTimeout(() => {
        const data = {
          query: this.autocomplete.advertiser.value
        }
        if (data.query) {
          this.autocomplete.advertiser.isLoading = true
          this.loadAdvertisers(data)
            .then((response) => {
              this.autocomplete.advertiser.items = response.data
              this.autocomplete.advertiser.isLoading = false
            })
            .catch((error) => {
              this.autocomplete.advertiser.isLoading = false
              this.$notify({
                title: 'Advertiser Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    },
    loadAsyncTagTree () {
      this.loadTagTree()
        .then((response) => {
          this.tagTree = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Tag Tree Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onReset () {
      this.errors = {}
      this.form = {
        name: '',
        desc: '',
        duration: 0,
        status: 'IDEAL',
        advertiser: null
      }
      this.$notify({
        title: 'Reset Form',
        text: 'Advertisement form is reset with default value, please continue...',
        type: 'is-info'
      })
    }
  },
  created () {
    this.onReset()
    this.loadAsyncTagTree()
  }
}
</script>
