<template>
  <section class="secure-module">
    <top-nav-bar
      class="has-shadow is-fixed-top"
      @toggleNav="toggleLeftSideNav"
    ></top-nav-bar>
    <div
      id="secure-wrapper"
      class="wrapper"
      v-if="isProfileLoaded"
    >
      <div class="columns is-gapless">
        <left-aside
          class="has-right-shadow desktop-aside"
          :navs="navs"
          :class="{ 'is-active' : showLeftAside }"
        ></left-aside>
        <div
          id="main-body"
          class="column full-height-minus-header"
          :class="{ 'is-aside-offset' : !showLeftAside, 'is-hide-on-mobile' : showLeftAside }"
        >
          <div class="main-content">
            <router-view></router-view>
          </div>
          <footer class="footer">
            <div class="container-fluid">
              <div class="content has-text-centered">
                <p>
                  <strong>@Copyright 2021 SXM</strong>
                </p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import TopNavBar from './components/navbars/TopNav'
import LeftAside from './components/navbars/Aside'

export default {
  name: 'SecureModule',
  components: {
    'top-nav-bar': TopNavBar,
    'left-aside': LeftAside
  },
  data () {
    return {
      showLeftAside: false
    }
  },
  computed: {
    ...mapGetters('Profile', { user: 'getProfile' }),
    ...mapGetters('Profile', ['isAdvertiser', 'isChannel', 'isDistributor', 'isAgent', 'isAdministrator']),
    ...mapGetters('Auth', { isAuth: 'isAuthenticated' }),
    isProfileLoaded () {
      return this.isAuth && this.user !== null && this.user.name !== undefined
    },
    navs () {
      let result = this.commonNavs
      if (this.isAdministrator) {
        result = [...result, ...this.adminNavs]
      } else {
        if (this.isChannel) {
          result = [...result, ...this.channelNavs]
        }
        if (this.isDistributor) {
          result = [...result, ...this.distributorNavs]
        }
        if (this.isAgent) {
          result = [...result, ...this.agentNavs]
        }
        if (this.isAdvertiser) {
          result = [...result, ...this.advertiserNavs]
        }
      }
      return result
    },
    commonNavs () {
      return [
        {
          label: 'GENERAL',
          children: [
            {
              icon: 'fas fa-th-large',
              name: 'Dashboard',
              routeName: 'Dashboard'
            },
            {
              name: 'My Profile',
              routeName: 'MyTicketList',
              icon: 'fas fa-id-card',
              mobileOnly: true
            }
            // {
            //   name: 'My Tickets',
            //   routeName: 'MyTicketList',
            //   icon: 'fab fa-stack-exchange',
            // },
            // {
            //   name: 'HelpDesk',
            //   routeName: 'SecureHelpDesk',
            //   icon: 'fas fa-question-circle',
            //   mobileOnly: true
            // }
          ]
        }
      ]
    },
    advertiserNavs () {
      return [
        {
          label: 'ADVERTISER VIEW',
          children: [
            {
              name: 'My Ads',
              routeName: 'MyAdvertisementList',
              icon: 'fab fa-adversal'
            },
            /* {
              name: 'Upload Ads',
              routeName: 'MyAdvertisementCreate',
              icon: 'fas fa-cloud-upload-alt'
            }, */
            {
              name: 'Book Your Ads',
              routeName: 'BookingSearch',
              icon: 'fas fa-project-diagram'
            },
            {
              name: 'Ads History',
              routeName: 'ReservationList',
              icon: 'fab fa-researchgate'
            }
          ]
        }
      ]
    },
    agentNavs () {
      return [
        {
          label: 'AGENT VIEW',
          children: [
            {
              name: 'Displays',
              routeName: 'MyDeviceList',
              params: { userView: 'agent' },
              icon: 'fas fa-desktop'
            }
          ]
        }
      ]
    },
    channelNavs () {
      return [
        {
          label: 'CHANNEL VIEW',
          children: [
            {
              name: 'Displays',
              routeName: 'MyDeviceList',
              params: { userView: 'channel' },
              icon: 'fas fa-desktop'
            }
          ]
        }
      ]
    },
    distributorNavs () {
      return [
        {
          label: 'DISTRIBUTOR VIEW',
          children: [
            {
              name: 'Agents',
              routeName: 'MyAgentList',
              icon: 'fas fa-users'
            },
            {
              name: 'Displays',
              routeName: 'MyDeviceList',
              params: { userView: 'distributor' },
              icon: 'fas fa-desktop'
            }
          ]
        }
      ]
    },
    adminNavs () {
      return [
        {
          label: 'ADMINISTRATOR',
          scopes: ['USER-LIST', 'ROLE-LIST', 'TICKET-LIST', 'CATEGORY-LIST', 'LOCATION-LIST', 'TAG-LIST', 'DEVICE-LIST', 'BOOKING-SEARCH', 'RESERVATION-LIST', 'ADVERTISEMENT-LIST', 'TEXT-ADVERTISEMENT-LIST', 'REPORT-DEVICE-REVENUE', 'REPORT-DEVICE-OPERATION', 'SLIDER-LIST', 'MEDIA-LIST', 'PAGE-LIST', 'NAV-LIST', 'SETTING-LIST', 'ORDER-LIST'],
          children: [
            {
              icon: 'fas fa-user-lock',
              name: 'Users & Permissions',
              scopes: ['USER-LIST', 'ROLE-LIST', 'TICKET-LIST'],
              children: [
                {
                  name: 'Users',
                  routeName: 'UserList',
                  icon: 'fas fa-user-shield',
                  scopes: ['USER-LIST']
                },
                {
                  name: 'Roles',
                  routeName: 'RoleList',
                  icon: 'fas fa-users-cog',
                  scopes: ['ROLE-LIST']
                },
                {
                  name: 'User Tickets',
                  routeName: 'TicketList',
                  icon: 'fab fa-stack-exchange',
                  scopes: ['TICKET-LIST']
                }
              ]
            },
            {
              icon: 'fab fa-cloudversify',
              name: 'Booking Resources',
              scopes: ['CATEGORY-LIST', 'LOCATION-LIST', 'TAG-LIST', 'DEVICE-LIST', 'CAMPAIGN-LIST'],
              children: [
                {
                  name: 'Displays',
                  routeName: 'DeviceList',
                  icon: 'fas fa-desktop',
                  scopes: ['DEVICE-LIST']
                },
                {
                  name: 'Place Categories',
                  routeName: 'CategoryList',
                  icon: 'fas fa-location-arrow',
                  scopes: ['LOCATION-LIST']
                },
                {
                  name: 'Locations',
                  routeName: 'LocationList',
                  icon: 'fas fa-globe',
                  scopes: ['LOCATION-LIST']
                },
                {
                  name: 'Ads Category',
                  routeName: 'TagList',
                  icon: 'fas fa-tags',
                  scopes: ['TAG-LIST']
                },
                {
                  name: 'Device Windows',
                  routeName: 'DeviceWindowList',
                  icon: 'fas fa-clock',
                  scopes: ['DEVICE-WINDOW-LIST']
                },
                {
                  name: 'Campaigns',
                  routeName: 'CampaignList',
                  icon: 'fas fa-tablet-alt',
                  SCOPES: ['CAMPAIGN-LIST']
                },
                {
                  name: 'Website/Application',
                  routeName: 'WebsiteList',
                  icon: 'fa fa-edge',
                  SCOPES: ['WEBSITE-LIST']
                }
              ]
            },
            {
              icon: 'fab fa-cloudversify',
              name: 'Booking Engine',
              scopes: ['BOOKING-SEARCH', 'RESERVATION-LIST', 'ADVERTISEMENT-LIST', 'TEXT-ADVERTISEMENT-LIST', 'ORDER-LIST'],
              children: [
                {
                  name: 'Book Your Ads',
                  routeName: 'BookingSearch',
                  icon: 'fas fa-project-diagram',
                  scopes: ['BOOKING-SEARCH']
                },
                {
                  name: 'Ads History',
                  routeName: 'ReservationList',
                  icon: 'fab fa-researchgate',
                  scopes: ['RESERVATION-LIST']
                },
                {
                  name: 'Video Ads',
                  routeName: 'AdvertisementList',
                  icon: 'fab fa-adversal',
                  scopes: ['ADVERTISEMENT-LIST']
                },
                {
                  name: 'Text Ads',
                  routeName: 'TextAdvertisementList',
                  icon: 'fab fa-adversal',
                  scopes: ['TEXT-ADVERTISEMENT-LIST']
                },
                {
                  name: 'Booking Orders',
                  routeName: 'OrderList',
                  icon: 'fas fa-file-invoice-dollar',
                  scopes: ['ORDER-LIST']
                }
              ]
            },
            {
              icon: 'fas fa-chart-pie',
              name: 'Reports',
              scopes: ['REPORT-DEVICE-REVENUE', 'REPORT-DEVICE-OPERATION'],
              children: [
                {
                  name: 'Revenue Report',
                  routeName: 'ReportRevenue',
                  icon: 'fas fa-chart-line',
                  scopes: ['REPORT-DEVICE-REVENUE']
                },
                {
                  name: 'Operation Report',
                  routeName: 'ReportOperation',
                  icon: 'far fa-chart-bar',
                  scopes: ['REPORT-DEVICE-OPERATION']
                }
              ]
            }
          ]
        },
        {
          label: 'Others',
          scopes: ['SLIDER-LIST', 'MEDIA-LIST', 'PAGE-LIST', 'NAV-LIST', 'SETTING-LIST'],
          children: [
            {
              icon: 'fas fa-clone',
              routeName: 'SliderList',
              name: 'Sliders',
              scopes: ['SLIDER-LIST']
            },
            {
              icon: 'fas fa-images',
              routeName: 'MediaList',
              name: 'Media Library',
              scopes: ['MEDIA-LIST']
            },
            {
              icon: 'fas fa-file-alt',
              routeName: 'PageList',
              name: 'Pages',
              scopes: ['PAGE-LIST']
            },
            {
              icon: 'fas fa-road',
              routeName: 'NavList',
              name: 'Navbars',
              scopes: ['NAV-LIST']
            },
            {
              icon: 'fas fa-cogs',
              routeName: 'SettingList',
              name: 'Settings',
              scopes: ['SETTING-LIST']
            }
          ]
        }
      ]
    }
  },
  methods: {
    toggleLeftSideNav () {
      this.showLeftAside = !this.showLeftAside
    }
  }
}
</script>

<style lang="sass">
@import '@/sass/mixins.sass'
.secure-module
  #secure-wrapper
    background: #2e363f
    .desktop-aside
      &:hover
        +desktop
          overflow-y: auto
      +desktop
        width: 17%
        position: fixed
        height: calc(100vh - 3.25rem)
        height: -o-calc(100vh - 3.25px) /* opera */
        height: -webkit-calc(100vh - 3.25rem) /* google, safari */
        height: -moz-calc(100vh - 3.25rem) /* firefox */
        overflow-y: hidden
  #main-body
    background: #f9f9f9
#main-body
  .offsetdevicepick
    top: 3.25rem
    background: #fff
    margin-left: 1.25rem
    margin-right: 2rem
    left: 25%
    right: 0px
    z-index: 1001
  .offsetforms
    top: 3.25rem
    left: 0px
    right: 0px
    margin-right: 2rem
    margin-left: 2rem
    z-index: 1001
  &.is-hide-on-mobile
    +mobile
      display: none
  &.is-aside-offset
    +desktop
      margin-left: 17%
      .offsetforms
        left: 17%
      .offsetdevicepick
        left: 17.9%
  +tablet-only
    margin: 0px !important
  +mobile
    margin: 0px !important
    .offsetdevicepick
      left: 0%
+desktop
  ::-webkit-scrollbar
    width: 6px
  ::-webkit-scrollbar-track
    -webkit-box-shadow: inset 0 0 12px #fbf88e
    border-radius: 10px
  ::-webkit-scrollbar-thumb
    border-radius: 10px
    -webkit-box-shadow: inset 0 0 12px #ff00d4
</style>
