<template>
  <section class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">New Ticket Request</p>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <label class="label">Type</label>
        <div class="control">
          <v-select
            v-model="ticketType"
            :options="type_options"
            placeholder="Pick type of your ticket"
            class="is-small"
            :class="{'is-danger' : errors.type}"
          ></v-select>
          <p
            v-if="errors.type"
            class="help is-danger"
          >{{ errors.type | implode }}</p>
        </div>
      </div>
      <div v-if="this.form.type === 1">
        <div class="field">
          <label class="label">Amount</label>
          <div class="control">
            <input
              v-model="form.amount"
              class="input"
              type="number"
              placeholder="Enter amount to redeem"
              :class="{'is-danger' : errors.amount}"
            />
          </div>
          <p
            v-if="errors.amount"
            class="help is-danger"
          >{{ errors.amount | implode }}</p>
        </div>
      </div>
      <div class="field is-grouped action-buttons">
        <div class="control">
          <button
            type="button"
            :disabled="isApiProcessing"
            class="button is-primary is-rounded"
            @click="formSubmit"
            :class="{ 'is-loading': isApiProcessing }"
          >Submit Request</button>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import { TICKET_CREATE } from '@/store/actions/myActions'

export default {
  name: 'MyTicketCreate',
  data () {
    return {
      type_options: [
        { label: 'REDEEM WALLET MONEY', value: 1 }
      ],
      ticketType: null,
      form: null,
      errors: {},
      isApiProcessing: false
    }
  },
  watch: {
    ticketType: 'onTicketTypeChange'
  },
  methods: {
    ...mapActions('MyActions', { createTicket: TICKET_CREATE }),
    onTicketTypeChange () {
      if (this.ticketType !== undefined && this.ticketType !== null) {
        this.form.type = this.ticketType.value
      } else {
        this.form.type = null
      }
    },
    formSubmit () {
      this.errors = {}
      this.createTicket(this.form)
        .then(() => {
          this.$notify({
            title: 'Your Ticket Sent',
            text: `Your ticket (${this.ticketType.value}) is sent to support team for further processing...`,
            type: 'is-success'
          })
          this.$parent.close()
          this.$emit('ticket-created')
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Request',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onReset () {
      this.errors = {}
      this.form = {
        type: '',
        amount: ''
      }
      this.$notify({
        title: 'Reset Form',
        text: 'Ticket request form is reset with default value, please continue...',
        type: 'is-info'
      })
    }
  },
  created () {
    this.onReset()
  }
}
</script>
