<template>
  <div>
    <!-- <loading v-show="isAgentLoading"></loading> -->
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div v-if="user">
      <card :title="user.name">
        <template slot="header">
          <span class="tag is-success margin-left-10">Your Current Balance <i class="fas fa-rupee-sign margin-left-10 margin-right-5"></i> {{ wallet.alltime_balance }}</span>
        </template>
        <template slot="actions">
          <router-link
            :to="{ name: 'MyAgentShow', params: { agentId: user.id }}"
            class="dropdown-item"
          >
            <span class="icon">
              <i class="fas fa-eye"></i>
            </span>
            <span>AGENT DETAILS</span>
          </router-link>
        </template>

        <div class="tile is-ancestor has-text-centered">
          <div class="tile is-parent">
            <div
              class="tile is-child relative-position"
              :class="{ 'element is-loading' : isInfoLoading}"
            >
              <article class="tile is-child notification is-primary top-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.allTime.deposit }}</p>
                <p class="subtitle is-5">Deposits</p>
              </article>
              <article class="tile is-child notification is-success bottom-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.allTime.withdrawal }}</p>
                <p class="subtitle is-5">Withdrawals</p>
              </article>
              <span class="tag is-link top-right">ALL TIME</span>
            </div>
          </div>
          <div class="tile is-parent">
            <div
              class="tile is-child relative-position"
              :class="{ 'element is-loading' : isInfoLoading}"
            >
              <article class="tile is-child notification is-primary top-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.today.deposit }}</p>
                <p class="subtitle is-5">Deposits</p>
              </article>
              <article class="tile is-child notification is-success bottom-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.today.withdrawal }}</p>
                <p class="subtitle is-5">Withdrawals</p>
              </article>
              <span class="tag is-link top-right">TODAY</span>
            </div>
          </div>
          <div class="tile is-parent">
            <div
              class="tile is-child relative-position"
              :class="{ 'element is-loading' : isInfoLoading}"
            >
              <article class="tile is-child notification is-primary top-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.yesterday.deposit }}</p>
                <p class="subtitle is-5">Deposits</p>
              </article>
              <article class="tile is-child notification is-success bottom-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.yesterday.withdrawal }}</p>
                <p class="subtitle is-5">Withdrawals</p>
              </article>
              <span class="tag is-link top-right">YESTERDAY</span>
            </div>
          </div>
          <div class="tile is-parent">
            <div
              class="tile is-child relative-position"
              :class="{ 'element is-loading' : isInfoLoading}"
            >
              <article class="tile is-child notification is-primary top-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.currentMonth.deposit }}</p>
                <p class="subtitle is-5">Deposits</p>
              </article>
              <article class="tile is-child notification is-success bottom-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.currentMonth.withdrawal }}</p>
                <p class="subtitle is-5">Withdrawals</p>
              </article>
              <span class="tag is-link top-right">CURRENT MONTH</span>
            </div>
          </div>
          <div class="tile is-parent">
            <div
              class="tile is-child relative-position"
              :class="{ 'element is-loading' : isInfoLoading}"
            >
              <article class="tile is-child notification is-primary top-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.lastMonth.deposit }}</p>
                <p class="subtitle is-5">Deposits</p>
              </article>
              <article class="tile is-child notification is-success bottom-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.lastMonth.withdrawal }}</p>
                <p class="subtitle is-5">Withdrawals</p>
              </article>
              <span class="tag is-link top-right">LAST MONTH</span>
            </div>
          </div>
        </div>
      </card>
    </div>
    <advance-filter
      :is-loading="isTransactionsLoading"
      @onStart="onFilter"
    >
      <div class="columns is-multiline">
        <div class="column field is-3">
          <label class="label is-small">Transaction ID</label>
          <p class="control has-icons-left">
            <input
              class="input"
              type="text"
              placeholder="Enter transaction ID"
              v-model="filter.transaction_id"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-search"></i>
            </span>
          </p>
        </div>
        <div class="column field is-4">
          <label class="label is-small">DEPOSIT/WITHDRAWAL</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              multiple
              v-model="filter.types"
              :options="type_options"
              placeholder="Pick types for filter"
              class="is-small"
            ></v-select>
          </div>
        </div>
        <div class="column field is-4">
          <label class="label is-small">Transaction Method</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              placeholder="Pick methods for filter"
              class="is-small"
              multiple
              v-model="filter.transaction_methods"
              :options="method_options"
            ></v-select>
          </div>
        </div>
        <div class="column field is-4">
          <label class="label is-small">Transaction Status</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              placeholder="Pick statuses for filter"
              class="is-small"
              multiple
              v-model="filter.statuses"
              :options="status_options"
            ></v-select>
          </div>
        </div>
      </div>
    </advance-filter>

    <div class="card card-section">
      <div class="card-content">
        <b-field
          grouped
          group-multiline
        >
          <b-select
            v-model="filter.size"
            :disabled="!hasItems"
            @input="onFilter"
          >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </b-select>
        </b-field>
        <b-table
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :mobile-cards="true"
          :loading="isTransactionsLoading"
          paginated
          backend-pagination
          :per-page="filter.size"
          :total="meta.total"
          @page-change="onPageChange"
          backend-sorting
          default-sort-direction="desc"
          :default-sort="[filter.sortField, filter.sortOrder]"
          @sort="onSort"
          :data="items"
        >
          <b-table-column
            label="S.No."
            width="40"
            numeric
            v-slot="props"
          >
            {{ meta.from + props.index }}
          </b-table-column>

          <b-table-column
            field="created_at"
            label="Date Time"
            sortable
            v-slot="props"
          >
            {{ props.row.created_at }}
          </b-table-column>

          <b-table-column
            field="type"
            label="Type"
            sortable
            v-slot="props"
          >
            {{ props.row.type }}
          </b-table-column>
          <b-table-column
            field="amount"
            label="Amount"
            v-slot="props"
          >
            {{ props.row.currency }} {{ props.row.amount }}
          </b-table-column>
          <b-table-column
            field="transaction_id"
            label="Transaction ID"
            v-slot="props"
          >
            {{ props.row.transaction_id }}
          </b-table-column>
          <b-table-column
            label="Transaction Status"
            v-slot="props"
          >
            {{ props.row.status }}
          </b-table-column>
          <b-table-column
            label="Actions"
            v-slot="props"
          >
            <button
              @click="showTransactionRecord(props.row)"
              class="button is-primary is-rounded"
            >DETAILS</button>
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="emoticon-sad"
                    size="is-large"
                  >
                  </b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Card from '@/lib/Card'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import { AGENT_SHOW, AGENT_WALLET_INFO, AGENT_WALLET_TRANSACTION_LIST } from '@/store/actions/myActions'

export default {
  name: 'MyAgentWallet',
  components: {
    Breadcrumb,
    Card,
    AdvanceFilter
  },
  props: {
    agentId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncAgent'
  },
  data () {
    return {
      pageTitle: 'Agent Wallet Details',
      breadcrumb: {
        list: [
          {
            icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard'
          },
          {
            icon: 'fas fa-user-shield', routeName: 'MyAgentList', name: 'Agent List'
          }
        ],
        current: { icon: 'fas fa-wallet', name: 'Agent Wallet' },
        actions: [
          {
            icon: 'fas fa-user-shield', routeName: 'MyAgentList', name: 'Agent List', scope: 'USER-LIST'
          }
        ]
      },
      user: null,
      transaction_record: null,
      wallet: {
        alltime_balance: 0,
        allTime: {
          deposit: 0,
          withdrawal: 0
        },
        currentMonth: {
          deposit: 0,
          withdrawal: 0
        },
        lastMonth: {
          deposit: 0,
          withdrawal: 0
        },
        currentWeek: {
          deposit: 0,
          withdrawal: 0
        },
        lastWeek: {
          deposit: 0,
          withdrawal: 0
        },
        yesterday: {
          deposit: 0,
          withdrawal: 0
        },
        today: {
          deposit: 0,
          withdrawal: 0
        }
      },
      errors: {},
      filter: {
        transaction_id: '',
        types: [],
        transaction_methods: [],
        statuses: [],
        page: 1,
        size: 10,
        sortField: 'created_at',
        sortOrder: 'desc'
      },
      type_options: ['DEPOSIT', 'WITHDRAWAL'],
      method_options: ['CASH', 'CHEQUE', 'DEBIT CARD', 'CREDIT CARD', 'NEFT/IMPS/RTGS', 'PAYTM'],
      status_options: ['MANUAL DEPOSIT', 'MANUAL WITHDRAWAL', 'AUTO DEPOSIT', 'AUTO WITHDRAWAL'],
      items: [],
      meta: {
        total: 0
      },
      fetchAgentStatus: '',
      fetchWalletStatus: '',
      fetchTransactionStatus: ''
    }
  },
  computed: {
    isAgentLoading () {
      return this.fetchAgentStatus === 'AGENT_LOADING'
    },
    isInfoLoading () {
      return this.fetchWalletStatus === 'WALLET_LOADING'
    },
    isTransactionsLoading () {
      return this.fetchTransactionStatus === 'TRANSACTION_LOADING'
    },
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    }
  },
  methods: {
    ...mapActions('MyActions', { loadAgent: AGENT_SHOW, loadWalletInfo: AGENT_WALLET_INFO, loadTransactionList: AGENT_WALLET_TRANSACTION_LIST }),
    showTransactionRecord (transactionRecord) {
      this.transaction_record = transactionRecord
      this.isWalletTransactionDetailModalActive = true
    },
    loadAsyncAgent () {
      this.user = null
      this.fetchAgentStatus = 'AGENT_LOADING'
      this.loadAgent(this.agentId)
        .then((response) => {
          this.user = response.data
          this.fetchAgentStatus = 'AGENT_SUCCESS'
        })
        .catch((error) => {
          this.fetchAgentStatus = 'AGENT_ERROR'
          this.$buefy.dialog.alert({
            title: 'Error (User Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'MyAgentList' }),
            onConfirm: () => this.$router.replace({ name: 'MyAgentList' })
          })
        })
    },
    loadAsyncAgentWallet () {
      this.fetchWalletStatus = 'WALLET_LOADING'
      this.loadWalletInfo(this.agentId)
        .then((response) => {
          this.wallet.alltime_balance = response.wallet_balance
          this.wallet.allTime = { deposit: response.all_time.DEPOSIT, withdrawal: response.all_time.WITHDRAWAL }
          this.wallet.currentMonth = { deposit: response.current_month.DEPOSIT, withdrawal: response.current_month.WITHDRAWAL }
          this.wallet.lastMonth = { deposit: response.last_month.DEPOSIT, withdrawal: response.last_month.WITHDRAWAL }
          this.wallet.currentWeek = { deposit: response.current_week.DEPOSIT, withdrawal: response.current_week.WITHDRAWAL }
          this.wallet.lastWeek = { deposit: response.last_week.DEPOSIT, withdrawal: response.last_week.WITHDRAWAL }
          this.wallet.yesterday = { deposit: response.yesterday.DEPOSIT, withdrawal: response.yesterday.WITHDRAWAL }
          this.wallet.today = { deposit: response.today.DEPOSIT, withdrawal: response.today.WITHDRAWAL }
          this.fetchWalletStatus = 'WALLET_SUCCESS'
        })
        .catch((error) => {
          this.fetchWalletStatus = 'WALLET_ERROR'
          this.$notify({
            title: 'Wallet Info',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onFilter () {
      this.loadAsyncData()
    },
    onPageChange (page) {
      this.filter.page = page
      this.loadAsyncData()
    },
    onSort (field, order) {
      this.filter.sortField = field
      this.filter.sortOrder = order
      this.loadAsyncData()
    },
    loadAsyncData () {
      this.fetchTransactionStatus = 'TRANSACTION_LOADING'
      const data = {
        agentId: this.agentId,
        filterData: this.filter
      }
      this.loadTransactionList(data)
        .then((response) => {
          this.items = response.data
          this.meta = response.meta
          this.fetchTransactionStatus = 'TRANSACTION_SUCCESS'
        })
        .catch((error) => {
          this.fetchTransactionStatus = 'TRANSACTION_ERROR'
          this.$notify({
            title: 'Page Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    reset () {
      this.filter = {
        transaction_id: '',
        types: [],
        transaction_methods: [],
        statuses: [],
        page: 1,
        size: 10,
        sortField: 'created_at',
        sortOrder: 'desc'
      }
      this.items = []
      this.meta = {
        total: 0
      }
      this.onFilter()
    }
  },
  created () {
    this.loadAsyncAgent()
    this.loadAsyncAgentWallet()
    this.reset()
  }
}
</script>
