import { axios } from './axios'

export default {
  deleteSlide (sliderId, slideId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/sliders/${sliderId}/slides/${slideId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  updateSlide (sliderId, slideId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/sliders/${sliderId}/slides/${slideId}`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadSlide (sliderId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/sliders/${sliderId}/slides`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/sliders', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  show (sliderId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/sliders/${sliderId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  create (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/sliders', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  update (sliderId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/sliders/${sliderId}`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  delete (sliderId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/sliders/${sliderId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
