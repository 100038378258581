<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="card card-section">
      <div class="card-content">
        <form
          @submit.prevent="onStart"
          v-if="media"
        >
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Title</label>
                <div class="control">
                  <input
                    required
                    class="input"
                    type="text"
                    placeholder="Enter Media Title"
                    v-model="media.title"
                    :class="{'is-danger' : errors.title}"
                  />
                </div>
                <p
                  v-if="errors.title"
                  class="help is-danger"
                >{{ errors.title | implode }}</p>
              </div>
              <div class="field">
                <label class="label">Description</label>
                <div class="control">
                  <textarea
                    v-model="media.description"
                    class="textarea"
                    :class="{'is-danger' : errors.description}"
                    placeholder="Enter Media Description"
                  />
                </div>
                <p
                  v-if="errors.description"
                  class="help is-danger"
                >{{ errors.description | implode }}</p>
              </div>
              <div class="field">
                <label class="label">Anchor Link</label>
                <div class="control">
                  <input
                    v-model="media.href"
                    class="input"
                    :class="{'is-danger' : errors.href}"
                    type="text"
                    placeholder="Enter Media Anchor Link"
                  />
                </div>
                <p
                  v-if="errors.href"
                  class="help is-danger"
                >{{ errors.href | implode }}</p>
              </div>
              <div class="field">
                <label class="label">Status</label>
                <div class="control">
                  <!-- <div class="control is-expanded has-icons-left"> -->
                  <v-select
                    v-model="media.status"
                    :options="status_options"
                    :class="{'is-danger' : errors.status}"
                    placeholder="Pick status for media"
                    class="is-small"
                  ></v-select>
                </div>
                <p
                  v-if="errors.status"
                  class="help is-danger"
                >{{ errors.status | implode }}</p>
              </div>
            </div>
            <div class="column is-two-fifths">
              <div class="card card-section">
                <div class="card-content">
                  <div class="card">
                    <div class="card-image">
                      <figure class="image is-4by3">
                        <img
                          v-if="!media.thumbnail"
                          src="/img/placeholders/image-placeholder.png"
                          alt="Media Thumbnail"
                        >
                        <img
                          v-if="media.thumbnail"
                          :src="media.thumbnail"
                          alt="Media Thumbnail"
                        >
                      </figure>
                    </div>
                    <div class="card-content">
                      <div class="file has-name is-fullwidth is-primary">
                        <label class="file-label">
                          <input
                            class="file-input"
                            type="file"
                            name="mediaFile"
                            ref="mediaFile"
                            @change="onMediaChange"
                          />
                          <span class="file-cta">
                            <span class="file-icon">
                              <i class="fas fa-upload"></i>
                            </span>
                            <span class="file-label">
                              Choose a media file to upload…
                            </span>
                          </span>
                          <span class="file-name">
                            {{ mediaFileName }}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-grouped action-buttons">
            <div class="control">
              <button
                type="submit"
                :disabled="isLoading"
                class="button is-primary is-rounded"
                :class="{ 'is-loading': isLoading }"
              >Update Media</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { MEDIA_SHOW, MEDIA_UPDATE } from '@/store/actions/mediaActions'

export default {
  name: 'MediaEdit',
  components: { Breadcrumb },
  props: {
    mediaId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncMedia'
  },
  data () {
    return {
      pageTitle: 'Edit Media Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-images', routeName: 'MediaList', name: 'Manage Media Library' }
        ],
        current: { icon: 'fas fa-edit', name: 'Edit Media' },
        actions: [
          { icon: 'fas fa-images', routeName: 'MediaList', name: 'Manage Media Library' },
          {
            icon: 'fas fa-eye', routeName: 'MediaShow', params: { mediaId: this.mediaId }, name: 'Display Media'
          }
        ]
      },
      status_options: ['ENABLE', 'DISABLE'],
      media: null,
      errors: {},
      mediaFileName: null
    }
  },
  computed: {
    ...mapGetters('Media', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Media', { updateMedia: MEDIA_UPDATE, loadMedia: MEDIA_SHOW }),
    onMediaChange () {
      const mediaFiles = this.$refs.mediaFile
      if (!mediaFiles || !mediaFiles.files.length) {
        return
      }
      this.mediaFileName = mediaFiles.files[0].name;
      [this.media.mediaFile] = mediaFiles.files
      this.createImage(mediaFiles.files[0])
    },
    createImage (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.media.thumbnail = e.target.result
      }
      reader.readAsDataURL(file)
    },
    onStart () {
      this.errors = {}
      const formData = new FormData()
      formData.set('title', this.media.title)
      formData.set('description', this.media.description)
      formData.set('href', this.media.href)
      formData.set('mediaFile', this.media.mediaFile)
      formData.set('status', this.media.status)
      const data = {
        mediaId: this.mediaId,
        formData
      }
      this.updateMedia(data)
        .then(() => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: 'Media is created successfully',
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Media Library',
            onAction: () => {
              this.$router.push({
                name: 'MediaList'
              })
            }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Media Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncMedia () {
      this.media = null
      this.loadMedia(this.mediaId)
        .then((response) => {
          this.media = {
            id: response.data.id,
            title: response.data.title,
            description: response.data.description,
            type: response.data.type,
            href: response.data.href,
            status: response.data.status,
            thumbnail: response.data.thumbnail,
            mediaFile: null
          }
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Media Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'MediaList' }),
            onConfirm: () => this.$router.replace({ name: 'MediaList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncMedia()
  }
}
</script>
