<template>
  <div>
    <loading v-show="isDetailLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="card card-section"
      v-if="device"
    >
      <div class="card-content">
        <b-tabs v-model="activeTab">
          <b-tab-item label="General Properties">
            <my-device-general
              :user-view="userView"
              :device="device"
            ></my-device-general>
          </b-tab-item>
          <b-tab-item label="Picture Gallery">
            <my-device-gallery
              :user-view="userView"
              :device="device"
            ></my-device-gallery>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import MyDeviceGeneral from './MyDeviceGeneral'
import MyDeviceGallery from './MyDeviceGallery'
import { DEVICE_SHOW } from '@/store/actions/myActions'

export default {
  name: 'MyDeviceEdit',
  components: {
    Breadcrumb,
    MyDeviceGeneral,
    MyDeviceGallery
  },
  props: {
    userView: {
      required: true
    },
    deviceId: {
      required: true
    },
    openTab: {
      required: false,
      default: 0
    }
  },
  watch: {
    $route: 'loadAsyncDevice'
  },
  data () {
    return {
      activeTab: this.openTab,
      pageTitle: 'Edit Device Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-desktop', routeName: 'MyDeviceList', name: 'Manage Devices' }
        ],
        current: { icon: 'fas fa-edit', name: 'Edit Device' },
        actions: [
          { icon: 'fas fa-desktop', routeName: 'MyDeviceList', name: 'Manage Devices' },
          {
            icon: 'fas fa-eye', routeName: 'MyDeviceShow', params: { deviceId: this.deviceId }, name: 'Display Device'
          }
        ]
      },
      device: null,
      fetchDetailsStatus: ''
    }
  },
  computed: {
    isDetailLoading () {
      return this.fetchDetailsStatus === 'DEVICE_LOADING'
    }
  },
  methods: {
    ...mapActions('MyActions', { loadDevice: DEVICE_SHOW }),
    loadAsyncDevice () {
      this.fetchDetailsStatus = 'DEVICE_LOADING'
      this.device = null
      const data = { userView: this.userView, deviceId: this.deviceId }
      this.loadDevice(data)
        .then((response) => {
          this.device = {
            id: response.data.id,
            uuid: response.data.uuid,
            name: response.data.name,
            footfall: response.data.footfall,
            address: response.data.address,
            latitude: response.data.latitude,
            longitude: response.data.longitude,
            ads: response.data.ads,
            agent: response.data.agent,
            distributor: response.data.distributor,
            gallery: response.data.gallery,
            thumbnails: response.data.thumbnails
          }
          this.fetchDetailsStatus = 'DEVICE_SUCCESS'
        })
        .catch((error) => {
          this.fetchDetailsStatus = 'DEVICE_ERROR'
          this.$buefy.dialog.alert({
            title: 'Error (Device Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'MyDeviceList' }),
            onConfirm: () => this.$router.replace({ name: 'MyDeviceList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncDevice()
  }
}
</script>
