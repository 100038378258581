import {
  DEVICE_WINDOW_LIST,
  DEVICE_WINDOW_CREATE,
  DEVICE_WINDOW_SHOW,
  DEVICE_WINDOW_UPDATE,
  DEVICE_WINDOW_DELETE
} from '../../actions/deviceWindowActions'

import { LOADING, SUCCESS, ERROR } from '../../actions/requestStatus'
import deviceWindowApi from '../../../api/deviceWindowApi'

export default {
  namespaced: true,

  state: {
    status: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR
  },

  actions: {
    [DEVICE_WINDOW_SHOW]: ({ commit }, deviceWindowId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceWindowApi
          .show(deviceWindowId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_WINDOW_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceWindowApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_WINDOW_CREATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceWindowApi
          .create(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_WINDOW_UPDATE]: ({ commit }, { deviceWindowId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceWindowApi
          .update(deviceWindowId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_WINDOW_DELETE]: ({ commit }, deviceWindowId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceWindowApi
          .delete(deviceWindowId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    }
  }
}
