import { axios } from './axios'

export default {
  list (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/navs', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  show (navId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/navs/${navId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  create (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/navs', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  update (navId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/navs/${navId}`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  delete (navId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/navs/${navId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  tree (groupName) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/navs/tree', {
          params: {
            group: groupName
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  treeUpdate (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/navs/tree', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
