import { axios } from './axios'

export default {
  rechargeWallet (userId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/wallets/${userId}/recharge`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  getWalletInfo (userId, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/wallets/${userId}/info`, {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  getWalletBalance (userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/wallets/${userId}/balance`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  listTransactions (userId, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/wallets/${userId}/transactions`, {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
