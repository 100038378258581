<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="card card-section">
      <div class="card-content">
        <form
          @submit.prevent="onStart"
          v-if="form"
        >
          <div class="field">
            <label class="label">Name</label>
            <div class="control">
              <input
                v-model="form.name"
                class="input"
                :class="{'is-danger' : errors.name}"
                type="text"
                placeholder="Enter User Name"
              />
            </div>
            <p
              v-if="errors.name"
              class="help is-danger"
            >{{ errors.name | implode }}</p>
          </div>

          <div class="field">
            <label class="label">E-Mail</label>
            <div class="control has-icons-left">
              <input
                v-model="form.email"
                class="input"
                :class="{'is-danger' : errors.email}"
                type="email"
                placeholder="Enter User E-Mail"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
            </div>
            <p
              v-if="errors.email"
              class="help is-danger"
            >{{ errors.email | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Password</label>
            <div class="control has-icons-left">
              <input
                v-model="form.password"
                class="input"
                :class="{'is-danger' : errors.password}"
                type="password"
                placeholder="Enter User Password"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-key"></i>
              </span>
            </div>
            <p
              v-if="errors.password"
              class="help is-danger"
            >{{ errors.password | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Confirm Password</label>
            <div class="control has-icons-left">
              <input
                v-model="form.password_confirmation"
                class="input"
                :class="{'is-danger' : errors.password_confirmation}"
                type="text"
                placeholder="Enter Password Again"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-key"></i>
              </span>
            </div>
            <p
              v-if="errors.password_confirmation"
              class="help is-danger"
            >{{ errors.password_confirmation | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Mobile</label>
            <div class="control has-icons-left">
              <input
                v-model="form.mobile"
                class="input"
                :class="{'is-danger' : errors.mobile}"
                type="text"
                placeholder="Enter User Mobile"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-phone"></i>
              </span>
            </div>
            <p
              v-if="errors.mobile"
              class="help is-danger"
            >{{ errors.mobile | implode }}</p>
          </div>
          <div class="field">
            <b-switch
              true-value="MALE"
              false-value="FEMALE"
              v-model="form.profiles.gender"
              type="is-success"
            >
              {{ form.profiles.gender }}
            </b-switch>
          </div>
          <div class="field">
            <label class="label">Address</label>
            <div class="control">
              <textarea
                v-model="form.profiles.address"
                class="textarea"
                :class="{'is-danger' : errors.address}"
                placeholder="Enter User Address"
              ></textarea>
            </div>
            <p
              v-if="errors.address"
              class="help is-danger"
            >{{ errors.address | implode }}</p>
          </div>
          <b-field label="Expire Date">
            <b-datepicker
              v-model="form.expire_date"
              :first-day-of-week="1"
              icon="calendar-alt"
              :min-date="minDate"
              placeholder="Click to select user expire date..."
            >

              <button
                type="button"
                class="button is-primary"
                @click="form.expire_date = new Date()"
              >
                <b-icon icon="calendar-check"></b-icon>
                <span>Today</span>
              </button>

              <button
                type="button"
                class="button is-danger"
                @click="form.expire_date = null"
              >
                <b-icon icon="window-close"></b-icon>
                <span>Clear</span>
              </button>
            </b-datepicker>
          </b-field>
          <div class="field">
            <label class="label">Status</label>
            <div class="control">
              <!-- <div class="control is-expanded has-icons-left"> -->
              <v-select
                v-model="form.status"
                :options="status_options"
                :class="{'is-danger' : errors.status}"
                placeholder="Pick status for user"
                class="is-small"
              ></v-select>
            </div>
            <p
              v-if="errors.status"
              class="help is-danger"
            >{{ errors.status | implode }}</p>
          </div>
          <div class="field">
            <b-switch
              :value="true"
              v-model="form.is_administrator"
              type="is-info"
            >
              {{ form.is_administrator ? 'Administrator' : 'Not an Administrator' }}
            </b-switch>
          </div>
          <div class="field">
            <b-switch
              :value="true"
              v-model="form.is_customer"
              type="is-success"
            >
              {{ form.is_customer ? 'Customer' : 'Not an Customer' }}
            </b-switch>
          </div>
          <div class="field">
            <b-switch
              :value="true"
              v-model="form.is_channel"
              type="is-success"
            >
              {{ form.is_channel ? 'Channel Owner' : 'Not A Channel Owner' }}
            </b-switch>
          </div>
          <div class="field">
            <b-switch
              :value="true"
              v-model="form.is_advertiser"
              type="is-success"
            >
              {{ form.is_advertiser ? 'Advertiser' : 'Not an Advertiser' }}
            </b-switch>
          </div>
          <div class="field">
            <b-switch
              :value="true"
              v-model="form.is_distributor"
              type="is-success"
            >
              {{ form.is_distributor ? 'Distributor' : 'Not an Distributor' }}
            </b-switch>
          </div>
          <div class="field">
            <b-switch
              :value="true"
              v-model="form.is_agent"
              type="is-success"
            >
              {{ form.is_agent ? 'Agent' : 'Not an Agent' }}
            </b-switch>
          </div>
          <div class="field">
            <b-switch
              :value="true"
              v-model="form.e_active"
              type="is-info"
            >
              {{ form.e_active ? 'E-Mail Login Allowed' : 'E-Mail Login Not Allowed' }}
            </b-switch>
          </div>
          <div class="field">
            <b-switch
              :value="true"
              v-model="form.m_active"
              type="is-success"
            >
              {{ form.m_active ? 'Mobile Login Allowed' : 'Mobile Login Not Allowed' }}
            </b-switch>
          </div>
          <div
            class="field"
            v-show="form.is_administrator"
          >
            <label class="label">Role</label>
            <div class="control">
              <v-select
                v-model="form.role"
                :options="roles"
                label="name"
                :class="{'is-danger' : errors.role}"
                placeholder="Pick role for user"
                class="is-small"
              ></v-select>
            </div>
            <p
              v-if="errors.role"
              class="help is-danger"
            >{{ errors.role | implode }}</p>
          </div>
          <div class="field is-grouped action-buttons">
            <div class="control">
              <button
                type="submit"
                :disabled="isLoading"
                class="button is-primary is-rounded"
                :class="{ 'is-loading': isLoading }"
              >Submit</button>
            </div>
            <div class="control">
              <button
                type="button"
                @click="onReset"
                class="button is-rounded"
              >Reset</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { USER_CREATE } from '@/store/actions/userActions'
import { ROLE_LIST } from '@/store/actions/roleActions'

export default {
  name: 'UserCreate',
  components: {
    Breadcrumb
  },
  data () {
    return {
      pageTitle: 'New User Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-user-shield', routeName: 'UserList', name: 'Manage Users' }
        ],
        current: { icon: 'fas fa-user-plus', name: 'New User' },
        actions: [{ icon: 'fas fa-user-shield', routeName: 'UserList', name: 'Manage Users' }]
      },
      status_options: ['ACTIVE', 'BANNED', 'EXPIRED'],
      minDate: new Date(),
      form: null,
      errors: {},
      roles: []
    }
  },
  computed: {
    ...mapGetters('Users', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Users', { createUser: USER_CREATE }),
    ...mapActions('Roles', { loadRoles: ROLE_LIST }),
    onStart () {
      this.errors = {}
      let expireDate = null
      if (this.form.expire_date !== undefined && this.form.expire_date !== null) {
        expireDate = moment(this.form.expire_date).format('YYYY-MM-DD HH:mm:ss ZZ')
      }
      const formData = {
        name: this.form.name,
        email: this.form.email,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
        status: this.form.status,
        is_advertiser: this.form.is_advertiser,
        is_distributor: this.form.is_distributor,
        is_agent: this.form.is_agent,
        is_customer: this.form.is_customer,
        is_administrator: this.form.is_administrator,
        is_channel: this.form.is_channel,
        e_active: this.form.e_active,
        m_active: this.form.m_active,
        mobile: this.form.mobile,
        expire_date: expireDate,
        profiles: this.form.profiles,
        role: this.form.role
      }
      this.createUser(formData)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.name}) User is created successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage User',
            onAction: () => {
              this.$router.push({
                name: 'UserList'
              })
            }
          })
          this.onReset()
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid User Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onReset () {
      this.errors = {}
      this.form = {
        name: null,
        email: null,
        password: null,
        password_confirmation: null,
        status: 'ACTIVE',
        is_advertiser: false,
        is_distributor: false,
        is_agent: false,
        is_customer: true,
        is_channel: false,
        is_administrator: false,
        e_active: true,
        m_active: false,
        mobile: null,
        expire_date: null,
        profiles: {
          address: null,
          gender: 'MALE'
        },
        role: null
      }
      this.$notify({
        title: 'Reset Form',
        text: 'User form is reset with default value, please continue...',
        type: 'is-info'
      })
    },
    loadAsyncRoles () {
      this.loadRoles()
        .then((response) => {
          this.roles = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Role Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    this.onReset()
    this.loadAsyncRoles()
  }
}
</script>
