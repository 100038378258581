<template>
  <b-field
    label="Video Advertisement"
    custom-class="is-small"
  >
    <b-autocomplete
      icon-pack="fas"
      icon="search"
      placeholder="Type advertisement to search and select"
      field="name"
      :keep-first="true"
      :data="items"
      v-model="queryStr"
      :loading="isLoading"
      @input="loadOptionsSuggestion"
      @select="onOptionSelection"
    >
      <template slot="empty">No advertisement found</template>
      <template slot-scope="props">
        <div class="media">
          <div class="media-content">
            <strong>{{ props.option.type }}</strong>
            <br />
            {{ props.option.name }} ({{ props.option.duration }} Secs.)
            <br />
            <small>
              {{ props.option.advertiser.name }} ({{ props.option.advertiser.email }} )
            </small>
          </div>
        </div>
      </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
import { mapActions } from 'vuex'
import searchInputMixins from './search-input-mixin'
import { ADVERTISEMENT_LIST } from '@/store/actions/advertisementActions'

export default {
  name: 'VideoAdvertisementSearchInput',
  mixins: [searchInputMixins],
  methods: {
    ...mapActions('Advertisements', { loadAdvertisementOptions: ADVERTISEMENT_LIST }),
    loadOptions (data) {
      return this.loadAdvertisementOptions(data)
    }
  }
}
</script>
