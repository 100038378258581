<template>
  <div>
    <div class="card">
      <header class="card-header has-background-primary">
        <p class="card-header-title">Device Filters</p>
        <button
          class="card-header-icon"
          aria-label="more options"
        ><span class="icon"><i
              class="fas fa-angle-down"
              aria-hidden="true"
            ></i></span></button>
      </header>
      <div class="card-content p-4">
        <div class="columns is-multiline">
          <div class="column is-one-fifth-tablet">
            <div class="field">
              <label class="label">Booking Date</label>
              <div class="control">
                <b-datepicker
                  v-model="filter.date"
                  icon="calendar-alt"
                  placeholder="Click or Select for date"
                  :first-day-of-week="1"
                  :min-date="options.minDate"
                  :max-date="options.maxDate"
                  @input="onDateChange"
                >
                  <button
                    type="button"
                    class="button is-primary"
                    @click="filter.date = new Date()"
                  >
                    <b-icon icon="calendar-check"></b-icon> <span>Today</span>
                  </button>
                  <button
                    type="button"
                    class="button is-danger"
                    @click="filter.date = null"
                  >
                    <b-icon icon="window-close"></b-icon> <span>Clear</span>
                  </button>
                </b-datepicker>
              </div>
            </div>
          </div>

          <div class="column is-one-fifth-tablet">
            <div class="field">
              <label class="label">Intervals</label>
              <div class="control">
                <v-select
                  class="is-small"
                  placeholder="Pick intervals for bookings"
                  v-model="filter.intervals"
                  :multiple="true"
                  :options="options.intervals"
                  @input="onIntervalChange"
                ></v-select>
              </div>
            </div>
          </div>

          <div class="column is-one-fifth-tablet">
            <div class="field">
              <label class="label">Locations</label>
              <div class="control">
                <treeselect
                  placeholder="Pick locations for the device..."
                  value-consists-of="BRANCH_PRIORITY"
                  v-model="filter.locations"
                  :multiple="true"
                  :options="options.locations"
                  :normalizer="normalizer"
                  @input="onLocationChange"
                ></treeselect>
              </div>
            </div>
          </div>

          <div class="column is-one-fifth-tablet">
            <div class="field">
              <label class="label">Place Categories</label>
              <div class="control">
                <treeselect
                  placeholder="Pick place categories for the device..."
                  value-consists-of="BRANCH_PRIORITY"
                  v-model="filter.places"
                  :multiple="true"
                  :options="options.places"
                  :normalizer="normalizer"
                  @input="onPlaceChange"
                ></treeselect>
              </div>
            </div>
          </div>

          <div class="column is-one-fifth-tablet">
            <div class="field">
              <label class="label">Ads Categories</label>
              <div class="control">
                <treeselect
                  placeholder="Pick categories for the device..."
                  value-consists-of="BRANCH_PRIORITY"
                  v-model="filter.tags"
                  :multiple="true"
                  :options="options.tags"
                  :normalizer="normalizer"
                  @input="onTagChange"
                ></treeselect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <nav class="panel">
    <p class="panel-heading has-background-primary">Date</p>
    <div class="panel-block">
      <p class="control">
        <b-datepicker v-model="filter.date" icon="calendar-alt" placeholder="Click or Select for date"
            :first-day-of-week="1" :min-date="options.minDate" :max-date="options.maxDate" @input="onDateChange">
            <button type="button" class="button is-primary" @click="filter.date = new Date()">
                <b-icon icon="calendar-check"></b-icon> <span>Today</span>
            </button>
            <button type="button" class="button is-danger" @click="filter.date = null">
                <b-icon icon="window-close"></b-icon> <span>Clear</span>
            </button>
        </b-datepicker>
      </p>
    </div>
  </nav>
  <nav class="panel">
    <p class="panel-heading has-background-primary">Intervals</p>
    <div class="panel-block" v-if="options.intervals.length > 0">
      <v-select class="is-small" placeholder="Pick intervals for bookings"
        v-model="filter.intervals" :multiple="true" :options="options.intervals" @input="onIntervalChange"></v-select>
    </div>
  </nav>
  <nav class="panel" v-if="options.locations.length > 0">
    <p class="panel-heading has-background-primary">Locations</p>
    <div class="panel-block">
      <treeselect placeholder="Pick locations for the device..." value-consists-of="BRANCH_PRIORITY"
        v-model="filter.locations"
        :multiple="true"
        :options="options.locations"
        :normalizer="normalizer"
         @input="onLocationChange"></treeselect>
    </div>
  </nav>
  <nav class="panel" v-if="options.places.length > 0">
    <p class="panel-heading has-background-primary">Place Categories</p>
    <div class="panel-block">
      <treeselect placeholder="Pick place categories for the device..." value-consists-of="BRANCH_PRIORITY"
        v-model="filter.places"
        :multiple="true"
        :options="options.places"
        :normalizer="normalizer"
         @input="onPlaceChange"></treeselect>
    </div>
  </nav>
  <nav class="panel" v-if="options.tags.length > 0">
    <p class="panel-heading has-background-primary">Ads Categories</p>
    <div class="panel-block">
      <treeselect placeholder="Pick categories for the device..." value-consists-of="BRANCH_PRIORITY"
        v-model="filter.tags"
        :multiple="true"
        :options="options.tags"
        :normalizer="normalizer"
         @input="onTagChange"></treeselect>
    </div>
  </nav> -->
    <!-- <nav class="panel">
    <div class="panel-block">
      <div class="field is-grouped">
        <p class="control">
          <button class="button is-link" @click="onFilter">Search</button>
        </p>
        <p class="control">
          <button class="button is-warning" @click="onReset">Reset</button>
        </p>
      </div>
    </div>
  </nav> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { CATEGORY_TREE, LOCATION_TREE, TAG_TREE } from '@/store/actions/bookingActions'

export default {
  name: 'BookingFilter',
  components: { Treeselect },
  props: {
    adsData: {
      required: true,
      default: null
    },
    adsType: {
      required: true,
      default: null
    },
    formFilter: {
      required: false,
      default: () => { }
    }
  },
  watch: {
    adsData: {
      immediate: true,
      handler: 'setAdsTags',
      deep: true
    }
  },
  data () {
    return {
      options: {
        intervals: [
          { id: '00 - 01', label: '00 - 01' },
          { id: '01 - 02', label: '01 - 02' },
          { id: '02 - 03', label: '02 - 03' },
          { id: '03 - 04', label: '03 - 04' },
          { id: '04 - 05', label: '04 - 05' },
          { id: '05 - 06', label: '05 - 06' },
          { id: '06 - 07', label: '06 - 07' },
          { id: '07 - 08', label: '07 - 08' },
          { id: '08 - 09', label: '08 - 09' },
          { id: '09 - 10', label: '09 - 10' },
          { id: '10 - 11', label: '10 - 11' },
          { id: '11 - 12', label: '11 - 12' },
          { id: '12 - 13', label: '12 - 13' },
          { id: '13 - 14', label: '13 - 14' },
          { id: '14 - 15', label: '14 - 15' },
          { id: '15 - 16', label: '15 - 16' },
          { id: '16 - 17', label: '16 - 17' },
          { id: '17 - 18', label: '17 - 18' },
          { id: '18 - 19', label: '18 - 19' },
          { id: '19 - 20', label: '19 - 20' },
          { id: '20 - 21', label: '20 - 21' },
          { id: '21 - 22', label: '21 - 22' },
          { id: '22 - 23', label: '22 - 23' },
          { id: '23 - 24', label: '23 - 24' }
        ],
        locations: [],
        places: [],
        tags: [],
        minDate: new Date(),
        maxDate: new Date(),
        types: ['VIDEO', 'TEXT']
      },
      filter: {
        date: null,
        intervals: [],
        locations: [],
        places: [],
        tags: [],
        type: ''
      },
      formData: {
        date: '',
        intervals: '',
        locations: '',
        places: '',
        tags: '',
        type: ''
      },
      filterTimout: null
    }
  },
  methods: {
    ...mapActions('Bookings', {
      loadPlaces: CATEGORY_TREE,
      loadLocations: LOCATION_TREE,
      loadTags: TAG_TREE
    }),
    setAdsTags () {
      this.filter.tags = []
      if (this.adsData !== null && this.adsData.tags !== null) {
        this.filter.tags = this.adsData.tags.map(tag => tag.id)
      }
      this.updateByFormFilter()
    },
    updateByFormFilter () {
      if (this.formFilter !== null) {
        const intervalOptions = this.options.intervals
        this.filter.intervals = this.formFilter.intervals.map(function (item) {
          return intervalOptions.find(interval => interval.id === item)
        })
        this.filter.locations = this.formFilter.locations
        this.filter.places = this.formFilter.places
        this.filter.tags = [...new Set([...this.filter.tags.values(), ...this.formFilter.tags.values()])]
        this.formData.intervals = this.filter.intervals.map((interval) => interval.id).join()
        this.formData.locations = this.filter.locations.join()
        this.formData.places = this.filter.places.join()
        this.formData.tags = this.filter.tags.join()
        this.emitFilter()
      }
    },
    normalizer (node) {
      let result = null
      if (node.children.length > 0) {
        result = {
          id: node.id,
          label: node.name,
          children: node.children
        }
      } else {
        result = {
          id: node.id,
          label: node.name
        }
      }
      return result
    },
    onDateChange (value) {
      let filterDate = null
      if (this.filter.date !== undefined && this.filter.date !== null) {
        filterDate = moment(this.filter.date).format('YYYY-MM-DD HH:mm:ss ZZ')
      }
      this.formData.date = filterDate
      this.emitFilter()
    },
    onTypeChange (value) {
      this.formData.type = this.filter.type
      this.emitFilter()
    },
    onIntervalChange (value) {
      this.formData.intervals = this.filter.intervals.map((interval) => interval.id).join()
      this.emitFilter()
    },
    /* eslint no-unused-vars: ["error", { "args": "none" }] */
    onPlaceChange (node) {
      this.formData.places = this.filter.places.join()
      this.emitFilter()
    },
    onLocationChange (node) {
      this.formData.locations = this.filter.locations.join()
      this.emitFilter()
    },
    onTagChange (node) {
      this.formData.tags = this.filter.tags.join()
      this.emitFilter()
    },
    loadAsyncLocations () {
      this.loadLocations()
        .then((response) => {
          this.options.locations = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Place Categories Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncPlaces () {
      this.loadPlaces()
        .then((response) => {
          this.options.places = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Places Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncTags () {
      this.loadTags()
        .then((response) => {
          this.options.tags = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Tags Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onFilter () {
      let filterValid = true
      if (this.formData.type === undefined || this.formData.type === null || this.formData.type === '') {
        filterValid = false
        this.$notify({
          title: 'Booking Filter Form Invalid',
          text: 'Please select one of advertisement type in booking filter to continue...',
          type: 'is-danger'
        })
      }
      if (this.formData.intervals === undefined || this.formData.intervals === null || this.formData.intervals === '') {
        filterValid = false
        this.$notify({
          title: 'Booking Filter Form Invalid',
          text: 'Please select atleast one of intervals in booking filter to continue...',
          type: 'is-danger'
        })
      }
      if (filterValid) {
        this.emitFilter()
      }
    },
    onReset () {
      this.filter.date = moment().add(1, 'day').startOf('day').toDate()
      this.filter.intervals = [{ id: '08 - 09', label: '08 - 09' }]
      this.filter.locations = []
      this.filter.places = []
      this.filter.tags = []
      this.filter.type = this.adsType

      let filterDate = null
      if (this.filter.date !== undefined && this.filter.date !== null) {
        filterDate = moment(this.filter.date).format('YYYY-MM-DD HH:mm:ss ZZ')
      }
      this.formData.date = filterDate
      this.filter.type = this.adsType
      this.formData.intervals = '00 - 01'
      this.formData.locations = ''
      this.formData.places = ''
      this.formData.tags = ''
      this.emitFilter()
    },
    emitFilter () {
      if (this.adsData !== null) {
        // const tTags = this.formData.tags.split(',');
        // this.adsData.tags.forEach((tag) => {
        //   if (!tTags.includes(`${tag.id}`)) {
        //     tTags.push(`${tag.id}`);
        //   }
        // });
        // this.formData.tags = tTags.join().replace(/(^,)|(,$)/g, '');
        clearTimeout(this.filterTimout)
        this.filterTimout = setTimeout(() => {
          this.$emit('on-filter', this.formData)
        }, 1000)
      }
    }
  },
  created () {
    this.options.minDate = moment().startOf('day').toDate()
    this.options.maxDate = moment().add(30, 'days').startOf('day').toDate()
    this.onReset()
    this.loadAsyncLocations()
    this.loadAsyncPlaces()
    this.loadAsyncTags()
  }
}
</script>

<style>
div.tree.search-side {
  width: 100%;
  max-height: 250px;
}
div.tree.search-side .tree-arrow {
  position: absolute !important;
  right: 0px;
}
</style>
