<template>
  <section class="hero is-primary is-bold">
    <div class="hero-body">
      <div class="container-fluid">
        <div class="card white has-card-deep-shadow">
          <div class="card-content is-paddingless">
            <div class="columns is-gapless">
              <div class="column">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d754.8896874369053!2d77.37756378773061!3d28.625333298900735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjjCsDM3JzMxLjIiTiA3N8KwMjInNDEuMiJF!5e1!3m2!1sen!2sin!4v1532788213403"
                  width="600"
                  height="450"
                  frameborder="0"
                  style="border:0"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="column">
                <div class="padding-25">
                  <h1 class="title is-size-3 has-text-black">Drop a Line</h1>

                  <h1 class="title is-size-5 has-text-black">
                    <i class="fas fa-envelope"></i> Don't hesitate to contact us
                  </h1>

                  <p>
                    <a
                      href="mailto:info@starxmedia.in?Subject=HelpDesk:%20Ask%20Your%20Query"
                      target="_blank"
                    >
                      <span class="has-text-weight-bold">E-Mail: </span> info@starxmedia.in</a>
                  </p>
                  <p><span class="has-text-weight-bold">Address: </span> H-160, Sector 63, Noida, Uttar Pradesh (201301)</p>
                  <p><span class="has-text-weight-bold is-size-6">Propress Instrumentation &amp; Solutions Pvt. Ltd.</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AUTH_REQUEST } from '@/store/actions/authActions'

export default {
  name: 'Login',
  props: {
    redirect: null
  },
  data () {
    return {
      email: '',
      password: '',
      rememberMe: false,
      message: ''
    }
  },
  computed: {
    ...mapGetters('Auth', ['authStatus']),
    isLoading () {
      return this.authStatus === 'loading'
    },
    isSuccess () {
      return this.authStatus === 'success'
    },
    isDanger () {
      return this.authStatus === 'error'
    }
  },
  methods: {
    ...mapActions('Auth', { loginAction: AUTH_REQUEST }),
    login () {
      const { email, password, rememberMe } = this
      this.loginAction({ email, password, rememberMe })
        .then(() => {
          this.$notify({
            title: 'Authentication',
            text: 'You are successfully login for this session...',
            type: 'is-success'
          })
          if (this.redirect !== undefined) {
            this.$router.push({ path: this.redirect })
          } else {
            this.$router.push('/')
          }
        })
        .catch((error) => {
          this.message = error.message
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
