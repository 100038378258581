<template>
  <nav
    class="navbar"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a
        href="#"
        class="navbar-item"
      >
        <img
          class="logo-top-nav"
          src="@/assets/logo-horizontal.png"
        />
      </a>
      <div
        class="navbar-burger"
        :class="{ 'is-active' : isActive }"
        @click="toggle"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div
      id="top-navbar"
      class="navbar-menu"
      :class="{ 'is-active' : isActive }"
    >
      <div class="navbar-start">
      </div>

      <div class="navbar-end">
        <a
          class="navbar-item"
          href="#"
        >
          Home
        </a>
        <!-- <router-link class="navbar-item" :to="{ name: 'FrontendHelpDesk' }">
          Helpdesk
        </router-link> -->
        <!-- <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link" href="#">
            Docs
          </a>
          <div class="navbar-dropdown">
            <a class="navbar-item" href="#">
              Overview
            </a>
            <a class="navbar-item" href="#">
              Modifiers
            </a>
            <a class="navbar-item" href="#">
              Columns
            </a>
            <a class="navbar-item" href="#">
              Layout
            </a>
            <a class="navbar-item" href="#">
              Form
            </a>
            <hr class="navbar-divider">
            <a class="navbar-item" href="#">
              Elements
            </a>
            <a class="navbar-item is-active" href="#">
              Components
            </a>
          </div>
        </div> -->
        <div
          class="navbar-item"
          v-if="!isAuth"
        >
          <div class="field is-grouped">
            <!-- <p class="control">
              <router-link class="bd-tw-button button" :to="{ name: 'Register' }">
                <span class="icon"><i class="fas fa-user"></i></span>
                <span>Register</span>
              </router-link>
            </p> -->
            <p class="control">
              <router-link
                class="button is-primary"
                :to="{ name: 'Login' }"
              >
                <span class="icon"><i class="fas fa-lock"></i></span>
                <span>Login</span>
              </router-link>
            </p>
          </div>
        </div>
        <div
          class="navbar-item has-dropdown is-hoverable"
          v-if="isProfileLoaded"
        >
          <a
            class="navbar-link"
            href="/documentation/overview/start/"
          >
            {{ user.name }}
          </a>
          <div class="navbar-dropdown">
            <a
              class="navbar-item"
              href="/documentation/overview/start/"
            >
              Profile
            </a>
            <hr class="navbar-divider">
            <a
              @click.prevent="getUserLoggedOut"
              class="navbar-item is-active"
              href="#"
            >
              Sign Out
            </a>
            <hr class="navbar-divider">
            <a
              @click.prevent="getUserLoggedOutFromAll"
              class="navbar-item"
              href="#"
            >
              Sign Out From All
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AUTH_LOGOUT, AUTH_LOGOUT_ALL } from '@/store/actions/authActions'

const APP_NAME = process.env.VUE_APP_TITLE

export default {
  name: 'TopNavBar',
  data () {
    return {
      isActive: false,
      appName: APP_NAME
    }
  },
  computed: {
    ...mapGetters('Profile', { user: 'getProfile' }),
    ...mapGetters('Auth', { isAuth: 'isAuthenticated' }),
    isProfileLoaded () {
      return this.isAuth && this.user !== null && this.user.name !== undefined
    }
  },
  methods: {
    ...mapActions('Auth', { userLoggedOut: AUTH_LOGOUT, userLoggedOutFromAll: AUTH_LOGOUT_ALL }),
    getUserLoggedOut () {
      this.userLoggedOut().then(() => {
        this.$notify({
          title: 'User Action',
          text: 'User successfully signed out from this session...',
          type: 'is-success'
        })
        this.$router.replace({ name: 'Login' })
      })
    },
    getUserLoggedOutFromAll () {
      this.userLoggedOutFromAll().then(() => {
        this.$notify({
          title: 'User Action',
          text: 'User successfully signed out from all sessions...',
          type: 'is-success'
        })
        this.$router.replace({ name: 'Login' })
      })
    },
    toggle () {
      this.isActive = !this.isActive
    },
    hideAside () {
      this.isActive = false
      this.$emit('toggleNav')
    }
  },
  created () {
    this.$eventHub.$on('next-route', this.hideAside)
  },
  beforeDestroy () {
    this.$eventHub.$off('next-route')
  }
}
</script>

<style lang="sass" scoped>
@import '@/sass/mixins.sass'

.navbar
  .navbar-link
    font-weight: 700
  .navbar-item
    font-weight: 700
  img.user-avatar
    border-radius: 50%
    margin-right: 10px
    vertical-align: middle
</style>
