<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="advertisement"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ advertisement.name }}</p>
                  </div>
                </div>
                <div class="content">
                  <div class="columns">
                    <div class="column">
                      <table class="table is-striped">
                        <tbody>
                          <tr>
                            <th>Desc</th>
                            <td>{{ advertisement.desc }}</td>
                          </tr>
                          <tr>
                            <th>Ads Duration</th>
                            <td>{{ advertisement.duration }} Secs</td>
                          </tr>
                          <tr>
                            <th>Status</th>
                            <td>{{ advertisement.status }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="card">
                        <div class="card-content">
                          <div class="field is-grouped">
                            <p class="control">
                              <router-link
                                :to="{ name: 'MyAdvertisementList'}"
                                class="button is-primary"
                              >
                                <span class="icon">
                                  <i class="fas fa-th-list"></i>
                                </span>
                                <span>LIST</span>
                              </router-link>
                            </p>
                            <p
                              class="control"
                              v-if="advertisement.status === 'IDEAL'"
                            >
                              <router-link
                                class="button is-warning"
                                :to="{ name: 'MyAdvertisementEdit', params: { advertisementId: advertisement.id }}"
                              >
                                <span class="icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                                <span>EDIT</span>
                              </router-link>
                            </p>
                            <p class="control">
                              <a
                                class="button is-danger"
                                @click.prevent="confirmDeleteAdvertisement"
                              >
                                <span class="icon">
                                  <i class="fas fa-trash"></i>
                                </span>
                                <span>DELETE</span>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-two-fifths">
                      <div class="card card-section">
                        <header class="card-header">
                          <p class="card-header-title has-background-primary has-text-white">
                            Ads
                          </p>
                        </header>
                        <div class="card-image">
                          <a
                            :href="advertisement.ads ? advertisement.ads.path : '#'"
                            target="_blank"
                          >
                            <figure class="image is-4by3">
                              <img
                                v-if="!advertisement.ads"
                                src="/img/placeholders/image-placeholder.png"
                                alt="Advertisement Ads"
                              >
                              <img
                                v-if="advertisement.ads"
                                :src="advertisement.ads.thumbnail"
                                alt="Advertisement Ads"
                              >
                            </figure>
                          </a>
                        </div>
                      </div>
                      <div class="card card-section">
                        <header class="card-header">
                          <p class="card-header-title has-background-primary has-text-white">
                            Thumbnail
                          </p>
                        </header>
                        <div class="card-image">
                          <figure class="image is-4by3">
                            <img
                              v-if="!advertisement.thumbnails"
                              src="/img/placeholders/image-placeholder.png"
                              alt="Advertisement Thumbnail"
                            >
                            <img
                              v-if="advertisement.thumbnails"
                              :src="advertisement.thumbnails.large"
                              alt="Advertisement Thumbnail"
                            >
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapActions } from 'vuex'
import { ADVERTISEMENT_SHOW, ADVERTISEMENT_DELETE } from '@/store/actions/advertisementActions'

export default {
  name: 'MyAdvertisementShow',
  components: { Breadcrumb },
  props: {
    advertisementId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncAdvertisement'
  },
  data () {
    return {
      pageTitle: 'My Ads Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fab fa-adversal', routeName: 'MyAdvertisementList', name: 'My Ads' }
        ],
        current: { icon: 'fas fa-eye', name: 'Ads Details' },
        actions: [{ icon: 'fab fa-adversal', routeName: 'AdvertisementList', name: 'My Ads' }]
      },
      advertisement: null,
      isLoading: false
    }
  },
  methods: {
    ...mapActions('MyActions', { loadAdvertisement: ADVERTISEMENT_SHOW, sendDeleteAdvertisementRequest: ADVERTISEMENT_DELETE }),
    confirmDeleteAdvertisement () {
      this.$buefy.dialog.confirm({
        title: 'Deleting Ads',
        message: 'Are you sure you want to <b>delete</b> this ads? This action cannot be undone.',
        confirmText: 'Delete Ads',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deleteAdvertisement()
      })
    },
    deleteAdvertisement () {
      this.sendDeleteAdvertisementRequest(this.advertisement.id)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.$router.replace({ name: 'MyAdvertisementList' })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncAdvertisement () {
      this.isLoading = true
      this.advertisement = null
      this.loadAdvertisement(this.advertisementId)
        .then((response) => {
          this.isLoading = false
          this.advertisement = response.data
        })
        .catch((error) => {
          this.isLoading = false
          this.$buefy.dialog.alert({
            title: 'Error (Advertisement Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'MyAdvertisementList' }),
            onConfirm: () => this.$router.replace({ name: 'MyAdvertisementList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncAdvertisement()
  }
}
</script>
