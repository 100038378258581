<template>
  <form @submit.prevent="saveFinanceInformation">
    <div class="field">
      <label class="label">Bank Name</label>
      <div class="control">
        <input
          v-model="form.bank_name"
          class="input"
          :class="{'is-danger' : errors.bank_name}"
          type="text"
          placeholder="Enter Bank Name"
        />
      </div>
      <p
        v-if="errors.bank_name"
        class="help is-danger"
      >{{ errors.bank_name | implode }}</p>
    </div>

    <div class="field">
      <label class="label">Your Name (As per bank a/c)</label>
      <div class="control">
        <input
          v-model="form.account_name"
          class="input"
          :class="{'is-danger' : errors.account_name}"
          type="text"
          placeholder="Enter Your Name As Per Your Bank A/C"
        />
      </div>
      <p
        v-if="errors.account_name"
        class="help is-danger"
      >{{ errors.account_name | implode }}</p>
    </div>

    <div class="field">
      <label class="label">Account Number</label>
      <div class="control">
        <input
          v-model="form.account_number"
          class="input"
          :class="{'is-danger' : errors.account_number}"
          type="text"
          placeholder="Enter Account Number"
        />
      </div>
      <p
        v-if="errors.account_number"
        class="help is-danger"
      >{{ errors.account_number | implode }}</p>
    </div>

    <div class="field">
      <label class="label">Account Type</label>
      <div class="control">
        <input
          v-model="form.account_type"
          class="input"
          :class="{'is-danger' : errors.account_type}"
          type="text"
          placeholder="Enter Account Type"
        />
      </div>
      <p
        v-if="errors.account_type"
        class="help is-danger"
      >{{ errors.account_type | implode }}</p>
    </div>

    <div class="field">
      <label class="label">Account IFSC</label>
      <div class="control">
        <input
          v-model="form.account_ifsc"
          class="input"
          :class="{'is-danger' : errors.account_ifsc}"
          type="text"
          placeholder="Enter Account IFSC"
        />
      </div>
      <p
        v-if="errors.account_ifsc"
        class="help is-danger"
      >{{ errors.account_ifsc | implode }}</p>
    </div>

    <div class="field">
      <label class="label">Paytm Number</label>
      <div class="control">
        <input
          v-model="form.paytm_number"
          class="input"
          :class="{'is-danger' : errors.paytm_number}"
          type="text"
          placeholder="Enter Paytm Number"
        />
      </div>
      <p
        v-if="errors.paytm_number"
        class="help is-danger"
      >{{ errors.paytm_number | implode }}</p>
    </div>

    <div class="field is-grouped action-buttons">
      <div class="control"><button
          type="submit"
          class="button is-primary is-rounded"
        >Save Finance Information</button></div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { PROFILE_REQUEST, STORE_FINANCE_PROFILE } from '@/store/actions/profileActions'

export default {
  name: 'FinanceInformation',
  data () {
    return {
      form: {
        bank_name: '',
        account_name: '',
        account_number: '',
        account_ifsc: '',
        account_type: '',
        paytm_number: ''
      },
      errors: {}
    }
  },
  computed: {
    ...mapGetters('Profile', { user: 'getProfile' })
  },
  methods: {
    ...mapActions('Profile', { saveFinanceProfile: STORE_FINANCE_PROFILE, reLoadProfile: PROFILE_REQUEST }),
    saveFinanceInformation () {
      const loadingComponent = this.$buefy.loading.open({ container: null, canCancel: false })
      this.saveFinanceProfile(this.form)
        .then(() => this.reLoadProfile())
        .then(() => {
          loadingComponent.close()
          this.$buefy.snackbar.open({
            duration: 4000,
            message: 'Financial information is saved successfully',
            type: 'is-success',
            position: 'is-top'
          })
        })
        .catch((error) => {
          loadingComponent.close()
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    if (this.user.profiles) {
      this.form.account_ifsc = this.user.profiles.account_ifsc
      this.form.account_name = this.user.profiles.account_name
      this.form.account_number = this.user.profiles.account_number
      this.form.account_type = this.user.profiles.account_type
      this.form.bank_name = this.user.profiles.bank_name
      this.form.paytm_number = this.user.profiles.paytm_number
    }
  }
}
</script>
