<template>
  <div id="app">
    <notifications group="top-notifications" position="top right">
      <div slot="body" slot-scope="props" class="notification" :class="[props.item.type ? props.item.type : '']">
        <button class="delete" @click="props.close"></button>
        <div class="notification-title">{{props.item.title}}</div>
        <div class="notification-content" v-html="props.item.text"></div>
      </div>
    </notifications>
    <notifications position="bottom right">
      <div slot="body" slot-scope="props" class="notification" :class="[props.item.type ? props.item.type : '']">
        <button class="delete" @click="props.close"></button>
        <div class="notification-title">{{props.item.title}}</div>
        <div class="notification-content" v-html="props.item.text"></div>
      </div>
    </notifications>
    <section v-if="!isLoaded" class="hero is-fullheight is-primary is-bold page-preloader">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title">
            <i class="fas fa-snowflake fa-spin"></i>
          </h1>
          <h2 class="subtitle">
            Loading...
          </h2>
        </div>
      </div>
    </section>
    <router-view v-if="isLoaded" @page-loaded="onPageLoaded" @page-loading="onPageLoading"></router-view>
    <back-to-top bottom="50px" right="50px" visibleoffset="50">
      <span class="btn-to-top has-background-primary has-text-white"><i class="fas fa-chevron-up"></i></span>
    </back-to-top>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BackToTop from 'vue-backtotop'
import { AUTH_LOAD } from './store/actions/authActions'
import { WALLET_BALANCE_INFO } from './store/actions/myWalletActions'

export default {
  name: 'App',
  components: { BackToTop },
  data () {
    return {
      isLoaded: false
    }
  },
  computed: {
    ...mapGetters('Auth', { isAuth: 'isAuthenticated' })
  },
  methods: {
    onPageLoaded () {
      this.isLoaded = true
    },
    onPageLoading () {
      this.isLoaded = false
    }
  },
  created () {
    if (this.isAuth) {
      Promise.all([
        this.$store.dispatch(`Auth/${AUTH_LOAD}`),
        this.$store.dispatch(`Profile/${WALLET_BALANCE_INFO}`)
      ]).then(() => {
        this.isLoaded = true
      }).catch(() => {
        this.isLoaded = true
      })
    } else {
      this.isLoaded = true
    }
  }
}
</script>

<style lang="sass">
@import './sass/main.sass'
</style>
