<template>
  <div class="dashboard">
    <my-wallet-balance v-if="!isAdministrator" />
    <my-wallet-transactions v-if="!isAdministrator" />
    <div class="tile is-ancestor has-text-centered" v-if="isAdministrator">
      <div class="tile is-parent">
        <div class="tile is-child relative-position element">
          <kiosks-working-doughnut />
        </div>
      </div>
      <div class="tile is-parent">
        <div class="tile is-child relative-position element">
          <ads-running-status />
        </div>
      </div>
    </div>
    <div class="tile is-ancestor has-text-centered" v-if="isAdministrator">
      <div class="tile is-parent">
        <div class="tile is-child relative-position element">
          <kiosks-doughnut />
        </div>
      </div>
      <div class="tile is-parent">
        <div class="tile is-child relative-position element">
          <campaign-doughnut />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import KiosksWorkingDoughnut from '@/pages/secure/charts/KiosksWorkingDoughnut.vue'
import AdsRunningStatus from '@/pages/secure/charts/AdsRunningStatus.vue'
import KiosksDoughnut from '@/pages/secure/charts/KiosksDoughnut.vue'
import CampaignDoughnut from '@/pages/secure/charts/CampaignDoughnut.vue'
import MyWalletBalance from './cards/MyWalletBalance'
import MyWalletTransactions from './cards/MyWalletTransactions'

export default {
  name: 'Dashboard',
  components: {
    MyWalletBalance,
    MyWalletTransactions,
    KiosksDoughnut,
    KiosksWorkingDoughnut,
    AdsRunningStatus,
    CampaignDoughnut
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters('Profile', { user: 'getProfile' }),
    isAgent () {
      return this.isUserTypesNotUndefined && this.user.types.includes('AGENT')
    },
    isAdministrator () {
      return this.isUserTypesNotUndefined && this.user.types.includes('ADMINISTRATOR')
    },
    isUserTypesNotUndefined () {
      return this.user !== undefined && this.user.types !== undefined
    }
  }
}
</script>
