<template>
  <div class="card card-section">
    <header
      class="card-header"
      :class="headerClass"
    >
      <div class="card-header-title">
        {{ title }}
        <span
          v-if="tagText"
          class="tag is-success margin-left-10"
        ><i
            v-if="tagIcon"
            :class="tagIcon"
            class="margin-right-5"
          ></i> {{ tagText }}</span>
        <slot name="header"></slot>
      </div>
      <div
        v-if="hasActionsSlot"
        class="card-header-icon dropdown is-right"
        :class="{ 'is-active' : isActionShow }"
        v-click-outside="hideActions"
      >
        <div class="dropdown-trigger">
          <a
            href="#"
            aria-haspopup="true"
            aria-controls="burger-menu"
            @click.prevent="toggleActions"
          >
            <span class="icon">
              <i
                class="fas fa-ellipsis-v"
                aria-hidden="true"
              ></i>
            </span>
          </a>
        </div>
        <div
          class="dropdown-menu"
          id="burger-menu"
          role="menu"
        >
          <div class="dropdown-content">
            <slot name="actions"></slot>
          </div>
        </div>
      </div>
      <button
        class="card-header-icon"
        aria-label="more options"
        @click="toogleCardView"
      >
        <span
          class="icon"
          v-show="!cardView"
        >
          <i
            class="fas fa-angle-up"
            aria-hidden="true"
          ></i>
        </span>
        <span
          class="icon"
          v-show="cardView"
        >
          <i
            class="fas fa-angle-down"
            aria-hidden="true"
          ></i>
        </span>
      </button>
    </header>
    <div
      class="card-content"
      v-show="cardView"
    >
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    initView: {
      required: false,
      default: false
    },
    title: {
      required: false
    },
    tagText: {
      required: false
    },
    tagIcon: {
      required: false
    },
    headerClass: {
      default: ''
    }
  },
  data () {
    return {
      isActionShow: false,
      cardView: this.initView
    }
  },
  computed: {
    hasActionsSlot () {
      return !!this.$slots.actions
    }
  },
  methods: {
    toogleCardView () {
      this.cardView = !this.cardView
    },
    toggleActions () {
      this.isActionShow = !this.isActionShow
    },
    hideActions () {
      this.isActionShow = false
    }
  }
}
</script>
