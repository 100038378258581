<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'CategoryModule',
  data () {
    return {}
  }
}
</script>
