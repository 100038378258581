<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="tag"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ tag.name }}</p>
                  </div>
                </div>
                <div class="content">
                  <table class="table is-striped">
                    <tbody>
                      <tr>
                        <th>Parent</th>
                        <td>{{ tag.parent ? tag.parent.name : '' }}</td>
                      </tr>
                      <tr>
                        <th>Status</th>
                        <td>{{ tag.status }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="field is-grouped">
                    <p class="control">
                      <router-link
                        :to="{ name: 'TagList'}"
                        class="button is-primary"
                      >
                        <span class="icon">
                          <i class="fas fa-th-list"></i>
                        </span>
                        <span>LIST</span>
                      </router-link>
                    </p>
                    <p class="control">
                      <router-link
                        :to="{ name: 'TagEdit', params: { tagId: tag.id }}"
                        class="button is-warning"
                      >
                        <span class="icon">
                          <i class="fas fa-edit"></i>
                        </span>
                        <span>EDIT</span>
                      </router-link>
                    </p>
                    <p class="control">
                      <a
                        class="button is-danger"
                        @click.prevent="confirmDeleteTag"
                      >
                        <span class="icon">
                          <i class="fas fa-trash"></i>
                        </span>
                        <span>DELETE</span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-image">
                <figure class="image is-4by3">
                  <img
                    v-if="!tag.thumbnails"
                    src="/img/placeholders/image-placeholder.png"
                    alt="Ads Category Image"
                  >
                  <img
                    v-if="tag.thumbnails"
                    :src="tag.thumbnails.large"
                    alt="Ads Category Image"
                  >
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { TAG_SHOW, TAG_DELETE } from '@/store/actions/tagActions'

export default {
  name: 'TagShow',
  components: {
    Breadcrumb
  },
  props: {
    tagId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncTag'
  },
  data () {
    return {
      pageTitle: 'Ads Category Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-tags', routeName: 'TagList', name: 'Manage Ads Categories' }
        ],
        current: { icon: 'fas fa-eye', name: 'Ads Category Details' },
        actions: [{ icon: 'fas fa-tags', routeName: 'TagList', name: 'Manage Ads Categories' }]
      },
      tag: null
    }
  },
  computed: {
    ...mapGetters('Tags', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Tags', { loadTag: TAG_SHOW, sendDeleteTagRequest: TAG_DELETE }),
    confirmDeleteTag () {
      this.$buefy.dialog.confirm({
        title: 'Deleting Ads Category',
        message: 'Are you sure you want to <b>delete</b> this product tag? This action cannot be undone.',
        confirmText: 'Delete Ads Category',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deleteTag()
      })
    },
    deleteTag () {
      this.sendDeleteTagRequest(this.tag.id)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.$router.replace({ name: 'TagList' })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncTag () {
      this.tag = null
      this.loadTag(this.tagId)
        .then((response) => {
          this.tag = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Ads Category Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'TagList' }),
            onConfirm: () => this.$router.replace({ name: 'TagList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncTag()
  }
}
</script>
