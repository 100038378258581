import { axios } from './axios'

export default {
  listUserSuggestions (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/users/suggestions', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  listAgents (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/users/agent/list', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  listDistributors (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/users/distributor/list', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  listAdvertisers (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/users/advertiser/list', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  listChannels (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/users/channel/list', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  list (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/users', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  show (userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/users/${userId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  create (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/users', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  update (userId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/users/${userId}`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  delete (userId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/users/${userId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
