<template>
  <div>
    <!-- <loading v-show="isDataLoading"></loading> -->
    <advance-filter
      :is-loading="isDataLoading"
      @onStart="onFilter"
      @onReset="reset"
    >
      <div class="columns is-multiline">
        <div class="column field is-3">
          <label class="label is-small">Booking ID</label>
          <p class="control has-icons-left">
            <input
              class="input"
              type="text"
              placeholder="Enter booking id"
              v-model="filter.booking_id"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-search"></i>
            </span>
          </p>
        </div>
        <div class="column field is-3">
          <label class="label is-small">Workflow Dispatched Status</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              v-model="filter.is_dispatched"
              :options="['Yes', 'No']"
              placeholder="Pick option for filter"
              class="is-small"
            >
            </v-select>
          </div>
        </div>
        <div class="column field is-3">
          <label class="label is-small">Status</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              multiple
              v-model="filter.status"
              :options="['IN_PROCESS', 'PROCESSED', 'REFUNDED', 'CANCELLED']"
              placeholder="Pick statuses for filter"
              class="is-small"
            >
            </v-select>
          </div>
        </div>
      </div>
    </advance-filter>
    <div class="card card-section">
      <div class="card-content">
        <b-field
          grouped
          group-multiline
        >
          <b-select
            v-model="filter.size"
            :disabled="!hasItems"
            @input="onFilter"
          >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </b-select>
        </b-field>
        <b-table
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :mobile-cards="true"
          :loading="isDataLoading"
          paginated
          backend-pagination
          :per-page="filter.size"
          :total="meta.total"
          @page-change="onPageChange"
          backend-sorting
          default-sort-direction="desc"
          :default-sort="[filter.sortField, filter.sortOrder]"
          @sort="onSort"
          :data="items"
        >
          <b-table-column
            label="S.No."
            width="40"
            numeric
            v-slot="props"
          >
            {{ meta.from + props.index }}
          </b-table-column>
          <b-table-column
            field="booking_id"
            label="Booking ID"
            v-slot="props"
          >
            <strong>{{ props.row.booking_id }}</strong>
          </b-table-column>
          <b-table-column
            field="location"
            label="Ads Location"
            v-slot="props"
          >
            <strong>{{ props.row.location }}</strong>
          </b-table-column>
          <b-table-column
            field="start"
            width="200"
            label="Ads Run Time"
            v-slot="props"
          >
            <div><strong>{{ props.row.start }}</strong></div>
            <div class="has-text-centered">
              <i class="fas fa-angle-double-down is-hidden-mobile"></i>
              <i class="fas fa-angle-double-right is-flex-mobile" style="display: none;"></i>
            </div>
            <div><strong>{{ props.row.end }}</strong></div>
          </b-table-column>
          <b-table-column
            field="duration"
            label="Duration"
            v-slot="props"
          >
            <strong>{{ props.row.duration }} Secs.</strong>
          </b-table-column>
          <b-table-column
            cell-class="workflow-column"
            field="dispatched"
            label="Workflow Status"
            v-slot="props"
          >
            <div class="workflow-item has-text-centered" v-if="!props.row.dispatched">
              <div class="workflow-label has-text-weight-bold is-inline-flex-mobile">Waiting to Dispatch</div>
              <div class="workflow-arrow is-inline-flex-mobile">
                <i class="fas fa-angle-double-down is-hidden-mobile"></i>
                <i class="fas fa-angle-double-right is-flex-mobile" style="display: none;"></i>
              </div>
            </div>
            <div class="workflow-item has-text-centered" v-if="props.row.dispatched">
              <div class="workflow-label has-text-weight-bold is-inline-flex-mobile">Dispatched</div>
              <div class="workflow-arrow is-inline-flex-mobile">
                <i class="fas fa-angle-double-down is-hidden-mobile"></i>
                <i class="fas fa-angle-double-right is-flex-mobile" style="display: none;"></i>
              </div>
            </div>
            <div class="workflow-item has-text-centered" v-if="props.row.processed">
              <div class="workflow-label has-text-weight-bold is-inline-flex-mobile">Processed</div>
              <div class="workflow-arrow is-inline-flex-mobile">
                <i class="fas fa-angle-double-down is-hidden-mobile"></i>
                <i class="fas fa-angle-double-right is-flex-mobile" style="display: none;"></i>
              </div>
            </div>
            <div class="workflow-item has-text-centered" v-if="props.row.cancelled">
              <div class="workflow-label has-text-weight-bold is-inline-flex-mobile">Cancelled</div>
              <div class="workflow-arrow is-inline-flex-mobile">
                <i class="fas fa-angle-double-down is-hidden-mobile"></i>
                <i class="fas fa-angle-double-right is-flex-mobile" style="display: none;"></i>
              </div>
            </div>
            <div class="workflow-item has-text-centered" v-if="props.row.has_refund">
              <div class="workflow-label has-text-weight-bold is-inline-flex-mobile">Refunded</div>
              <div class="workflow-arrow is-inline-flex-mobile">
                <i class="fas fa-angle-double-down is-hidden-mobile"></i>
                <i class="fas fa-angle-double-right is-flex-mobile" style="display: none;"></i>
              </div>
            </div>
          </b-table-column>
          <b-table-column
            field="status"
            label="Status"
            v-slot="props"
          >
            <strong>{{ props.row.status | lowercase | nounderscore | titleize }}</strong>
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="emoticon-sad"
                    size="is-large"
                  > </b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import { mapActions } from 'vuex'
import { ORDER_ITEMS } from '@/store/actions/orderActions'

export default {
  name: 'OrderItemList',
  components: { AdvanceFilter },
  props: {
    orderId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncOrder'
  },
  data () {
    return {
      filter: {},
      items: [],
      meta: {
        total: 0
      },
      isDataLoading: false
    }
  },
  computed: {
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    }
  },
  methods: {
    ...mapActions('Orders', { loadList: ORDER_ITEMS }),
    onFilter () {
      this.loadAsyncData()
    },
    onPageChange (page) {
      this.filter.page = page
      this.onFilter()
    },
    onSort (field, order) {
      this.filter.sortField = field
      this.filter.sortOrder = order
      this.onFilter()
    },
    loadAsyncData () {
      if (!this.isDataLoading) {
        this.isDataLoading = true
        const data = this.getFilterData()
        this.loadList(data)
          .then((response) => {
            this.items = response.data
            this.meta = response.meta
          })
          .catch((error) => {
            this.$notify({
              title: 'Order Items Loading Failed',
              text: error.message,
              type: 'is-danger'
            })
          })
          .finally(() => {
            this.isDataLoading = false
          })
      }
    },
    getFilterData () {
      const data = {
        ...this.filter,
        orderId: this.orderId
      }
      return data
    },
    onReset () {
      this.reset()
      this.onFilter()
    },
    reset () {
      this.filter = {
        booking_id: null,
        is_dispatched: null,
        status: [],
        page: 1,
        size: 10,
        sortField: 'updated_at',
        sortOrder: 'desc'
      }
      this.items = []
      this.meta = {
        total: 0
      }
    }
  },
  created () {
    this.onReset()
  }
}
</script>
