import { WALLET_RECHARGE, WALLET_INFO, WALLET_BALANCE, WALLET_TRANSACTION_LIST } from '../../actions/walletActions'
import {
  RECHARGE_LOADING,
  RECHARGE_SUCCESS,
  RECHARGE_ERROR,
  INFO_LOADING,
  INFO_SUCCESS,
  INFO_ERROR,
  TRANSACTIONS_LOADING,
  TRANSACTIONS_SUCCESS,
  TRANSACTIONS_ERROR
} from '../../actions/walletStatus'
import walletApi from '../../../api/walletApi'

export default {
  namespaced: true,

  state: {
    rechargeStatus: '',
    balanceStatus: '',
    listStatus: ''
  },

  getters: {
    isRechargeLoading: (state) => state.rechargeStatus === RECHARGE_LOADING,
    isRechargeSuccess: (state) => state.rechargeStatus === RECHARGE_SUCCESS,
    isRechargeError: (state) => state.rechargeStatus === RECHARGE_ERROR,

    isInfoLoading: (state) => state.balanceStatus === INFO_LOADING,
    isInfoSuccess: (state) => state.balanceStatus === INFO_SUCCESS,
    isInfoError: (state) => state.balanceStatus === INFO_ERROR,

    isTransactionsLoading: (state) => state.listStatus === TRANSACTIONS_LOADING
  },

  actions: {
    [WALLET_RECHARGE]: ({ commit }, { userId, formData }) =>
      new Promise((resolve, reject) => {
        commit(RECHARGE_LOADING)
        walletApi
          .rechargeWallet(userId, formData)
          .then((response) => {
            commit(RECHARGE_SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(RECHARGE_ERROR)
            reject(error)
          })
      }),

    [WALLET_BALANCE]: ({ commit }, userId) =>
      new Promise((resolve, reject) => {
        commit(INFO_LOADING)
        walletApi
          .getWalletBalance(userId)
          .then((response) => {
            commit(INFO_SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(INFO_ERROR)
            reject(error)
          })
      }),

    [WALLET_INFO]: ({ commit }, { userId, filterData }) =>
      new Promise((resolve, reject) => {
        commit(INFO_LOADING)
        walletApi
          .getWalletInfo(userId, filterData)
          .then((response) => {
            commit(INFO_SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(INFO_ERROR)
            reject(error)
          })
      }),

    [WALLET_TRANSACTION_LIST]: ({ commit }, { userId, filterData }) =>
      new Promise((resolve, reject) => {
        commit(TRANSACTIONS_LOADING)
        walletApi
          .listTransactions(userId, filterData)
          .then((response) => {
            commit(TRANSACTIONS_SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(TRANSACTIONS_ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [RECHARGE_LOADING]: (state) => {
      state.rechargeStatus = RECHARGE_LOADING
    },
    [RECHARGE_SUCCESS]: (state) => {
      state.rechargeStatus = RECHARGE_SUCCESS
    },
    [RECHARGE_ERROR]: (state) => {
      state.rechargeStatus = RECHARGE_ERROR
    },
    [INFO_LOADING]: (state) => {
      state.balanceStatus = INFO_LOADING
    },
    [INFO_SUCCESS]: (state) => {
      state.balanceStatus = INFO_SUCCESS
    },
    [INFO_ERROR]: (state) => {
      state.balanceStatus = INFO_ERROR
    },
    [TRANSACTIONS_LOADING]: (state) => {
      state.listStatus = TRANSACTIONS_LOADING
    },
    [TRANSACTIONS_SUCCESS]: (state) => {
      state.listStatus = TRANSACTIONS_SUCCESS
    },
    [TRANSACTIONS_ERROR]: (state) => {
      state.listStatus = TRANSACTIONS_ERROR
    }
  }
}
