import { AUTH_LOAD, AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT, AUTH_LOGOUT_ALL } from '../../actions/authActions'
import { PROFILE_REQUEST } from '../../actions/profileActions'
import { WALLET_BALANCE_INFO } from '../../actions/myWalletActions'
import authApi from '../../../api/authApi'

export default {
  namespaced: true,

  state: {
    token: localStorage.getItem('access_token') || '',
    status: ''
  },

  getters: {
    isAuthenticated: (state) => !!state.token,
    authStatus: (state) => state.status
  },

  actions: {
    [AUTH_LOAD]: ({ dispatch }) => {
      const accessToken = localStorage.getItem('access_token')
      authApi.setAuthToken(accessToken)
      return dispatch(`Profile/${PROFILE_REQUEST}`, {}, { root: true })
    },
    [AUTH_REQUEST]: ({ commit, dispatch }, { email, password, rememberMe }) =>
      new Promise((resolve, reject) => {
        commit(AUTH_REQUEST)
        authApi
          .login(email, password, rememberMe)
          .then((response) => {
            localStorage.setItem('access_token', response.access_token)
            localStorage.setItem('expires_in', response.expires_in + Date.now())
            authApi.setAuthToken(response.access_token)
            commit(AUTH_SUCCESS, response)
            dispatch(`Profile/${PROFILE_REQUEST}`, {}, { root: true })
            dispatch(`Profile/${WALLET_BALANCE_INFO}`, {}, { root: true })
            resolve(response)
          })
          .catch((error) => {
            commit(AUTH_ERROR, error)
            localStorage.removeItem('access_token')
            reject(error)
          })
      }),
    [AUTH_LOGOUT]: ({ commit }) =>
      new Promise((resolve) => {
        commit(AUTH_LOGOUT)
        commit(`Profile/${AUTH_LOGOUT}`, {}, { root: true })
        localStorage.removeItem('access_token')
        localStorage.removeItem('expires_in')
        authApi
          .logout()
          .then(() => {
            authApi.setAuthToken('')
            resolve()
          })
          .catch(() => {
            authApi.setAuthToken('')
            resolve()
          })
      }),
    [AUTH_LOGOUT_ALL]: ({ commit }) =>
      new Promise((resolve) => {
        commit(AUTH_LOGOUT_ALL)
        commit(`Profile/${AUTH_LOGOUT_ALL}`, {}, { root: true })
        localStorage.removeItem('access_token')
        localStorage.removeItem('expires_in')
        authApi
          .logoutFromAll()
          .then(() => {
            authApi.setAuthToken('')
            resolve()
          })
          .catch(() => {
            authApi.setAuthToken('')
            resolve()
          })
      })
  },

  mutations: {
    [AUTH_REQUEST]: (state) => {
      state.status = 'loading'
    },
    [AUTH_SUCCESS]: (state, resp) => {
      state.status = 'success'
      state.token = resp.access_token
    },
    [AUTH_ERROR]: (state) => {
      state.status = 'error'
    },
    [AUTH_LOGOUT]: (state) => {
      state.token = ''
    },
    [AUTH_LOGOUT_ALL]: (state) => {
      state.token = ''
    }
  }
}
