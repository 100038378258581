<template>
  <div class="basic-module">
    <top-nav-bar class="has-shadow is-fixed-top" @toggleNav="toggleNav"></top-nav-bar>
    <div class="full-height-minus-header">
      <div class="main-content">
        <router-view></router-view>
      </div>
      <footer class="footer">
        <div class="container-fluid">
          <div class="content has-text-centered">
            <p>
              <strong>@Copyright 2021 SXM</strong>
            </p>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import TopNavBar from './components/navbars/TopNav'

export default {
  name: 'BasicModule',
  components: {
    'top-nav-bar': TopNavBar
  },
  data () {
    return {
      showNav: false
    }
  },
  methods: {
    toggleNav () {
      this.showNav = !this.showNav
    }
  }
}
</script>
