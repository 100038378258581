<template>
  <div
    v-if="campaign"
    class="kiosk"
  >
    <div
      class="columns is-gapless"
      v-for="(row, rIndex) in spaces"
      :key="`r-${rIndex}`"
    >
      <div
        class="column"
        v-for="(col, cIndex) in row"
        :key="`c-${cIndex}`"
      >
        <kiosk-space
          :id="`space-${rIndex}-${cIndex}`"
          :space="col"
          :can-ads-upload="canAdsUpload"
          @upload-ads="(adsFormData) => uploadSpaceAds(adsFormData, rIndex, cIndex)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import kioskMixins from '../../../../lib/kiosk-mixin'

export default {
  name: 'CampaignSingleScreenKiosk',
  mixins: [kioskMixins]
}
</script>
