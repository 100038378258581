import { axios } from './axios'

export default {
  deleteGallery (deviceId, galleryId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/devices/${deviceId}/gallery/${galleryId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  uploadGallery (deviceId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/devices/${deviceId}/gallery`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadThumbnail (deviceId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/devices/${deviceId}/thumbnail`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadAds (deviceId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/devices/${deviceId}/ads`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/devices', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  show (deviceId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/devices/${deviceId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  reportOperation (deviceId, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/devices/${deviceId}/report/operation`, {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  reportRevenue (deviceId, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/devices/${deviceId}/report/revenue`, {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  reportAggregateOperation (deviceId, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/devices/${deviceId}/report-metadata/operation`, {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  reportAggregateRevenue (deviceId, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/devices/${deviceId}/report-metadata/revenue`, {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  showDetails (deviceId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/devices/${deviceId}/details`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  create (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/devices', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  updateDeviceRelation (deviceId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/devices/${deviceId}/relations`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  updateDevicePrices (deviceId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/devices/${deviceId}/prices`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  update (deviceId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/devices/${deviceId}`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  uploadAdsByText (deviceId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/devices/${deviceId}/text-to-ads`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  delete (deviceId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/devices/${deviceId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  listWebsiteUserLogs (deviceId, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/devices/${deviceId}/website-user-logs`, {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  listPings (deviceId, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/devices/${deviceId}/pings`, {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  createPings (deviceId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/devices/${deviceId}/pings`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
