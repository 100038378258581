<template>
  <div>
    <!-- <loading v-show="isLoading"></loading> -->
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <advance-filter
      :is-loading="isLoading"
      @onStart="onFilter"
      @onReset="reset"
      :is-hide="false"
    >
      <div class="columns is-multiline">
        <div class="column field is-3">
          <label class="label is-small">Advertisement Type</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              v-model="filter.type"
              :options="type_options"
              placeholder="Pick type for filter"
              class="is-small"
            ></v-select>
          </div>
        </div>
        <div class="column field is-3">
          <label class="label is-small">Status</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              v-model="filter.status"
              :options="status_options"
              placeholder="Pick status for filter"
              class="is-small"
            ></v-select>
          </div>
        </div>
        <div class="column field is-3">
          <label class="label is-small">Start Time</label>
          <div class="control is-expanded has-icons-left">
            <input
              class="input"
              type="datetime-local"
              placeholder="Enter Start Time"
              v-model="filter.start"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-calendar"></i>
            </span>
          </div>
        </div>
        <div class="column field is-3">
          <label class="label is-small">End Time</label>
          <div class="control is-expanded has-icons-left">
            <input
              class="input"
              type="datetime-local"
              placeholder="Enter End Time"
              v-model="filter.end"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-calendar"></i>
            </span>
          </div>
        </div>
      </div>
    </advance-filter>

    <div class="card card-section">
      <div class="card-content">
        <b-field
          grouped
          group-multiline
        >
          <b-select
            v-model="filter.size"
            :disabled="!hasItems"
            @input="onFilter"
          >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </b-select>
        </b-field>
        <b-table
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :mobile-cards="true"
          :loading="isLoading"
          paginated
          backend-pagination
          :per-page="filter.size"
          :total="meta.total"
          @page-change="onPageChange"
          backend-sorting
          default-sort-direction="desc"
          :default-sort="[filter.sortField, filter.sortOrder]"
          @sort="onSort"
          :data="items"
        >
          <b-table-column
            label="S.No."
            width="40"
            numeric
            v-slot="props"
          >
            {{ meta.from + props.index }}
          </b-table-column>
          <b-table-column
            field="thumbnail"
            label="Thumbnail"
            width="150"
            v-slot="props"
          >
            <a
              v-if="props.row.thumbnails"
              href="#"
              aria-haspopup="true"
              aria-controls="burger-menu"
              @click.prevent="showVideo(props.row)"
            >
              <b-image
                :src="props.row.thumbnails.small"
                th:alt="props.row.advertiser.name"
                ratio="1by1"
              ></b-image>
            </a>
          </b-table-column>
          <b-table-column
            field="start"
            label="Details"
            sortable
            v-slot="props"
          >
            <strong>Start At: </strong>{{ props.row.start }}<br />
            <strong>End At: </strong>{{ props.row.end }}<br />
            <strong>Type: </strong>{{ props.row.type ? props.row.type : 'TEXT' }}<br />
            <strong>Duration: </strong>{{ props.row.duration }} Secs.
          </b-table-column>
          <b-table-column
            field="dispatched"
            label="Dispatched"
            v-slot="props"
          >
            <strong>Dispatched: </strong>{{ props.row.dispatched | yesOrNo }}
          </b-table-column>
          <b-table-column
            field="processed"
            label="Processed"
            v-slot="props"
          >
            <strong>Processed: </strong>{{ props.row.processed | yesOrNo }}
          </b-table-column>
          <b-table-column
            field="cancelled"
            label="Cancelled"
            v-slot="props"
          >
            <strong>Cancelled: </strong>{{ props.row.cancelled | yesOrNo }}
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="emoticon-sad"
                    size="is-large"
                  > </b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import { mapActions } from 'vuex'
import { DEVICE_ADS_HISTORY_LIST } from '@/store/actions/myActions'

export default {
  name: 'MyDeviceAdsHistoryList',
  components: {
    Breadcrumb,
    AdvanceFilter
  },
  props: {
    userView: {
      required: true
    },
    deviceId: {
      required: true
    }
  },
  data () {
    return {
      pageTitle: 'Device Ads History',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-desktop', routeName: 'MyDeviceList', name: 'Devices' },
          { icon: 'fas fa-tablet', routeName: 'MyDeviceShow', params: { deviceId: this.deviceId }, name: 'Device Details' }
        ],
        current: { icon: 'fab fa-adversal', name: 'Ads History' }
      },
      type_options: ['VIDEO', 'TEXT'],
      status_options: ['NOT DISPATCHED', 'DISPATCHED', 'NOT PROCESSED', 'PROCESSED', 'CANCELLED', 'CHANGED'],
      filter: {},
      items: [],
      meta: {},
      actionStatus: ''
    }
  },
  computed: {
    getDeviceId () {
      return this.deviceId
    },
    isLoading () {
      return this.actionStatus === 'LOADING'
    },
    isSuccess () {
      return this.actionStatus === 'SUCCESS'
    },
    isError () {
      return this.actionStatus === 'ERROR'
    },
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    }
  },
  methods: {
    ...mapActions('MyActions', { loadList: DEVICE_ADS_HISTORY_LIST }),
    onFilter () {
      if (!this.isLoading) {
        this.loadAsyncData()
      }
    },
    onPageChange (page) {
      if (!this.isLoading) {
        this.filter.page = page
        this.loadAsyncData()
      }
    },
    onSort (field, order) {
      if (!this.isLoading) {
        this.filter.sortField = field
        this.filter.sortOrder = order
        this.loadAsyncData()
      }
    },
    loadAsyncData () {
      if (!this.isLoading) {
        this.actionStatus = 'LOADING'
        const data = {
          userView: this.userView,
          deviceId: this.deviceId,
          formData: this.filter
        }
        this.loadList(data)
          .then((response) => {
            this.actionStatus = 'SUCCESS'
            this.items = response.data
            this.meta = response.meta
          })
          .catch((error) => {
            this.actionStatus = 'ERROR'
            this.$notify({
              title: 'Reservation Loading Failed',
              text: error.message,
              type: 'is-danger'
            })
          })
      }
    },
    reset () {
      this.filter = {
        type: 'VIDEO',
        status: '',
        agent: null,
        distributer: null,
        advertiser: null,
        start: null,
        end: null,
        page: 1,
        size: 10,
        sortField: 'updated_at',
        sortOrder: 'desc'
      }
      this.items = []
      this.meta = {
        total: 0
      }
      this.onFilter()
    },
    showVideo (item) {
      let itemStatusMsg = ''
      if (item.processed) {
        itemStatusMsg = 'This advertisement is already viewed'
      } else if (item.cancelled) {
        itemStatusMsg = 'This advertisement is cancelled'
      } else if (item.changed) {
        itemStatusMsg = 'This advertisement is replaced with another advertisement'
      } else if (item.dispatched) {
        itemStatusMsg = 'This advertisement is sent to the device for viewing'
      } else {
        itemStatusMsg = 'This advertisement is waiting to sent to the device'
      }
      const h = this.$createElement
      const vnode = h('div', { attrs: { id: 'monitor' } }, [
        h('div', { attrs: { id: 'monitorscreen' } }, [
          h('div', { attrs: { class: 'scroll-left' } }, [
            h('p', itemStatusMsg)
          ]),
          h('video', { attrs: { controls: true, autoplay: true } }, [
            h('source', { attrs: { src: item.path, type: 'video/mp4' } })
          ])
        ])
      ])
      this.$buefy.modal.open({
        customClass: 'video-model',
        content: [vnode]
      })
    }
  },
  created () {
    this.reset()
  }
}
</script>
