<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="website"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ website.name }}</p>
                  </div>
                </div>
                <div class="content">
                  <table class="table is-striped">
                    <tbody>
                      <tr>
                        <th>Description</th>
                        <td>{{ website.description || 'NA' }}</td>
                      </tr>
                      <tr>
                        <th>Website (HTTP) Link</th>
                        <td>{{ website.link || 'NA' }}</td>
                      </tr>
                      <tr>
                        <th>Whitelist URLs</th>
                        <td>{{ website.whitelist_urls || 'NA' }}</td>
                      </tr>
                      <tr>
                        <th>Blacklist URLs</th>
                        <td>{{ website.blacklist_urls || 'NA' }}</td>
                      </tr>
                      <tr>
                        <th>Order No.</th>
                        <td>{{ website.order_no }}</td>
                      </tr>
                      <tr>
                        <th>Status</th>
                        <td>{{ website.status }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="field is-grouped">
                    <p class="control">
                      <router-link
                        :to="{ name: 'WebsiteList'}"
                        class="button is-primary"
                      >
                        <span class="icon">
                          <i class="fas fa-th-list"></i>
                        </span>
                        <span>LIST</span>
                      </router-link>
                    </p>
                    <p class="control">
                      <router-link
                        :to="{ name: 'WebsiteEdit', params: { websiteId: website.id }}"
                        class="button is-warning"
                      >
                        <span class="icon">
                          <i class="fas fa-edit"></i>
                        </span>
                        <span>EDIT</span>
                      </router-link>
                    </p>
                    <p class="control">
                      <a
                        class="button is-danger"
                        @click.prevent="confirmDeleteWebsite"
                      >
                        <span class="icon">
                          <i class="fas fa-trash"></i>
                        </span>
                        <span>DELETE</span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-image">
                <figure class="image is-4by3">
                  <img
                    v-if="!website.thumbnails"
                    src="/img/placeholders/image-placeholder.png"
                    alt="Website Image"
                  >
                  <img
                    v-if="website.thumbnails"
                    :src="website.thumbnails.large"
                    alt="Website Image"
                  >
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { WEBSITE_SHOW, WEBSITE_DELETE } from '@/store/actions/websiteActions'

export default {
  name: 'WebsiteShow',
  components: {
    Breadcrumb
  },
  props: {
    websiteId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncWebsite'
  },
  data () {
    return {
      pageTitle: 'Website Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-globe', routeName: 'WebsiteList', name: 'Manage Websites' }
        ],
        current: { icon: 'fas fa-eye', name: 'Website Details' },
        actions: [{ icon: 'fas fa-globe', routeName: 'WebsiteList', name: 'Manage Websites' }]
      },
      website: null
    }
  },
  computed: {
    ...mapGetters('Websites', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Websites', { loadWebsite: WEBSITE_SHOW, sendDeleteWebsiteRequest: WEBSITE_DELETE }),
    confirmDeleteWebsite () {
      this.$buefy.dialog.confirm({
        title: 'Deleting Website',
        message: 'Are you sure you want to <b>delete</b> this website? This action cannot be undone.',
        confirmText: 'Delete Website',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deleteWebsite()
      })
    },
    deleteWebsite () {
      this.sendDeleteWebsiteRequest(this.website.id)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.$router.replace({ name: 'WebsiteList' })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncWebsite () {
      this.website = null
      this.loadWebsite(this.websiteId)
        .then((response) => {
          this.website = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Website Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'WebsiteList' }),
            onConfirm: () => this.$router.replace({ name: 'WebsiteList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncWebsite()
  }
}
</script>
