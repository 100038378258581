<template>
  <card
    title="Ads Running Status"
    :init-view="true"
  >
    <img
      v-if="error"
      title="Error on fetching report"
      alt="Error on fetching report"
      src="@/assets/cloud-error.png"
      style="max-height: 395px;"
    />
    <div v-else>
      <div class="box relative-position notification is-primary" v-for="(countRecord, index) in countRecords" :key="countRecord.status" :style="{ 'background-color': backgroundColors[index]}">
        <article class="top-radius">
          <p class="title is-4">{{ countRecord.total }}</p>
          <p class="subtitle is-6">Advertisements</p>
        </article>
        <span class="tag is-link top-right">{{ countRecord.status }}</span>
      </div>
    </div>
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </card>
</template>

<script>
import { mapActions } from 'vuex'
import { REPORT_ADS_RUNNING_STATUS_COUNT } from '@/store/actions/reportActions'
import Card from '@/lib/Card'

export default {
  name: 'AdsRunningStatus',
  components: {
    Card
  },
  data () {
    return {
      error: false,
      isLoading: true,
      countRecords: [],
      backgroundColors: ['#5E35B1', '#00BCD4', '#4CAF50', '#FF9800', '#D500F9', '#18FFFF']
    }
  },
  methods: {
    ...mapActions('Reports', { loadReportAdsRunningStatusCount: REPORT_ADS_RUNNING_STATUS_COUNT })
  },
  mounted () {
    this.isLoading = true
    this.error = false
    const filterData = {}
    this.loadReportAdsRunningStatusCount(filterData)
      .then((response) => {
        if (response.length > 0) {
          this.countRecords = response
          this.isLoading = false
        } else {
          this.isLoading = true
        }
      })
      .catch((error) => {
        this.error = true
        this.isLoading = false
        this.$notify({
          title: 'Display Working Status',
          text: error.message,
          type: 'is-danger'
        })
      })
  }
}
</script>
