var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isNavShow)?_c('li',{class:{'has-dropdown' : _vm.item.children, 'is-hidden-tablet' : _vm.item.mobileOnly}},[(!_vm.isRouteNameOrLinkExists)?_c('a',{class:_vm.expandClass,on:{"click":function($event){$event.preventDefault();return _vm.toggleSubmenu.apply(null, arguments)}}},[(_vm.item.icon)?_c('span',{staticClass:"icon"},[_c('i',{class:_vm.item.icon})]):_vm._e(),_vm._v(" "+_vm._s(_vm.item.name)+" ")]):_vm._e(),(_vm.isRouteName)?_c('router-link',{attrs:{"to":{ name: _vm.item.routeName, params: _vm.item.params},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{class:{ 'is-active is-open-active router-link-active': isActive },attrs:{"href":href},on:{"click":navigate}},[(_vm.item.icon)?_c('span',{staticClass:"icon"},[_c('i',{class:_vm.item.icon})]):_vm._e(),_vm._v(" "+_vm._s(_vm.item.name)+" ")])]}}],null,false,2182182206)}):_vm._e(),(_vm.isRouteLink)?_c('router-link',{attrs:{"to":{ path: _vm.item.routeName, params: _vm.item.params},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{class:{ 'is-active is-open-active router-link-active': isActive },attrs:{"href":href},on:{"click":navigate}},[(_vm.item.icon)?_c('span',{staticClass:"icon"},[_c('i',{class:_vm.item.icon})]):_vm._e(),_vm._v(" "+_vm._s(_vm.item.name)+" ")])]}}],null,false,2182182206)}):_vm._e(),(_vm.item.children)?_c('ul',{class:{'is-active' : _vm.expandMenu }},_vm._l((_vm.item.children),function(submenu,index){return _c('menu-item',{key:index + '-' + _vm.item.name,attrs:{"name":_vm.item.name,"item":submenu}})}),1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }