<template>
  <!-- <div class="columns"> -->
  <div class="">
    <!-- <back-to-top visibleoffset="170" class="offsetdevicepick" style="bottom: none; right: none;">
      <div class="card filters booking-filter" v-if="meta">
        <div class="card-content">
          <b-checkbox @input="selectAllItems">Select All ({{ bookingIds.length }} Items)</b-checkbox>
          <b-checkbox @input="selectAllItemsBooking">Select {{ meta.total }} items</b-checkbox>
          <button @click="onProceedClick" class="button is-success"><span>Proceed Booking</span><i class="fas fa-hand-point-right"></i></button>
        </div>
      </div>
    </back-to-top> -->
    <!-- <booking-filter class="column is-one-quarter search-sidebar" :ads-data="adsData" :ads-type="adsType" @on-filter="onFilterUpdates"></booking-filter> -->
    <booking-filter
      class="search-sidebar"
      :form-filter="formFilter"
      :ads-data="adsData"
      :ads-type="adsType"
      @on-filter="onFilterUpdates"
    ></booking-filter>
    <!-- <div class="column booking-search-content"> -->
    <div class="mt-3 booking-search-content">
      <div
        class="card filters booking-filter"
        v-if="meta"
      >
        <div class="card-content">
          <b-checkbox
            class="my-2"
            @input="selectAllItems"
          >Select All ({{ bookingIds.length }} Items)</b-checkbox>
          <b-checkbox
            class="my-2"
            @input="selectAllItemsBooking"
          >Select {{ meta.total }} items</b-checkbox>
          <button
            @click="onProceedClick"
            class="button is-success"
          ><span class="pr-3">Proceed Booking</span><i class="fas fa-hand-point-right"></i></button>
        </div>
      </div>
      <back-to-top
        visibleoffset="400"
        class="offsetdevicepick is-hidden-mobile"
        style="bottom: none; right: none;"
      >
        <div
          class="card filters booking-filter"
          v-if="meta"
        >
          <div class="card-content">
            <b-checkbox
              class="my-2"
              @input="selectAllItems"
            >Select All ({{ bookingIds.length }} Items)</b-checkbox>
            <b-checkbox
              class="my-2"
              @input="selectAllItemsBooking"
            >Select {{ meta.total }} items</b-checkbox>
            <button
              @click="onProceedClick"
              class="button is-success"
            ><span class="pr-3">Proceed Booking</span><i class="fas fa-hand-point-right"></i></button>
          </div>
        </div>
      </back-to-top>
      <div
        class="columns is-multiline"
        v-if="items"
      >
        <div
          class="column is-half-tablet is-one-quarter-desktop"
          v-for="(item, index) in items"
          :key="`item-${item.device.id}-${index}`"
        >
          <booking-reservation
            :item="item"
            :bookingIds="bookingIds"
            @selected="toggleDevideForBooking"
          ></booking-reservation>
        </div>
      </div>
      <infinite-loading
        :identifier="infiniteId"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <h1
          slot="no-more"
          class="title is-3 has-text-centered"
        >There is no more devices found!</h1>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import BackToTop from 'vue-backtotop'
import BookingFilter from './BookingFilter'
import BookingReservation from './BookingReservation'
import { RESERVATION_SEARCH } from '@/store/actions/bookingActions'

export default {
  name: 'BookingDevicesPicker',
  components: { BookingFilter, InfiniteLoading, BookingReservation, BackToTop },
  props: {
    adsData: {
      required: true,
      default: null
    },
    adsType: {
      required: true,
      default: null
    },
    deviceIds: {
      required: true,
      default: () => []
    },
    formFilter: {
      required: false,
      default: () => { }
    }
  },
  data () {
    return {
      filter: {},
      filterTimout: null,
      nextUrl: null,
      meta: null,
      items: null,
      bookingIds: [],
      isBookedForAll: false,
      isAllItemSelected: false,
      infiniteId: +new Date()
    }
  },
  watch: {
    filter: {
      handler: 'onFilterChanged',
      deep: true
    },
    deviceIds: {
      handler: 'onDeviceIdUpdated',
      deep: true
    }
  },
  computed: {
    totalSelectedDeviceCount () {
      return this.isBookedForAll ? this.meta.total : this.bookingIds.length
    }
  },
  methods: {
    ...mapActions('Bookings', { searchReservation: RESERVATION_SEARCH }),
    onFilterUpdates (filterData) {
      this.filter = filterData
    },
    onDeviceIdUpdated () {
      this.bookingIds = this.deviceIds
    },
    onFilterChanged () {
      clearTimeout(this.filterTimout)
      this.nextUrl = null
      this.meta = null
      // this.bookingIds = []
      this.isBookedForAll = false
      this.isAllItemSelected = false
      this.items = []
      this.$nextTick(() => {
        this.infiniteId += 1
      })
    },
    infiniteHandler ($state) {
      if (this.items === null || this.adsData === null || this.adsType === null) {
        $state.complete()
      } else {
        this.onSearch().then(({ meta, data, links }) => {
          this.meta = meta
          data.forEach((ads) => {
            this.items.push(ads)
          })
          $state.loaded()
          if (links.next === null) {
            $state.complete()
          } else {
            this.nextUrl = links.next
          }
        })
      }
    },
    onSearch () {
      return new Promise((resolve, reject) => {
        clearTimeout(this.filterTimout)
        const filterData = JSON.parse(JSON.stringify(this.filter))
        if (this.adsData.tags !== null && this.adsData.tags !== undefined) {
          this.adsData.tags.forEach(tag => {
            if (filterData.tags === undefined && filterData.tags === null && filterData.tags === '') {
              filterData.tags = `${tag.id}`
            } else if (!(filterData.tags.includes(`,${tag.id},`) || filterData.tags.startsWith(`${tag.id},`) || filterData.tags.endsWith(`,${tag.id}`) || filterData.tags === `${tag.id}`)) {
              filterData.tags = `${filterData.tags},${tag.id}`
            }
          })
        }
        const formData = { ...filterData, type: this.adsType, next: this.nextUrl }
        this.filterTimout = setTimeout(() => {
          this.searchReservation(formData)
            .then((response) => {
              resolve({ meta: response.meta, data: response.data, links: response.links })
            })
            .catch((error) => {
              reject(error)
            })
        }, 1000)
      })
    },
    toggleDevideForBooking (itemId) {
      const itemIndex = this.bookingIds.indexOf(itemId)
      if (itemIndex !== -1) {
        this.bookingIds.splice(itemIndex, 1)
      } else {
        this.bookingIds.push(itemId)
      }
      this.isBookedForAll = false
      this.isAllItemSelected = false
    },
    selectAllItems (value) {
      this.isAllItemSelected = value
      if (this.isAllItemSelected) {
        this.bookingIds = []
        this.items.forEach((item) => {
          this.bookingIds.push(item.device.id)
        })
      } else {
        this.bookingIds = []
      }
    },
    selectAllItemsBooking (value) {
      this.isBookedForAll = value
    },
    onProceedClick () {
      if (this.filter.date === undefined || this.filter.date === null || this.filter.date === '') {
        this.$notify({
          title: 'No Booking Date Found',
          text: 'Please select booking date on you want to run your advertisements...',
          type: 'is-danger'
        })
      } else if (this.totalSelectedDeviceCount <= 0) {
        this.$notify({
          title: 'No Booking Device Found',
          text: 'Please select devices on you want to run your advertisements...',
          type: 'is-danger'
        })
      } else if (this.isBookedForAll) {
        this.$buefy.dialog.confirm({
          title: `Booking ${this.meta.total} Devices`,
          message: 'Are you sure you want to <b>book</b> all this devices? This action cannot be undone.',
          confirmText: 'Proceed',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.proceedBooking()
          }
        })
      } else {
        this.proceedBooking()
      }
    },
    proceedBooking () {
      const data = {
        filter: this.filter,
        bookingIds: this.bookingIds,
        isBookedForAll: this.isBookedForAll,
        totalDeviceSelected: this.totalSelectedDeviceCount
      }
      this.$emit('proceed-booking', data)
    }
  }
}
</script>

<style lang="sass" scoped>
.search-sidebar
  padding-top: 0px
  padding-left: 0px
</style>
