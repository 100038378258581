import {
  WEBSITE_LIST,
  WEBSITE_CREATE,
  WEBSITE_SHOW,
  WEBSITE_THUMBNAIL,
  WEBSITE_UPDATE,
  WEBSITE_DELETE
} from '../../actions/websiteActions'

import { LOADING, SUCCESS, ERROR, LOADING_THUMBNAIL, SUCCESS_THUMBNAIL, ERROR_THUMBNAIL } from '../../actions/requestStatus'
import websiteApi from '../../../api/websiteApi'

export default {
  namespaced: true,

  state: {
    status: '',
    thumbnailStatus: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR,
    isThumbnailLoading: (state) => state.thumbnailStatus === LOADING_THUMBNAIL,
    isThumbnailSuccess: (state) => state.thumbnailStatus === SUCCESS_THUMBNAIL,
    isThumbnailError: (state) => state.thumbnailStatus === ERROR_THUMBNAIL
  },

  actions: {
    [WEBSITE_THUMBNAIL]: ({ commit }, { websiteId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING_THUMBNAIL)
        websiteApi
          .uploadThumbnail(websiteId, formData)
          .then((response) => {
            commit(SUCCESS_THUMBNAIL)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR_THUMBNAIL)
            reject(error)
          })
      }),

    [WEBSITE_SHOW]: ({ commit }, websiteId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        websiteApi
          .show(websiteId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [WEBSITE_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        websiteApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [WEBSITE_CREATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        websiteApi
          .create(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [WEBSITE_UPDATE]: ({ commit }, { websiteId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        websiteApi
          .update(websiteId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [WEBSITE_DELETE]: ({ commit }, websiteId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        websiteApi
          .delete(websiteId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    },
    [LOADING_THUMBNAIL]: (state) => {
      state.thumbnailStatus = LOADING
    },
    [SUCCESS_THUMBNAIL]: (state) => {
      state.thumbnailStatus = SUCCESS
    },
    [ERROR_THUMBNAIL]: (state) => {
      state.thumbnailStatus = ERROR
    }
  }
}
