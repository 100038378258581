import { WALLET_INFO, WALLET_TRANSACTION_LIST } from '../../actions/myWalletActions'
import {
  INFO_LOADING,
  INFO_SUCCESS,
  INFO_ERROR,
  TRANSACTIONS_LOADING,
  TRANSACTIONS_SUCCESS,
  TRANSACTIONS_ERROR
} from '../../actions/myWalletStatus'
import walletApi from '../../../api/myWalletApi'

export default {
  namespaced: true,

  state: {
    balanceStatus: '',
    listStatus: ''
  },

  getters: {
    isInfoLoading: (state) => state.balanceStatus === INFO_LOADING,
    isInfoSuccess: (state) => state.balanceStatus === INFO_SUCCESS,
    isInfoError: (state) => state.balanceStatus === INFO_ERROR,

    isTransactionsLoading: (state) => state.listStatus === TRANSACTIONS_LOADING
  },

  actions: {
    [WALLET_INFO]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(INFO_LOADING)
        walletApi
          .getWalletInfo()
          .then((response) => {
            commit(INFO_SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(INFO_ERROR)
            reject(error)
          })
      }),

    [WALLET_TRANSACTION_LIST]: ({ commit }, { filterData }) =>
      new Promise((resolve, reject) => {
        commit(TRANSACTIONS_LOADING)
        walletApi
          .listTransactions(filterData)
          .then((response) => {
            commit(TRANSACTIONS_SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(TRANSACTIONS_ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [INFO_LOADING]: (state) => {
      state.balanceStatus = INFO_LOADING
    },
    [INFO_SUCCESS]: (state) => {
      state.balanceStatus = INFO_SUCCESS
    },
    [INFO_ERROR]: (state) => {
      state.balanceStatus = INFO_ERROR
    },
    [TRANSACTIONS_LOADING]: (state) => {
      state.listStatus = TRANSACTIONS_LOADING
    },
    [TRANSACTIONS_SUCCESS]: (state) => {
      state.listStatus = TRANSACTIONS_SUCCESS
    },
    [TRANSACTIONS_ERROR]: (state) => {
      state.listStatus = TRANSACTIONS_ERROR
    }
  }
}
