import {
  PAGE_LIST,
  PAGE_CREATE,
  PAGE_SHOW,
  PAGE_DETAILS,
  PAGE_UPDATE,
  PAGE_DELETE,
  PAGE_THUMBNAIL
} from '../../actions/pageActions'

import { LOADING, SUCCESS, ERROR, LOADING_THUMBNAIL, SUCCESS_THUMBNAIL, ERROR_THUMBNAIL } from '../../actions/requestStatus'
import pageApi from '../../../api/pageApi'

export default {
  namespaced: true,

  state: {
    status: '',
    thumbnailStatus: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR,
    isThumbnailLoading: (state) => state.thumbnailStatus === LOADING_THUMBNAIL,
    isThumbnailSuccess: (state) => state.thumbnailStatus === SUCCESS_THUMBNAIL,
    isThumbnailError: (state) => state.thumbnailStatus === ERROR_THUMBNAIL
  },

  actions: {
    [PAGE_THUMBNAIL]: ({ commit }, { pageId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING_THUMBNAIL)
        pageApi
          .uploadThumbnail(pageId, formData)
          .then((response) => {
            commit(SUCCESS_THUMBNAIL)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR_THUMBNAIL)
            reject(error)
          })
      }),

    [PAGE_SHOW]: ({ commit }, pageId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        pageApi
          .show(pageId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [PAGE_DETAILS]: ({ commit }, pageId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        pageApi
          .showDetails(pageId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [PAGE_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        pageApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [PAGE_CREATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        pageApi
          .create(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [PAGE_UPDATE]: ({ commit }, { pageId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        pageApi
          .update(pageId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [PAGE_DELETE]: ({ commit }, pageId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        pageApi
          .delete(pageId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    },
    [LOADING_THUMBNAIL]: (state) => {
      state.thumbnailStatus = LOADING_THUMBNAIL
    },
    [SUCCESS_THUMBNAIL]: (state) => {
      state.thumbnailStatus = SUCCESS_THUMBNAIL
    },
    [ERROR_THUMBNAIL]: (state) => {
      state.thumbnailStatus = ERROR_THUMBNAIL
    }
  }
}
