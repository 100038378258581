<template>
  <card
    title="Screen Working Status"
    :init-view="true"
  >
    <img
      v-if="error"
      title="Error on fetching report"
      alt="Error on fetching report"
      src="@/assets/cloud-error.png"
      style="max-height: 395px;"
    />
    <doughnut
      v-else
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </card>
</template>

<script>
import { mapActions } from 'vuex'
import { Doughnut } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js'
import { REPORT_DEVICE_WORKING_STATUS_COUNT } from '@/store/actions/reportActions'
import Card from '@/lib/Card'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'KiosksWorkingDoughnut',
  components: {
    Doughnut, Card
  },
  props: {
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      error: false,
      isLoading: true,
      chartData: {
        labels: ['Online', 'Offline'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651'],
            data: [1, 1]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      bgColors: {
        ONLINE: '#41B883',
        OFFLINE: '#E46651'
      }
    }
  },
  methods: {
    ...mapActions('Reports', { loadReportDeviceWorkingStatusCount: REPORT_DEVICE_WORKING_STATUS_COUNT })
  },
  mounted () {
    this.isLoading = true
    this.error = false
    const filterData = {}
    this.loadReportDeviceWorkingStatusCount(filterData)
      .then((response) => {
        if (response.length > 0) {
          const data = {
            labels: [],
            datasets: [
              {
                backgroundColor: [],
                data: []
              }
            ]
          }
          response.forEach(statusObject => {
            data.labels.push(statusObject.status)
            data.datasets[0].backgroundColor.push(this.bgColors[statusObject.status])
            data.datasets[0].data.push(statusObject.total)
          })
          this.chartData = data
          this.isLoading = false
        } else {
          this.isLoading = true
        }
      })
      .catch((error) => {
        this.error = true
        this.isLoading = false
        this.$notify({
          title: 'Display Working Status',
          text: error.message,
          type: 'is-danger'
        })
      })
  }
}
</script>
