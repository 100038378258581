import { axios } from './axios'

export default {
  list (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/orders', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  showItems (filterData) {
    return new Promise((resolve, reject) => {
      const orderId = filterData.orderId
      if (orderId === undefined || orderId === null) {
        reject(new Error('No order id found'))
      } else {
        axios
          .get(`/api/orders/${orderId}/items`, {
            params: filterData
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error.data)
          })
      }
    })
  },

  summaryFinancial (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/orders/summary/financial', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  summarySlots (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/orders/summary/slots', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  show (orderId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/orders/${orderId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  changeOrderAds (orderId, adsId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/orders/${orderId}/ads/${adsId}/change`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
