<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="card card-section">
      <div class="card-content">
        <b-tabs
          v-model="activeTab"
          v-if="form"
        >
          <b-tab-item label="General Properties">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">Name</label>
                  <div class="control">
                    <input
                      v-model="form.name"
                      class="input"
                      type="text"
                      placeholder="Enter Campaign Name"
                      :class="{'is-danger' : errors.name}"
                    />
                  </div>
                  <p
                    v-if="errors.name"
                    class="help is-danger"
                  >{{ errors.name | implode }}</p>
                </div>
                <b-field label="Agent">
                  <b-autocomplete
                    placeholder="Type agent to search and select"
                    field="name"
                    :keep-first="true"
                    :data="autocomplete.agent.items"
                    v-model="autocomplete.agent.value"
                    :loading="autocomplete.agent.isLoading"
                    @input="loadAgentsSuggestion"
                    @select="option => form.agent = option"
                  >
                    <template slot="empty">No agent found</template>
                    <template slot-scope="props">
                      <div class="media">
                        <div class="media-content">
                          {{ props.option.name }}
                          <br>
                          <small>
                            {{ props.option.email }},
                          </small>
                        </div>
                      </div>
                    </template>
                  </b-autocomplete>
                  <p
                    v-if="errors.agent"
                    class="help is-danger"
                  >{{ errors.agent | implode }}</p>
                </b-field>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Campaign Type</label>
                  <div class="control">
                    <v-select
                      v-model="form.type"
                      :options="types"
                      :class="{'is-danger' : errors.type}"
                      placeholder="Pick type of campaign"
                      class="is-small"
                    ></v-select>
                  </div>
                  <p
                    v-if="errors.type"
                    class="help is-danger"
                  >{{ errors.type | implode }}</p>
                </div>
                <div class="field">
                  <label class="label">Status</label>
                  <div class="control">
                    <v-select
                      v-model="form.status"
                      :options="status_options"
                      :class="{'is-danger' : errors.status}"
                      placeholder="Pick status for campaign"
                      class="is-small"
                    ></v-select>
                  </div>
                  <p
                    v-if="errors.status"
                    class="help is-danger"
                  >{{ errors.status | implode }}</p>
                </div>
              </div>
            </div>
            <div class="field is-campaigned action-buttons">
              <div class="control">
                <button
                  type="button"
                  :disabled="isLoading"
                  class="button is-primary is-rounded"
                  @click="formSubmit"
                  :class="{ 'is-loading': isLoading }"
                >Create</button>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item
            label="Picture Gallery"
            :disabled="true"
          ></b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { CAMPAIGN_CREATE } from '@/store/actions/campaignActions'
import { AGENT_LIST } from '@/store/actions/userActions'

export default {
  name: 'CampaignCreate',
  components: {
    Breadcrumb
  },
  data () {
    return {
      activeTab: 0,
      isSlugEditable: false,
      pageTitle: 'New Campaign Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-desktop', routeName: 'CampaignList', name: 'Manage Campaigns' }
        ],
        current: { icon: 'fas fa-plus', name: 'New Campaign' },
        actions: [{ icon: 'fas fa-desktop', routeName: 'CampaignList', name: 'Manage Campaigns' }]
      },
      status_options: ['IDEAL'],
      types: ['SINGLE-SCREEN-KIOSK', 'SCREEN-WITH-RIGHT-SIDE-KIOSK', 'INTERACTIVE-KIOSK', 'CUSTOM-KIOSK'],
      form: null,
      errors: {},
      autocomplete: {
        agent: {
          value: null,
          items: [],
          isLoading: false,
          timeout: null
        }
      }
    }
  },
  computed: {
    ...mapGetters('Campaigns', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Campaigns', { createCampaign: CAMPAIGN_CREATE }),
    ...mapActions('Users', { loadAgents: AGENT_LIST }),
    formSubmit () {
      this.errors = {}
      this.createCampaign(this.form)
        .then((response) => {
          this.$notify({
            title: 'Campaign Saved as Ideal',
            text: `(${response.data.uuid}) Campaign is created and saved as ${response.data.status} successfully`,
            type: 'is-success'
          })
          this.$router.push({
            name: 'CampaignEdit',
            params: { campaignId: response.data.id }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Campaign Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAgentsSuggestion () {
      clearTimeout(this.autocomplete.agent.timeout)
      this.autocomplete.agent.timeout = setTimeout(() => {
        const data = {
          query: this.autocomplete.agent.value
        }
        if (data.query) {
          this.autocomplete.agent.isLoading = true
          this.loadAgents(data)
            .then((response) => {
              this.autocomplete.agent.items = response.data
              this.autocomplete.agent.isLoading = false
            })
            .catch((error) => {
              this.autocomplete.agent.isLoading = false
              this.$notify({
                title: 'Agent Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    },
    onReset () {
      this.errors = {}
      this.form = {
        name: '',
        status: 'IDEAL',
        agent: null,
        type: null
      }
      this.$notify({
        title: 'Reset Form',
        text: 'Campaign form is reset with default value, please continue...',
        type: 'is-info'
      })
    }
  },
  created () {
    this.onReset()
  }
}
</script>
