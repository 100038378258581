import { axios } from './axios'

export default {
  list (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/reservations', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  show (reservationId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/reservations/${reservationId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
