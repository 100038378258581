export const RECHARGE_LOADING = 'RECHARGE_LOADING'
export const RECHARGE_SUCCESS = 'RECHARGE_SUCCESS'
export const RECHARGE_ERROR = 'RECHARGE_ERROR'

export const INFO_LOADING = 'INFO_LOADING'
export const INFO_SUCCESS = 'INFO_SUCCESS'
export const INFO_ERROR = 'INFO_ERROR'

export const TRANSACTIONS_LOADING = 'TRANSACTIONS_LOADING'
export const TRANSACTIONS_SUCCESS = 'TRANSACTIONS_SUCCESS'
export const TRANSACTIONS_ERROR = 'TRANSACTIONS_ERROR'
