<template>
  <div
    class="card reservation-card"
    v-if="item"
    @click="itemClicked"
    :class="{ 'is-selected' : isItemSelected }"
  >
    <div class="card-content">
      <div class="media">
        <div
          class="media-content"
          v-if="item.advertiser"
        >
          <p class="title is-7">{{ item.advertiser.name }} @Advertiser</p>
        </div>
      </div>
      <div class="content">
        <div class="control card-style">
          <h6 class="stick-header">Name</h6>
          <strong>{{ item.name }}</strong>
        </div>
        <div class="control card-style">
          <h6 class="stick-header">Ads Text</h6>
          <span class="stick-body">{{ shortText }}</span>
        </div>
        <div class="control card-style">
          <h6 class="stick-header">Duration</h6>
          <span class="stick-body">{{ item.duration }} Seconds</span>
        </div>
        <div class="control card-style">
          <h6 class="stick-header">Tags</h6>
          <div class="tags-text">
            <span
              v-for="tag in item.tags"
              :key="`tag-${tag.id}`"
            >{{ tag.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BookingAdvertisement',
  props: {
    item: {
      required: true
    },
    ads: {
      required: false,
      default: null
    }
  },
  computed: {
    isItemSelected () {
      return this.ads !== null && this.ads.id === this.item.id
    },
    shortText () {
      if (this.item.ads_text.length > 100) {
        return this.item.ads_text.substring(0, 100)
      }
      return this.item.ads_text
    }
  },
  methods: {
    itemClicked () {
      // this.isItemSelected = !this.isItemSelected;
      this.$emit('selected', this.item)
    }
  }
}
</script>

<style lang="sass" scoped>
.control.card-style
  background-color: #dadada
  padding: 20px 10px 10px 10px
  margin-bottom: 5px
  .stick-header
    position: absolute
    left: 0px
    top: 0px
    background-color: #0882b1
    color: #fff
    padding: 5px
    font-size: 10px
    border-radius: 0px 0px 6px 0px
  .stick-body
    font-size: 12px
  .tags
    display: inline-flex
    margin-right: 10px !important
    margin-bottom: 0px !important
.reservation-card
  .card-content
    padding: 0.5rem
.tags-text
  font-size: 12px
  font-weight: 700
.tags-text > span::before
  content: ', '
.tags-text > span:first-child::before
  content: ''
</style>
