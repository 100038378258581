<template>
  <nav
    class="navbar"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a
        href="#"
        class="navbar-item"
      >
        <img
          class="logo-top-nav"
          src="@/assets/logo-horizontal.png"
        />
      </a>
      <div
        class="navbar-burger aside-burger"
        :class="{ 'is-active' : isActive }"
        @click="toggle"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div
        class="navbar-burger"
        :class="{ 'is-active' : isActive }"
        @click="toggle"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="navbar-menu">
      <div class="navbar-start">
        <!-- navbar items -->
      </div>

      <div class="navbar-end">
        <router-link
          :to="{ name: 'Dashboard'}"
          class="navbar-item"
        >
          <span class="icon">
            <i class="fas fa-th-large"></i>
          </span>
          <span>Dashboard</span>
        </router-link>
        <router-link
          class="navbar-item"
          :to="{ name: 'MyTicketList' }"
        >
          <span class="icon">
            <i class="fas fa-ticket-alt"></i>
          </span>
          <span>My Tickets</span>
        </router-link>
        <!-- <router-link class="navbar-item" :to="{ name: 'SecureHelpDesk' }">
          <span class="icon">
            <i class="fas fa-question-circle"></i>
          </span>
          <span>Helpdesk</span>
        </router-link> -->
        <a
          class="navbar-item"
          @click.prevent="refreshWallet"
        >
          <span class="icon">
            <i class="fas fa-wallet"></i>
          </span>
          <span
            v-if="!isWalletLoading"
            class="wallet-nav-text"
          >Wallet Amount<br>{{ myWalletBalance || 0 }} INR</span>
          <span
            v-if="isWalletLoading"
            class="wallet-nav-text"
          >Wallet Amount<br><i class="fas fa-spinner fa-spin"></i> INR</span>
        </a>
        <div
          class="navbar-item has-dropdown is-hoverable"
          v-if="isProfileLoaded"
        >
          <a
            class="navbar-link"
            href="#"
          >
            <span class="icon">
              <i class="fas fa-user-circle"></i>
            </span>
            <span>{{ user.name }}</span>
            <!-- <img v-if="userPicture" :src="userPicture" :alt="userName" class="user-avatar" /> -->
          </a>
          <div class="navbar-dropdown is-right">
            <router-link
              class="navbar-item"
              :to="{ name: 'MyProfile' }"
            >
              <span class="icon">
                <i class="fas fa-id-card"></i>
              </span>
              <span>My Profile</span>
            </router-link>
            <hr class="navbar-divider">
            <a
              @click.prevent="getUserLoggedOut"
              class="navbar-item"
              href="#"
            >
              <span class="icon"><i class="fas fa-sign-out-alt"></i></span>
              <span>Sign Out</span>
            </a>
            <hr class="navbar-divider">
            <a
              @click.prevent="getUserLoggedOutFromAll"
              class="navbar-item"
              href="#"
            >
              <span class="icon"><i class="fas fa-sign-out-alt"></i></span>
              <span>Sign Out From All</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AUTH_LOGOUT, AUTH_LOGOUT_ALL } from '@/store/actions/authActions'
import { WALLET_BALANCE_INFO } from '@/store/actions/myWalletActions'

const APP_NAME = process.env.VUE_APP_TITLE

export default {
  name: 'TopNavBar',
  data () {
    return {
      isActive: false,
      appName: APP_NAME
    }
  },
  computed: {
    ...mapGetters('Profile', { user: 'getProfile', myWalletBalance: 'walletBalance', isWalletLoading: 'isWalletLoading' }),
    ...mapGetters('Auth', { isAuth: 'isAuthenticated' }),
    isProfileLoaded () {
      return this.isAuth && this.user !== null && this.user.name !== undefined
    }
  },
  methods: {
    ...mapActions('Auth', { userLoggedOut: AUTH_LOGOUT, userLoggedOutFromAll: AUTH_LOGOUT_ALL }),
    ...mapActions('Profile', { refreshWallet: WALLET_BALANCE_INFO }),
    getUserLoggedOut () {
      this.userLoggedOut().then(() => {
        this.$notify({
          title: 'User Action',
          text: 'User successfully signed out from this session...',
          type: 'is-success'
        })
        this.$router.replace({ name: 'Login' })
      })
    },
    getUserLoggedOutFromAll () {
      this.userLoggedOutFromAll().then(() => {
        this.$notify({
          title: 'User Action',
          text: 'User successfully signed out from all sessions...',
          type: 'is-success'
        })
        this.$router.replace({ name: 'Login' })
      })
    },
    toggle () {
      this.isActive = !this.isActive
      this.$emit('toggleNav')
    },
    hideAside () {
      if (this.isActive) {
        this.isActive = false
        this.$emit('toggleNav')
      }
    }
  },
  created () {
    this.$eventHub.$on('next-route', this.hideAside)
  },
  beforeDestroy () {
    this.$eventHub.$off('next-route')
  }
}
</script>

<style lang="sass" scoped>
@import '@/sass/mixins.sass'

.navbar
  .navbar-link
    font-weight: 700
  .navbar-item
    font-weight: 700
  img.user-avatar
    border-radius: 50%
    margin-right: 10px
    vertical-align: middle
  .navbar-burger.aside-burger
    display: flex
    margin-right: auto
    margin-left: inherit
    +touch
      display: none
  .wallet-nav-text
    font-size: 11px
</style>
