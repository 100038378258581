<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="device"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ device.name }} / ({{ device.uuid }})</p>
                  </div>
                </div>
                <div class="content">
                  <div class="columns">
                    <div class="column">
                      <table class="table is-striped">
                        <tbody>
                          <tr>
                            <th>Device Type</th>
                            <td>{{ device.type }}</td>
                          </tr>
                          <tr>
                            <th>SXM Time Share</th>
                            <td>{{ device.time_share }}</td>
                          </tr>
                          <tr v-if="device.type === 'RSRTC_TIMETABLE'">
                            <th>RSRTC Stop Code</th>
                            <td>{{ device.stop_code }}</td>
                          </tr>
                          <tr v-if="device.type === 'RSRTC_TIMETABLE'">
                            <th>RSRTC Stop Name</th>
                            <td>{{ device.stop_name }}</td>
                          </tr>
                          <tr v-if="device.type === 'RSRTC_TIMETABLE'">
                            <th>RSRTC Installation Location</th>
                            <td>{{ device.installation_location }}</td>
                          </tr>
                          <tr v-if="device.type === 'RSRTC_TIMETABLE'">
                            <th>RSRTC Department</th>
                            <td>{{ device.department }}</td>
                          </tr>
                          <tr>
                            <th>Address</th>
                            <td>{{ device.address }}</td>
                          </tr>
                          <tr>
                            <th>Live Web</th>
                            <td>{{ device.live_web }}</td>
                          </tr>
                          <tr>
                            <th>Status</th>
                            <td>{{ device.status }}</td>
                          </tr>
                          <tr>
                            <th>Online Status</th>
                            <td>{{ device.live ? 'Online' : 'Offline' }}</td>
                          </tr>
                          <tr>
                            <th>Last Online At</th>
                            <td>{{ device.live_at ? device.live_at : 'Not Activated' }}</td>
                          </tr>
                          <tr>
                            <th>WIFI Mobile Number</th>
                            <td>{{ device.mobile }}</td>
                          </tr>
                          <tr>
                            <th>WIFI Provider</th>
                            <td>{{ device.provider }}</td>
                          </tr>
                          <tr>
                            <th>WIFI Plan Expire At</th>
                            <td>{{ device.expire_at }}</td>
                          </tr>
                          <tr>
                            <th>WiFi-SSID</th>
                            <td>{{ device.wifissid }}</td>
                          </tr>
                          <tr>
                            <th>WiFi-Password</th>
                            <td>{{ device.wifipassword }}</td>
                          </tr>
                          <tr>
                            <th>Orientation</th>
                            <td>{{ device.orientation }}</td>
                          </tr>
                          <tr>
                            <th>Screen Flip</th>
                            <td>{{ device.screen_flip ? 'Yes' : 'No' }}</td>
                          </tr>
                          <tr>
                            <th>Allowed External</th>
                            <td>{{ device.allowed_external }}</td>
                          </tr>
                          <tr>
                            <th>Screen Type</th>
                            <td>{{ device.screen_type }}</td>
                          </tr>
                          <tr>
                            <th>Screen Size</th>
                            <td>{{ device.screen_size }}</td>
                          </tr>
                          <tr>
                            <th>Latitude</th>
                            <td>{{ device.latitude }}</td>
                          </tr>
                          <tr>
                            <th>Longitude</th>
                            <td>{{ device.longitude }}</td>
                          </tr>
                          <tr>
                            <th>Campaign</th>
                            <td>
                              <div v-if="device.campaign">
                                <div v-if="device.campaign.name">{{ device.campaign.name }}</div>
                                <div v-if="device.campaign.type">{{ device.campaign.type }}</div>
                                <div v-if="device.campaign.status">{{ device.campaign.status }}</div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>Agent</th>
                            <td>
                              <div v-if="device.agent">
                                <div v-if="device.agent.name">{{ device.agent.name }}</div>
                                <div v-if="device.agent.email">{{ device.agent.email }}</div>
                                <div v-if="device.agent.mobile">{{ device.agent.mobile }}</div>
                                <div v-if="device.agent.address">{{ device.agent.address }}</div>
                                <div v-if="device.agent.status">{{ device.agent.status }}</div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>Distributor</th>
                            <td>
                              <div v-if="device.distributor">
                                <div v-if="device.distributor.name">{{ device.distributor.name }}</div>
                                <div v-if="device.distributor.email">{{ device.distributor.email }}</div>
                                <div v-if="device.distributor.mobile">{{ device.distributor.mobile }}</div>
                                <div v-if="device.distributor.address">{{ device.distributor.address }}</div>
                                <div v-if="device.distributor.status">{{ device.distributor.status }}</div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>Authentication Token</th>
                            <td>{{ device.token }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="card">
                        <div class="card-content">
                          <div class="buttons">
                            <router-link
                              v-if="hasAnyScope('DEVICE-LIST')"
                              :to="{ name: 'DeviceList'}"
                              class="button is-small is-primary"
                            >
                              <span class="icon">
                                <i class="fas fa-th-list"></i>
                              </span>
                              <span>LIST</span>
                            </router-link>
                            <router-link
                              v-if="hasAnyScope('DEVICE-UPDATE')"
                              :to="{ name: 'DeviceEdit', params: { deviceId: device.id }}"
                              class="button is-small is-warning"
                            >
                              <span class="icon">
                                <i class="fas fa-edit"></i>
                              </span>
                              <span>EDIT</span>
                            </router-link>
                            <a
                              v-if="hasAnyScope('DEVICE-DELETE')"
                              class="button is-small is-danger"
                              @click.prevent="confirmDeleteDevice"
                            >
                              <span class="icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span>DELETE</span>
                            </a>
                            <router-link
                              v-if="hasAnyScope('DEVICE-KIOSK-WEBSITE-USER-LOGS-LIST') && device.campaign !== null && device.campaign.type === 'INTERACTIVE-KIOSK'"
                              :to="{ name: 'DeviceWebsiteUserLogs', params: { deviceId: device.id }}"
                              class="button is-small is-success"
                            >
                              <span class="icon">
                                <i class="fas fa-running"></i>
                              </span>
                              <span>KIOSK WEBSITE USER LOGS</span>
                            </router-link>
                            <router-link
                              v-if="hasAnyScope('DEVICE-PING-LIST')"
                              :to="{ name: 'DevicePingList', params: { deviceId: device.id }}"
                              class="button is-small is-info"
                            >
                              <span class="icon">
                                <i class="fas fa-heartbeat"></i>
                              </span>
                              <span>HEARTBEATS</span>
                            </router-link>
                            <router-link
                              v-if="hasAnyScope('DEVICE-TEMPERATURE-LIST')"
                              :to="{ name: 'DeviceTemperatureList', params: { deviceId: device.id }}"
                              class="button is-small is-info"
                            >
                              <span class="icon">
                                <i class="fas fa-heartbeat"></i>
                              </span>
                              <span>Temperature Status</span>
                            </router-link>

                            <router-link
                              v-if="hasAnyScope('DEVICE-DISK-LIST')"
                              :to="{ name: 'DeviceDiskList', params: { deviceId: device.id }}"
                              class="button is-small is-info"
                            >
                              <span class="icon">
                                <i class="fas fa-heartbeat"></i>
                              </span>
                              <span>Disks Status</span>
                            </router-link>

                            <router-link
                              v-if="hasAnyScope('REPORT-DEVICE-REVENUE')"
                              :to="{ name: 'DeviceRevenueReport', params: { deviceId: device.id }}"
                              class="button is-small is-info"
                            >
                              <span class="icon">
                                <i class="fas fa-chart-pie"></i>
                              </span>
                              <span>Revenue Report</span>
                            </router-link>
                            <router-link
                              v-if="hasAnyScope('REPORT-DEVICE-OPERATION')"
                              :to="{ name: 'DeviceOperationReport', params: { deviceId: device.id }}"
                              class="button is-small is-info"
                            >
                              <span class="icon">
                                <i class="fas fa-chart-line"></i>
                              </span>
                              <span>Operation Report</span>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-two-fifths">
                      <div
                        class="card card-section"
                        v-if="device.ads"
                      >
                        <header class="card-header">
                          <p class="card-header-title has-background-primary has-text-white">
                            Ads
                          </p>
                        </header>
                        <div class="card-image">
                          <a
                            :href="device.ads.path"
                            target="_blank"
                          >
                            <figure class="image is-4by3">
                              <img
                                v-if="!device.ads"
                                src="/img/placeholders/image-placeholder.png"
                                alt="Device Ads"
                              >
                              <img
                                v-if="device.ads"
                                :src="device.ads.thumbnail"
                                alt="Device Ads"
                              >
                            </figure>
                          </a>
                        </div>
                      </div>
                      <div class="card card-section">
                        <header class="card-header">
                          <p class="card-header-title has-background-primary has-text-white">
                            Thumbnail
                          </p>
                        </header>
                        <div class="card-image">
                          <figure class="image is-4by3">
                            <img
                              v-if="!device.thumbnails"
                              src="/img/placeholders/image-placeholder.png"
                              alt="Device Thumbnail"
                            >
                            <img
                              v-if="device.thumbnails"
                              :src="device.thumbnails.large"
                              alt="Device Thumbnail"
                            >
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card card-section"
                    v-if="hasAnyScope('DEVICE-UPDATE-PRICES')"
                  >
                    <header class="card-header">
                      <p class="card-header-title has-background-primary has-text-white">
                        Price Table
                      </p>
                    </header>
                    <div class="card-content">
                      <b-table
                        :data="device.prices"
                        :bordered="true"
                        :striped="true"
                        :hoverable="true"
                        :mobile-cards="true"
                      >
                        <b-table-column
                          field="currency"
                          label="Currency"
                          width="40"
                          v-slot="props"
                        >
                          <strong>{{ props.row.currency }}</strong>
                        </b-table-column>

                        <b-table-column
                          field="interval"
                          label="Interval"
                          v-slot="props"
                        >
                          <strong>{{ props.row.interval }}</strong>
                        </b-table-column>

                        <b-table-column
                          field="amount"
                          label="Amount (Paisa/Sec)"
                          v-slot="props"
                        >
                          {{ props.row.amount }}
                        </b-table-column>

                        <b-table-column
                          field="agent_amount"
                          label="Agent (Paisa/Sec)"
                          v-slot="props"
                        >
                          {{ props.row.agent_amount }}
                        </b-table-column>

                        <b-table-column
                          field="distributor_amount"
                          label="Distributor (Paisa/Sec)"
                          v-slot="props"
                        >
                          {{ props.row.distributor_amount }}
                        </b-table-column>
                      </b-table>
                    </div>
                  </div>
                  <picture-gallery
                    title="Picture Gallery"
                    :gallery="device.gallery"
                  ></picture-gallery>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import PictureGallery from '@/pages/layouts/secure/components/PictureGallery'
import { mapGetters, mapActions } from 'vuex'
import { DEVICE_SHOW_DETAILS, DEVICE_DELETE } from '@/store/actions/deviceActions'

export default {
  name: 'DeviceShow',
  components: { Breadcrumb, PictureGallery },
  props: {
    deviceId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncDevice'
  },
  data () {
    return {
      pageTitle: 'Device Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-desktop', routeName: 'DeviceList', name: 'Manage Devices' }
        ],
        current: { icon: 'fas fa-eye', name: 'Device Details' },
        actions: [{ icon: 'fas fa-desktop', routeName: 'DeviceList', name: 'Manage Devices' }]
      },
      device: null
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    ...mapGetters('Devices', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Devices', { loadDevice: DEVICE_SHOW_DETAILS, sendDeleteDeviceRequest: DEVICE_DELETE }),
    confirmDeleteDevice () {
      this.$buefy.dialog.confirm({
        title: 'Deleting Device',
        message: 'Are you sure you want to <b>delete</b> this device? This action cannot be undone.',
        confirmText: 'Delete Device',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deleteDevice()
      })
    },
    deleteDevice () {
      this.sendDeleteDeviceRequest(this.device.id)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.$router.replace({ name: 'DeviceList' })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncDevice () {
      this.device = null
      this.loadDevice(this.deviceId)
        .then((response) => {
          this.device = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Device Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'DeviceList' }),
            onConfirm: () => this.$router.replace({ name: 'DeviceList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncDevice()
  }
}
</script>
