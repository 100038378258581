<template>
  <div>
    <!-- <loading v-show="isLoading"></loading> -->
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <advance-filter
      :is-loading="isLoading"
      @onStart="onFilter"
      @onReset="reset"
    >
      <div class="columns is-multiline">
        <div class="column field is-3">
          <label class="label is-small">Query</label>
          <p class="control has-icons-left">
            <input
              class="input"
              type="text"
              placeholder="search"
              v-model="filter.query"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-search"></i>
            </span>
          </p>
        </div>
        <location-tree
          class="column is-3"
          v-model="filter.locations"
        />
        <category-tree
          class="column is-3"
          v-model="filter.places"
        />
        <tag-tree
          class="column is-3"
          v-model="filter.tags"
        />
        <agent-search-input
          class="column is-3"
          v-model="filter.agent"
          property-name="id"
        />
      </div>
    </advance-filter>

    <div class="card card-section">
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <b-field
              grouped
              group-multiline
            >
              <b-select
                v-model="filter.size"
                :disabled="!hasItems"
                @input="onFilter"
              >
                <option value="5">5 per page</option>
                <option value="10">10 per page</option>
                <option value="15">15 per page</option>
                <option value="20">20 per page</option>
                <option value="50">50 per page</option>
                <option value="100">100 per page</option>
              </b-select>
            </b-field>
          </div>
          <div
            class="column"
            v-if="hasAnyDeviceChecked"
          >
            <div class="is-flex is-flex-wrap-wrap is-flex-direction-roww">
              <b-field style="padding-top: 10px;text-align: right;">
                <b-checkbox
                  v-if="hasAllDevicesChecked"
                  v-model="selectAllByQuery"
                >Select all {{ meta.total }} devices</b-checkbox>
              </b-field>
              <div
                class="field is-action-buttons"
                style="padding-top: 5px;text-align: right;"
              >
                <div class="control">
                  <button
                    type="button"
                    :disabled="isLoading"
                    class="button is-primary is-rounded is-small"
                    @click="applyCampaignOnDevice"
                    :class="{ 'is-loading': isLoading }"
                  >Apply Campaign</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-table
          height="400"
          :sticky-header="true"
          :checked-rows.sync="checkedDevices"
          checkable
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :mobile-cards="true"
          :loading="isLoading"
          paginated
          backend-pagination
          :per-page="filter.size"
          :total="meta.total"
          @page-change="onPageChange"
          backend-sorting
          default-sort-direction="desc"
          :default-sort="[filter.sortField, filter.sortOrder]"
          @sort="onSort"
          :data="items"
          class="is-checked"
        >
          <b-table-column
            label="S.No."
            width="40"
            numeric
            v-slot="props"
          >
            {{ meta.from + props.index }}
          </b-table-column>
          <b-table-column
            field="name"
            label="Name"
            sortable
            v-slot="props"
          >
            <div>{{ props.row.name }}</div>
            <div><small>UUID: {{ props.row.uuid }}</small></div>
          </b-table-column>
          <b-table-column
            field="agent_id"
            label="Agent"
            v-slot="props"
          >
            <div v-if="props.row.agent">
              <div>{{ props.row.agent.name }}</div>
              <div><small>{{ props.row.agent.email }}</small></div>
            </div>
          </b-table-column>
          <b-table-column
            field="campaign_id"
            label="Campaign"
            v-slot="props"
          >
            <div v-if="props.row.campaign">
              <div>{{ props.row.campaign.name }}</div>
              <div><small>{{ props.row.campaign.type }}</small></div>
              <div><small>{{ props.row.campaign.status }}</small></div>
            </div>
          </b-table-column>
          <b-table-column
            field="status"
            label="Status"
            sortable
            v-slot="props"
          >
            {{ props.row.status }}
          </b-table-column>
          <b-table-column
            field="live"
            width="275"
            label="Is Online?"
            sortable
            v-slot="props"
          >
            <div>
              <span
                class="tag is-normal"
                :class="props.row.live ? 'is-success' : 'is-danger'"
              >{{ props.row.live ? 'Online' : 'Offline' }}</span>
            </div>
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="emoticon-sad"
                    size="is-large"
                  > </b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
          <template #bottom-left>
            <b>Total Selected</b>: {{ checkedDevices.length }}
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import { mapGetters, mapActions } from 'vuex'
import { LocationTree, CategoryTree, TagTree } from '@/lib/tree'
import { AgentSearchInput } from '@/lib/autocompletes'
import { CAMPAIGN_SHOW_DETAILS, CAMPAIGN_START } from '@/store/actions/campaignActions'
import { AGENT_LIST } from '@/store/actions/userActions'
import { DEVICE_LIST } from '@/store/actions/deviceActions'
export default {
  name: 'CampaignStart',
  props: {
    campaignId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncCampaign'
  },
  components: {
    Breadcrumb,
    AdvanceFilter,
    LocationTree,
    CategoryTree,
    TagTree,
    AgentSearchInput
  },
  data () {
    return {
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-desktop', routeName: 'CampaignList', name: 'Manage Campaigns' },
          { icon: 'fas fa-eye', routeName: 'CampaignShow', params: this.campaignId, name: 'Campaign Details' }
        ],
        current: { icon: 'fas fa-satellite-dish', name: 'Campaign Start/Apply' }
      },
      status_options: ['IDEAL', 'ENABLE', 'DISABLE'],
      live_options: ['YES', 'NO'],
      type_options: ['SINGLE-SCREEN-KIOSK', 'SCREEN-WITH-RIGHT-SIDE-KIOSK', 'INTERACTIVE-KIOSK', 'CUSTOM-KIOSK'],
      filter: {},
      items: [],
      meta: {},
      campaign: null,
      checkedDevices: [],
      selectAllByQuery: false,
      errors: {}
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    ...mapGetters('Devices', ['isLoading', 'isSuccess', 'isError']),
    hasAnyDeviceChecked () {
      return this.checkedDevices && this.checkedDevices.length > 0
    },
    hasAllDevicesChecked () {
      return this.checkedDevices && this.items && this.checkedDevices.length > 0 && this.checkedDevices.length === this.items.length
    },
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    },
    pageTitle () {
      if (this.campaign) {
        return `Campaign (${this.campaign.name}) Start/Apply`
      } else {
        return 'Campaign Start/Apply'
      }
    }
  },
  methods: {
    ...mapActions('Campaigns', { loadCampaign: CAMPAIGN_SHOW_DETAILS, applyCampaign: CAMPAIGN_START }),
    ...mapActions('Devices', { loadList: DEVICE_LIST }),
    ...mapActions('Users', { loadAgents: AGENT_LIST }),
    onFilter () {
      this.loadAsyncData()
    },
    onPageChange (page) {
      this.filter.page = page
      this.loadAsyncData()
    },
    onSort (field, order) {
      this.filter.sortField = field
      this.filter.sortOrder = order
      this.loadAsyncData()
    },
    loadAsyncData () {
      if (!this.isLoading) {
        const data = this.filter
        this.loadList(data)
          .then((response) => {
            this.items = response.data
            this.meta = response.meta
            this.checkedDevices = []
          })
          .catch((error) => {
            this.$notify({
              title: 'Device Loading Failed',
              text: error.message,
              type: 'is-danger'
            })
          })
      }
    },
    reset () {
      this.filter = {
        agent: null,
        query: '',
        page: 1,
        size: 10,
        locations: [],
        places: [],
        tags: [],
        sortField: 'updated_at',
        sortOrder: 'desc'
      }
      this.items = []
      this.meta = {
        total: 0
      }
      this.checkedDevices = []
      this.onFilter()
    },
    loadAsyncCampaign () {
      this.campaign = null
      this.loadCampaign(this.campaignId)
        .then((response) => {
          this.campaign = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Campaign Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'CampaignList' }),
            onConfirm: () => this.$router.replace({ name: 'CampaignList' })
          })
        })
    },
    applyCampaignOnDevice () {
      this.errors = {}
      const data = {
        campaignId: this.campaign.id,
        formData: {
          devices: this.checkedDevices.map(d => d.id),
          filter: this.filter,
          useQuery: this.selectAllByQuery
        }
      }
      this.applyCampaign(data)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: 'Campaign is applied successfully',
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Campaign',
            onAction: () => {
              this.$router.push({
                name: 'CampaignList'
              })
            }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Device Selection Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  },
  mounted () {
    this.reset()
  },
  created () {
    this.loadAsyncCampaign()
  }
}
</script>
