<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="card card-section"
      v-if="device"
    >
      <div class="card-content">
        <b-tabs v-model="activeTab">
          <b-tab-item
            label="General Properties"
            v-if="hasAnyScope(['DEVICE-UPDATE', 'DEVICE-UPDATE-GENERAL'])"
          >
            <device-general :device="device"></device-general>
          </b-tab-item>
          <b-tab-item
            label="Relationships"
            v-if="hasAnyScope(['DEVICE-UPDATE-RELATIONS'])"
          >
            <device-links
              :device="device"
              :categoryTree="categoryTree"
              :locationTree="locationTree"
              :tagTree="tagTree"
            ></device-links>
          </b-tab-item>
          <b-tab-item
            label="Picture Gallery"
            v-if="hasAnyScope(['DEVICE-UPDATE-GALLERY'])"
          >
            <device-gallery :device="device"></device-gallery>
          </b-tab-item>
          <b-tab-item
            label="Price Table"
            v-if="hasAnyScope(['DEVICE-UPDATE-PRICES'])"
          >
            <device-price :device="device"></device-price>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import DeviceGeneral from './DeviceGeneral'
import DeviceLinks from './DeviceLinks'
import DeviceGallery from './DeviceGallery'
import DevicePrice from './DevicePrice'
import { DEVICE_SHOW } from '@/store/actions/deviceActions'
import { LOCATION_TREE_SHOW } from '@/store/actions/locationActions'
import { CATEGORY_TREE_SHOW } from '@/store/actions/categoryActions'
import { TAG_TREE_SHOW } from '@/store/actions/tagActions'

export default {
  name: 'DeviceEdit',
  components: {
    Breadcrumb,
    DeviceGeneral,
    DeviceLinks,
    DeviceGallery,
    DevicePrice
  },
  props: {
    deviceId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncDevice'
  },
  data () {
    return {
      activeTab: 0,
      pageTitle: 'Edit Device Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-desktop', routeName: 'DeviceList', name: 'Manage Devices' }
        ],
        current: { icon: 'fas fa-edit', name: 'Edit Device' },
        actions: [
          { icon: 'fas fa-desktop', routeName: 'DeviceList', name: 'Manage Devices' },
          {
            icon: 'fas fa-eye', routeName: 'DeviceShow', params: { deviceId: this.deviceId }, name: 'Display Device'
          }
        ]
      },
      device: null,
      categoryTree: [],
      locationTree: [],
      tagTree: []
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    ...mapGetters('Devices', ['isLoading'])
  },
  methods: {
    ...mapActions('Devices', { loadDevice: DEVICE_SHOW }),
    ...mapActions('Locations', { loadLocationTree: LOCATION_TREE_SHOW }),
    ...mapActions('Categories', { loadCategoryTree: CATEGORY_TREE_SHOW }),
    ...mapActions('Tags', { loadTagTree: TAG_TREE_SHOW }),
    loadAsyncCategoryTree () {
      this.loadCategoryTree()
        .then((response) => {
          this.categoryTree = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Category Tree Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncLocationTree () {
      this.loadLocationTree()
        .then((response) => {
          this.locationTree = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Place Category Tree Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncTagTree () {
      this.loadTagTree()
        .then((response) => {
          this.tagTree = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Tag Tree Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncDevice () {
      this.device = null
      this.loadDevice(this.deviceId)
        .then((response) => {
          this.device = {
            id: response.data.id,
            uuid: response.data.uuid,
            type: response.data.type,
            time_share: response.data.time_share,
            stop_code: response.data.stop_code,
            stop_name: response.data.stop_name,
            installation_location: response.data.installation_location,
            department: response.data.department,
            name: response.data.name,
            footfall: response.data.footfall,
            address: response.data.address,
            orientation: response.data.orientation,
            allowed_external: response.data.allowed_external,
            screen_flip: response.data.screen_flip,
            screen_size: response.data.screen_size,
            screen_type: response.data.screen_type,
            mobile: response.data.mobile,
            provider: response.data.provider,
            expire_at: response.data.expire_at,
            wifissid: response.data.wifissid,
            wifipassword: response.data.wifipassword,
            latitude: response.data.latitude,
            longitude: response.data.longitude,
            live_web: response.data.live_web,
            status: response.data.status,
            ads: response.data.ads,
            agent: response.data.agent,
            distributor: response.data.distributor,
            channel: response.data.channel,
            gallery: response.data.gallery,
            thumbnails: response.data.thumbnails,
            categories: response.data.categories,
            locations: response.data.locations,
            tags: response.data.tags,
            prices: (response.data.prices && response.data.prices.length > 0) ? response.data.prices : this.getDefaultPrice()
          }
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Device Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'DeviceList' }),
            onConfirm: () => this.$router.replace({ name: 'DeviceList' })
          })
        })
    },
    getDefaultPrice () {
      return [
        {
          interval: '00 - 01', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '01 - 02', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '02 - 03', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '03 - 04', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '04 - 05', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '05 - 06', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '06 - 07', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '07 - 08', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '08 - 09', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '09 - 10', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '10 - 11', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '11 - 12', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '12 - 13', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '13 - 14', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '14 - 15', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '15 - 16', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '16 - 17', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '17 - 18', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '18 - 19', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '19 - 20', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '20 - 21', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '21 - 22', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '22 - 23', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        },
        {
          interval: '23 - 24', amount: 0, currency: 'INR', agent_amount: 0, distributor_amount: 0, channel_amount: 0
        }
      ]
    }
  },
  created () {
    this.loadAsyncDevice()
    this.loadAsyncCategoryTree()
    this.loadAsyncLocationTree()
    this.loadAsyncTagTree()
  }
}
</script>
