<template>
  <div>
    <b-table
      :data="prices"
      :bordered="true"
      :striped="true"
      :hoverable="true"
      :mobile-cards="true"
    >
      <b-table-column
        field="currency"
        label="Currency"
        width="40"
        v-slot="props"
      >
        <strong>{{ props.row.currency }}</strong>
      </b-table-column>

      <b-table-column
        field="interval"
        label="Interval"
        v-slot="props"
      >
        <strong>{{ props.row.interval }}</strong>
      </b-table-column>

      <b-table-column
        field="amount"
        label="Amount (Paisa/Sec)"
        v-slot="props"
      >
        <input
          class="input"
          v-model="props.row.amount"
          type="number"
          step="any"
        />
      </b-table-column>

      <b-table-column
        field="agent_amount"
        label="Agent (Paisa/Sec)"
        v-slot="props"
      >
        <input
          class="input"
          v-model="props.row.agent_amount"
          type="number"
          step="any"
        />
      </b-table-column>

      <b-table-column
        field="distributor_amount"
        label="Distributor (Paisa/Sec)"
        v-slot="props"
      >
        <input
          class="input"
          v-model="props.row.distributor_amount"
          type="number"
          step="any"
        />
      </b-table-column>

      <b-table-column
        field="channel_amount"
        label="Channel (Paisa/Sec)"
        v-slot="props"
      >
        <input
          class="input"
          v-model="props.row.channel_amount"
          type="number"
          step="any"
        />
      </b-table-column>
      <template slot="footer">
        <th colspan="2">Change To All</th>
        <th>
          <input
            type="number"
            v-model="commonAmount"
            step="any"
            class="input"
          />
        </th>
        <th>
          <input
            type="number"
            v-model="commonAgent"
            step="any"
            class="input"
          />
        </th>
        <th>
          <input
            type="number"
            v-model="commonDistributor"
            step="any"
            class="input"
          />
        </th>
        <th>
          <input
            type="number"
            v-model="commonChannel"
            step="any"
            class="input"
          />
        </th>
      </template>
    </b-table>
    <div class="field is-deviceed action-buttons">
      <div class="control">
        <button
          type="button"
          :disabled="isLoading"
          class="button is-primary is-rounded"
          @click="formSubmit"
          :class="{ 'is-loading': isLoading }"
        >Update Price Details</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { DEVICE_PRICE_UPDATE } from '@/store/actions/deviceActions'

export default {
  name: 'DevicePrice',
  props: {
    device: {
      required: true
    }
  },
  data () {
    return {
      errors: {},
      prices: this.device.prices,
      commonAmount: '',
      commonAgent: '',
      commonDistributor: '',
      commonChannel: ''
    }
  },
  watch: {
    commonAmount: 'updateAllAmount',
    commonAgent: 'updateAllAgent',
    commonDistributor: 'updateAllDistributor',
    commonChannel: 'updateAllChannel'
  },
  computed: {
    ...mapGetters('Devices', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Devices', { updateDevicePrices: DEVICE_PRICE_UPDATE }),
    updateAllAmount () {
      if (this.commonAmount === '') {
        this.prices = this.prices.map((price) => {
          const tempPrice = price
          tempPrice.amount = 0
          return tempPrice
        })
      } else {
        this.prices = this.prices.map((price) => {
          const tempPrice = price
          tempPrice.amount = this.commonAmount
          return tempPrice
        })
      }
    },
    updateAllAgent () {
      if (this.commonAgent === '') {
        this.prices = this.prices.map((price) => {
          const tempPrice = price
          tempPrice.agent_amount = 0
          return tempPrice
        })
      } else {
        this.prices = this.prices.map((price) => {
          const tempPrice = price
          tempPrice.agent_amount = this.commonAgent
          return tempPrice
        })
      }
    },
    updateAllDistributor () {
      if (this.commonDistributor === '') {
        this.prices = this.prices.map((price) => {
          const tempPrice = price
          tempPrice.distributor_amount = 0
          return tempPrice
        })
      } else {
        this.prices = this.prices.map((price) => {
          const tempPrice = price
          tempPrice.distributor_amount = this.commonDistributor
          return tempPrice
        })
      }
    },
    updateAllChannel () {
      if (this.commonChannel === '') {
        this.prices = this.prices.map((price) => {
          const tempPrice = price
          tempPrice.channel_amount = 0
          return tempPrice
        })
      } else {
        this.prices = this.prices.map((price) => {
          const tempPrice = price
          tempPrice.channel_amount = this.commonChannel
          return tempPrice
        })
      }
    },
    formSubmit () {
      this.errors = {}
      const formData = {
        prices: this.prices
      }
      const data = {
        deviceId: this.device.id,
        formData
      }
      this.updateDevicePrices(data)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: response.message,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Device',
            onAction: () => {
              this.$router.push({
                name: 'DeviceList'
              })
            }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Device Price Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    }

  }
}
</script>
