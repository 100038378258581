<template>
  <div class="card card-section">
    <advance-filter
      :is-loading="isListLoading"
      @onStart="onFilter"
      title="Wallet Transactions"
    >
      <div class="columns is-multiline">
        <div class="column field is-3">
          <label class="label is-small">Query</label>
          <p class="control has-icons-left">
            <input
              class="input"
              type="text"
              placeholder="search"
              v-model="filter.query"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-search"></i>
            </span>
          </p>
        </div>
        <div class="column field is-4">
          <label class="label is-small">Status</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              multiple
              v-model="filter.statuses"
              :options="status_options"
              placeholder="Pick statuses for filter"
              class="is-small"
            ></v-select>
          </div>
        </div>
      </div>
    </advance-filter>
    <div class="card-content">
      <b-field
        grouped
        group-multiline
      >
        <b-select
          v-model="filter.size"
          :disabled="!hasItems"
          @input="onFilter"
        >
          <option value="5">5 per page</option>
          <option value="10">10 per page</option>
          <option value="15">15 per page</option>
          <option value="20">20 per page</option>
          <option value="50">50 per page</option>
          <option value="100">100 per page</option>
        </b-select>
      </b-field>
      <b-table
        detailed
        detail-key="id"
        default-sort-direction="desc"
        paginated
        backend-pagination
        backend-sorting
        :bordered="true"
        :striped="true"
        :hoverable="true"
        :mobile-cards="true"
        :loading="isTransactionsLoading"
        :per-page="filter.size"
        :total="meta.total"
        @page-change="onPageChange"
        @sort="onSort"
        :default-sort="[filter.sortField, filter.sortOrder]"
        :data="items"
      >
        <b-table-column
          label="S.No."
          width="40"
          numeric
          v-slot="props"
        >
          {{ meta.from + props.index }}
        </b-table-column>

        <b-table-column
          field="created_at"
          label="Date Time"
          v-slot="props"
        >
          {{ props.row.created_at }}
        </b-table-column>

        <b-table-column
          field="type"
          label="Type"
          v-slot="props"
        >
          {{ props.row.type }}
        </b-table-column>
        <b-table-column
          field="amount"
          label="Amount"
          v-slot="props"
        >
          {{ props.row.currency }} {{ props.row.amount }}
        </b-table-column>
        <b-table-column
          field="transaction_id"
          label="Transaction ID"
          v-slot="props"
        >
          {{ props.row.transaction_id }}
        </b-table-column>
        <b-table-column
          label="Transaction Status"
          v-slot="props"
        >
          {{ props.row.status }}
        </b-table-column>
        <template
          slot="detail"
          slot-scope="props"
        >
          <p><strong>{{ props.row.type }} ({{ props.row.amount }} {{ props.row.currency }})</strong></p>
          <p>Remarks: {{ props.row.remarks }}</p>
          <p v-if="props.row.requested_by">Requested By: {{ props.row.requested_by.name }} ({{ props.row.requested_by.email }})</p>
          <p><strong>Transaction Details</strong></p>
          <p>
            <strong>ID:</strong> {{ props.row.transaction_id || 'NA' }},
            <strong>Method:</strong> {{ props.row.transaction_method || 'NA' }},
            <strong>Bank:</strong> {{ props.row.transaction_bank || 'NA' }},
            <strong>Details:</strong> {{ props.row.transaction_details || 'NA' }},
            <strong>Status:</strong> {{ props.row.status || 'NA' }}
          </p>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon
                  icon="emoticon-sad"
                  size="is-large"
                > </b-icon>
              </p>
              <p>Nothing here.</p>
            </div>
          </section>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon
                  icon="emoticon-sad"
                  size="is-large"
                >
                </b-icon>
              </p>
              <p>Nothing here.</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import { WALLET_TRANSACTION_LIST } from '@/store/actions/myWalletActions'

export default {
  name: 'MyWalletTransactions',
  components: {
    AdvanceFilter
  },
  data () {
    return {
      transaction_record: null,
      isModalActive: false,
      filter: {
        transaction_id: '',
        types: [],
        transaction_methods: [],
        statuses: [],
        page: 1,
        size: 10,
        sortField: 'created_at',
        sortOrder: 'desc'
      },
      items: [],
      meta: {
        total: 0
      },
      type_options: ['DEPOSIT', 'WITHDRAWAL'],
      method_options: ['CASH', 'CHEQUE', 'DEBIT CARD', 'CREDIT CARD', 'NEFT/IMPS/RTGS', 'PAYTM'],
      status_options: ['MANUAL DEPOSIT', 'MANUAL WITHDRAWAL', 'AUTO DEPOSIT', 'AUTO WITHDRAWAL'],

      isListLoading: false
    }
  },
  computed: {
    ...mapGetters('MyWallets', ['isTransactionsLoading']),
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    }
  },
  methods: {
    ...mapActions('MyWallets', { loadTransactionList: WALLET_TRANSACTION_LIST }),
    showTransactionRecord (transactionRecord) {
      this.transaction_record = transactionRecord
      this.isModalActive = true
    },
    onFilter () {
      this.loadAsyncData()
    },
    onPageChange (page) {
      this.filter.page = page
      this.loadAsyncData()
    },
    onSort (field, order) {
      this.filter.sortField = field
      this.filter.sortOrder = order
      this.loadAsyncData()
    },
    loadAsyncData () {
      this.isListLoading = true
      const data = {
        userId: this.userId,
        filterData: this.filter
      }
      this.loadTransactionList(data)
        .then((response) => {
          this.isListLoading = false
          this.items = response.data
          this.meta = response.meta
        })
        .catch((error) => {
          this.isListLoading = false
          this.$notify({
            title: 'Page Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    reset () {
      this.filter = {
        transaction_id: '',
        types: [],
        transaction_methods: [],
        statuses: [],
        page: 1,
        size: 10,
        sortField: 'created_at',
        sortOrder: 'desc'
      }
      this.items = []
      this.meta = {
        total: 0
      }
      this.onFilter()
    }
  },
  created () {
    this.reset()
  }
}
</script>
