<template>
  <div>
    <!-- <loading v-show="isUserLoading"></loading> -->
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
      @action-clicked="onBreadcrumbAction"
    ></breadcrumb>
    <div v-if="user">
      <advance-filter
        :is-loading="isTransactionsLoading"
        @onStart="onFilter"
        v-if="hasAnyScope('USER-WALLET-TRANSACTION-LIST')"
      >
        <div class="columns is-multiline">
          <div class="column field is-3">
            <label class="label is-small">Transaction ID</label>
            <p class="control has-icons-left">
              <input
                class="input"
                type="text"
                placeholder="Enter transaction ID"
                v-model="filter.transaction_id"
              >
              <span class="icon is-small is-left">
                <i class="fas fa-search"></i>
              </span>
            </p>
          </div>
          <div class="column field is-3">
            <label class="label is-small">DEPOSIT/WITHDRAWAL</label>
            <div class="control is-expanded has-icons-left">
              <v-select
                multiple
                v-model="filter.types"
                :options="type_options"
                placeholder="Pick types for filter"
                class="is-small"
              ></v-select>
            </div>
          </div>
          <div class="column field is-3">
            <label class="label is-small">Transaction Method</label>
            <div class="control is-expanded has-icons-left">
              <v-select
                placeholder="Pick methods for filter"
                class="is-small"
                multiple
                v-model="filter.transaction_methods"
                :options="method_options"
              ></v-select>
            </div>
          </div>
          <div class="column field is-3">
            <label class="label is-small">Transaction Status</label>
            <div class="control is-expanded has-icons-left">
              <v-select
                placeholder="Pick statuses for filter"
                class="is-small"
                multiple
                v-model="filter.statuses"
                :options="status_options"
              ></v-select>
            </div>
          </div>
          <div class="column field is-3">
            <label class="label is-small">Start Date</label>
            <p class="control has-icons-left">
              <b-datepicker
                v-model="filter.start_date"
                :first-day-of-week="1"
                icon="calendar-alt"
                placeholder="Click to select start date..."
              ></b-datepicker>
            </p>
          </div>
          <div class="column field is-3">
            <label class="label is-small">End Date</label>
            <p class="control has-icons-left">
              <b-datepicker
                v-model="filter.end_date"
                :first-day-of-week="1"
                icon="calendar-alt"
                placeholder="Click to select end date..."
              ></b-datepicker>
            </p>
          </div>
        </div>
      </advance-filter>
      <card :title="user.name">
        <template slot="header">
          <span class="tag is-success margin-left-10">Your Current Balance <i class="fas fa-rupee-sign margin-left-10 margin-right-5"></i> {{ wallet.alltime_balance }}</span>
        </template>
        <template slot="actions">
          <router-link
            :to="{ name: 'UserEdit', params: { userId: user.id }}"
            class="dropdown-item"
            v-if="hasAnyScope('USER-UPDATE')"
          >
            <span class="icon">
              <i class="fas fa-edit"></i>
            </span>
            <span>EDIT USER</span>
          </router-link>
          <router-link
            :to="{ name: 'UserShow', params: { userId: user.id }}"
            class="dropdown-item"
            v-if="hasAnyScope('USER-SHOW')"
          >
            <span class="icon">
              <i class="fas fa-eye"></i>
            </span>
            <span>USER DETAILS</span>
          </router-link>
        </template>

        <div class="tile is-ancestor has-text-centered">
          <div class="tile is-parent">
            <div
              class="tile is-child relative-position"
              :class="{ 'element is-loading' : isInfoLoading}"
            >
              <article class="tile is-child notification is-primary top-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.allTime.deposit }}</p>
                <p class="subtitle is-5">Deposits</p>
              </article>
              <article class="tile is-child notification is-success bottom-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.allTime.withdrawal }}</p>
                <p class="subtitle is-5">Withdrawals</p>
              </article>
              <span class="tag is-link top-right">{{ allTimeHeader }}</span>
            </div>
          </div>
          <div class="tile is-parent">
            <div
              class="tile is-child relative-position"
              :class="{ 'element is-loading' : isInfoLoading}"
            >
              <article class="tile is-child notification is-primary top-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.today.deposit }}</p>
                <p class="subtitle is-5">Deposits</p>
              </article>
              <article class="tile is-child notification is-success bottom-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.today.withdrawal }}</p>
                <p class="subtitle is-5">Withdrawals</p>
              </article>
              <span class="tag is-link top-right">TODAY</span>
            </div>
          </div>
          <div class="tile is-parent">
            <div
              class="tile is-child relative-position"
              :class="{ 'element is-loading' : isInfoLoading}"
            >
              <article class="tile is-child notification is-primary top-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.yesterday.deposit }}</p>
                <p class="subtitle is-5">Deposits</p>
              </article>
              <article class="tile is-child notification is-success bottom-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.yesterday.withdrawal }}</p>
                <p class="subtitle is-5">Withdrawals</p>
              </article>
              <span class="tag is-link top-right">YESTERDAY</span>
            </div>
          </div>
          <div class="tile is-parent">
            <div
              class="tile is-child relative-position"
              :class="{ 'element is-loading' : isInfoLoading}"
            >
              <article class="tile is-child notification is-primary top-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.currentMonth.deposit }}</p>
                <p class="subtitle is-5">Deposits</p>
              </article>
              <article class="tile is-child notification is-success bottom-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.currentMonth.withdrawal }}</p>
                <p class="subtitle is-5">Withdrawals</p>
              </article>
              <span class="tag is-link top-right">CURRENT MONTH</span>
            </div>
          </div>
          <div class="tile is-parent">
            <div
              class="tile is-child relative-position"
              :class="{ 'element is-loading' : isInfoLoading}"
            >
              <article class="tile is-child notification is-primary top-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.lastMonth.deposit }}</p>
                <p class="subtitle is-5">Deposits</p>
              </article>
              <article class="tile is-child notification is-success bottom-radius">
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.lastMonth.withdrawal }}</p>
                <p class="subtitle is-5">Withdrawals</p>
              </article>
              <span class="tag is-link top-right">LAST MONTH</span>
            </div>
          </div>
        </div>
      </card>
    </div>
    <b-modal
      :active.sync="isWalletRechargeModalActive"
      :has-modal-card="false"
      @on-update="loadAsyncUserWallet"
    >
      <wallet-recharge-form
        :user="user"
        :type="wallet_transaction_type"
      ></wallet-recharge-form>
    </b-modal>
    <b-modal
      :active.sync="isWalletTransactionDetailModalActive"
      :has-modal-card="false"
    >
      <wallet-transaction-details
        :user="user"
        :record="transaction_record"
      ></wallet-transaction-details>
    </b-modal>
    <div
      class="card card-section"
      v-if="hasAnyScope('USER-WALLET-TRANSACTION-LIST')"
    >
      <div class="card-content">
        <b-field
          grouped
          group-multiline
        >
          <b-select
            v-model="filter.size"
            :disabled="!hasItems"
            @input="onFilter"
          >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </b-select>
        </b-field>
        <b-table
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :mobile-cards="true"
          :loading="isTransactionsLoading"
          paginated
          backend-pagination
          :per-page="filter.size"
          :total="meta.total"
          @page-change="onPageChange"
          backend-sorting
          default-sort-direction="desc"
          :default-sort="[filter.sortField, filter.sortOrder]"
          @sort="onSort"
          :data="items"
        >
          <b-table-column
            label="S.No."
            width="40"
            numeric
            v-slot="props"
          >
            {{ meta.from + props.index }}
          </b-table-column>

          <b-table-column
            field="created_at"
            label="Date Time"
            sortable
            v-slot="props"
          >
            {{ props.row.created_at }}
          </b-table-column>

          <b-table-column
            field="type"
            label="Type"
            sortable
            v-slot="props"
          >
            {{ props.row.type }}
          </b-table-column>
          <b-table-column
            field="amount"
            label="Amount"
            v-slot="props"
          >
            {{ props.row.currency }} {{ props.row.amount }}
          </b-table-column>
          <b-table-column
            field="transaction_id"
            label="Transaction ID"
            v-slot="props"
          >
            {{ props.row.transaction_id }}
          </b-table-column>
          <b-table-column
            label="Transaction Status"
            v-slot="props"
          >
            {{ props.row.status }}
          </b-table-column>
          <b-table-column
            label="Actions"
            v-slot="props"
          >
            <button
              @click="showTransactionRecord(props.row)"
              class="button is-primary is-rounded"
            >DETAILS</button>
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="emoticon-sad"
                    size="is-large"
                  >
                  </b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import Card from '@/lib/Card'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import WalletRechargeForm from '@/pages/secure/users/WalletRechargeForm'
import WalletTransactionDetails from '@/pages/secure/users/WalletTransactionDetails'
import { USER_SHOW } from '@/store/actions/userActions'
import { WALLET_INFO, WALLET_TRANSACTION_LIST } from '@/store/actions/walletActions'

export default {
  name: 'UserWallet',
  components: {
    Breadcrumb,
    Card,
    WalletRechargeForm,
    WalletTransactionDetails,
    AdvanceFilter
  },
  props: {
    userId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncUser'
  },
  data () {
    return {
      pageTitle: 'User Wallet Details',
      breadcrumb: {
        list: [
          {
            icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard'
          },
          {
            icon: 'fas fa-user-shield', routeName: 'UserList', name: 'Manage Users'
          }
        ],
        current: { icon: 'fas fa-wallet', name: 'User Wallet' },
        actions: [
          {
            icon: 'fas fa-user-shield', routeName: 'UserList', name: 'Manage Users', scope: 'USER-LIST'
          },
          {
            icon: 'fas fa-rupee-sign', actionName: 'RechargeWalletAction', name: 'Recharge Wallet', scope: 'USER-WALLET-RECHARGE'
          },
          {
            icon: 'fas fa-rupee-sign', actionName: 'WithdrawalWalletAction', name: 'Withdrawal From Wallet', scope: 'USER-WALLET-RECHARGE'
          }
        ]
      },
      user: null,
      transaction_record: null,
      isWalletTransactionDetailModalActive: false,
      isWalletRechargeModalActive: false,
      wallet: {
        alltime_balance: 0,
        allTime: {
          deposit: 0,
          withdrawal: 0
        },
        currentMonth: {
          deposit: 0,
          withdrawal: 0
        },
        lastMonth: {
          deposit: 0,
          withdrawal: 0
        },
        currentWeek: {
          deposit: 0,
          withdrawal: 0
        },
        lastWeek: {
          deposit: 0,
          withdrawal: 0
        },
        yesterday: {
          deposit: 0,
          withdrawal: 0
        },
        today: {
          deposit: 0,
          withdrawal: 0
        }
      },
      wallet_transaction_type: 'DEPOSIT',
      errors: {},
      filter: {
        transaction_id: '',
        types: [],
        transaction_methods: [],
        statuses: [],
        page: 1,
        size: 10,
        start_date: null,
        end_date: null,
        sortField: 'created_at',
        sortOrder: 'desc'
      },
      type_options: ['DEPOSIT', 'WITHDRAWAL'],
      method_options: ['CASH', 'CHEQUE', 'DEBIT CARD', 'CREDIT CARD', 'NEFT/IMPS/RTGS', 'PAYTM'],
      status_options: ['MANUAL DEPOSIT', 'MANUAL WITHDRAWAL', 'AUTO DEPOSIT', 'AUTO WITHDRAWAL'],
      items: [],
      meta: {
        total: 0
      }
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    ...mapGetters('Users', { isUserLoading: 'isLoading' }),
    ...mapGetters('Wallets', ['isInfoLoading', 'isTransactionsLoading']),
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    },
    allTimeHeader () {
      let result = 'ALL TIME'
      if (
        (this.filter.start_date !== null && this.filter.start_date !== undefined) ||
        (this.filter.end_date !== null && this.filter.end_date !== undefined)
      ) {
        result = 'CUSTOM RANGE'
      }
      return result
    }
  },
  methods: {
    ...mapActions('Users', { loadUser: USER_SHOW }),
    ...mapActions('Wallets', { loadWalletInfo: WALLET_INFO, loadTransactionList: WALLET_TRANSACTION_LIST }),
    onBreadcrumbAction (actionName) {
      if (actionName === 'RechargeWalletAction') {
        this.wallet_transaction_type = 'DEPOSIT'
        this.isWalletRechargeModalActive = true
      } else if (actionName === 'WithdrawalWalletAction') {
        this.wallet_transaction_type = 'WITHDRAWAL'
        this.isWalletRechargeModalActive = true
      }
    },
    showTransactionRecord (transactionRecord) {
      this.transaction_record = transactionRecord
      this.isWalletTransactionDetailModalActive = true
    },
    loadAsyncUser () {
      this.user = null
      this.loadUser(this.userId)
        .then((response) => {
          this.user = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (User Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'UserList' }),
            onConfirm: () => this.$router.replace({ name: 'UserList' })
          })
        })
    },
    loadAsyncUserWallet () {
      let startDate = null
      let endDate = null
      if (this.filter.start_date !== undefined && this.filter.start_date !== null && this.filter.start_date !== '') {
        startDate = moment(this.filter.start_date).format('YYYY-MM-DD')
      }
      if (this.filter.end_date !== undefined && this.filter.end_date !== null && this.filter.end_date !== '') {
        endDate = moment(this.filter.end_date).format('YYYY-MM-DD')
      }
      const data = {
        userId: this.userId,
        filterData: {
          start_date: startDate,
          end_date: endDate
        }
      }
      this.loadWalletInfo(data)
        .then((response) => {
          this.wallet.alltime_balance = response.wallet_balance
          this.wallet.allTime = { deposit: response.all_time.DEPOSIT, withdrawal: response.all_time.WITHDRAWAL }
          this.wallet.currentMonth = { deposit: response.current_month.DEPOSIT, withdrawal: response.current_month.WITHDRAWAL }
          this.wallet.lastMonth = { deposit: response.last_month.DEPOSIT, withdrawal: response.last_month.WITHDRAWAL }
          this.wallet.currentWeek = { deposit: response.current_week.DEPOSIT, withdrawal: response.current_week.WITHDRAWAL }
          this.wallet.lastWeek = { deposit: response.last_week.DEPOSIT, withdrawal: response.last_week.WITHDRAWAL }
          this.wallet.yesterday = { deposit: response.yesterday.DEPOSIT, withdrawal: response.yesterday.WITHDRAWAL }
          this.wallet.today = { deposit: response.today.DEPOSIT, withdrawal: response.today.WITHDRAWAL }
        })
        .catch((error) => {
          this.$notify({
            title: 'Wallet Info',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onFilter () {
      this.loadAsyncData()
    },
    onPageChange (page) {
      this.filter.page = page
      this.loadAsyncData()
    },
    onSort (field, order) {
      this.filter.sortField = field
      this.filter.sortOrder = order
      this.loadAsyncData()
    },
    loadAsyncData () {
      let startDate = null
      let endDate = null
      if (this.filter.start_date !== undefined && this.filter.start_date !== null && this.filter.start_date !== '') {
        startDate = moment(this.filter.start_date).format('YYYY-MM-DD')
      }
      if (this.filter.end_date !== undefined && this.filter.end_date !== null && this.filter.end_date !== '') {
        endDate = moment(this.filter.end_date).format('YYYY-MM-DD')
      }
      if (startDate !== null || endDate !== null) {
        this.loadAsyncUserWallet()
      }
      const data = {
        userId: this.userId,
        filterData: {
          ...this.filter,
          start_date: startDate,
          end_date: endDate
        }
      }
      this.loadTransactionList(data)
        .then((response) => {
          this.items = response.data
          this.meta = response.meta
        })
        .catch((error) => {
          this.$notify({
            title: 'Page Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    reset () {
      this.filter = {
        transaction_id: '',
        types: [],
        transaction_methods: [],
        statuses: [],
        page: 1,
        size: 10,
        sortField: 'created_at',
        sortOrder: 'desc'
      }
      this.items = []
      this.meta = {
        total: 0
      }
      if (this.hasAnyScope('USER-WALLET-TRANSACTION-LIST')) {
        this.onFilter()
      }
    }
  },
  created () {
    this.loadAsyncUser()
    this.loadAsyncUserWallet()
    this.reset()
  }
}
</script>
