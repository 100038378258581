<template>
  <b-field
    label="Distributor"
    custom-class="is-small"
  >
    <b-autocomplete
      icon-pack="fas"
      icon="search"
      :placeholder="`Type ${type} details`"
      :field="field"
      :keep-first="true"
      :data="items"
      v-model="queryStr"
      :loading="isLoading"
      @input="loadUsersSuggestion"
      @select="onUseSelection"
    >
      <template slot="empty">No {{type}} found</template>
      <template slot-scope="props">
        <div class="media">
          <div class="media-content">
            {{ props.option.name }}
            <br>
            <small>
              {{ props.option.email }},
            </small>
          </div>
        </div>
      </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
import { mapActions } from 'vuex'
import searchInputMixins from './user-search-input-mixin'
import { DISTRIBUTOR_LIST } from '@/store/actions/userActions'

export default {
  name: 'DistributorSearchInput',
  mixins: [searchInputMixins],
  data () {
    return {
      type: 'distributor'
    }
  },
  methods: {
    ...mapActions('Users', { loadUsers: DISTRIBUTOR_LIST })
  }
}
</script>
