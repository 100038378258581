import { axios } from './axios'

export default {
  list (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/devicewindows', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  show (deviceWindowId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/devicewindows/${deviceWindowId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  create (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/devicewindows', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  update (deviceWindowId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/devicewindows/${deviceWindowId}`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  delete (deviceWindowId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/devicewindows/${deviceWindowId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
