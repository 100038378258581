<template>
  <div class="errors-page Aligner">
    <div class="Aligner">
      <div class="Aligner-item"><h1 class="error-msg">Something went wrong on to server, kindly contact with site administrator...</h1></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
.Aligner
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  .Aligner-item
    max-width: 50%
  .Aligner-item--top
    align-self: flex-start
  .Aligner-item--bottom
    align-self: flex-end
h1.error-msg
  font-size: 36px
  padding: 20px
  color: #636b6f;
  font-family: 'Raleway', sans-serif;
  font-weight: 100;
</style>
