import {
  TEXT_ADVERTISEMENT_LIST,
  TEXT_ADVERTISEMENT_CREATE,
  TEXT_ADVERTISEMENT_SHOW,
  TEXT_ADVERTISEMENT_UPDATE,
  TEXT_ADVERTISEMENT_DELETE,
  TEXT_ADVERTISEMENT_THUMBNAIL
} from '../../actions/textAdvertisementActions'

import { LOADING, SUCCESS, ERROR, LOADING_THUMBNAIL, SUCCESS_THUMBNAIL, ERROR_THUMBNAIL } from '../../actions/requestStatus'
import advertisementApi from '../../../api/textAdvertisementApi'

export default {
  namespaced: true,

  state: {
    status: '',
    thumbnailStatus: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR,
    isThumbnailLoading: (state) => state.thumbnailStatus === LOADING_THUMBNAIL,
    isThumbnailSuccess: (state) => state.thumbnailStatus === SUCCESS_THUMBNAIL,
    isThumbnailError: (state) => state.thumbnailStatus === ERROR_THUMBNAIL
  },

  actions: {
    [TEXT_ADVERTISEMENT_THUMBNAIL]: ({ commit }, { advertisementId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING_THUMBNAIL)
        advertisementApi
          .uploadThumbnail(advertisementId, formData)
          .then((response) => {
            commit(SUCCESS_THUMBNAIL)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR_THUMBNAIL)
            reject(error)
          })
      }),

    [TEXT_ADVERTISEMENT_SHOW]: ({ commit }, advertisementId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        advertisementApi
          .show(advertisementId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [TEXT_ADVERTISEMENT_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        advertisementApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [TEXT_ADVERTISEMENT_CREATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        advertisementApi
          .create(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [TEXT_ADVERTISEMENT_UPDATE]: ({ commit }, { advertisementId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        advertisementApi
          .update(advertisementId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [TEXT_ADVERTISEMENT_DELETE]: ({ commit }, advertisementId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        advertisementApi
          .delete(advertisementId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    },
    [LOADING_THUMBNAIL]: (state) => {
      state.thumbnailStatus = LOADING_THUMBNAIL
    },
    [SUCCESS_THUMBNAIL]: (state) => {
      state.thumbnailStatus = SUCCESS_THUMBNAIL
    },
    [ERROR_THUMBNAIL]: (state) => {
      state.thumbnailStatus = ERROR_THUMBNAIL
    }
  }
}
