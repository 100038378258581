import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './store/modules/auth'
import Profile from './store/modules/profile'
import Users from './store/modules/users'
import Roles from './store/modules/roles'
import Navs from './store/modules/navs'
import Settings from './store/modules/settings'
import Categories from './store/modules/categories'
import DeviceWindows from './store/modules/deviceWindows'
import Locations from './store/modules/locations'
import Websites from './store/modules/websites'
import Campaigns from './store/modules/campaigns'
import Tags from './store/modules/tags'
import Devices from './store/modules/devices'
import Advertisements from './store/modules/advertisements'
import TextAdvertisements from './store/modules/textadvertisements'
import Sliders from './store/modules/sliders'
import Media from './store/modules/media'
import Pages from './store/modules/pages'
import Bookings from './store/modules/bookings'
import Reservations from './store/modules/reservations'
import Orders from './store/modules/orders'
import Wallets from './store/modules/wallets'
import MyWallets from './store/modules/mywallets'
import MyActions from './store/modules/myactions'
import Tickets from './store/modules/tickets'
import Reports from './store/modules/reports'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Auth,
    Wallets,
    Profile,
    Users,
    Roles,
    Tickets,
    Navs,
    Categories,
    Sliders,
    Media,
    Pages,
    Settings,
    DeviceWindows,
    Locations,
    Websites,
    Campaigns,
    Tags,
    Devices,
    Advertisements,
    TextAdvertisements,
    Bookings,
    Reservations,
    Orders,
    MyActions,
    MyWallets,
    Reports
  },
  state: {},
  getters: {},
  actions: {},
  mutations: {},
  strict: debug
})
