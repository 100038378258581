import Treeselect from '@riophae/vue-treeselect'

export default {
  props: ['value'],
  components: { Treeselect },
  data () {
    return {
      type: 'Options',
      options: [],
      values: [],
      filterTimout: null
    }
  },
  watch: {
    value: 'selectFromProps'
  },
  methods: {
    selectFromProps () {
      if (this.value) {
        const eles = []
        this.value.forEach(val => {
          eles.push(val)
        })
        this.values = eles
      } else {
        this.values = []
      }
    },
    normalizer (node) {
      let result = null
      if (node.children.length > 0) {
        result = {
          id: node.id,
          label: node.name,
          children: node.children
        }
      } else {
        result = {
          id: node.id,
          label: node.name
        }
      }
      return result
    },
    loadOptions () {
      return new Promise((resolve) => {
        resolve({ data: [] })
      })
    },
    loadAsyncOptions () {
      return new Promise((resolve, reject) => {
        this.loadOptions()
          .then((response) => {
            this.options = response.data
            resolve(response.data)
          })
          .catch((error) => {
            this.$notify({
              title: `${this.type} Loading Failed`,
              text: error.message,
              type: 'is-danger'
            })
            reject(error)
          })
      })
    },
    onValueChange (node) {
      this.emitFilter()
    },
    emitFilter () {
      clearTimeout(this.filterTimout)
      this.filterTimout = setTimeout(() => {
        this.$emit('input', this.values)
      }, 1000)
    }
  },
  created () {
    this.loadAsyncOptions()
      .then(() => {
        this.selectFromProps()
      })
  }
}
