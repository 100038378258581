export const CAMPAIGN_LIST = 'CAMPAIGN_LIST'
export const CAMPAIGN_CREATE = 'CAMPAIGN_CREATE'
export const CAMPAIGN_UPDATE = 'CAMPAIGN_UPDATE'
export const CAMPAIGN_RELATION_UPDATE = 'CAMPAIGN_RELATION_UPDATE'
export const CAMPAIGN_SHOW = 'CAMPAIGN_SHOW'
export const CAMPAIGN_SHOW_DETAILS = 'CAMPAIGN_SHOW_DETAILS'
export const CAMPAIGN_DELETE = 'CAMPAIGN_DELETE'
export const CAMPAIGN_THUMBNAIL = 'CAMPAIGN_THUMBNAIL'
export const CAMPAIGN_ADS = 'CAMPAIGN_ADS'
export const CAMPAIGN_UPLOAD_SPACES = 'CAMPAIGN_UPLOAD_SPACES'
export const CAMPAIGN_START = 'CAMPAIGN_START'
