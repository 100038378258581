import { axios } from './axios'

export default {
  reportOperation (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/reports/operation', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  reportRevenue (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/reports/revenue', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  reportAggregateOperation (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/reports-metadata/operation', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  reportAggregateRevenue (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/reports-metadata/revenue', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  reportDeviceStatusCount (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/reports-charts/device-status-count', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  reportDeviceWorkingStatusCount (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/reports-charts/device-working-status-count', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  reportAdsRunningStatusCount (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/reports-charts/ads-running-status-count', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  reportCampaignStatusCount (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/reports-charts/campaign-status-count', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
