<template>
  <div>
    <!-- <loading v-show="isTicketsLoading"></loading> -->
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
      @action-clicked="onBreadcrumbAction"
    ></breadcrumb>
    <advance-filter
      :is-loading="isTicketsLoading"
      @onStart="onFilter"
    >
      <div class="columns is-multiline">
        <div class="column field is-3">
          <label class="label is-small">Query</label>
          <p class="control has-icons-left">
            <input
              class="input"
              type="text"
              placeholder="search"
              v-model="filter.query"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-search"></i>
            </span>
          </p>
        </div>
        <div class="column field is-3">
          <label class="label is-small">Ticket Status</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              multiple
              v-model="filter.statuses"
              :options="status_options"
              placeholder="Pick statuses for filter"
              class="is-small"
            ></v-select>
          </div>
        </div>
      </div>
    </advance-filter>

    <div class="card card-section">
      <div class="card-content">
        <b-field
          grouped
          group-multiline
        >
          <b-select
            v-model="filter.size"
            :disabled="!hasItems"
            @input="onFilter"
          >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </b-select>
        </b-field>
        <b-table
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :mobile-cards="true"
          :loading="isTicketsLoading"
          paginated
          backend-pagination
          :per-page="filter.size"
          :total="meta.total"
          @page-change="onPageChange"
          backend-sorting
          default-sort-direction="desc"
          :default-sort="[filter.sortField, filter.sortOrder]"
          @sort="onSort"
          :data="items"
          detailed
          detail-key="id"
        >
          <b-table-column
            label="S.No."
            width="40"
            numeric
            v-slot="props"
          >
            {{ meta.from + props.index }}
          </b-table-column>
          <b-table-column
            field="type"
            label="Type"
            v-slot="props"
          >
            {{ getTicketType(props.row.type) }}
          </b-table-column>
          <b-table-column
            label="Amount"
            width="40"
            numeric
            v-slot="props"
          >
            {{ props.row.amount }}
          </b-table-column>
          <b-table-column
            field="status"
            label="Status"
            sortable
            v-slot="props"
          >
            {{ props.row.status }}
          </b-table-column>
          <b-table-column
            field="updated_at"
            label="Last Modified"
            sortable
            v-slot="props"
          >
            {{ props.row.updated_at }}
          </b-table-column>
          <b-table-column
            label=""
            v-slot="props"
          >
            <b-dropdown position="is-bottom-left">
              <button
                class="button is-primary"
                slot="trigger"
              >
                <span>Actions</span>
                <b-icon
                  icon="chevron-down"
                  size="is-small"
                ></b-icon>
              </button>
              <b-dropdown-item has-link>
                <a
                  v-if="props.row.status !== 'CANCELLED'"
                  @click.prevent="confirmCancelledTicket(props.row.id)"
                >
                  <span class="icon">
                    <i class="fas fa-trash"></i>
                  </span>
                  <span>CANCEL</span>
                </a>
                <div v-else>
                  <span class="icon">
                    <i class="fas fa-trash"></i>
                  </span>
                  <span>ALREADY CANCELLED</span>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>
          <template
            slot="detail"
            slot-scope="props"
          >
            <p>User: {{ props.row.user.name }} ({{ props.row.user.email }})</p>
            <p>Type: {{ getTicketType(props.row.type) }}</p>
            <p>Amount: {{ props.row.amount }} {{ props.row.currency }}</p>
            <p>Remarks: {{ props.row.remarks }}</p>
            <p>Requested By: {{ props.row.requested_by.name }} ({{ props.row.requested_by.email }})</p>
            <p>Created On: {{ props.row.created_at }}</p>
            <p>Last Modified On: {{ props.row.updated_at }}</p>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="emoticon-sad"
                    size="is-large"
                  > </b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      :active.sync="isCreateModalActive"
      has-modal-card
    >
      <!-- <my-ticket-create v-bind="formProps"></my-ticket-create> -->
      <my-ticket-create @ticket-created="reset"></my-ticket-create>
    </b-modal>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import { mapActions } from 'vuex'
import { TICKET_LIST, TICKET_CREATE, TICKET_CANCELLED } from '@/store/actions/myActions'
import MyTicketCreate from './MyTicketCreate'

export default {
  name: 'MyTicketList',
  components: {
    Breadcrumb,
    AdvanceFilter,
    MyTicketCreate
  },
  data () {
    return {
      pageTitle: 'Manage My Tickets',
      breadcrumb: {
        list: [{ icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' }],
        current: { icon: 'fab fa-stack-exchange', name: 'My Tickets' },
        actions: [
          { icon: 'fas fa-plus', actionName: 'MyTicketCreate', name: 'Create Ticket' },
          { icon: 'fas fa-sync-alt', actionName: 'RefreshTickets', name: 'Refresh Ticket List' }
        ]
      },
      status_options: ['WAITING', 'PROCESSED', 'CANCELLED'],
      type_options: ['REDEEM WALLET MONEY'],
      filter: {},
      items: [],
      meta: {},
      isTicketsLoading: false,
      isCreateModalActive: false
    }
  },
  computed: {
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    }
  },
  methods: {
    ...mapActions('MyActions', { loadTickets: TICKET_LIST, createTicket: TICKET_CREATE, sendCancelledTicketRequest: TICKET_CANCELLED }),
    getTicketType (typeValue) {
      let result = ''
      if (typeValue === 1) {
        result = 'REDEEM WALLET MONEY'
      }
      return result
    },
    onFilter () {
      this.loadAsyncData()
    },
    onPageChange (page) {
      this.filter.page = page
      this.loadAsyncData()
    },
    onSort (field, order) {
      this.filter.sortField = field
      this.filter.sortOrder = order
      this.loadAsyncData()
    },
    confirmCancelledTicket (ticketId) {
      this.$buefy.dialog.confirm({
        title: 'Cancelled Ticket',
        message: 'Are you sure you want to <b>cancelled</b> this ticket? This action cannot be undone.',
        confirmText: 'Cancelled Ticket',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.cancelledTicket(ticketId)
      })
    },
    cancelledTicket (ticketId) {
      this.sendCancelledTicketRequest(ticketId)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.onFilter()
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncData () {
      if (!this.isTicketsLoading) {
        this.isTicketsLoading = true
        const data = this.filter
        this.loadTickets(data)
          .then((response) => {
            this.items = response.data
            this.meta = response.meta
            this.isTicketsLoading = false
          })
          .catch((error) => {
            this.isTicketsLoading = false
            this.$notify({
              title: 'Tickets Loading Failed',
              text: error.message,
              type: 'is-danger'
            })
          })
      }
    },
    reset () {
      this.filter = {
        statuses: [],
        types: [],
        query: '',
        page: 1,
        size: 10,
        sortField: 'updated_at',
        sortOrder: 'desc'
      }
      this.items = []
      this.meta = {
        total: 0
      }
      this.onFilter()
    },
    onBreadcrumbAction (actionName) {
      if (actionName === 'MyTicketCreate') {
        this.isCreateModalActive = true
      } else if (actionName === 'RefreshTickets') {
        this.reset()
      }
    }
  },
  created () {
    this.reset()
  }
}
</script>
