import { axios } from './axios'

export default {
  deviceDeleteGallery (userView, deviceId, galleryId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/my-devices/${userView}/${deviceId}/gallery/${galleryId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  deviceUploadGallery (userView, deviceId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/my-devices/${userView}/${deviceId}/gallery`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deviceUploadThumbnail (userView, deviceId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/my-devices/${userView}/${deviceId}/thumbnail`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deviceUploadAds (userView, deviceId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/my-devices/${userView}/${deviceId}/ads`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadAdsByText (userView, deviceId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/my-devices/${userView}/${deviceId}/text-to-ads`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  deviceList (userView, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/my-devices/${userView}`, {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  deviceShow (userView, deviceId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/my-devices/${userView}/${deviceId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  deviceShowDetails (userView, deviceId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/my-devices/${userView}/${deviceId}/details`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  reportRevenue (userView, deviceId, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/my-devices/${userView}/${deviceId}/report/revenue`, {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  reportAggregateRevenue (userView, deviceId, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/my-devices/${userView}/${deviceId}/report-metadata/revenue`, {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  deviceUpdate (userView, deviceId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/my-devices/${userView}/${deviceId}`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  deviceListPings (userView, deviceId, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/my-devices/${userView}/${deviceId}/pings`, {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  deviceAdsHistoryList (userView, deviceId, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/my-devices/${userView}/${deviceId}/ads-history`, {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  agentList (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/my-agents', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  agentShow (agentId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/my-agents/${agentId}/details`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },

  getWalletInfo (agentId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/my-agents/wallets/${agentId}/info`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  listTransactions (agentId, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/my-agents/wallets/${agentId}/transactions`, {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  advertisementSuggestions (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/my-advertisements-suggestions', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  advertisementList (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/my-advertisements', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  advertisementShow (advertisementId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/my-advertisements/${advertisementId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  advertisementCreate (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/my-advertisements', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  advertisementUpdate (advertisementId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/my-advertisements/${advertisementId}`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  advertisementDelete (advertisementId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/my-advertisements/${advertisementId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  advertisementUploadThumbnail (advertisementId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/my-advertisements/${advertisementId}/thumbnail`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  advertisementUploadAds (advertisementId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/my-advertisements/${advertisementId}/ads`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  ticketList (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/my-tickets', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  ticketCreate (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/my-tickets', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  ticketCancelled (ticketId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/my-tickets/${ticketId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
