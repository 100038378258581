import {
  REPORT_OPERATION, REPORT_REVENUE, REPORT_AGGREGATE_OPERATION, REPORT_AGGREGATE_REVENUE, REPORT_DEVICE_STATUS_COUNT, REPORT_DEVICE_WORKING_STATUS_COUNT, REPORT_ADS_RUNNING_STATUS_COUNT, REPORT_CAMPAIGN_STATUS_COUNT
} from '../../actions/reportActions'

import { LOADING, SUCCESS, ERROR } from '../../actions/requestStatus'
import reportApi from '../../../api/reportApi'

export default {
  namespaced: true,

  state: {
    status: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR
  },

  actions: {
    [REPORT_OPERATION]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        reportApi
          .reportOperation(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [REPORT_REVENUE]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        reportApi
          .reportRevenue(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [REPORT_AGGREGATE_OPERATION]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        reportApi
          .reportAggregateOperation(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [REPORT_AGGREGATE_REVENUE]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        reportApi
          .reportAggregateRevenue(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [REPORT_DEVICE_STATUS_COUNT]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        reportApi
          .reportDeviceStatusCount(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [REPORT_DEVICE_WORKING_STATUS_COUNT]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        reportApi
          .reportDeviceWorkingStatusCount(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [REPORT_ADS_RUNNING_STATUS_COUNT]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        reportApi
          .reportAdsRunningStatusCount(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [REPORT_CAMPAIGN_STATUS_COUNT]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        reportApi
          .reportCampaignStatusCount(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    }
  }
}
