<template>
  <div class="columns is-mobile is-multiline">
    <div class="column is-12">
      <div class="file has-name is-fullwidth is-primary">
        <label class="file-label">
          <input
            class="file-input"
            multiple
            type="file"
            name="slidesFiles"
            ref="slidesFiles"
            @change="uploadSlidesFiles"
          />
          <span class="file-cta">
            <span class="file-icon">
              <i class="fas fa-upload"></i>
            </span>
            <span class="file-label">
              Choose files to upload…
            </span>
          </span>
          <span class="file-name">

          </span>
        </label>
      </div>
    </div>
    <div
      class="column is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd"
      v-for="queue in uploadQueues"
      :key="queue.id"
    >
      <div class="card">
        <div class="card-image">
          <figure class="image">
            <img
              :src="queue.image"
              alt="Queue Image"
            >
          </figure>
          <div
            v-show="queue.status === 'uploading'"
            class="card-image-text-ontop has-background-primary"
          >
            <i class="fas fa-cloud-upload-alt"></i> Uploading...<i class="fas fa-spinner fa-spin"></i>
          </div>
          <div
            v-show="queue.status === 'success'"
            class="card-image-text-ontop has-background-success"
          >
            <i class="fas fa-cloud-upload-alt"></i> Uploaded to server...
          </div>
          <div
            v-show="queue.status === 'error'"
            class="card-image-text-ontop has-background-danger"
          >
            <i class="fas fa-cloud-upload-alt"></i> Failed to upload on server...
          </div>
        </div>
        <div
          class="card-content"
          v-show="queue.status === 'success'"
        >
          <div class="field">
            <label class="label">Title</label>
            <div class="control">
              <input
                v-model="queue.title"
                class="input"
                type="text"
                placeholder="Enter Slider Title"
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Sub-Title</label>
            <div class="control">
              <input
                v-model="queue.subtitle"
                class="input"
                type="text"
                placeholder="Enter Slider Sub-Title"
              />
            </div>
          </div>
          <div class="field">
            <label class="label">HREF Link</label>
            <div class="control">
              <input
                v-model="queue.link"
                class="input"
                type="text"
                placeholder="Enter Slider HREF Link"
              />
            </div>
          </div>
        </div>
        <footer
          class="card-footer"
          v-show="queue.status === 'success'"
        >
          <button
            :disabled="queue.deleteInProcess"
            class="card-footer-item has-background-primary has-text-white"
            @click="updateSlideFromServer(queue, 'queue')"
          >
            <i
              class="fab fa-wpforms"
              :class="{ 'fa-spin' : queue.deleteInProcess }"
            ></i> <span class="delete-text">Update Slide</span>
          </button>
          <button
            :disabled="queue.deleteInProcess"
            class="card-footer-item has-background-danger has-text-white"
            @click="deleteSlideFromServer(queue, 'queue')"
          >
            <i
              class="fas fa-trash-alt"
              :class="{ 'fa-spin' : queue.deleteInProcess }"
            ></i> <span class="delete-text">Remove Slide</span>
          </button>
        </footer>
      </div>
    </div>
    <div
      class="column is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd"
      v-for="slideImage in slides"
      :key="slideImage.id"
    >
      <div class="card">
        <div class="card-image">
          <figure class="image">
            <img
              :src="slideImage.large"
              alt="Slides Image"
            >
          </figure>
        </div>
        <div class="card-content">
          <div class="field">
            <label class="label">Title</label>
            <div class="control">
              <input
                v-model="slideImage.title"
                class="input"
                type="text"
                placeholder="Enter Slider Title"
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Sub-Title</label>
            <div class="control">
              <input
                v-model="slideImage.subtitle"
                class="input"
                type="text"
                placeholder="Enter Slider Sub-Title"
              />
            </div>
          </div>
          <div class="field">
            <label class="label">HREF Link</label>
            <div class="control">
              <input
                v-model="slideImage.link"
                class="input"
                type="text"
                placeholder="Enter Slider HREF Link"
              />
            </div>
          </div>
        </div>
        <footer class="card-footer">
          <button
            class="card-footer-item has-background-primary has-text-white"
            :disabled="slideImage.deleteInProcess"
            @click="updateSlideFromServer(slideImage, 'slides')"
          >
            <i
              class="fab fa-wpforms"
              :class="{ 'fa-spin' : slideImage.deleteInProcess }"
            ></i> <span class="delete-text">Update Slide</span>
          </button>
          <button
            class="card-footer-item has-background-danger has-text-white"
            :disabled="slideImage.deleteInProcess"
            @click="deleteSlideFromServer(slideImage, 'slides')"
          >
            <i
              class="fas fa-trash-alt"
              :class="{ 'fa-spin' : slideImage.deleteInProcess }"
            ></i> <span class="delete-text">Remove Slide</span>
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { SLIDE_CREATE, SLIDE_UPDATE, SLIDE_DELETE } from '@/store/actions/sliderActions'

export default {
  name: 'SliderGallery',
  props: {
    slider: {
      required: true
    }
  },
  data () {
    return {
      slides: this.slider.slides ? this.slider.slides : [],
      uploadQueues: []
    }
  },
  methods: {
    ...mapActions('Sliders', { uploadSlide: SLIDE_CREATE, updateSlide: SLIDE_UPDATE, deleteSlide: SLIDE_DELETE }),
    updateSlideFromServer (slideItem, queueName) {
      const tempSlideItem = slideItem
      const data = {
        sliderId: this.slider.id,
        slideId: tempSlideItem.id,
        formData: {
          title: tempSlideItem.title,
          subtitle: tempSlideItem.subtitle,
          link: tempSlideItem.link
        }
      }
      if (queueName === 'slides') {
        this.$set(tempSlideItem, 'deleteInProcess', true)
      } else {
        tempSlideItem.deleteInProcess = true
      }
      this.updateSlide(data)
        .then(() => {
          tempSlideItem.deleteInProcess = false
          this.$notify({
            title: 'Slide Updated',
            text: 'Slide is updated at server successfully',
            type: 'is-success'
          })
        })
        .catch((error) => {
          tempSlideItem.deleteInProcess = false
          this.$notify({
            title: 'Error Slide Updation',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    deleteSlideFromServer (slideItem, queueName) {
      const tempSlideItem = slideItem
      const data = {
        sliderId: this.slider.id,
        slideId: tempSlideItem.id
      }
      if (queueName === 'slides') {
        this.$set(tempSlideItem, 'deleteInProcess', true)
      } else {
        tempSlideItem.deleteInProcess = true
      }
      this.deleteSlide(data)
        .then(() => {
          if (queueName === 'slides') {
            const slideItemIndex = this.slides.findIndex((item) => item.id === slideItem.id)
            this.slides.splice(slideItemIndex, 1)
          } else {
            const slideItemIndex = this.uploadQueues.findIndex((item) => item.id === slideItem.id)
            this.uploadQueues.splice(slideItemIndex, 1)
          }
          this.$notify({
            title: 'Slide Image Removed',
            text: 'Slide image is removed from server successfully',
            type: 'is-success'
          })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error Slide Removal',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    uploadSlidesFiles () {
      const { slidesFiles } = this.$refs
      if (!slidesFiles || !slidesFiles.files.length) {
        return
      }
      Array.from(slidesFiles.files).forEach((slideFile) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          const slideItem = {
            id: this.generateUniqueId(),
            title: slideFile.name,
            subtitle: '',
            link: '',
            image: e.target.result,
            file: slideFile,
            status: 'uploading',
            deleteInProcess: false
          }
          this.uploadQueues.unshift(slideItem)
          this.uploadFormSlide(slideItem)
        }
        reader.readAsDataURL(slideFile)
      })
    },
    chr4 () {
      return Math.random().toString(16).slice(-4)
    },
    generateUniqueId () {
      return (`${Date.now().toString(36)}-${this.chr4()}-${this.chr4()}-${this.chr4()}`).toLowerCase()
    },
    uploadFormSlide (slideItem) {
      const tempSlideItem = slideItem
      const slidesFormData = new FormData()
      slidesFormData.set('slide', tempSlideItem.file)
      slidesFormData.set('id', tempSlideItem.id)
      const data = {
        sliderId: this.slider.id,
        formData: slidesFormData
      }
      this.uploadSlide(data)
        .then(() => {
          tempSlideItem.status = 'success'
          this.$notify({
            title: 'Slides Image',
            text: `${tempSlideItem.title} is uploaded to server successfully`,
            type: 'is-success'
          })
        })
        .catch((error) => {
          tempSlideItem.status = 'error'
          this.$notify({
            title: 'Invalid Slider Slides Image',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
