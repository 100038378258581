<template>
  <nav class="menu" v-if="isNavShow">
    <p class="menu-label">
      {{ nav.label }}
    </p>
    <ul class="menu-list">
      <menu-item
        v-for="(menu, index) in navChildren"
        :key="index + '-nav'"
        :item="menu"
      ></menu-item>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import MenuItem from './MenuItem'

export default {
  name: 'MenuList',
  components: {
    'menu-item': MenuItem
  },
  props: {
    nav: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    isNavShow () {
      let result = false
      if (this.nav.children && (this.nav.scopes === undefined || this.hasAnyScope(this.nav.scopes))) {
        result = true
      }
      return result
    },
    navChildren () {
      return this.nav.children.filter(navChild => this.isNavNeeded(navChild))
    }
  },
  methods: {
    isNavListNeeded (nav) {
      let result = false
      if (nav.children && (nav.scopes === undefined || this.hasAnyScope(nav.scopes))) {
        result = true
      }
      return result
    },
    isNavNeeded (nav) {
      let result = false
      if (nav.scopes === undefined || this.hasAnyScope(nav.scopes)) {
        result = true
      }
      return result
    }
  }
}
</script>
