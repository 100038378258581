import { axios } from './axios'

export default {
  list (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/settings', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  show (settingId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/settings/${settingId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  create (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/settings', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  update (settingId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/settings/${settingId}`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  delete (settingId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/settings/${settingId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
