import Vue from 'vue'
import { PROFILE_REQUEST, PROFILE_SUCCESS, PROFILE_ERROR, STORE_GENERAL_PROFILE, STORE_SECURE_PROFILE, STORE_FINANCE_PROFILE } from '../../actions/profileActions'
import { WALLET_BALANCE_LOADING, WALLET_BALANCE_INFO } from '../../actions/myWalletActions'
import { AUTH_LOGOUT, AUTH_LOGOUT_ALL } from '../../actions/authActions'
import profileApi from '../../../api/profileApi'
import walletApi from '../../../api/myWalletApi'

export default {
  namespaced: true,

  state: {
    profile: {},
    status: '',
    walletBalance: null,
    walletLoading: false
  },

  getters: {
    isWalletLoading: (state) => state.walletLoading,
    walletBalance: (state) => state.walletBalance,
    getAuthId: (state) => state.profile.id,
    getProfile: (state) => state.profile,
    isAdvertiser: (state) => state.profile.types !== undefined && state.profile.types.includes('ADVERTISER'),
    isDistributor: (state) => state.profile.types !== undefined && state.profile.types.includes('DISTRIBUTOR'),
    isAgent: (state) => state.profile.types !== undefined && state.profile.types.includes('AGENT'),
    isChannel: (state) => state.profile.types !== undefined && state.profile.types.includes('CHANNEL'),
    isAdministrator: (state) => state.profile.types !== undefined && state.profile.types.includes('ADMINISTRATOR'),
    isProfileLoaded: (state) => !!state.profile.name,
    hasScope: (state) => (scope) => {
      let result = false
      if (scope !== undefined && scope !== null && state.profile.permissions !== undefined) {
        result = state.profile.permissions.some((permission) => permission === scope)
      }
      return result
    },
    hasAnyScope: (state) => (scopes) => {
      let result = false
      if (scopes !== undefined && scopes !== null && state.profile.permissions !== undefined) {
        result = state.profile.permissions.some((permission) => scopes.indexOf(permission) >= 0)
      }
      return result
    }
  },

  actions: {
    [WALLET_BALANCE_INFO]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(WALLET_BALANCE_LOADING)
        walletApi
          .getWalletBalanceInfo()
          .then((response) => {
            commit(WALLET_BALANCE_INFO, response)
            resolve(response)
          })
          .catch((error) => {
            commit(WALLET_BALANCE_LOADING)
            reject(error)
          })
      }),

    [PROFILE_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(PROFILE_REQUEST)
        profileApi
          .getProfile()
          .then((resp) => {
            commit(PROFILE_SUCCESS, resp)
            resolve()
          })
          .catch((error) => {
            commit(PROFILE_ERROR)
            reject(error)
          })
      }),
    [STORE_GENERAL_PROFILE]: (context, formData) =>
      new Promise((resolve, reject) => {
        profileApi
          .saveGeneralProfile(formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),
    [STORE_SECURE_PROFILE]: (context, formData) =>
      new Promise((resolve, reject) => {
        profileApi
          .saveSecurityProfile(formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),
    [STORE_FINANCE_PROFILE]: (context, formData) =>
      new Promise((resolve, reject) => {
        profileApi
          .saveFinanceProfile(formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
  },

  mutations: {
    [WALLET_BALANCE_INFO]: (state, response) => {
      state.walletLoading = false
      state.walletBalance = response.balance
    },
    [WALLET_BALANCE_LOADING]: (state) => {
      state.walletLoading = true
    },
    [PROFILE_REQUEST]: (state) => {
      state.status = 'loading'
    },
    [PROFILE_SUCCESS]: (state, response) => {
      state.status = 'success'
      Vue.set(state, 'profile', response)
    },
    [PROFILE_ERROR]: (state) => {
      state.status = 'error'
    },
    [AUTH_LOGOUT]: (state) => {
      state.profile = {}
    },
    [AUTH_LOGOUT_ALL]: (state) => {
      state.profile = {}
    }
  }
}
