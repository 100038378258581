import { axios } from './axios'

export default {
  uploadThumbnail (websiteId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/websites/${websiteId}/thumbnail`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/websites', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  show (websiteId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/websites/${websiteId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  create (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/websites', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  update (websiteId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/websites/${websiteId}`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  delete (websiteId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/websites/${websiteId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
