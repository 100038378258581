import {
  MEDIA_LIST,
  MEDIA_CREATE,
  MEDIA_SHOW,
  MEDIA_UPDATE,
  MEDIA_DELETE
} from '../../actions/mediaActions'
import { LOADING, SUCCESS, ERROR } from '../../actions/requestStatus'
import mediaApi from '../../../api/mediaApi'

export default {
  namespaced: true,

  state: {
    status: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR
  },

  actions: {
    [MEDIA_SHOW]: ({ commit }, mediaId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        mediaApi
          .show(mediaId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [MEDIA_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        mediaApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [MEDIA_CREATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        mediaApi
          .create(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [MEDIA_UPDATE]: ({ commit }, { mediaId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        mediaApi
          .update(mediaId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [MEDIA_DELETE]: ({ commit }, mediaId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        mediaApi
          .delete(mediaId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    }
  }
}
