<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="advertisement"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ advertisement.name }}</p>
                  </div>
                </div>
                <div class="content">
                  <div class="columns">
                    <div class="column">
                      <table class="table is-striped">
                        <tbody>
                          <tr>
                            <th>Ads Text</th>
                            <td>{{ advertisement.ads_text }}</td>
                          </tr>
                          <tr>
                            <th>Duration</th>
                            <td>{{ advertisement.duration }} Sec.</td>
                          </tr>
                          <tr>
                            <th>Status</th>
                            <td>{{ advertisement.status }}</td>
                          </tr>
                          <tr>
                            <th>Advertiser</th>
                            <td>
                              <div v-if="advertisement.advertiser">
                                <div v-if="advertisement.advertiser.name">{{ advertisement.advertiser.name }}</div>
                                <div v-if="advertisement.advertiser.email">{{ advertisement.advertiser.email }}</div>
                                <div v-if="advertisement.advertiser.mobile">{{ advertisement.advertiser.mobile }}</div>
                                <div v-if="advertisement.advertiser.address">{{ advertisement.advertiser.address }}</div>
                                <div v-if="advertisement.advertiser.status">{{ advertisement.advertiser.status }}</div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>Ads Categories</th>
                            <td>
                              <div
                                class="tags"
                                v-if="advertisement.tags"
                              >
                                <div
                                  class="tag is-primary"
                                  v-for="tag in advertisement.tags"
                                  :key="`tag-${tag.id}`"
                                >{{ tag.name }}</div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="card">
                        <div class="card-content">
                          <div class="field is-grouped">
                            <p class="control">
                              <router-link
                                :to="{ name: 'TextAdvertisementList'}"
                                class="button is-primary"
                              >
                                <span class="icon">
                                  <i class="fas fa-th-list"></i>
                                </span>
                                <span>LIST</span>
                              </router-link>
                            </p>
                            <p class="control">
                              <router-link
                                class="button is-warning"
                                :to="{ name: 'TextAdvertisementEdit', params: { advertisementId: advertisement.id }}"
                              >
                                <span class="icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                                <span>EDIT</span>
                              </router-link>
                            </p>
                            <p class="control">
                              <a
                                class="button is-danger"
                                @click.prevent="confirmDeleteAdvertisement"
                              >
                                <span class="icon">
                                  <i class="fas fa-trash"></i>
                                </span>
                                <span>DELETE</span>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-two-fifths">
                      <div class="card card-section">
                        <header class="card-header">
                          <p class="card-header-title has-background-primary has-text-white">
                            Thumbnail
                          </p>
                        </header>
                        <div class="card-image">
                          <figure class="image is-4by3">
                            <img
                              v-if="!advertisement.thumbnails"
                              src="/img/placeholders/image-placeholder.png"
                              alt="Advertisement Thumbnail"
                            >
                            <img
                              v-if="advertisement.thumbnails"
                              :src="advertisement.thumbnails.large"
                              alt="Advertisement Thumbnail"
                            >
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { TEXT_ADVERTISEMENT_SHOW, TEXT_ADVERTISEMENT_DELETE } from '@/store/actions/textAdvertisementActions'

export default {
  name: 'TextAdvertisementShow',
  components: { Breadcrumb },
  props: {
    advertisementId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncAdvertisement'
  },
  data () {
    return {
      pageTitle: 'Text Advertisement Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fab fa-adversal', routeName: 'TextAdvertisementList', name: 'Manage Text Advertisements' }
        ],
        current: { icon: 'fas fa-eye', name: 'Text Advertisement Details' },
        actions: [{ icon: 'fab fa-adversal', routeName: 'TextAdvertisementList', name: 'Manage Text Advertisements' }]
      },
      advertisement: null
    }
  },
  computed: {
    ...mapGetters('TextAdvertisements', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('TextAdvertisements', { loadAdvertisement: TEXT_ADVERTISEMENT_SHOW, sendDeleteAdvertisementRequest: TEXT_ADVERTISEMENT_DELETE }),
    confirmDeleteAdvertisement () {
      this.$buefy.dialog.confirm({
        title: 'Deleting Text Advertisement',
        message: 'Are you sure you want to <b>delete</b> this advertisement? This action cannot be undone.',
        confirmText: 'Delete Advertisement',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deleteAdvertisement()
      })
    },
    deleteAdvertisement () {
      this.sendDeleteAdvertisementRequest(this.advertisement.id)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.$router.replace({ name: 'TextAdvertisementList' })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncAdvertisement () {
      this.advertisement = null
      this.loadAdvertisement(this.advertisementId)
        .then((response) => {
          this.advertisement = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Advertisement Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'TextAdvertisementList' }),
            onConfirm: () => this.$router.replace({ name: 'TextAdvertisementList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncAdvertisement()
  }
}
</script>
