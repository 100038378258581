<template>
  <div>
    <!-- <loading v-show="isLoading"></loading> -->
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <advance-filter
      :is-loading="isLoading"
      @onStart="onFilter"
      @onReset="reset"
      :is-hide="false"
    >
      <div class="columns is-multiline">
        <div
          class="column field is-3"
          v-if="showReservationType"
        >
          <label class="label is-small">Advertisement Type</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              v-model="filter.type"
              :options="type_options"
              placeholder="Pick type for filter"
              class="is-small"
            ></v-select>
          </div>
        </div>
        <div class="column field is-3">
          <label class="label is-small">Status</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              v-model="filter.status"
              :options="status_options"
              placeholder="Pick status for filter"
              class="is-small"
            ></v-select>
          </div>
        </div>
        <b-field
          class="column is-3"
          label="Device"
          custom-class="is-small"
        >
          <b-autocomplete
            icon-pack="fas"
            icon="search"
            placeholder="Type device uuid"
            field="uuid"
            :keep-first="true"
            :data="autocomplete.device.items"
            v-model="autocomplete.device.value"
            :loading="autocomplete.device.isLoading"
            @input="loadDevicesSuggestion"
            @select="option => filter.device = option.id"
          >
            <template slot="empty">No device found</template>
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  {{ props.option.uuid }}
                  <br>
                  <small>
                    {{ props.option.name }},
                  </small>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
        <b-field
          class="column is-3"
          label="Agent"
          custom-class="is-small"
        >
          <b-autocomplete
            icon-pack="fas"
            icon="search"
            placeholder="Type agent details"
            field="name"
            :keep-first="true"
            :data="autocomplete.agent.items"
            v-model="autocomplete.agent.value"
            :loading="autocomplete.agent.isLoading"
            @input="loadAgentsSuggestion"
            @select="option => filter.agent = option.id"
          >
            <template slot="empty">No agent found</template>
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  {{ props.option.name }}
                  <br>
                  <small>
                    {{ props.option.email }},
                  </small>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
        <b-field
          class="column is-3"
          label="Distributor"
          custom-class="is-small"
        >
          <b-autocomplete
            icon-pack="fas"
            icon="search"
            placeholder="Type distributor details"
            field="name"
            :keep-first="true"
            :data="autocomplete.distributor.items"
            v-model="autocomplete.distributor.value"
            :loading="autocomplete.distributor.isLoading"
            @input="loadDistributorsSuggestion"
            @select="option => filter.distributor = option.id"
          >
            <template slot="empty">No distributor found</template>
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  {{ props.option.name }}
                  <br>
                  <small>
                    {{ props.option.email }},
                  </small>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
        <b-field
          class="column is-3"
          label="Advertiser"
          custom-class="is-small"
          v-if="showReservationType"
        >
          <b-autocomplete
            icon-pack="fas"
            icon="search"
            placeholder="Type advertiser details"
            field="name"
            :keep-first="true"
            :data="autocomplete.advertiser.items"
            v-model="autocomplete.advertiser.value"
            :loading="autocomplete.advertiser.isLoading"
            @input="loadAdvertisersSuggestion"
            @select="option => filter.advertiser = option.id"
          >
            <template slot="empty">No advertiser found</template>
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  {{ props.option.name }}
                  <br>
                  <small>
                    {{ props.option.email }},
                  </small>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
        <div class="column field is-3">
          <label class="label is-small">Start Time</label>
          <div class="control is-expanded has-icons-left">
            <input
              class="input"
              type="datetime-local"
              placeholder="Enter Start Time"
              v-model="filter.start"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-calendar"></i>
            </span>
          </div>
        </div>
        <div class="column field is-3">
          <label class="label is-small">End Time</label>
          <div class="control is-expanded has-icons-left">
            <input
              class="input"
              type="datetime-local"
              placeholder="Enter End Time"
              v-model="filter.end"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-calendar"></i>
            </span>
          </div>
        </div>
      </div>
    </advance-filter>
    <div class="card card-section">
      <div class="card-content">
        <b-field
          grouped
          group-multiline
        >
          <b-select
            v-model="filter.size"
            :disabled="!hasItems"
            @input="onFilter"
          >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </b-select>
        </b-field>
        <b-table
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :mobile-cards="true"
          :loading="isLoading"
          paginated
          backend-pagination
          :per-page="filter.size"
          :total="meta.total"
          @page-change="onPageChange"
          backend-sorting
          default-sort-direction="desc"
          :default-sort="[filter.sortField, filter.sortOrder]"
          @sort="onSort"
          :data="items"
        >
          <b-table-column
            label="S.No."
            width="40"
            numeric
            v-slot="props"
          >
            {{ meta.from + props.index }}
          </b-table-column>
          <b-table-column
            field="thumbnail"
            label="Thumbnail"
            width="150"
            v-slot="props"
          >
            <a
              v-if="props.row.thumbnails"
              href="#"
              aria-haspopup="true"
              aria-controls="burger-menu"
              @click.prevent="showVideo(props.row)"
            >
              <b-image
                :src="props.row.thumbnails.small"
                th:alt="props.row.advertiser.name"
                ratio="1by1"
              ></b-image>
            </a>
          </b-table-column>
          <b-table-column
            field="start"
            label="Details"
            sortable
            v-slot="props"
          >
            <strong>Start At: </strong>{{ props.row.start }}<br />
            <strong>End At: </strong>{{ props.row.end }}<br />
            <strong>Type: </strong>{{ props.row.type ? props.row.type : 'TEXT' }}<br />
            <strong>Duration: </strong>{{ props.row.duration }} Secs.<br />
            <div
              class="list-items"
              v-if="props.row.device"
            >
              <div class="list-li-head">Device:</div>
              <div class="list-li-desc">{{ props.row.device.uuid }}</div>
              <div class="list-li-desc">{{ props.row.device.address }}</div>
            </div>
          </b-table-column>
          <b-table-column
            field="dispatched"
            label="Status"
            v-slot="props"
          >
            <strong>Dispatched: </strong>{{ props.row.dispatched | yesOrNo }}<br />
            <strong>Processed: </strong>{{ props.row.processed | yesOrNo }}<br />
            <strong>Cancelled: </strong>{{ props.row.cancelled | yesOrNo }}<br />
            <strong>Changed: </strong>{{ props.row.changed | yesOrNo }}
          </b-table-column>
          <b-table-column
            label="Relations"
            v-slot="props"
          >
            <div
              class="list-items"
              v-if="props.row.agent"
            >
              <div class="list-li-head">Agent:</div>
              <div class="list-li-desc">{{ props.row.agent.name }}</div>
              <div
                v-if="showReservationType"
                class="list-li-desc"
              >{{ props.row.agent.email }}</div>
            </div>
            <div
              class="list-items"
              v-if="props.row.distributor"
            >
              <div class="list-li-head">Distributor:</div>
              <div class="list-li-desc">{{ props.row.distributor.name }}</div>
              <div
                v-if="showReservationType"
                class="list-li-desc"
              >{{ props.row.distributor.email }}</div>
            </div>
            <div
              class="list-items"
              v-if="props.row.advertiser"
            >
              <div class="list-li-head">Advertiser:</div>
              <div class="list-li-desc">{{ props.row.advertiser.name }}</div>
              <div
                v-if="showReservationType"
                class="list-li-desc"
              >{{ props.row.advertiser.email }}</div>
            </div>
            <div
              class="list-items"
              v-if="props.row.channel"
            >
              <div class="list-li-head">Channel Owner:</div>
              <div class="list-li-desc">{{ props.row.channel.name }}</div>
              <div
                v-if="showReservationType"
                class="list-li-desc"
              >{{ props.row.channel.email }}</div>
            </div>
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="emoticon-sad"
                    size="is-large"
                  > </b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import { mapGetters, mapActions } from 'vuex'
import { RESERVATION_LIST } from '@/store/actions/reservationActions'
import { AGENT_LIST, DISTRIBUTOR_LIST, ADVERTISER_LIST } from '@/store/actions/userActions'
import { DEVICE_LIST } from '@/store/actions/deviceActions'

export default {
  name: 'ReservationList',
  components: {
    Breadcrumb,
    AdvanceFilter
  },
  data () {
    return {
      pageTitle: 'Manage Reservations',
      breadcrumb: {
        list: [{ icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' }],
        current: { icon: 'fab fa-researchgate', name: 'Reservations' }
      },
      type_options: ['VIDEO', 'TEXT'],
      status_options: ['NOT DISPATCHED', 'DISPATCHED', 'NOT PROCESSED', 'PROCESSED', 'CANCELLED', 'CHANGED'],
      filter: {},
      items: [],
      meta: {},
      autocomplete: {
        agent: {
          value: '',
          items: [],
          isLoading: false,
          timeout: null
        },
        distributor: {
          value: '',
          items: [],
          isLoading: false,
          timeout: null
        },
        advertiser: {
          value: '',
          items: [],
          isLoading: false,
          timeout: null
        },
        device: {
          value: '',
          items: [],
          isLoading: false,
          timeout: null
        }
      }
    }
  },
  computed: {
    ...mapGetters('Profile', ['isAdvertiser', 'isAdministrator']),
    ...mapGetters('Reservations', ['isLoading', 'isSuccess', 'isError']),
    showReservationType () {
      return this.isAdministrator || !this.isAdvertiser
    },
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    }
  },
  methods: {
    ...mapActions('Reservations', { loadList: RESERVATION_LIST }),
    ...mapActions('Users', { loadAgents: AGENT_LIST, loadDistributors: DISTRIBUTOR_LIST, loadAdvertisers: ADVERTISER_LIST }),
    ...mapActions('Devices', { loadDevices: DEVICE_LIST }),
    onFilter () {
      this.loadAsyncData()
    },
    onPageChange (page) {
      this.filter.page = page
      this.loadAsyncData()
    },
    onSort (field, order) {
      this.filter.sortField = field
      this.filter.sortOrder = order
      this.loadAsyncData()
    },
    loadAsyncData () {
      if (!this.isLoading) {
        const data = this.filter
        this.loadList(data)
          .then((response) => {
            this.items = response.data
            this.meta = response.meta
          })
          .catch((error) => {
            this.$notify({
              title: 'Reservation Loading Failed',
              text: error.message,
              type: 'is-danger'
            })
          })
      }
    },
    loadDevicesSuggestion () {
      clearTimeout(this.autocomplete.device.timeout)
      this.autocomplete.device.timeout = setTimeout(() => {
        const data = {
          query: this.autocomplete.device.value
        }
        if (data.query) {
          this.autocomplete.device.isLoading = true
          this.loadDevices(data)
            .then((response) => {
              this.autocomplete.device.items = response.data
              this.autocomplete.device.isLoading = false
            })
            .catch((error) => {
              this.autocomplete.device.isLoading = false
              this.$notify({
                title: 'Device Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    },
    loadAgentsSuggestion () {
      clearTimeout(this.autocomplete.agent.timeout)
      this.autocomplete.agent.timeout = setTimeout(() => {
        const data = {
          query: this.autocomplete.agent.value
        }
        if (data.query) {
          this.autocomplete.agent.isLoading = true
          this.loadAgents(data)
            .then((response) => {
              this.autocomplete.agent.items = response.data
              this.autocomplete.agent.isLoading = false
            })
            .catch((error) => {
              this.autocomplete.agent.isLoading = false
              this.$notify({
                title: 'Agent Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    },
    loadDistributorsSuggestion () {
      clearTimeout(this.autocomplete.distributor.timeout)
      this.autocomplete.distributor.timeout = setTimeout(() => {
        const data = {
          query: this.autocomplete.distributor.value
        }
        if (data.query) {
          this.autocomplete.distributor.isLoading = true
          this.loadDistributors(data)
            .then((response) => {
              this.autocomplete.distributor.items = response.data
              this.autocomplete.distributor.isLoading = false
            })
            .catch((error) => {
              this.autocomplete.distributor.isLoading = false
              this.$notify({
                title: 'Distributor Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    },
    loadAdvertisersSuggestion () {
      clearTimeout(this.autocomplete.advertiser.timeout)
      this.autocomplete.advertiser.timeout = setTimeout(() => {
        const data = {
          query: this.autocomplete.advertiser.value
        }
        if (data.query) {
          this.autocomplete.advertiser.isLoading = true
          this.loadAgents(data)
            .then((response) => {
              this.autocomplete.advertiser.items = response.data
              this.autocomplete.advertiser.isLoading = false
            })
            .catch((error) => {
              this.autocomplete.advertiser.isLoading = false
              this.$notify({
                title: 'Advertiser Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    },
    reset () {
      this.filter = {
        type: 'VIDEO',
        status: '',
        agent: null,
        distributer: null,
        advertiser: null,
        start: null,
        end: null,
        page: 1,
        size: 10,
        sortField: 'updated_at',
        sortOrder: 'desc'
      }
      this.items = []
      this.meta = {
        total: 0
      }
      this.onFilter()
    },
    showVideo (item) {
      let itemStatusMsg = ''
      if (item.processed) {
        itemStatusMsg = 'This advertisement is already viewed'
      } else if (item.cancelled) {
        itemStatusMsg = 'This advertisement is cancelled'
      } else if (item.changed) {
        itemStatusMsg = 'This advertisement is replaced with another advertisement'
      } else if (item.dispatched) {
        itemStatusMsg = 'This advertisement is sent to the device for viewing'
      } else {
        itemStatusMsg = 'This advertisement is waiting to sent to the device'
      }
      const h = this.$createElement
      const vnode = h('div', { attrs: { id: 'monitor' } }, [
        h('div', { attrs: { id: 'monitorscreen' } }, [
          h('div', { attrs: { class: 'scroll-left' } }, [
            h('p', itemStatusMsg)
          ]),
          h('video', { attrs: { controls: true, autoplay: true } }, [
            h('source', { attrs: { src: item.path, type: 'video/mp4' } })
          ])
        ])
      ])
      this.$buefy.modal.open({
        customClass: 'video-model',
        content: [vnode]
      })
    }
  },
  created () {
    this.reset()
  }
}
</script>
