<template>
  <div>
    <loading v-show="isApiProcessing"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="card card-section">
      <div class="card-content">
        <div class="field">
          <label class="label">Name</label>
          <div class="control">
            <input
              v-model="form.name"
              class="input"
              type="text"
              placeholder="Enter Advertisement Name"
              :class="{'is-danger' : errors.name}"
            />
          </div>
          <p
            v-if="errors.name"
            class="help is-danger"
          >{{ errors.name | implode }}</p>
        </div>
        <div class="field">
          <label class="label">Ads Duration (Secs)</label>
          <div class="control">
            <input
              v-model="form.duration"
              class="input"
              type="text"
              placeholder="Enter Advertisement Duration"
              :class="{'is-danger' : errors.duration}"
            />
          </div>
          <p
            v-if="errors.duration"
            class="help is-danger"
          >{{ errors.duration | implode }}</p>
        </div>
        <div class="field">
          <label class="label">Desc</label>
          <div class="control">
            <textarea
              v-model="form.desc"
              class="textarea"
              placeholder="Enter Advertisement Desc"
              :class="{'is-danger' : errors.desc}"
            ></textarea>
          </div>
          <p
            v-if="errors.desc"
            class="help is-danger"
          >{{ errors.desc | implode }}</p>
        </div>
        <div class="field">
          <label class="label">Product Tags</label>
          <div class="control">
            <treeselect
              placeholder="Select product tags..."
              value-consists-of="ALL_WITH_INDETERMINATE"
              v-model="form.tags"
              :multiple="true"
              :options="tagTree"
              :normalizer="normalizer"
            ></treeselect>
          </div>
        </div>
        <div class="field is-grouped action-buttons">
          <div class="control">
            <button
              type="button"
              :disabled="isApiProcessing"
              class="button is-primary is-rounded"
              @click="formSubmit"
              :class="{ 'is-loading': isApiProcessing }"
            >Create</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { ADVERTISEMENT_CREATE } from '@/store/actions/myActions'
import { TAG_TREE_SHOW } from '@/store/actions/tagActions'

export default {
  name: 'MyAdvertisementCreate',
  components: {
    Breadcrumb, Treeselect
  },
  data () {
    return {
      pageTitle: 'New Ads Upload Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fab fa-adversal', routeName: 'MyAdvertisementList', name: 'My Ads' }
        ],
        current: { icon: 'fas fa-plus', name: 'New Ads Upload' },
        actions: [{ icon: 'fab fa-adversal', routeName: 'AdvertisementList', name: 'My Ads' }]
      },
      form: null,
      errors: {},
      tagTree: [],
      isApiProcessing: false
    }
  },
  methods: {
    ...mapActions('Tags', { loadTagTree: TAG_TREE_SHOW }),
    ...mapActions('MyActions', { createAdvertisement: ADVERTISEMENT_CREATE }),
    normalizer (node) {
      return {
        id: node.id,
        label: node.name,
        children: node.children
      }
    },
    formSubmit () {
      this.errors = {}
      this.createAdvertisement(this.form)
        .then((response) => {
          this.$notify({
            title: 'Advertisement Saved as Draft',
            text: `(${response.data.name}) Advertisement is created and saved as ${response.data.status} successfully`,
            type: 'is-success'
          })
          this.$router.push({
            name: 'MyAdvertisementEdit',
            params: { advertisementId: response.data.id }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Ads Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncTagTree () {
      this.loadTagTree()
        .then((response) => {
          this.tagTree = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Tag Tree Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onReset () {
      this.errors = {}
      this.form = {
        name: '',
        desc: '',
        duration: 0
      }
      this.$notify({
        title: 'Reset Form',
        text: 'Ads form is reset with default value, please continue...',
        type: 'is-info'
      })
    }
  },
  created () {
    this.onReset()
    this.loadAsyncTagTree()
  }
}
</script>
