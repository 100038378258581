import { axios } from './axios'

export default {
  getWalletInfo () {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/my-wallets/info')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  getWalletBalanceInfo () {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/my-wallets/balance')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  listTransactions (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/my-wallets/transactions', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
