<template>
  <div class="card modal-card">
    <header class="card-header">
      <p class="card-header-title">Wallet Transaction Details</p>
    </header>
    <div class="card-content" v-if="record">
      <table class="table is-fullwidth is-striped is-hoverable">
        <tbody>
          <tr>
            <th>Type</th>
            <td>{{ record.type }}</td>
          </tr>
          <tr>
            <th>Amount</th>
            <td>{{ record.currency }} {{ record.amount }}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td>{{ record.status }}</td>
          </tr>
          <tr>
            <th>Date &amp; Time</th>
            <td>{{ record.created_at }}</td>
          </tr>
          <tr>
            <th>User Details</th>
            <td>{{ record.user.name }} ({{ record.user.email }})</td>
          </tr>
          <tr>
            <th>Requested By</th>
            <td>{{ record.request_by.name }} ({{ record.request_by.email }})</td>
          </tr>
          <tr>
            <th>Remarks</th>
            <td>{{ record.remarks }}</td>
          </tr>
        </tbody>
      </table>
      <table class="table is-fullwidth is-striped is-hoverable">
        <tbody>
          <tr>
            <th>Method</th>
            <td>{{ record.transaction_method }}</td>
          </tr>
          <tr v-if="!isTransactionByCash">
            <th>Transaction ID</th>
            <td>{{ record.transaction_id }}</td>
          </tr>
          <tr v-if="isBankTransaction">
            <th>Transaction Bank</th>
            <td>{{ record.transaction_bank }}</td>
          </tr>
          <tr>
            <th>Other Details</th>
            <td>{{ record.transaction_details }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WalletTransactionDetails',
  props: {
    user: {
      required: true
    },
    record: {
      required: true
    }
  },
  computed: {
    isBankTransaction () {
      return this.record.transaction_method === 'DEBIT CARD' ||
            this.record.transaction_method === 'CREDIT CARD' ||
            this.record.transaction_method === 'CHEQUE' ||
            this.record.transaction_method === 'NEFT/IMPS/RTGS'
    },
    isTransactionByCash () {
      return this.record.transaction_method === 'CASH'
    }
  }
}
</script>
