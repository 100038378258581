<template>
  <div v-if="campaign">
    <div class="kiosk">
      <div
        class="columns is-gapless"
        v-for="(row, rIndex) in spaces"
        :key="`r-${rIndex}`"
      >
        <div
          class="column"
          v-for="(col, cIndex) in row"
          :key="`c-${cIndex}`"
        >
          <kiosk-space
            :id="`space-${rIndex}-${cIndex}`"
            :space="col"
            :can-ads-upload="canAdsUpload"
            @upload-ads="(adsFormData) => uploadSpaceAds(adsFormData, rIndex, cIndex)"
          />
        </div>
        <div
          class="button-column"
          v-if="needEdit && !viewOnly"
        >
          <div class="control"><button
              type="button"
              class="button is-small is-success"
              @click="addColumn(rIndex)"
            >+</button></div>
          <div class="control"><button
              type="button"
              class="button is-small is-danger"
              @click="removeColumn(rIndex)"
            >-</button></div>
        </div>
      </div>
      <div
        class="button-row buttons"
        v-if="needEdit && !viewOnly"
      >
        <button
          type="button"
          class="button is-small is-success"
          @click="addRow()"
        >Add Row</button>
        <button
          type="button"
          class="button is-small is-danger"
          @click="removeRow()"
        >Remove Row</button>
      </div>
    </div>
    <div
      class="button-row buttons is-justify-content-center"
      v-if="!viewOnly"
    >
      <button
        v-if="!needEdit"
        type="button"
        class="button is-small is-primary"
        @click="editDisplay"
      >Edit Display</button>
      <button
        v-if="needEdit"
        type="button"
        class="button is-small is-primary"
        @click="saveDisplay"
      >Save Display</button>
    </div>
  </div>
</template>

<script>
import kioskMixins from '../../../../lib/kiosk-mixin'

export default {
  name: 'CampaignScreenWithCustomKiosk',
  mixins: [kioskMixins]
}
</script>
