<template>
  <div>
    <!-- <loading v-show="isListLoading"></loading> -->
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <advance-filter
      :is-loading="isListLoading"
      @onStart="onFilter"
    >
      <div class="columns is-multiline">
        <div class="column field is-3">
          <label class="label is-small">Query</label>
          <p class="control has-icons-left">
            <input
              class="input"
              type="text"
              placeholder="search"
              v-model="filter.query"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-search"></i>
            </span>
          </p>
        </div>
        <div class="column field is-3">
          <label class="label is-small">Advertisement Status</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              multiple
              v-model="filter.statuses"
              :options="status_options"
              placeholder="Pick statuses for filter"
              class="is-small"
            ></v-select>
          </div>
        </div>
      </div>
    </advance-filter>

    <div class="card card-section">
      <div class="card-content">
        <b-field
          grouped
          group-multiline
        >
          <b-select
            v-model="filter.size"
            :disabled="!hasItems"
            @input="onFilter"
          >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </b-select>
        </b-field>
        <b-table
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :mobile-cards="true"
          :loading="isListLoading"
          paginated
          backend-pagination
          :per-page="filter.size"
          :total="meta.total"
          @page-change="onPageChange"
          backend-sorting
          default-sort-direction="desc"
          :default-sort="[filter.sortField, filter.sortOrder]"
          @sort="onSort"
          :data="items"
        >
          <b-table-column
            label="S.No."
            width="40"
            numeric
            v-slot="props"
          >
            {{ meta.from + props.index }}
          </b-table-column>
          <b-table-column
            field="thumbnail"
            label="Thumbnail"
            v-slot="props"
          >
            <img
              v-if="props.row.thumbnails"
              :src="props.row.thumbnails.small"
            />
          </b-table-column>
          <b-table-column
            field="name"
            label="Name"
            sortable
            v-slot="props"
          >
            {{ props.row.name }}
          </b-table-column>
          <b-table-column
            label="Ads Duration (Sec)"
            width="120"
            numeric
            v-slot="props"
          >
            {{ props.row.duration }}
          </b-table-column>
          <b-table-column
            field="status"
            label="Status"
            sortable
            v-slot="props"
          >
            {{ props.row.status }}
          </b-table-column>
          <b-table-column
            label=""
            v-slot="props"
          >
            <b-dropdown position="is-bottom-left">
              <button
                class="button is-primary"
                slot="trigger"
              >
                <span>Actions</span>
                <b-icon
                  icon="chevron-down"
                  size="is-small"
                ></b-icon>
              </button>

              <b-dropdown-item has-link>
                <router-link :to="{ name: 'MyAdvertisementShow', params: { advertisementId: props.row.id }}">
                  <span class="icon">
                    <i class="fas fa-eye"></i>
                  </span>
                  <span>DETAILS</span>
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item
                has-link
                v-if="props.row.status === 'IDEAL'"
              >
                <router-link :to="{ name: 'MyAdvertisementEdit', params: { advertisementId: props.row.id }}">
                  <span class="icon">
                    <i class="fas fa-edit"></i>
                  </span>
                  <span>EDIT</span>
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item has-link>
                <a @click.prevent="confirmDeleteAdvertisement(props.row.id)">
                  <span class="icon">
                    <i class="fas fa-trash"></i>
                  </span>
                  <span>DELETE</span>
                </a>
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="emoticon-sad"
                    size="is-large"
                  > </b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import { mapActions } from 'vuex'
import { ADVERTISEMENT_LIST, ADVERTISEMENT_DELETE } from '@/store/actions/myActions'

export default {
  name: 'MyAdvertisementList',
  components: {
    Breadcrumb,
    AdvanceFilter
  },
  data () {
    return {
      pageTitle: 'Manage Video Ads',
      breadcrumb: {
        list: [{ icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' }],
        current: { icon: 'fab fa-adversal', name: 'My Video Ads' },
        actions: [{ icon: 'fas fa-plus', routeName: 'MyAdvertisementCreate', name: 'Upload New Ads' }]
      },
      status_options: ['IDEAL', 'ENABLE', 'DISABLE'],
      filter: {},
      items: [],
      meta: {},
      isListLoading: false
    }
  },
  computed: {
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    }
  },
  methods: {
    ...mapActions('MyActions', { loadList: ADVERTISEMENT_LIST, sendDeleteAdvertisementRequest: ADVERTISEMENT_DELETE }),
    onFilter () {
      this.loadAsyncData()
    },
    onPageChange (page) {
      this.filter.page = page
      this.loadAsyncData()
    },
    onSort (field, order) {
      this.filter.sortField = field
      this.filter.sortOrder = order
      this.loadAsyncData()
    },
    confirmDeleteAdvertisement (advertisementId) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Advertisement',
        message: 'Are you sure you want to <b>delete</b> this ads? This action cannot be undone.',
        confirmText: 'Delete Ads',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deleteAdvertisement(advertisementId)
      })
    },
    deleteAdvertisement (advertisementId) {
      this.sendDeleteAdvertisementRequest(advertisementId)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.onFilter()
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncData () {
      if (!this.isListLoading) {
        this.isListLoading = true
        const data = this.filter
        this.loadList(data)
          .then((response) => {
            this.items = response.data
            this.meta = response.meta
            this.isListLoading = false
          })
          .catch((error) => {
            this.isListLoading = false
            this.$notify({
              title: 'Ads Loading Failed',
              text: error.message,
              type: 'is-danger'
            })
          })
      }
    },
    reset () {
      this.filter = {
        statuses: [],
        query: '',
        page: 1,
        size: 10,
        sortField: 'updated_at',
        sortOrder: 'desc'
      }
      this.items = []
      this.meta = {
        total: 0
      }
      this.onFilter()
    }
  },
  created () {
    this.reset()
  }
}
</script>
