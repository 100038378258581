import { axios, setAuthorizationToken } from './axios'

/* const { API_CLIENT_ID, API_CLIENT_SECRET } = process.env; */

export default {
  login (email, password, rememberMe) {
    return new Promise((resolve, reject) => {
      const data = {
        username: email,
        password,
        remember_me: rememberMe
      }
      axios
        .post('/api/auth/login', data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  /* login(email, password) {
    return new Promise((resolve, reject) => {
      const data = {
        client_id: API_CLIENT_ID,
        client_secret: API_CLIENT_SECRET,
        grant_type: 'password',
        username: email,
        password,
      };
      axios
        .post('/oauth/token', data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.data);
        });
    });
  }, */

  logout () {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/me/logout')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  logoutFromAll () {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/me/logout-all')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },

  setAuthToken (token) {
    setAuthorizationToken(token)
  }
}
