<template>
  <li :class="{'has-dropdown' : item.children, 'is-hidden-tablet' : item.mobileOnly}" v-if="isNavShow">
    <a v-if="!isRouteNameOrLinkExists" :class="expandClass" @click.prevent="toggleSubmenu">
      <span v-if="item.icon" class="icon"><i :class="item.icon"></i></span>
      {{ item.name }}
    </a>
    <router-link :to="{ name: item.routeName, params: item.params}" v-if="isRouteName" custom v-slot="{ href, navigate, isActive }">
      <a :class="{ 'is-active is-open-active router-link-active': isActive }" :href="href" @click="navigate">
        <span v-if="item.icon" class="icon"><i :class="item.icon"></i></span>
        {{ item.name }}
      </a>
    </router-link>
    <router-link :to="{ path: item.routeName, params: item.params}" v-if="isRouteLink" custom v-slot="{ href, navigate, isActive }">
      <a :class="{ 'is-active is-open-active router-link-active': isActive }" :href="href" @click="navigate">
        <span v-if="item.icon" class="icon"><i :class="item.icon"></i></span>
        {{ item.name }}
      </a>
    </router-link>
    <ul :class="{'is-active' : expandMenu }" v-if="item.children">
      <menu-item
        v-for="(submenu, index) in item.children"
        :key="index + '-' + item.name"
        :name="item.name"
        :item="submenu"
      ></menu-item>
    </ul>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MenuItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      expandMenu: null
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    isRouteNameOrLinkExists () {
      return this.item.routeName !== undefined && this.item.routeName !== null
    },
    isRouteLink () {
      let result = false
      if (this.item.routeName && this.item.routeName.includes('/')) {
        result = true
      }
      return result
    },
    isRouteName () {
      let result = false
      if (this.item.routeName && !this.item.routeName.includes('/')) {
        result = true
      }
      return result
    },
    expandClass () {
      let returnClass = ''
      if (this.expandMenu != null) {
        if (this.expandMenu) {
          returnClass = 'is-open-active'
        } else {
          returnClass = 'is-close-active'
        }
      }
      return returnClass
    },
    isNavShow () {
      let result = false
      if (this.item.scopes === undefined || this.hasAnyScope(this.item.scopes)) {
        result = true
      }
      return result
    }
  },
  methods: {
    isNavListNeeded (nav) {
      let result = false
      if (nav.children && (nav.scopes === undefined || this.hasAnyScope(nav.scopes))) {
        result = true
      }
      return result
    },
    isNavNeeded (nav) {
      let result = false
      if (nav.scopes === undefined || this.hasAnyScope(nav.scopes)) {
        result = true
      }
      return result
    },
    toggleSubmenu () {
      if (this.expandMenu == null) {
        this.expandMenu = true
      } else {
        this.expandMenu = !this.expandMenu
      }
    }
  },
  created () {
    if (this.item.children) {
      const matchedChild = this.item.children.find((menuChild) => menuChild.routeName === this.$route.name)
      if (matchedChild !== undefined && matchedChild !== null) {
        this.expandMenu = true
      }
    }
  }
}
</script>
