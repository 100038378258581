<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="card card-section"
      v-if="page"
    >
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Title</label>
              <div class="control">
                <input
                  v-model="page.title"
                  class="input"
                  type="text"
                  placeholder="Enter Page Title"
                  :class="{'is-danger' : errors.title}"
                />
              </div>
              <p
                v-if="errors.title"
                class="help is-danger"
              >{{ errors.title | implode }}</p>
            </div>
            <div class="field">
              <label class="label">Slug</label>
              <div class="control">
                <input
                  v-model="page.slug"
                  class="input"
                  type="text"
                  placeholder="Enter Page Slug"
                  :class="{'is-danger' : errors.slug}"
                />
              </div>
              <p
                v-if="errors.slug"
                class="help is-danger"
              >{{ errors.slug | implode }}</p>
            </div>
            <div class="field">
              <label class="label">Body</label>
              <div class="control">
                <vue-editor
                  v-model="page.body"
                  :editorToolbar="customToolbar"
                  placeholder="Enter Page Body"
                ></vue-editor>
              </div>
              <p
                v-if="errors.body"
                class="help is-danger"
              >{{ errors.body | implode }}</p>
            </div>
            <div class="field">
              <label class="label">Status</label>
              <div class="control">
                <v-select
                  v-model="page.status"
                  :options="status_options"
                  :class="{'is-danger' : errors.status}"
                  placeholder="Pick status for page"
                  class="is-small"
                ></v-select>
              </div>
              <p
                v-if="errors.status"
                class="help is-danger"
              >{{ errors.status | implode }}</p>
            </div>
            <div class="field is-pageed action-buttons">
              <div class="control">
                <button
                  type="button"
                  :disabled="isLoading"
                  class="button is-primary is-rounded"
                  @click="formSubmit"
                  :class="{ 'is-loading': isLoading }"
                >Update Page</button>
              </div>
            </div>
          </div>
          <div class="column is-two-fifths">
            <div class="card card-section">
              <div class="card-content">
                <div class="card">
                  <div class="card-image">
                    <figure class="image is-4by3">
                      <img
                        v-if="!page.thumbnails"
                        src="/img/placeholders/image-placeholder.png"
                        alt="Page Thumbnail"
                      >
                      <img
                        v-if="page.thumbnails"
                        :src="page.thumbnails.large"
                        alt="Page Thumbnail"
                      >
                    </figure>
                    <div
                      v-show="isThumbnailLoading"
                      class="card-image-text-ontop has-background-primary"
                    >
                      <i class="fas fa-cloud-upload-alt"></i> Uploading...<i class="fas fa-spinner fa-spin"></i>
                    </div>
                    <div
                      v-show="isThumbnailSuccess"
                      class="card-image-text-ontop has-background-success"
                    >
                      <i class="fas fa-cloud-upload-alt"></i> Uploaded to server...
                    </div>
                    <div
                      v-show="isThumbnailError"
                      class="card-image-text-ontop has-background-danger"
                    >
                      <i class="fas fa-cloud-upload-alt"></i> Failed to upload on server...
                    </div>
                  </div>
                  <div class="card-content">
                    <div class="file has-name is-fullwidth is-primary">
                      <label class="file-label">
                        <input
                          class="file-input"
                          type="file"
                          name="thumbnailFile"
                          ref="thumbnailFile"
                          @change="onThumbnailChange"
                          :disabled="isThumbnailLoading"
                        />
                        <span class="file-cta">
                          <span class="file-icon">
                            <i class="fas fa-upload"></i>
                          </span>
                          <span class="file-label">
                            Choose a file…
                          </span>
                        </span>
                        <span class="file-name">
                          {{ thumbnailName }}
                        </span>
                      </label>
                    </div>
                    <br />
                    <div class="field">
                      <label class="label">Thumbnail Caption</label>
                      <div class="control">
                        <input
                          v-model="page.thumbnail_caption"
                          class="input"
                          type="text"
                          placeholder="Enter Page Thumbnail Caption"
                          :class="{'is-danger' : errors.thumbnail_caption}"
                        />
                      </div>
                      <p
                        v-if="errors.thumbnail_caption"
                        class="help is-danger"
                      >{{ errors.thumbnail_caption | implode }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { VueEditor } from 'vue2-editor'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { PAGE_SHOW, PAGE_UPDATE, PAGE_THUMBNAIL } from '@/store/actions/pageActions'

export default {
  name: 'PageEdit',
  components: {
    Breadcrumb, VueEditor
  },
  props: {
    pageId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncPage'
  },
  data () {
    return {
      pageTitle: 'Edit Page Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-file-alt', routeName: 'PageList', name: 'Manage Pages' }
        ],
        current: { icon: 'fas fa-edit', name: 'Edit Page' },
        actions: [
          { icon: 'fas fa-file-alt', routeName: 'PageList', name: 'Manage Pages' },
          {
            icon: 'fas fa-eye', routeName: 'PageShow', params: { pageId: this.pageId }, name: 'Display Page'
          }
        ]
      },
      page: null,
      status_options: ['DRAFT', 'ENABLE', 'DISABLE'],
      errors: {},
      thumbnailName: null,

      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ align: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [
          { list: 'ordered' }, { list: 'bullet' }
        ],
        [
          { indent: '-1' }, { indent: '+1' }
        ],
        [
          { script: 'sub' }, { script: 'super' }
        ],
        [
          { color: [] }, { background: [] }
        ],
        ['link', 'blockquote', 'code-block', 'clean']
      ]
    }
  },
  computed: {
    ...mapGetters('Pages', ['isLoading', 'isSuccess', 'isError', 'isThumbnailLoading', 'isThumbnailSuccess', 'isThumbnailError'])
  },
  methods: {
    ...mapActions('Pages', { loadPage: PAGE_SHOW, updatePage: PAGE_UPDATE, uploadThumbnail: PAGE_THUMBNAIL }),
    onThumbnailChange () {
      const thumbnailFiles = this.$refs.thumbnailFile
      if (!thumbnailFiles || !thumbnailFiles.files.length) {
        return
      }
      this.thumbnailName = thumbnailFiles.files[0].name
      this.createImage(thumbnailFiles.files[0])
      this.uploadPageThumbnail(thumbnailFiles.files[0])
    },
    createImage (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.page.thumbnails = { large: e.target.result }
      }
      reader.readAsDataURL(file)
    },
    uploadPageThumbnail (thumbnail) {
      const thumbnailFormData = new FormData()
      thumbnailFormData.set('thumbnail', thumbnail)
      thumbnailFormData.set('thumbnail_caption', this.page.thumbnail_caption)
      const data = {
        pageId: this.page.id,
        formData: thumbnailFormData
      }
      this.uploadThumbnail(data)
        .then((response) => {
          this.$notify({
            title: 'Page Thumbnail',
            text: response.message,
            type: 'is-success'
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Page Thumbnail Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    formSubmit () {
      this.errors = {}
      const data = {
        pageId: this.page.id,
        formData: this.page
      }
      this.updatePage(data)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.title}) Page is updated successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Page',
            onAction: () => {
              this.$router.push({
                name: 'PageList'
              })
            }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Page Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncPage () {
      this.page = null
      this.loadPage(this.pageId)
        .then((response) => {
          this.page = {
            id: response.data.id,
            slug: response.data.slug,
            title: response.data.title,
            body: response.data.body,
            status: response.data.status,
            thumbnails: response.data.thumbnails,
            thumbnail_caption: response.data.thumbnail_caption
          }
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Page Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'PageList' }),
            onConfirm: () => this.$router.replace({ name: 'PageList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncPage()
  }
}
</script>
