<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="card card-section"
      v-if="setting"
    >
      <div class="card-content">
        <form @submit.prevent="onStart">
          <div class="field">
            <label class="label">Slug</label>
            <div class="control">
              <input
                v-model="setting.slug"
                class="input"
                :class="{'is-danger' : errors.slug}"
                type="text"
                placeholder="Enter Setting Slug"
              />
            </div>
            <p
              v-if="errors.slug"
              class="help is-danger"
            >{{ errors.slug | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Name</label>
            <div class="control">
              <input
                v-model="setting.name"
                class="input"
                :class="{'is-danger' : errors.name}"
                type="text"
                placeholder="Enter Setting Name"
              />
            </div>
            <p
              v-if="errors.name"
              class="help is-danger"
            >{{ errors.name | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Icon</label>
            <div class="control">
              <input
                v-model="setting.icon"
                class="input"
                :class="{'is-danger' : errors.icon}"
                type="text"
                placeholder="Enter Setting Icon"
              />
            </div>
            <p
              v-if="errors.icon"
              class="help is-danger"
            >{{ errors.icon | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Value</label>
            <div class="control">
              <input
                v-model="setting.value"
                class="input"
                :class="{'is-danger' : errors.value}"
                type="text"
                placeholder="Enter Setting Value"
              />
            </div>
            <p
              v-if="errors.value"
              class="help is-danger"
            >{{ errors.value | implode }}</p>
          </div>
          <div class="field is-grouped action-buttons">
            <div class="control">
              <button
                type="submit"
                :disabled="isLoading"
                class="button is-primary is-rounded"
                :class="{ 'is-loading': isLoading }"
              >Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { SETTING_SHOW, SETTING_UPDATE } from '@/store/actions/settingActions'

export default {
  name: 'SettingEdit',
  components: { Breadcrumb },
  props: {
    settingId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncSetting'
  },
  data () {
    return {
      pageTitle: 'Edit Setting Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-cogs', routeName: 'SettingList', name: 'Manage Settings' }
        ],
        current: { icon: 'fas fa-ribbon', name: 'Edit Setting' },
        actions: [
          { icon: 'fas fa-cogs', routeName: 'SettingList', name: 'Manage Settings' },
          {
            icon: 'fas fa-ribbon', routeName: 'SettingShow', params: { settingId: this.settingId }, name: 'Display Setting'
          }
        ]
      },
      setting: null,
      errors: {}
    }
  },
  computed: {
    ...mapGetters('Settings', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Settings', { updateSetting: SETTING_UPDATE, loadSetting: SETTING_SHOW }),
    onStart () {
      this.errors = {}
      const data = {
        settingId: this.settingId,
        formData: this.setting
      }
      this.updateSetting(data)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.name}) Setting is updated successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Setting',
            onAction: () => {
              this.$router.push({
                name: 'SettingList'
              })
            }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Setting Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncSetting () {
      this.setting = null
      this.loadSetting(this.settingId)
        .then((response) => {
          this.setting = {
            id: response.data.id,
            slug: response.data.slug,
            icon: response.data.icon,
            name: response.data.name,
            value: response.data.value
          }
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Setting Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'SettingList' }),
            onConfirm: () => this.$router.replace({ name: 'SettingList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncSetting()
  }
}
</script>
