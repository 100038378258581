<template>
  <div class="myProfile columns">
    <div class="container profile">
      <div class="section profile-heading">
        <div class="columns is-mobile is-multiline">
          <div class="column is-1">
            <span class="header-icon user-profile-image">
              <img
                alt=""
                :src="user.avatars.medium"
              >
            </span>
          </div>
          <div class="column is-4-tablet is-10-mobile name">
            <p><span class="title is-5 is-bold">{{ user.name }}</span><span v-if="user.role">({{ user.role.name }})</span></p>
            <p class="tagline"><strong>E-Mail: </strong>{{ user.email }}</p>
            <p class="tagline"><strong>Mobile: </strong>{{ user.mobile }}</p>
            <p class="tagline"><strong>Address: </strong>{{ user.address }}</p>
          </div>
        </div>
      </div>
      <div class="profile-options is-fullwidth">
        <b-tabs type="is-boxed">
          <b-tab-item
            label="GENERAL INFORMATION"
            icon="user-cog"
          >
            <general-information v-if="isProfileLoaded"></general-information>
          </b-tab-item>
          <b-tab-item
            label="SECURITY INFORMATION"
            icon="shield-alt"
          >
            <security-information v-if="isProfileLoaded"></security-information>
          </b-tab-item>
          <b-tab-item
            label="FINANCE INFORMATION"
            icon="money-check"
          >
            <finance-information v-if="isProfileLoaded"></finance-information>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { PROFILE_REQUEST } from '@/store/actions/profileActions'
import GeneralInformation from './components/GeneralInformation'
import SecurityInformation from './components/SecurityInformation'
import FinanceInformation from './components/FinanceInformation'

export default {
  name: 'MyProfile',
  components: {
    GeneralInformation,
    SecurityInformation,
    FinanceInformation
  },
  data () {
    return {
      isProfileLoaded: false
    }
  },
  computed: {
    ...mapGetters('Profile', { user: 'getProfile' })
  },
  methods: {
    ...mapActions('Profile', { loadProfile: PROFILE_REQUEST })
  },
  created () {
    const loadingComponent = this.$buefy.loading.open({ container: null, canCancel: false })
    this.loadProfile()
      .then(() => {
        loadingComponent.close()
        this.isProfileLoaded = true
      })
      .catch((error) => {
        loadingComponent.close()
        this.errors = error.errors
        this.$notify({
          title: 'Error in Loading Profile',
          type: 'is-danger'
        })
      })
  }
}
</script>
