<template>
  <section class="breadcrumb-hero hero is-primary is-bold">
    <div class="hero-body">
      <div class="mb-1">
        <h1 class="subtitle breadcrumb-inline-on-desktop">{{ title }}</h1>
        <p class="buttons inline-buttons" v-if="breadcrumb.actions">
          <router-link
            v-for="(action, index) in breadcrumbRoutes"
            :key="`br-${index}`"
            :to="{ name: action.routeName, params: action.params}"
            class="button is-small is-link is-inverted is-rounded"
          >
            <span v-if="action.icon" class="icon is-small"><i :class="action.icon"></i></span>
            <span>{{ action.name }}</span>
          </router-link>
          <a class="button is-small is-link is-inverted is-rounded"
            v-for="(action, index) in breadcrumbActions"
            :key="`ba-${index}`"
            @click.prevent="emitAction(action.actionName)"
            >
            <span v-if="action.icon" class="icon is-small"><i :class="action.icon" aria-hidden="true"></i></span>
            <span>{{ action.name }}</span>
          </a>
        </p>
      </div>
      <nav class="breadcrumb is-small has-succeeds-separator" aria-label="breadcrumbs">
        <ul>
          <li
            v-for="(breadcrumb, index) in breadcrumb.list"
            :key="index"
          >
            <router-link
              :to="{ name: breadcrumb.routeName, params: breadcrumb.params}"
            >
              <span v-if="breadcrumb.icon" class="icon is-small"><i :class="breadcrumb.icon"></i></span>
              <span>{{ breadcrumb.name }}</span>
            </router-link>
          </li>
          <li class="is-active" v-if="breadcrumb.current">
            <a href="#" aria-current="page">
              <span v-if="breadcrumb.current.icon" class="icon is-small"><i :class="breadcrumb.current.icon" aria-hidden="true"></i></span>
              <span>{{ breadcrumb.current.name }}</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Breadcrumb',
  props: {
    title: {
      required: true
    },
    breadcrumb: {
      type: Object,
      default: () => ({
        list: [],
        actions: [],
        current: {}
      })
    }
  },
  data () {
    return {
      isActive: false
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    breadcrumbRoutes () {
      return this.breadcrumb.actions.filter(breadcrumbAction => this.havingActionRoute(breadcrumbAction))
    },
    breadcrumbActions () {
      return this.breadcrumb.actions.filter(breadcrumbAction => this.havingActionName(breadcrumbAction))
    }
  },
  methods: {
    havingActionRoute (action) {
      return ((!action.scope || this.hasAnyScope(action.scope)) && action.routeName)
    },
    havingActionName (action) {
      return ((!action.scope || this.hasAnyScope(action.scope)) && action.actionName)
    },
    emitAction (actionName) {
      this.$emit('action-clicked', actionName)
    },
    toggle () {
      this.isActive = !this.isActive
      this.$emit('toggleNav')
    }
  }
}
</script>

<style lang="sass" scoped>
@import '@/sass/mixins.sass'

.navbar
  .navbar-link
    font-weight: 700
  .navbar-item
    font-weight: 700
  img.user-avatar
    border-radius: 50%
    margin-right: 10px
    vertical-align: middle
  .navbar-burger.aside-burger
    display: flex
    margin-right: auto
    margin-left: inherit
    +touch
      display: none
</style>
