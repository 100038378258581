<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="campaign"
    >
      <div class="column">
        <div class="card card-section mb-0">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ campaign.name }}</p>
                  </div>
                </div>
                <div class="content">
                  <div class="columns">
                    <div class="column">
                      <table class="table is-striped">
                        <tbody>
                          <tr>
                            <th>Campaign Type</th>
                            <td>{{ campaign.type }}</td>
                          </tr>
                          <tr>
                            <th>Status</th>
                            <td>{{ campaign.status }}</td>
                          </tr>
                          <tr>
                            <th>Agent</th>
                            <td>
                              <div v-if="campaign.agent">
                                <div v-if="campaign.agent.name">{{ campaign.agent.name }}</div>
                                <div v-if="campaign.agent.email">{{ campaign.agent.email }}</div>
                                <div v-if="campaign.agent.mobile">{{ campaign.agent.mobile }}</div>
                                <div v-if="campaign.agent.address">{{ campaign.agent.address }}</div>
                                <div v-if="campaign.agent.status">{{ campaign.agent.status }}</div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>Places</th>
                            <td>
                              <span
                                class="tag is-black mr-1"
                                v-for="d in campaign.categories"
                                :key="d.id"
                              >{{ d.name }}</span>
                            </td>
                          </tr>
                          <tr>
                            <th>Locations</th>
                            <td>
                              <span
                                class="tag is-black mr-1"
                                v-for="d in campaign.locations"
                                :key="d.id"
                              >{{ d.name }}</span>
                            </td>
                          </tr>
                          <tr>
                            <th>Categories</th>
                            <td>
                              <span
                                class="tag is-black mr-1"
                                v-for="d in campaign.tags"
                                :key="d.id"
                              >{{ d.name }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="card">
                        <div class="card-content">
                          <div class="buttons">
                            <router-link
                              v-if="hasAnyScope('CAMPAIGN-LIST')"
                              :to="{ name: 'CampaignList'}"
                              class="button is-small is-primary"
                            >
                              <span class="icon">
                                <i class="fas fa-th-list"></i>
                              </span>
                              <span>LIST</span>
                            </router-link>
                            <router-link
                              v-if="hasAnyScope('CAMPAIGN-UPDATE')"
                              :to="{ name: 'CampaignEdit', params: { campaignId: campaign.id }}"
                              class="button is-small is-warning"
                            >
                              <span class="icon">
                                <i class="fas fa-edit"></i>
                              </span>
                              <span>EDIT</span>
                            </router-link>
                            <a
                              v-if="hasAnyScope('CAMPAIGN-DELETE')"
                              class="button is-small is-danger"
                              @click.prevent="confirmDeleteCampaign"
                            >
                              <span class="icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span>DELETE</span>
                            </a>
                            <router-link
                              :to="{ name: 'CampaignStart', params: { campaignId: campaign.id }}"
                              v-if="hasAnyScope('CAMPAIGN-START') && campaign.status === 'ENABLE'"
                              class="button is-small is-success"
                            >
                              <span class="icon">
                                <i class="fas fa-play"></i>
                              </span>
                              <span>Apply</span>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-two-fifths">
                      <div class="card card-section">
                        <header class="card-header">
                          <p class="card-header-title has-background-primary has-text-white">
                            Thumbnail
                          </p>
                        </header>
                        <div class="card-image">
                          <figure class="image is-4by3">
                            <img
                              v-if="!campaign.thumbnails"
                              src="/img/placeholders/image-placeholder.png"
                              alt="Campaign Thumbnail"
                            >
                            <img
                              v-if="campaign.thumbnails"
                              :src="campaign.thumbnails.large"
                              alt="Campaign Thumbnail"
                            >
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="campaign"
      class="mb-6"
    >
      <campaign-single-screen-kiosk
        v-if="hasSingleScreenKiosk"
        :view-only="true"
        :campaign="campaign"
      />
      <campaign-screen-with-side-kiosk
        v-else-if="hasScreenWithRightSideKiosk"
        :view-only="true"
        :campaign="campaign"
      />
      <campaign-screen-with-interactive-kiosk
        v-else-if="hasScreenWithInteractiveKiosk"
        :view-only="true"
        :campaign="campaign"
      />
      <campaign-screen-with-custom-kiosk
        v-else-if="hasCustomScreenKiosk"
        :view-only="true"
        :campaign="campaign"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { CAMPAIGN_SHOW_DETAILS, CAMPAIGN_DELETE } from '@/store/actions/campaignActions'
import CampaignSingleScreenKiosk from './kiosks/CampaignSingleScreenKiosk'
import CampaignScreenWithInteractiveKiosk from './kiosks/CampaignScreenWithInteractiveKiosk'
import CampaignScreenWithSideKiosk from './kiosks/CampaignScreenWithSideKiosk'
import CampaignScreenWithCustomKiosk from './kiosks/CampaignScreenWithCustomKiosk'

export default {
  name: 'CampaignShow',
  components: {
    Breadcrumb,
    CampaignSingleScreenKiosk,
    CampaignScreenWithSideKiosk,
    CampaignScreenWithInteractiveKiosk,
    CampaignScreenWithCustomKiosk
  },
  props: {
    campaignId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncCampaign'
  },
  data () {
    return {
      pageTitle: 'Campaign Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-desktop', routeName: 'CampaignList', name: 'Manage Campaigns' }
        ],
        current: { icon: 'fas fa-eye', name: 'Campaign Details' },
        actions: [{ icon: 'fas fa-desktop', routeName: 'CampaignList', name: 'Manage Campaigns' }]
      },
      campaign: null
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    ...mapGetters('Campaigns', ['isLoading', 'isSuccess', 'isError']),
    hasSingleScreenKiosk () {
      return this.campaign.type === 'SINGLE-SCREEN-KIOSK'
    },
    hasScreenWithRightSideKiosk () {
      return this.campaign.type === 'SCREEN-WITH-RIGHT-SIDE-KIOSK'
    },
    hasScreenWithInteractiveKiosk () {
      return this.campaign.type === 'INTERACTIVE-KIOSK'
    },
    hasCustomScreenKiosk () {
      return this.campaign.type === 'CUSTOM-KIOSK'
    }
  },
  methods: {
    ...mapActions('Campaigns', { loadCampaign: CAMPAIGN_SHOW_DETAILS, sendDeleteCampaignRequest: CAMPAIGN_DELETE }),
    confirmDeleteCampaign () {
      this.$buefy.dialog.confirm({
        title: 'Deleting Campaign',
        message: 'Are you sure you want to <b>delete</b> this campaign? This action cannot be undone.',
        confirmText: 'Delete Campaign',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deleteCampaign()
      })
    },
    deleteCampaign () {
      this.sendDeleteCampaignRequest(this.campaign.id)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.$router.replace({ name: 'CampaignList' })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncCampaign () {
      this.campaign = null
      this.loadCampaign(this.campaignId)
        .then((response) => {
          this.campaign = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Campaign Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'CampaignList' }),
            onConfirm: () => this.$router.replace({ name: 'CampaignList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncCampaign()
  }
}
</script>
