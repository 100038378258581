<template>
  <div
    v-if="validFinalForm"
    class="columns"
  >
    <div class="column">
      <booking-video-advertisement
        v-if="finalForm.type === 'VIDEO'"
        :item="finalForm.ads"
      ></booking-video-advertisement>
      <booking-text-advertisement
        v-if="finalForm.type === 'TEXT'"
        :item="finalForm.ads"
      ></booking-text-advertisement>
    </div>
    <div class="column">
      <nav class="panel">
        <p class="panel-heading has-background-info has-text-white">
          Budget Information
        </p>
        <a
          class="panel-block is-active"
          @click.prevent="loadUserWalletBalance"
          v-if="finalForm.type === 'VIDEO'"
        >
          <span class="panel-icon">
            <i
              class="fas fa-wallet"
              aria-hidden="true"
            ></i>
          </span>
          <span
            v-if="!isWalletMoneyLoading"
            class="wallet-nav-text"
          >Wallet Amount {{ walletMoney || 0 }} INR</span>
          <span
            v-if="isWalletMoneyLoading"
            class="wallet-nav-text"
          >Wallet Amount <i class="fas fa-spinner fa-spin"></i> INR</span>
        </a>
        <p
          class="panel-block"
          v-if="finalForm.type === 'VIDEO'"
        >
          <b-field>
            <b-switch
              :value="true"
              v-model="isBudgetAlloted"
              type="is-info"
            >
              Should Budget Alloted?
            </b-switch>
          </b-field>
        </p>
        <p
          class="panel-block"
          v-if="isBudgetAlloted"
        >
          <b-field label="Budget Alloted Per Day">
            <b-input
              type="number"
              v-model="budgetAlloted"
              min="1"
              :max="walletMoneyNumber"
            ></b-input>
          </b-field>
        </p>
        <p
          class="panel-block"
          v-if="isBudgetAlloted"
        >
          <b-field>
            <b-switch
              :value="true"
              v-model="noLimitOnRunTimes"
              type="is-info"
            >
              Should Ads Repeat Until Budget Removed
            </b-switch>
          </b-field>
        </p>
        <p
          class="panel-block"
          v-if="showRunTimesSwitch"
        >
          <b-field>
            <b-switch
              :value="true"
              v-model="isRunMultipleTimes"
              type="is-info"
            >
              Should Ads Run Mutiple Times?
            </b-switch>
          </b-field>
        </p>
        <p
          class="panel-block"
          v-if="showRunTimesInput"
        >
          <b-field label="Ads Run Times Par Interval">
            <b-input
              type="number"
              v-model="runTimes"
              min="1"
            ></b-input>
          </b-field>
          <b-field label="Continuous Days to Runs">
            <b-input
              type="number"
              v-model="days"
              min="1"
            ></b-input>
          </b-field>
        </p>
        <p class="panel-block">
          <b-field>
            <button
              @click="startBooking"
              class="button is-success"
            ><span>Submit Booking</span></button>
          </b-field>
        </p>
      </nav>

      <nav class="panel">
        <p class="panel-heading has-background-info has-text-white">
          Booking Information
        </p>
        <table class="table is-fullwidth">
          <tbody>
            <tr>
              <th>Booking Date</th>
              <td>{{ finalForm.filter.date }}</td>
            </tr>
            <tr>
              <th>Time Interval</th>
              <td>{{ finalForm.filter.intervals }}</td>
            </tr>
            <tr>
              <th>Device Picked</th>
              <td>{{ finalForm.totalDeviceSelected }}</td>
            </tr>
            <!-- <tr>
              <th>Locations</th>
              <td>{{ finalForm.filter.locations }}</td>
            </tr>
            <tr>
              <th>Places</th>
              <td>{{ finalForm.filter.places }}</td>
            </tr>
            <tr>
              <th>Tags</th>
              <td>{{ finalForm.filter.tags }}</td>
            </tr> -->
          </tbody>
        </table>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BookingVideoAdvertisement from './BookingVideoAdvertisement'
import BookingTextAdvertisement from './BookingTextAdvertisement'
import { WALLET_BALANCE } from '@/store/actions/walletActions'

export default {
  name: 'BookingPreview',
  components: { BookingVideoAdvertisement, BookingTextAdvertisement },
  props: {
    finalForm: {
      required: true,
      default: null
    }
  },
  data () {
    return {
      budgetAlloted: 0,
      runTimes: 1,
      days: 1,
      isBudgetAlloted: false,
      noLimitOnRunTimes: false,
      isRunMultipleTimes: false,
      walletMoney: null,
      isWalletMoneyLoading: false
    }
  },
  watch: {
    finalForm: {
      handler: 'onFinalFormChange',
      deep: true
    }
  },
  computed: {
    ...mapGetters('Profile', { authId: 'getAuthId', myWalletBalance: 'walletBalance', isWalletLoading: 'isWalletLoading' }),
    validFinalForm () {
      return this.finalForm !== null && this.finalForm.type !== null && this.finalForm.ads !== null && this.finalForm.filter !== null
    },
    walletMoneyNumber () {
      if (this.walletMoney === null || this.walletMoney === '') {
        return 0
      }
      return parseFloat(this.walletMoney.replace(',', ''))
    },
    advertiser () {
      if (this.finalForm !== null && this.finalForm.ads !== null && this.finalForm.ads.advertiser !== null) {
        return this.finalForm.ads.advertiser
      }
      return null
    },
    showRunTimesSwitch () {
      return !this.isBudgetAlloted || !this.noLimitOnRunTimes
    },
    showRunTimesInput () {
      return this.showRunTimesSwitch && this.isRunMultipleTimes
    }
  },
  methods: {
    ...mapActions('Wallets', { loadWalletBalance: WALLET_BALANCE }),
    onFinalFormChange () {
      this.walletMoney = 0
      this.budgetAlloted = 0
      this.runTimes = 1
      this.days = 1
      this.isBudgetAlloted = false
      this.noLimitOnRunTimes = false
      this.isRunMultipleTimes = false
      this.isWalletMoneyLoading = false
      this.loadUserWalletBalance()
    },
    loadUserWalletBalance () {
      if (this.advertiser !== null) {
        if (this.authId === this.advertiser.id) {
          this.walletMoney = this.myWalletBalance
        } else {
          this.isWalletMoneyLoading = true
          this.loadWalletBalance(this.advertiser.id)
            .then((response) => {
              this.walletMoney = response.wallet_balance
              this.isWalletMoneyLoading = false
            })
            .catch((error) => {
              this.isWalletMoneyLoading = false
              this.$notify({
                title: 'Wallet Loading Error',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }
    },
    startBooking () {
      const data = {
        runTimes: (this.isRunMultipleTimes ? this.runTimes : -1),
        days: (this.isRunMultipleTimes ? this.days : 1),
        noLimitOnRunTimes: (this.isBudgetAlloted && this.noLimitOnRunTimes),
        budgetAlloted: (this.isBudgetAlloted ? this.budgetAlloted : -1)
      }
      this.$emit('start-booking', data)
    }
  }
}
</script>
