import {
  LOCATION_LIST,
  LOCATION_CREATE,
  LOCATION_SHOW,
  LOCATION_THUMBNAIL,
  LOCATION_UPDATE,
  LOCATION_DELETE,
  LOCATION_TREE_SHOW,
  LOCATION_TREE_UPDATE
} from '../../actions/locationActions'

import { LOADING, SUCCESS, ERROR, LOADING_THUMBNAIL, SUCCESS_THUMBNAIL, ERROR_THUMBNAIL } from '../../actions/requestStatus'
import locationApi from '../../../api/locationApi'

export default {
  namespaced: true,

  state: {
    status: '',
    thumbnailStatus: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR,
    isThumbnailLoading: (state) => state.thumbnailStatus === LOADING_THUMBNAIL,
    isThumbnailSuccess: (state) => state.thumbnailStatus === SUCCESS_THUMBNAIL,
    isThumbnailError: (state) => state.thumbnailStatus === ERROR_THUMBNAIL
  },

  actions: {
    [LOCATION_THUMBNAIL]: ({ commit }, { locationId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING_THUMBNAIL)
        locationApi
          .uploadThumbnail(locationId, formData)
          .then((response) => {
            commit(SUCCESS_THUMBNAIL)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR_THUMBNAIL)
            reject(error)
          })
      }),

    [LOCATION_SHOW]: ({ commit }, locationId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        locationApi
          .show(locationId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [LOCATION_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        locationApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [LOCATION_CREATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        locationApi
          .create(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [LOCATION_UPDATE]: ({ commit }, { locationId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        locationApi
          .update(locationId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [LOCATION_DELETE]: ({ commit }, locationId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        locationApi
          .delete(locationId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [LOCATION_TREE_SHOW]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        locationApi
          .tree()
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [LOCATION_TREE_UPDATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        locationApi
          .treeUpdate(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    },
    [LOADING_THUMBNAIL]: (state) => {
      state.thumbnailStatus = LOADING
    },
    [SUCCESS_THUMBNAIL]: (state) => {
      state.thumbnailStatus = SUCCESS
    },
    [ERROR_THUMBNAIL]: (state) => {
      state.thumbnailStatus = ERROR
    }
  }
}
