<template>
  <div>
    <b-loading
      :is-full-page="true"
      :active.sync="isBookingInProcess"
      :can-cancel="false"
    ></b-loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <b-tabs
      v-model="tabs.active"
      class="booking-search-tabs"
    >
      <b-tab-item
        label="Pick Advertisement"
        icon="ad"
        :disabled="!tabs.pickAds"
      >
        <booking-ads-picker
          :wait-for-order="waitForOrder"
          :order-ads="orderAds"
          @ads-picked="onAdsPicked"
        ></booking-ads-picker>
      </b-tab-item>
      <b-tab-item
        label="Pick Devices"
        icon="tv"
        :disabled="!tabs.pickDevice"
      >
        <booking-devices-picker
          :form-filter="form.filter"
          :ads-data="form.ads"
          :device-ids="form.bookingIds"
          :ads-type="form.type"
          @proceed-booking="onBookingProceed"
        ></booking-devices-picker>
      </b-tab-item>
      <b-tab-item
        label="Final Preview"
        icon="receipt"
        :disabled="!tabs.preview"
      >
        <booking-preview
          :final-form="form"
          @start-booking="onBookingStart"
        ></booking-preview>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import BookingAdsPicker from './BookingAdsPicker'
import BookingDevicesPicker from './BookingDevicesPicker'
import BookingPreview from './BookingPreview'
import { ORDER_SHOW } from '@/store/actions/orderActions'
import { PROCESS_BOOKING_RESERVATION } from '@/store/actions/bookingActions'

export default {
  name: 'BookingSearch',
  components: {
    Breadcrumb,
    BookingAdsPicker,
    BookingDevicesPicker,
    BookingPreview
  },
  data () {
    return {
      pageTitle: 'Booking Search',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' }
        ],
        current: { icon: 'fas fa-project-diagram', name: 'Booking Search' }
      },
      tabs: {
        pickAds: true,
        pickDevice: false,
        preview: false,
        active: 0
      },
      form: {
        type: '',
        ads: null,
        filter: null,
        bookingIds: null,
        isBookedForAll: null,
        totalDeviceSelected: 0,
        runTimes: 1,
        days: 1,
        budgetAlloted: null,
        noLimitOnRunTimes: false
      },
      order: null,
      waitForOrder: true,
      orderAds: null,
      isBookingInProcess: false
    }
  },
  created () {
    this.waitForOrder = true
    this.orderAds = null
    const orderId = this.$route.query.order
    if (orderId !== null && orderId !== undefined) {
      this.loadAsyncOrder(orderId)
    } else {
      this.waitForOrder = false
      this.orderAds = null
    }
  },
  methods: {
    ...mapActions('Orders', { loadOrder: ORDER_SHOW }),
    ...mapActions('Bookings', { processBookingReservation: PROCESS_BOOKING_RESERVATION }),
    loadAsyncOrder (orderId) {
      this.order = null
      this.loadOrder(orderId)
        .then((response) => {
          this.order = response.data
          this.orderAds = this.order.advertisement
          if (this.order.booking_ids !== null && this.order.booking_ids !== undefined) {
            this.form.bookingIds = this.order.booking_ids.split(',').map(function (item) {
              return parseInt(item, 10)
            })
          }
          this.form.filter = {
            date: this.order.booked_at,
            intervals: [],
            locations: [],
            places: [],
            tags: [],
            type: 'VIDEO'
          }
          if (this.order.intervals !== undefined && this.order.intervals !== undefined !== null && this.order.intervals.trim() !== '') {
            this.form.filter.intervals = this.order.intervals.split(',')
          } else {
            this.form.filter.intervals = []
          }

          if (this.order.locations !== undefined && this.order.locations !== undefined !== null && this.order.locations.trim() !== '') {
            this.form.filter.locations = this.order.locations.split(',').map(function (item) {
              return parseInt(item, 10)
            })
          } else {
            this.form.filter.locations = []
          }

          if (this.order.places !== undefined && this.order.places !== undefined !== null && this.order.places.trim() !== '') {
            this.form.filter.places = this.order.places.split(',').map(function (item) {
              return parseInt(item, 10)
            })
          } else {
            this.form.filter.places = []
          }

          if (this.order.tags !== undefined && this.order.tags !== undefined !== null && this.order.tags.trim() !== '') {
            this.form.filter.tags = this.order.tags.split(',').map(function (item) {
              return parseInt(item, 10)
            })
          } else {
            this.form.filter.tags = []
          }
          this.form.isBookedForAll = this.order.is_all_booked
          this.form.runTimes = this.order.run_times
          this.form.days = 1
          this.form.budgetAlloted = this.order.budget_alloted
          this.form.noLimitOnRunTimes = this.order.has_limit
          this.waitForOrder = false
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Order Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'OrderList' }),
            onConfirm: () => this.$router.replace({ name: 'OrderList' })
          })
        })
    },
    onAdsPicked ({ type, ads }) {
      this.form.type = type
      this.form.ads = ads
      this.tabs.pickDevice = true
      this.tabs.active = 1
    },
    onBookingProceed ({
      filter,
      bookingIds,
      isBookedForAll,
      totalDeviceSelected
    }) {
      this.form.filter = filter
      this.form.bookingIds = bookingIds
      this.form.isBookedForAll = isBookedForAll
      this.form.totalDeviceSelected = totalDeviceSelected
      this.tabs.preview = true
      this.tabs.active = 2
    },
    onBookingStart ({ runTimes, days, budgetAlloted, noLimitOnRunTimes }) {
      this.form.runTimes = runTimes
      this.form.days = days
      this.form.budgetAlloted = budgetAlloted
      this.form.noLimitOnRunTimes = noLimitOnRunTimes
      const data = {
        formData: this.form,
        type: this.form.type.toLowerCase()
      }
      this.isBookingInProcess = true
      this.processBookingReservation(data)
        .then((response) => {
          this.$buefy.dialog.alert({
            title: 'Reservation Booked',
            message: response.message,
            type: 'is-primary',
            hasIcon: true,
            icon: 'thumbs-up',
            iconPack: 'fas'
          })
          this.tabs.pickAds = true
          this.tabs.pickDevice = false
          this.tabs.preview = false
          this.tabs.active = 0
          this.isBookingInProcess = false
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Reservation Failed',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'thumbs-down',
            iconPack: 'fas'
          })
          this.isBookingInProcess = false
        })
    }
  }
}
</script>
