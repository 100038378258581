<template>
  <div>
    <loading v-show="isDetailLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="user"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ user.name }}</p>
                  </div>
                </div>
                <div class="content">
                  <table class="table is-striped">
                    <tbody>
                      <tr>
                        <th>E-Mail</th>
                        <td>{{ user.email }}</td>
                      </tr>
                      <tr>
                        <th>Mobile</th>
                        <td>{{ user.mobile }}</td>
                      </tr>
                      <tr>
                        <th>Gender</th>
                        <td>{{ user.profiles ? user.profiles.gender : '' }}</td>
                      </tr>
                      <tr>
                        <th>Status</th>
                        <td>{{ user.status }}</td>
                      </tr>
                      <tr>
                        <th>Expire Date</th>
                        <td>{{ user.expire_date | moment("dddd, MMMM Do YYYY") }}</td>
                      </tr>
                      <tr>
                        <th>Address</th>
                        <td>{{ user.profiles ? user.profiles.address : '' }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="field is-grouped">
                    <p class="control">
                      <router-link
                        :to="{ name: 'MyAgentList'}"
                        class="button is-primary"
                      >
                        <span class="icon">
                          <i class="fas fa-th-list"></i>
                        </span>
                        <span>LIST AGENTS</span>
                      </router-link>
                    </p>
                    <p class="control">
                      <router-link
                        :to="{ name: 'MyAgentWallet', params: { agentId: user.id }}"
                        class="button is-link"
                      >
                        <span class="icon">
                          <i class="fas fa-wallet"></i>
                        </span>
                        <span>AGENT WALLET</span>
                      </router-link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapActions } from 'vuex'
import { AGENT_SHOW } from '@/store/actions/myActions'

export default {
  name: 'MyAgentShow',
  components: {
    Breadcrumb
  },
  props: {
    agentId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncAgent'
  },
  data () {
    return {
      pageTitle: 'Agnet Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-user-shield', routeName: 'MyAgentList', name: 'Manage Agents' }
        ],
        current: { icon: 'fas fa-user-tie', name: 'Agent Details' },
        actions: [
          {
            icon: 'fas fa-user-shield', routeName: 'MyAgentList', name: 'Manage Agents'
          }
        ]
      },
      user: null,
      fetchDetailsStatus: ''
    }
  },
  computed: {
    isDetailLoading () {
      return this.fetchDetailsStatus === 'AGENT_LOADING'
    }
  },
  methods: {
    ...mapActions('MyActions', { loadAgent: AGENT_SHOW }),
    loadAsyncAgent () {
      this.user = null
      this.fetchDetailsStatus = 'AGENT_LOADING'
      this.loadAgent(this.agentId)
        .then((response) => {
          this.user = response.data
          this.fetchDetailsStatus = 'AGENT_SUCCESS'
        })
        .catch((error) => {
          this.fetchDetailsStatus = 'AGENT_ERROR'
          this.$notify({
            title: 'Agent Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    this.loadAsyncAgent()
  }
}
</script>
