import { axios } from './axios'

export default {
  uploadThumbnail (advertisementId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/text-advertisements/${advertisementId}/thumbnail`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/text-advertisements', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  show (advertisementId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/text-advertisements/${advertisementId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  create (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/text-advertisements', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  update (advertisementId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/text-advertisements/${advertisementId}`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  delete (advertisementId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/text-advertisements/${advertisementId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
