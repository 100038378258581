<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="card card-section">
      <div class="card-content">
        <form
          @submit.prevent="onStart"
          v-if="form"
        >
          <div class="field">
            <label class="label">Name</label>
            <div class="control">
              <input
                v-model="form.name"
                class="input"
                :class="{'is-danger' : errors.name}"
                type="text"
                placeholder="Enter Place Name"
              />
            </div>
            <p
              v-if="errors.name"
              class="help is-danger"
            >{{ errors.name | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Parent Place</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <v-select
                  v-model="form.parent"
                  :options="parents"
                  label="name"
                  :class="{'is-danger' : errors.parent}"
                  placeholder="Pick parent for location"
                  class="is-small"
                ></v-select>
              </div>
              <div class="control">
                <button
                  type="button"
                  class="button is-info"
                  :class="{ 'is-loading': isLoading }"
                  :disabled="isLoading"
                  @click="loadAsyncParents()"
                >Re-Load Parents</button>
              </div>
            </div>
            <p
              v-if="errors.parent"
              class="help is-danger"
            >{{ errors.parent | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Status</label>
            <div class="control">
              <!-- <div class="control is-expanded has-icons-left"> -->
              <v-select
                v-model="form.status"
                :options="status_options"
                :class="{'is-danger' : errors.status}"
                placeholder="Pick status for location"
                class="is-small"
              ></v-select>
            </div>
            <p
              v-if="errors.status"
              class="help is-danger"
            >{{ errors.status | implode }}</p>
          </div>
          <div class="field is-grouped action-buttons">
            <div class="control">
              <button
                type="submit"
                :disabled="isLoading"
                class="button is-primary is-rounded"
                :class="{ 'is-loading': isLoading }"
              >Submit</button>
            </div>
            <div class="control">
              <button
                type="button"
                @click="onReset"
                class="button is-rounded"
              >Reset</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { LOCATION_CREATE, LOCATION_LIST } from '@/store/actions/locationActions'

export default {
  name: 'LocationCreate',
  components: {
    Breadcrumb
  },
  data () {
    return {
      pageTitle: 'New Place Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-globe', routeName: 'LocationList', name: 'Manage Places' }
        ],
        current: { icon: 'fas fa-plus', name: 'New Place' },
        actions: [{ icon: 'fas fa-globe', routeName: 'LocationList', name: 'Manage Places' }]
      },
      status_options: ['ENABLE', 'DISABLE'],
      form: {
        name: null,
        status: 'ENABLE',
        parent: null
      },
      errors: {},
      parents: []
    }
  },
  computed: {
    ...mapGetters('Locations', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Locations', { createLocation: LOCATION_CREATE, loadParents: LOCATION_LIST }),
    onStart () {
      this.errors = {}
      this.createLocation(this.form)
        .then((response) => {
          this.$notify({
            title: 'Place Saved',
            text: `(${response.data.name}) Place is created successfully`,
            type: 'is-success'
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Place Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onReset () {
      this.errors = {}
      this.form.name = null
      this.form.status = 'ENABLE'
      this.$notify({
        title: 'Reset Form',
        text: 'Place form is reset with default value, please continue...',
        type: 'is-info'
      })
    },
    loadAsyncParents () {
      this.loadParents()
        .then((response) => {
          this.parents = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Parent Places Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    this.onReset()
    this.loadAsyncParents()
  }
}
</script>
