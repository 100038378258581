import {
  CAMPAIGN_LIST,
  CAMPAIGN_CREATE,
  CAMPAIGN_SHOW,
  CAMPAIGN_SHOW_DETAILS,
  CAMPAIGN_UPDATE,
  CAMPAIGN_RELATION_UPDATE,
  CAMPAIGN_DELETE,
  CAMPAIGN_THUMBNAIL,
  CAMPAIGN_ADS,
  CAMPAIGN_UPLOAD_SPACES,
  CAMPAIGN_START
} from '../../actions/campaignActions'

import { LOADING, SUCCESS, ERROR, LOADING_THUMBNAIL, SUCCESS_THUMBNAIL, ERROR_THUMBNAIL } from '../../actions/requestStatus'
import campaignApi from '../../../api/campaignApi'

export default {
  namespaced: true,

  state: {
    status: '',
    thumbnailStatus: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR,
    isThumbnailLoading: (state) => state.thumbnailStatus === LOADING_THUMBNAIL,
    isThumbnailSuccess: (state) => state.thumbnailStatus === SUCCESS_THUMBNAIL,
    isThumbnailError: (state) => state.thumbnailStatus === ERROR_THUMBNAIL
  },

  actions: {
    [CAMPAIGN_THUMBNAIL]: ({ commit }, { campaignId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING_THUMBNAIL)
        campaignApi
          .uploadThumbnail(campaignId, formData)
          .then((response) => {
            commit(SUCCESS_THUMBNAIL)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR_THUMBNAIL)
            reject(error)
          })
      }),

    [CAMPAIGN_SHOW]: ({ commit }, campaignId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        campaignApi
          .show(campaignId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [CAMPAIGN_SHOW_DETAILS]: ({ commit }, campaignId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        campaignApi
          .showDetails(campaignId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [CAMPAIGN_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        campaignApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [CAMPAIGN_CREATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        campaignApi
          .create(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [CAMPAIGN_UPDATE]: ({ commit }, { campaignId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        campaignApi
          .update(campaignId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [CAMPAIGN_RELATION_UPDATE]: ({ commit }, { campaignId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        campaignApi
          .updateCampaignRelation(campaignId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [CAMPAIGN_DELETE]: ({ commit }, campaignId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        campaignApi
          .delete(campaignId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [CAMPAIGN_ADS]: (context, { campaignId, formData }) =>
      new Promise((resolve, reject) => {
        campaignApi
          .uploadAds(campaignId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [CAMPAIGN_UPLOAD_SPACES]: (context, { campaignId, formData }) =>
      new Promise((resolve, reject) => {
        campaignApi
          .uploadSpaces(campaignId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [CAMPAIGN_START]: (context, { campaignId, formData }) =>
      new Promise((resolve, reject) => {
        campaignApi
          .start(campaignId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    },
    [LOADING_THUMBNAIL]: (state) => {
      state.thumbnailStatus = LOADING_THUMBNAIL
    },
    [SUCCESS_THUMBNAIL]: (state) => {
      state.thumbnailStatus = SUCCESS_THUMBNAIL
    },
    [ERROR_THUMBNAIL]: (state) => {
      state.thumbnailStatus = ERROR_THUMBNAIL
    }
  }
}
