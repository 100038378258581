import { axios } from './axios'

export default {
  permissions () {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/permissions')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  list (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/roles', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  show (roleId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/roles/${roleId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  create (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/roles', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  update (roleId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/roles/${roleId}`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  delete (roleId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/roles/${roleId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
