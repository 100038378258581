<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="role"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ role.name }}</p>
                  </div>
                </div>

                <div class="content">
                  <table class="table is-striped">
                    <tbody>
                      <tr>
                        <th>Status</th>
                        <td>{{ role.status }}</td>
                      </tr>
                      <tr>
                        <th>Permissions</th>
                        <td>
                          <div class="tags">
                            <span
                              v-for="permission in role.permissions"
                              :key="permission"
                              class="tag is-primary"
                            >{{ permission }}</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="field is-grouped">
                    <p
                      class="control"
                      v-if="hasAnyScope('ROLE-LIST')"
                    >
                      <router-link
                        :to="{ name: 'RoleList'}"
                        class="button is-primary"
                      >
                        <span class="icon">
                          <i class="fas fa-th-list"></i>
                        </span>
                        <span>LIST</span>
                      </router-link>
                    </p>
                    <p
                      class="control"
                      v-if="hasAnyScope('ROLE-UPDATE')"
                    >
                      <router-link
                        :to="{ name: 'RoleEdit', params: { roleId: role.id }}"
                        class="button is-warning"
                      >
                        <span class="icon">
                          <i class="fas fa-edit"></i>
                        </span>
                        <span>EDIT</span>
                      </router-link>
                    </p>
                    <p
                      class="control"
                      v-if="hasAnyScope('ROLE-DELETE')"
                    >
                      <a
                        class="button is-danger"
                        @click.prevent="confirmDeleteRole"
                      >
                        <span class="icon">
                          <i class="fas fa-trash"></i>
                        </span>
                        <span>DELETE</span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-image">
                <figure class="image is-4by3">
                  <img
                    src="/img/placeholders/image-placeholder.png"
                    alt="Role Image"
                  >
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { ROLE_SHOW, ROLE_DELETE } from '@/store/actions/roleActions'

export default {
  name: 'RoleShow',
  components: { Breadcrumb },
  props: {
    roleId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncRole'
  },
  data () {
    return {
      pageTitle: 'Role Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-users-cog', routeName: 'RoleList', name: 'Manage Roles' }
        ],
        current: { icon: 'fas fa-ribbon', name: 'Role Details' },
        actions: [
          {
            icon: 'fas fa-users-cog', routeName: 'RoleList', name: 'Manage Roles', scope: 'ROLE-LIST'
          }
        ]
      },
      role: null
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    ...mapGetters('Roles', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Roles', { loadRole: ROLE_SHOW, sendDeleteRoleRequest: ROLE_DELETE }),
    confirmDeleteRole () {
      this.$buefy.dialog.confirm({
        title: 'Deleting Role',
        message: 'Are you sure you want to <b>delete</b> this role? This action cannot be undone.',
        confirmText: 'Delete Role',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deleteRole()
      })
    },
    deleteRole () {
      this.sendDeleteRoleRequest(this.role.id)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.$router.replace({ name: 'RoleList' })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncRole () {
      this.role = null
      this.loadRole(this.roleId)
        .then((response) => {
          this.role = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Role Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'RoleList' }),
            onConfirm: () => this.$router.replace({ name: 'RoleList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncRole()
  }
}
</script>
