<template>
  <div class="field has-addons" ref="colorpicker" v-click-outside="hidePicker">
    <div class="control">
      <input v-model="colorValue" type="text" class="input" @focus="showPicker()" @input="updateFromInput" />
    </div>
    <div class="control">
      <button @click="togglePicker()"
        class="button" :style="'background-color: ' + colorValue">
        PICK
      </button>
      <sketch-picker :value="colors" @input="updateFromPicker" v-if="displayPicker" />
    </div>
  </div>
</template>

<script>
import { Sketch } from 'vue-color'

export default {
  name: 'ColorPicker',
  props: ['color'],
  components: {
    'sketch-picker': Sketch
  },
  data () {
    return {
      colors: '#000000',
      colorValue: '',
      displayPicker: false
    }
  },
  watch: {
    colorValue (val) {
      if (val) {
        this.updateColors(val)
        this.$emit('input', val)
      }
    }
  },
  methods: {
    setColor (color) {
      this.updateColors(color)
      this.colorValue = color
    },
    updateColors (color) {
      if (color.slice(0, 1) === '#') {
        this.colors = {
          hex: color
        }
      } else if (color.slice(0, 4) === 'rgba') {
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
        /* eslint no-bitwise: ["error", { "allow": ["<<", ">>"] }] */
        const hex = `#${((1 << 24) + (parseInt(rgba[0], 10) << 16) + (parseInt(rgba[1], 10) << 8) + parseInt(rgba[2], 10)).toString(16).slice(1)}`
        this.colors = {
          hex,
          a: rgba[3]
        }
      }
    },
    showPicker () {
      this.displayPicker = true
    },
    hidePicker () {
      this.displayPicker = false
    },
    togglePicker () {
      if (this.displayPicker) {
        this.hidePicker()
      } else {
        this.showPicker()
      }
    },
    updateFromInput () {
      this.updateColors(this.colorValue)
    },
    updateFromPicker (color) {
      this.colors = color
      if (color.rgba.a === 1) {
        this.colorValue = color.hex
      } else {
        this.colorValue = `rgba(${color.rgba.r}, ${color.rgba.g}, ${color.rgba.b}, ${color.rgba.a})`
      }
    }
  },
  mounted () {
    this.setColor(this.color || '#000000')
  }
}
</script>
