<template>
  <div>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="box">
      <div
        class="columns is-multiline is-mobile"
        v-if="items"
      >
        <div
          class="column is-full-mobile is-one-third-tablet is-one-quarter-desktop"
          v-for="item in items"
          :key="`item-${item.id}`"
        >
          <booking-video-advertisement
            v-if="item.type === 'Video Ads'"
            :ads="ads"
            :item="item"
            @selected="onAdvertisementSelect"
          ></booking-video-advertisement>
        </div>
      </div>
      <infinite-loading
        @infinite="infiniteHandler"
        ref="infiniteLoading"
        spinner="waveDots"
      >
        <h1
          slot="no-more"
          class="title is-3 has-text-centered"
        >There is no more advertisement found!</h1>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import InfiniteLoading from 'vue-infinite-loading'
import BookingVideoAdvertisement from '../bookings/BookingVideoAdvertisement'
import { SEARCH_ADVERTISEMENTS } from '@/store/actions/bookingActions'
import { ORDER_ADS_CHANGE } from '@/store/actions/orderActions'

export default {
  name: 'OrderAdsChange',
  components: {
    Breadcrumb,
    InfiniteLoading,
    BookingVideoAdvertisement
  },
  props: {
    orderId: {
      required: true
    }
  },
  data () {
    return {
      pageTitle: 'Booking Ads Change',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' }
        ],
        current: { icon: 'fas fa-project-diagram', name: 'Booking Ads Change' }
      },
      filter: {
        query: '',
        type: 'VIDEO'
      },
      filterTimout: null,
      nextUrl: null,
      meta: null,
      items: [],
      ads: null
    }
  },
  watch: {
    filter: {
      handler: 'onFilterChanged',
      deep: true
    }
  },
  methods: {
    ...mapActions('Bookings', { searchAdvertisements: SEARCH_ADVERTISEMENTS }),
    ...mapActions('Orders', { changeOrderAds: ORDER_ADS_CHANGE }),
    onAdvertisementSelect (item) {
      this.ads = item
      if (this.ads !== null) {
        this.$buefy.dialog.confirm({
          title: 'Order Ads Change',
          message: 'Are you sure, you want to <b>change</b> with this ads? This action cannot be undone.',
          cancelText: 'No, Cancel change.',
          confirmText: 'Yes, I am sure.',
          type: 'is-info',
          onConfirm: () => this.onAdsChange()
        })
      }
    },
    onFilterChanged () {
      clearTimeout(this.filterTimout)
      this.filterTimout = setTimeout(() => {
        this.nextUrl = null
        this.items = []
        this.$nextTick(() => {
          this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset')
        })
      }, 1000)
    },
    onSearch () {
      return new Promise((resolve, reject) => {
        const formData = {
          type: this.filter.type,
          filterData: this.filter,
          nextUrl: this.nextUrl
        }
        this.searchAdvertisements(formData)
          .then((response) => {
            resolve({ meta: response.meta, data: response.data, links: response.links })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    infiniteHandler ($state) {
      this.onSearch().then(({ meta, data, links }) => {
        this.meta = meta
        data.forEach((ads) => {
          this.items.push(ads)
        })
        $state.loaded()
        if (links.next === null) {
          $state.complete()
        } else {
          this.nextUrl = links.next
        }
      })
    },
    onAdsChange () {
      const data = {
        orderId: this.orderId,
        adsId: this.ads.id
      }
      this.changeOrderAds(data)
        .then((response) => {
          this.$buefy.dialog.alert({
            title: `Ads Change ${response.status}`,
            message: response.message,
            type: (response.status === 'success') ? 'is-primary' : 'is-info',
            hasIcon: true,
            icon: (response.status === 'success') ? 'thumbs-up' : 'thumbs-down',
            iconPack: 'fas'
          })
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Ads Change Failed',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'thumbs-down',
            iconPack: 'fas'
          })
        })
    }
  }
}
</script>
