<template>
  <b-field
    :label="type | titleize"
    custom-class="is-small"
  >
    <b-select :placeholder="`Select ${type}`" :expanded="true" v-model="result">
      <option v-for="item in items" :value="item.id" :key="item.id">
        {{ item.name }}
        <br>
        <small>
          {{ item.email }},
        </small>
      </option>
    </b-select>
    <!-- <b-autocomplete
      icon-pack="fas"
      icon="search"
      :placeholder="`Type ${type} details`"
      :field="field"
      :keep-first="true"
      :data="items"
      v-model="queryStr"
      :loading="isLoading"
      @typing="getAsyncData"
      @select="onUseSelection"
    >
      <template slot="empty">No {{ type }} found</template>
      <template slot-scope="props">
        <div class="media">
          <div class="media-content">
            {{ props.option.name }}
            <br>
            <small>
              {{ props.option.email }},
            </small>
          </div>
        </div>
      </template>
    </b-autocomplete> -->
  </b-field>
</template>

<script>
import { mapActions } from 'vuex'
import userOptionsMixins from './user-options-mixin'
import { ADVERTISER_LIST } from '@/store/actions/userActions'

export default {
  name: 'AdvertiserSelectBox',
  mixins: [userOptionsMixins],
  data () {
    return {
      type: 'advertiser'
    }
  },
  methods: {
    ...mapActions('Users', { loadUsers: ADVERTISER_LIST })
  }
}
</script>
