<template>
  <div
    class="card card-section mx-auto"
    style="max-width: 800px;"
  >
    <div class="card-image">
      <video
        class="image full-width"
        v-if="spaceData.path !== undefined && spaceData.path !== null && spaceData.path.toLowerCase().endsWith('.mp4')"
        controls
      >
        <source
          :src="spaceData.path"
          type="video/mp4"
        >
        Your browser does not support the video tag.
      </video>
      <figure
        v-else
        class="image full-width"
      >
        <img
          :src="spaceData.thumbnail"
          alt="Space Ads Thumbnail"
        />
      </figure>
    </div>
    <div
      v-if="canAdsUpload"
      class="file is-small has-name is-primary center-input-group"
    >
      <label class="file-label">
        <input
          class="file-input"
          type="file"
          name="adsFile"
          ref="adsFile"
          @change="onAdsChange"
          :disabled="isAdsLoading"
        />
        <span class="icon-text upload-icon">
          <span class="icon has-text-white">
            <i class="icon fas fa-upload"></i>
          </span>
          <span class="has-text-white">Upload</span>
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KioskSpace',
  props: {
    space: {
      required: true
    },
    canAdsUpload: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  data () {
    return {
      spaceData: this.space,
      errors: {},
      LOADING_ADS: 'LOADING_ADS',
      SUCCESS_ADS: 'SUCCESS_ADS',
      ERROR_ADS: 'ERROR_ADS'
    }
  },
  watch: {
    space: {
      handler (newValue, oldValue) {
        this.spaceData = newValue
      },
      deep: true
    }
  },
  computed: {
    isAdsLoading () {
      return this.spaceData.status === this.LOADING_ADS
    },
    isAdsSuccess () {
      return this.spaceData.status === this.SUCCESS_ADS
    },
    isAdsError () {
      return this.spaceData.status === this.ERROR_ADS
    }
  },
  methods: {
    createImage (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.spaceData.thumbnail = { large: e.target.result }
      }
      reader.readAsDataURL(file)
    },
    onAdsChange () {
      const adsFiles = this.$refs.adsFile
      if (!adsFiles || !adsFiles.files.length) {
        return
      }
      this.spaceData.adsName = adsFiles.files[0].name
      this.createImage(adsFiles.files[0])
      this.uploadSpaceAds(adsFiles.files[0])
    },
    uploadSpaceAds (ads) {
      const adsFormData = new FormData()
      adsFormData.set('ads', ads)
      this.$emit('upload-ads', adsFormData)
    }
  }
}
</script>
