<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        {{ title }}
      </p>
    </header>
    <div class="card-content">
      <div class="content">
        <div class="columns is-desktop is-multiline">
          <div
            class="column is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd"
              v-for="picture in gallery" :key="`picture-${picture.id}`">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <img :src="picture.large" alt="Gallery Picture">
                </figure>
              </div>
              <div class="card-content" v-if="properties">
                <table>
                  <tbody>
                    <tr v-for="property in properties" :key="`property-${picture.id}-${property}`">
                      <th>{{ property }}</th>
                      <td>{{ picture[property] }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PictureGallery',
  props: {
    title: {
      required: true
    },
    gallery: {
      required: true
    },
    properties: {
      required: false
    }
  }
}
</script>
