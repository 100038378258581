export default {
  data () {
    return {
      type: 'advertiser',
      result: null,
      items: [],
      isLoading: false
    }
  },
  watch: {
    value: 'selectValue',
    result: 'onOptionSelection'
  },
  methods: {
    selectValue () {
      this.result = this.value
    },
    loadUsers () {
      return new Promise((resolve) => {
        resolve({ data: [] })
      })
    },
    asyncLoadUsers () {
      this.loadUsers()
        .then((response) => {
          this.items = response.data
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          this.$notify({
            title: 'Agent Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onOptionSelection () {
      this.$emit('input', this.result)
    }
  },
  created () {
    this.asyncLoadUsers()
  }
}
