<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="card card-section">
      <div class="card-content">
        <form @submit.prevent="onStart">
          <div class="field">
            <label class="label">Type</label>
            <div class="control">
              <v-select
                v-model="ticketType"
                :options="type_options"
                placeholder="Pick type of your ticket"
                class="is-small"
                :class="{'is-danger' : errors.type}"
              ></v-select>
              <p
                v-if="errors.type"
                class="help is-danger"
              >{{ errors.type | implode }}</p>
            </div>
          </div>
          <div v-if="this.form.type === 1">
            <b-field
              label="Agent/Distributor"
              :type="{ 'is-danger' : errors['user.id'] }"
              :message="errors['user.id'] | implode"
            >
              <b-autocomplete
                placeholder="Type agent/distributor to search and select"
                field="name"
                :keep-first="true"
                :data="autocomplete.agents_distributors.items"
                :loading="autocomplete.agents_distributors.isLoading"
                v-model="autocomplete.agents_distributors.value"
                @input="loadAgentsAndDistributorsSuggestion"
                @select="option => form.user = option"
              >
                <template slot="empty">No agent/distributor found</template>
                <template slot-scope="props">
                  <div class="media">
                    <div class="media-content">
                      {{ props.option.name }}
                      <br>
                      <small>
                        {{ props.option.email }},
                      </small>
                      <br>
                      <small>
                        {{ props.option.types | implode }},
                      </small>
                    </div>
                  </div>
                </template>
              </b-autocomplete>
            </b-field>
            <div class="field">
              <label class="label">Amount</label>
              <div class="control">
                <input
                  v-model="form.amount"
                  class="input"
                  type="number"
                  placeholder="Enter amount to redeem"
                  :class="{'is-danger' : errors.amount}"
                />
              </div>
              <p
                v-if="errors.amount"
                class="help is-danger"
              >{{ errors.amount | implode }}</p>
            </div>
          </div>
          <div class="field is-grouped action-buttons">
            <div class="control">
              <button
                type="button"
                :disabled="isLoading"
                class="button is-primary is-rounded"
                @click="formSubmit"
                :class="{ 'is-loading': isLoading }"
              >Submit Request</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { TICKET_CREATE } from '@/store/actions/ticketActions'
import { USER_SUGGENTIONS } from '@/store/actions/userActions'

export default {
  name: 'TicketCreate',
  components: { Breadcrumb },
  data () {
    return {
      pageTitle: 'New Ticket Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-cogs', routeName: 'TicketList', name: 'Manage Tickets' }
        ],
        current: { icon: 'fas fa-ribbon', name: 'New Ticket' },
        actions: [{ icon: 'fab fa-stack-exchange', routeName: 'TicketList', name: 'Manage Tickets' }]
      },
      type_options: [
        { label: 'REDEEM WALLET MONEY', value: 1 }
      ],
      ticketType: null,
      form: null,
      errors: {},
      autocomplete: {
        agents_distributors: {
          value: null,
          items: [],
          isLoading: false,
          timeout: null
        }
      }
    }
  },
  watch: {
    ticketType: 'onTicketTypeChange'
  },
  computed: {
    ...mapGetters('Tickets', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Tickets', { createTicket: TICKET_CREATE }),
    ...mapActions('Users', { loadUserSuggentions: USER_SUGGENTIONS }),
    onTicketTypeChange () {
      if (this.ticketType !== undefined && this.ticketType !== null) {
        this.form.type = this.ticketType.value
      } else {
        this.form.type = null
      }
    },
    formSubmit () {
      this.errors = {}
      this.createTicket(this.form)
        .then(() => {
          this.$notify({
            title: 'Your Ticket Sent',
            text: `Your ticket (${this.ticketType.label}) is sent to support team for further processing...`,
            type: 'is-success'
          })
          this.onReset()
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Request',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAgentsAndDistributorsSuggestion () {
      clearTimeout(this.autocomplete.agents_distributors.timeout)
      this.autocomplete.agents_distributors.timeout = setTimeout(() => {
        const data = {
          query: this.autocomplete.agents_distributors.value,
          types: ['agent', 'distributor']
        }
        if (data.query) {
          this.autocomplete.agents_distributors.isLoading = true
          this.loadUserSuggentions(data)
            .then((response) => {
              this.autocomplete.agents_distributors.items = response.data
              this.autocomplete.agents_distributors.isLoading = false
            })
            .catch((error) => {
              this.autocomplete.agents_distributors.isLoading = false
              this.$notify({
                title: 'Distributor Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    },
    onReset () {
      this.errors = {}
      this.form = {
        type: '',
        amount: '',
        user: null
      }
      this.autocomplete.agents_distributors.value = null
      this.$notify({
        title: 'Reset Form',
        text: 'Ticket request form is reset with default value, please continue...',
        type: 'is-info'
      })
    }
  },
  created () {
    this.onReset()
  }
}
</script>
