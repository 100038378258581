import { axios } from './axios'

export default {
  getProfile () {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/me/profile')
        .then((response) => {
          if (response.data.data !== undefined) {
            resolve(response.data.data)
          } else {
            resolve(response.data)
          }
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },
  saveGeneralProfile (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/me/profile', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  saveSecurityProfile (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/me/profile/secure', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  saveFinanceProfile (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/me/profile/finance', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
