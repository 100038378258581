<template>
  <div class="columns">
    <div class="column">
      <div class="field">
        <label class="label">Name</label>
        <div class="control">
          <input
            v-model="slider.name"
            class="input"
            type="text"
            placeholder="Enter Slider Name"
            :class="{'is-danger' : errors.name}"
          />
        </div>
        <p
          v-if="errors.name"
          class="help is-danger"
        >{{ errors.name | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Status</label>
        <div class="control">
          <v-select
            v-model="slider.status"
            :options="status_options"
            :class="{'is-danger' : errors.status}"
            placeholder="Pick status for slider"
            class="is-small"
          ></v-select>
        </div>
        <p
          v-if="errors.status"
          class="help is-danger"
        >{{ errors.status | implode }}</p>
      </div>
      <div class="field is-slidered action-buttons">
        <div class="control">
          <button
            type="button"
            :disabled="isLoading"
            class="button is-primary is-rounded"
            @click="formSubmit"
            :class="{ 'is-loading': isLoading }"
          >Update Slider</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { SLIDER_UPDATE } from '@/store/actions/sliderActions'

export default {
  name: 'SliderGeneral',
  props: {
    slider: {
      required: true
    }
  },
  data () {
    return {
      status_options: ['DRAFT', 'ENABLE', 'DISABLE'],
      errors: {}
    }
  },
  computed: {
    ...mapGetters('Sliders', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Sliders', { updateSlider: SLIDER_UPDATE }),
    formSubmit () {
      this.errors = {}
      const data = {
        sliderId: this.slider.id,
        formData: this.slider
      }
      this.updateSlider(data)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.name}) Slider is updated successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Slider',
            onAction: () => {
              this.$router.push({
                name: 'SliderList'
              })
            }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Slider Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
