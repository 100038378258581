import {
  DEVICE_LIST,
  DEVICE_SHOW,
  DEVICE_SHOW_DETAILS,
  DEVICE_UPDATE,
  DEVICE_TEXT_TO_ADS,
  DEVICE_THUMBNAIL,
  DEVICE_ADS,
  DEVICE_GALLERY_CREATE,
  DEVICE_GALLERY_DELETE,
  DEVICE_PING_LIST,
  DEVICE_ADS_HISTORY_LIST,
  DEVICE_REPORT_REVENUE,
  DEVICE_REPORT_AGGREGATE_REVENUE,
  AGENT_LIST,
  AGENT_SHOW,
  AGENT_WALLET_INFO,
  AGENT_WALLET_TRANSACTION_LIST,
  ADVERTISEMENT_SUGGESTION,
  ADVERTISEMENT_LIST,
  ADVERTISEMENT_CREATE,
  ADVERTISEMENT_UPDATE,
  ADVERTISEMENT_SHOW,
  ADVERTISEMENT_DELETE,
  ADVERTISEMENT_THUMBNAIL,
  ADVERTISEMENT_ADS,
  TICKET_LIST,
  TICKET_CREATE,
  TICKET_CANCELLED
} from '../../actions/myActions'
import { LOADING, SUCCESS, ERROR } from '../../actions/requestStatus'
import myActionApi from '../../../api/myActionApi'

export default {
  namespaced: true,

  state: {
    status: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR
  },

  actions: {
    [AGENT_SHOW]: (context, agentId) =>
      new Promise((resolve, reject) => {
        myActionApi
          .agentShow(agentId)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [AGENT_LIST]: (context, filterData) =>
      new Promise((resolve, reject) => {
        myActionApi
          .agentList(filterData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_GALLERY_CREATE]: (context, { userView, deviceId, formData }) =>
      new Promise((resolve, reject) => {
        myActionApi
          .deviceUploadGallery(userView, deviceId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_GALLERY_DELETE]: (context, { userView, deviceId, galleryId }) =>
      new Promise((resolve, reject) => {
        myActionApi
          .deviceDeleteGallery(userView, deviceId, galleryId)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_THUMBNAIL]: (context, { userView, deviceId, formData }) =>
      new Promise((resolve, reject) => {
        myActionApi
          .deviceUploadThumbnail(userView, deviceId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_ADS]: (context, { userView, deviceId, formData }) =>
      new Promise((resolve, reject) => {
        myActionApi
          .deviceUploadAds(userView, deviceId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_TEXT_TO_ADS]: (context, { userView, deviceId, formData }) =>
      new Promise((resolve, reject) => {
        myActionApi
          .uploadAdsByText(userView, deviceId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_SHOW]: (context, { userView, deviceId }) =>
      new Promise((resolve, reject) => {
        myActionApi
          .deviceShow(userView, deviceId)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_SHOW_DETAILS]: (context, { userView, deviceId }) =>
      new Promise((resolve, reject) => {
        myActionApi
          .deviceShowDetails(userView, deviceId)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_LIST]: (context, { userView, filterData }) =>
      new Promise((resolve, reject) => {
        myActionApi
          .deviceList(userView, filterData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_UPDATE]: (context, { userView, deviceId, formData }) =>
      new Promise((resolve, reject) => {
        myActionApi
          .deviceUpdate(userView, deviceId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_PING_LIST]: (context, { userView, deviceId, formData }) =>
      new Promise((resolve, reject) => {
        myActionApi
          .deviceListPings(userView, deviceId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_ADS_HISTORY_LIST]: (context, { userView, deviceId, formData }) =>
      new Promise((resolve, reject) => {
        myActionApi
          .deviceAdsHistoryList(userView, deviceId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_REPORT_REVENUE]: ({ commit }, { userView, deviceId, filterData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        myActionApi
          .reportRevenue(userView, deviceId, filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_REPORT_AGGREGATE_REVENUE]: ({ commit }, { userView, deviceId, filterData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        myActionApi
          .reportAggregateRevenue(userView, deviceId, filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [AGENT_WALLET_INFO]: (context, agentId) =>
      new Promise((resolve, reject) => {
        myActionApi
          .getWalletInfo(agentId)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [AGENT_WALLET_TRANSACTION_LIST]: (context, { agentId, filterData }) =>
      new Promise((resolve, reject) => {
        myActionApi
          .listTransactions(agentId, filterData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [ADVERTISEMENT_SUGGESTION]: (context, filterData) =>
      new Promise((resolve, reject) => {
        myActionApi
          .advertisementSuggestions(filterData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [ADVERTISEMENT_LIST]: (context, filterData) =>
      new Promise((resolve, reject) => {
        myActionApi
          .advertisementList(filterData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [ADVERTISEMENT_THUMBNAIL]: (context, { advertisementId, formData }) =>
      new Promise((resolve, reject) => {
        myActionApi
          .advertisementUploadThumbnail(advertisementId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [ADVERTISEMENT_ADS]: (context, { advertisementId, formData }) =>
      new Promise((resolve, reject) => {
        myActionApi
          .advertisementUploadAds(advertisementId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [ADVERTISEMENT_SHOW]: (context, advertisementId) =>
      new Promise((resolve, reject) => {
        myActionApi
          .advertisementShow(advertisementId)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [ADVERTISEMENT_CREATE]: (context, formData) =>
      new Promise((resolve, reject) => {
        myActionApi
          .advertisementCreate(formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [ADVERTISEMENT_UPDATE]: (context, { advertisementId, formData }) =>
      new Promise((resolve, reject) => {
        myActionApi
          .advertisementUpdate(advertisementId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [ADVERTISEMENT_DELETE]: (context, advertisementId) =>
      new Promise((resolve, reject) => {
        myActionApi
          .advertisementDelete(advertisementId)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [TICKET_LIST]: (context, filterData) =>
      new Promise((resolve, reject) => {
        myActionApi
          .ticketList(filterData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [TICKET_CREATE]: (context, formData) =>
      new Promise((resolve, reject) => {
        myActionApi
          .ticketCreate(formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [TICKET_CANCELLED]: (context, ticketId) =>
      new Promise((resolve, reject) => {
        myActionApi
          .ticketCancelled(ticketId)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    }
  }
}
