<template>
  <aside
    class="column aside"
    v-if="isProfileLoaded"
  >
    <div class="aside-content">
      <menu-list
        v-for="(nav, index) in navs"
        :key="index + '-menu-list'"
        :nav="nav"
      ></menu-list>
      <nav class="menu">
        <ul class="menu-list">
          <li>
            <a
              class="has-background-info"
              @click.prevent="getUserLoggedOut"
              href="#"
            >
              <span class="icon"><i class="fas fa-sign-out-alt"></i></span>
              <span>Sign Out</span>
            </a>
          </li>
          <li>
            <a
              class="has-background-grey-light"
              @click.prevent="getUserLoggedOutFromAll"
              href="#"
            >
              <span class="icon"><i class="fas fa-sign-out-alt"></i></span>
              <span>Sign Out From All</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MenuList from './MenuList'
import { AUTH_LOGOUT, AUTH_LOGOUT_ALL } from '@/store/actions/authActions'

export default {
  name: 'Aside',
  components: {
    'menu-list': MenuList
  },
  props: {
    navs: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters('Profile', { user: 'getProfile', hasAnyScope: 'hasAnyScope' }),
    ...mapGetters('Auth', { isAuth: 'isAuthenticated' }),
    isProfileLoaded () {
      return this.isAuth && this.user !== null && this.user.name !== undefined
    }
  },
  methods: {
    ...mapActions('Auth', { userLoggedOut: AUTH_LOGOUT, userLoggedOutFromAll: AUTH_LOGOUT_ALL }),
    getUserLoggedOut () {
      this.userLoggedOut().then(() => {
        this.$notify({
          title: 'User Action',
          text: 'User successfully signed out from this session...',
          type: 'is-success'
        })
        this.$router.replace({ name: 'Login' })
      })
    },
    getUserLoggedOutFromAll () {
      this.userLoggedOutFromAll().then(() => {
        this.$notify({
          title: 'User Action',
          text: 'User successfully signed out from all sessions...',
          type: 'is-success'
        })
        this.$router.replace({ name: 'Login' })
      })
    },
    isNavNeeded (nav) {
      let result = false
      if (nav.children && (nav.scopes === undefined || this.hasAnyScope(nav.scopes))) {
        result = true
      }
      return result
    }
  }
}
</script>

<style lang="sass">
@import '@/sass/mixins.sass'
.aside
  z-index: 10
  background: $aside-bg-color
  display: none !important
  +desktop
    display: block !important
  &.is-active
    display: block !important
    +desktop
      display: none !important
  .menu-list
    a.is-open-active
      background-color: #006556
      color: #ffffff
</style>
