<template>
  <div class="field">
    <label class="label is-small">{{ label }}</label>
    <div class="control is-expanded has-icons-left">
      <b-datetimepicker
        ref="bPicker"
        v-model="dateTime"
        rounded
        locale="en-US"
        placeholder="Click to select date & time..."
        icon="fas fa-calendar"
        :icon-right="dateTime ? 'close-circle' : ''"
        icon-right-clickable
        :first-day-of-week="0"
        :timepicker="timepickerConfig"
        :datetime-formatter="displayDateFormat"
        :min-datetime="minDate"
        :max-datetime="maxDate"
        @icon-right-click="clearDateTime"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DateTimePicker',
  props: {
    value: {
      default: null
    },
    minDate: {
      default: null
    },
    maxDate: {
      default: null
    },
    label: {
      default: 'Date & Time'
    }
  },
  data () {
    return {
      dateTime: null,
      timepickerConfig: {
        enableSeconds: false,
        hourFormat: '24'
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal) {
        this.dateTime = newVal
      }
    },
    dateTime: {
      handler (newVal) {
        this.onChanged()
      }
    }
  },
  methods: {
    onChanged () {
      if (this.dateTime !== undefined && this.dateTime !== null) {
        if (this.minDate !== undefined && this.minDate !== null) {
          if (moment(this.minDate).isAfter(moment(this.dateTime))) {
            this.dateTime = this.minDate
          }
        }
        if (this.maxDate !== undefined && this.maxDate !== null) {
          if (moment(this.maxDate).isBefore(moment(this.dateTime))) {
            this.dateTime = this.maxDate
          }
        }
      }
      this.$emit('input', this.dateTime)
    },
    clearDateTime () {
      this.dateTime = null
    },
    displayDateFormat (date) {
      return new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(date)
    }
  }
}
</script>
