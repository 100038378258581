import { ORDER_LIST, SUMMARY_SLOTS, SUMMARY_FINANCIAL, ORDER_SHOW, ORDER_ITEMS, ORDER_ADS_CHANGE } from '../../actions/orderActions'

import { LOADING, SUCCESS, ERROR } from '../../actions/requestStatus'
import orderApi from '../../../api/orderApi'

export default {
  namespaced: true,

  state: {
    status: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR
  },

  actions: {
    [ORDER_ADS_CHANGE]: (context, { orderId, adsId }) =>
      new Promise((resolve, reject) => {
        orderApi
          .changeOrderAds(orderId, adsId)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [ORDER_ITEMS]: (context, filterData) =>
      new Promise((resolve, reject) => {
        orderApi
          .showItems(filterData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [ORDER_SHOW]: ({ commit }, orderId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        orderApi
          .show(orderId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [ORDER_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        orderApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [SUMMARY_FINANCIAL]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        orderApi
          .summaryFinancial(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [SUMMARY_SLOTS]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        orderApi
          .summarySlots(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })

  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    }
  }
}
