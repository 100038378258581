<template>
  <form @submit.prevent="saveSecureInformation">
    <div class="field">
      <label class="label">Current Password</label>
      <div class="control">
        <input
          v-model="form.current_password"
          class="input"
          :class="{'is-danger' : errors.current_password}"
          type="password"
          placeholder="Enter Current Password"
        />
      </div>
      <p
        v-if="errors.current_password"
        class="help is-danger"
      >{{ errors.current_password | implode }}</p>
    </div>

    <div class="field">
      <label class="label">Password</label>
      <div class="control">
        <input
          v-model="form.password"
          class="input"
          :class="{'is-danger' : errors.password}"
          type="password"
          placeholder="Enter New Password"
        />
      </div>
      <p
        v-if="errors.password"
        class="help is-danger"
      >{{ errors.password | implode }}</p>
    </div>

    <div class="field">
      <label class="label">Confirm Password</label>
      <div class="control">
        <input
          v-model="form.password_confirmation"
          class="input"
          :class="{'is-danger' : errors.password_confirmation}"
          type="password"
          placeholder="Retype Password"
        />
      </div>
      <p
        v-if="errors.password_confirmation"
        class="help is-danger"
      >{{ errors.password_confirmation | implode }}</p>
    </div>
    <div class="field is-grouped action-buttons">
      <div class="control"><button
          type="submit"
          class="button is-primary is-rounded"
        >Change Password</button></div>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import { STORE_SECURE_PROFILE } from '@/store/actions/profileActions'

export default {
  name: 'SecurityInformation',
  data () {
    return {
      form: {
        current_password: '',
        password: '',
        password_confirmation: ''
      },
      errors: {}
    }
  },
  methods: {
    ...mapActions('Profile', { saveSecureProfile: STORE_SECURE_PROFILE }),
    resetForm () {
      this.form.current_password = ''
      this.form.password = ''
      this.form.password_confirmation = ''
    },
    saveSecureInformation () {
      const loadingComponent = this.$buefy.loading.open({ container: null, canCancel: false })
      this.saveSecureProfile(this.form)
        .then(() => {
          loadingComponent.close()
          this.$buefy.snackbar.open({
            duration: 4000,
            message: 'Password changed successfully',
            type: 'is-success',
            position: 'is-top'
          })
          this.resetForm()
        })
        .catch((error) => {
          loadingComponent.close()
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
