<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <advance-filter
      :is-loading="isLoading"
      @onStart="onFilter"
      :title="deviceTitle"
      :headerBackgroundColor="deviceStatus"
    >
      <div class="columns is-multiline">
        <div class="column field is-3">
          <label class="label is-small">Start Date</label>
          <div class="control is-expanded has-icons-left">
            <input
              class="input"
              type="date"
              placeholder="Enter Start Time"
              v-model="filter.start"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-calendar"></i>
            </span>
          </div>
        </div>
        <div class="column field is-3">
          <label class="label is-small">End Date</label>
          <div class="control is-expanded has-icons-left">
            <input
              class="input"
              type="date"
              placeholder="Enter End Time"
              v-model="filter.end"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-calendar"></i>
            </span>
          </div>
        </div>
      </div>
    </advance-filter>
    <div
      class="tile is-ancestor mt-1"
      v-if="device"
    >
      <div class="tile is-parent">
        <article class="tile is-child notification is-info">
          <inner-loading v-show="isLoading"></inner-loading>
          <p class="is-size-5">{{ report.working_seconds | humanizeDuration | titleize }}</p>
          <p class="is-size-6 has-text-weight-bold">Working Time</p>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child notification is-info">
          <inner-loading v-show="isLoading"></inner-loading>
          <p class="is-size-5">{{ report.rsrtc_run_time | humanizeDuration | titleize }}</p>
          <p class="is-size-6 has-text-weight-bold">RSRTC Run Time</p>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child notification is-info">
          <inner-loading v-show="isLoading"></inner-loading>
          <p class="is-size-5">{{ report.ads_sseconds | humanizeDuration | titleize }}</p>
          <p class="is-size-6 has-text-weight-bold">Ads Time</p>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child notification is-danger">
          <inner-loading v-show="isLoading"></inner-loading>
          <p class="is-size-5">{{ report.ads_fseconds | humanizeDuration | titleize }}</p>
          <p class="is-size-6 has-text-weight-bold">Ads Time</p>
        </article>
      </div>
    </div>
    <div
      class="tile is-ancestor"
      v-if="device"
    >
      <div
        class="tile is-parent"
        v-if="isAgentView"
      >
        <article class="tile is-child notification is-info">
          <inner-loading v-show="isLoading"></inner-loading>
          <p class="is-size-5">{{ report.ads_agent_samount }} INR</p>
          <p class="is-size-6 has-text-weight-bold">Agent Amount</p>
        </article>
      </div>
      <div
        class="tile is-parent"
        v-if="isDistributorView"
      >
        <article class="tile is-child notification is-info">
          <inner-loading v-show="isLoading"></inner-loading>
          <p class="is-size-5">{{ report.ads_distributor_samount }} INR</p>
          <p class="is-size-6 has-text-weight-bold">Distributor Amount</p>
        </article>
      </div>
      <div
        class="tile is-parent"
        v-if="isChannelView"
      >
        <article class="tile is-child notification is-info">
          <inner-loading v-show="isLoading"></inner-loading>
          <p class="is-size-5">{{ report.ads_channel_samount }} INR</p>
          <p class="is-size-6 has-text-weight-bold">Channel Amount</p>
        </article>
      </div>
    </div>
    <div class="card card-section">
      <div class="card-content">
        <b-field
          grouped
          group-multiline
        >
          <b-select
            v-model="filter.size"
            :disabled="!hasItems"
            @input="onFilter"
          >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
            <option value="1000">1000 per page</option>
            <option value="5000">5000 per page</option>
          </b-select>
          <b-button
            @click="downloadExl('exportTableData', 'revenue-report')"
            type="is-danger"
            icon-left="fas fa-file-download"
          >Export</b-button>
        </b-field>
        <div id="exportTableData">
          <b-table
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :hoverable="true"
            :mobile-cards="true"
            :loading="isLoading"
            paginated
            backend-pagination
            :per-page="filter.size"
            :total="meta.total"
            @page-change="onPageChange"
            backend-sorting
            default-sort-direction="desc"
            :default-sort="[filter.sortField, filter.sortOrder]"
            @sort="onSort"
            :data="items"
          >
            <b-table-column
              label="S.No."
              width="40"
              numeric
              v-slot="props"
            >{{ meta.from + props.index }}</b-table-column>
            <b-table-column
              field="working_date"
              label="Working Date"
              sortable
              v-slot="props"
            >{{ props.row.working_date }}</b-table-column>
            <b-table-column
              field="department"
              label="Department"
              v-slot="props"
            >{{ props.row.department }}</b-table-column>
            <b-table-column
              field="bus_stand"
              label="Bus Stand"
              v-slot="props"
            >{{ props.row.bus_stand }}</b-table-column>
            <b-table-column
              field="working_seconds"
              label="SXM Duration"
              v-slot="props"
            >{{ props.row.working_seconds | humanizeDuration | titleize }}</b-table-column>
            <b-table-column
              field="rsrtc_run_time"
              label="RSRTC Duration"
              v-slot="props"
            >{{ props.row.rsrtc_run_time | humanizeDuration | titleize }}</b-table-column>
            <b-table-column
              field="ads_sseconds"
              label="Ads Durations (Success)"
              v-slot="props"
            >{{ props.row.ads_sseconds | humanizeDuration | titleize }}</b-table-column>
            <b-table-column
              field="ads_fseconds"
              label="Ads Durations (Failed)"
              v-slot="props"
            >{{ props.row.ads_fseconds | humanizeDuration | titleize  }}</b-table-column>
            <b-table-column
              v-if="isAgentView"
              field="ads_agent_samount"
              label="Agent Amount"
              v-slot="props"
            >{{ props.row.ads_agent_samount }} INR</b-table-column>
            <b-table-column
              v-if="isDistributorView"
              field="ads_distributor_samount"
              label="Distributor Amount"
              v-slot="props"
            >{{ props.row.ads_distributor_samount }} INR</b-table-column>
            <b-table-column
              v-if="isChannelView"
              field="ads_channel_samount"
              label="Channel Amount"
              v-slot="props"
            >{{ props.row.ads_channel_samount }} INR</b-table-column>
            <b-table-column
              field="last_working_time"
              label="Last Working Time"
              sortable
              v-slot="props"
            >{{ props.row.last_working_time }}</b-table-column>
            <b-table-column
              field="status"
              label="Status"
              sortable
              v-slot="props"
            >{{ props.row.status }}</b-table-column>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon
                      icon="emoticon-sad"
                      size="is-large"
                    >
                    </b-icon>
                  </p>
                  <p>Nothing here.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import { DEVICE_SHOW_DETAILS, DEVICE_REPORT_REVENUE, DEVICE_REPORT_AGGREGATE_REVENUE } from '@/store/actions/myActions'

export default {
  name: 'MyDeviceRevenueReport',
  components: { Breadcrumb, AdvanceFilter },
  props: {
    userView: {
      required: true
    },
    deviceId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncDevice'
  },
  data () {
    return {
      pageTitle: 'Device Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-desktop', routeName: 'MyDeviceList', name: 'Manage Devices' },
          { icon: 'fas fa-tablet', routeName: 'MyDeviceShow', params: { deviceId: this.deviceId }, name: 'Device Details' }
        ],
        current: { icon: 'fas fa-chart-pie', name: 'Device Revenue Report' },
        actions: [{ icon: 'fas fa-desktop', routeName: 'MyDeviceList', name: 'Manage Devices' }]
      },
      device: null,
      report: {
        working_seconds: 0,
        rsrtc_run_time: 0,
        ads_sseconds: 0,
        ads_fseconds: 0,
        ads_samount: 0,
        ads_agent_samount: 0,
        ads_distributor_samount: 0,
        ads_channel_samount: 0
      },
      filter: {
        start: null,
        end: null,
        size: 10
      },
      items: [],
      meta: {}
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    ...mapGetters('MyActions', ['isLoading', 'isSuccess', 'isError']),
    deviceStatus () {
      if (this.device !== null) {
        if (this.device.status === 'ENABLE') {
          return 'has-background-success'
        } else if (this.device.status === 'DISABLE') {
          return 'has-background-danger'
        } else if (this.device.status === 'IDEAL') {
          return 'has-background-link'
        }
      }
      return 'has-background-primary'
    },
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    },
    deviceTitle () {
      if (this.device !== null) {
        return `${this.device.name} / (${this.device.uuid})`
      } else {
        return 'Loading Device'
      }
    },
    isAgentView () {
      return this.userView === 'agent'
    },
    isDistributorView () {
      return this.userView === 'distributor'
    },
    isChannelView () {
      return this.userView === 'channel'
    }
  },
  methods: {
    ...mapActions('MyActions', { loadDevice: DEVICE_SHOW_DETAILS, loadReports: DEVICE_REPORT_REVENUE, loadAggregateReports: DEVICE_REPORT_AGGREGATE_REVENUE }),
    loadAsyncDevice () {
      return new Promise((resolve, reject) => {
        this.device = null
        const data = { userView: this.userView, deviceId: this.deviceId }
        this.loadDevice(data)
          .then((response) => {
            this.device = response.data
            resolve()
          })
          .catch((error) => {
            this.$buefy.dialog.alert({
              title: 'Error (Device Loading Failed)',
              message: error.message,
              type: 'is-danger',
              hasIcon: true,
              icon: 'skull',
              iconPack: 'fas',
              onCancel: () => this.$router.replace({ name: 'MyDeviceList' }),
              onConfirm: () => this.$router.replace({ name: 'MyDeviceList' })
            })
            reject(error)
          })
      })
    },
    loadAsyncDeviceReports () {
      return new Promise((resolve, reject) => {
        const fData = { userView: this.userView, deviceId: this.deviceId, filterData: this.filter }
        this.loadReports(fData)
          .then((response) => {
            this.items = response.data
            this.meta = response.meta
            resolve()
          })
          .catch((error) => {
            this.$notify({
              title: 'Report Loading Failed',
              text: error.message,
              type: 'is-danger'
            })
            reject(error)
          })
      })
    },
    loadAsyncDeviceAggregateReports () {
      return new Promise((resolve, reject) => {
        const fData = { userView: this.userView, deviceId: this.deviceId, filterData: this.filter }
        this.loadAggregateReports(fData)
          .then((response) => {
            this.report = {
              ...this.report,
              ...response
            }
            resolve()
          })
          .catch((error) => {
            this.$notify({
              title: 'Report Loading Failed',
              text: error.message,
              type: 'is-danger'
            })
            reject(error)
          })
      })
    },
    onFilter () {
      this.loadAsyncDeviceAggregateReports()
      this.loadAsyncDeviceReports()
    },
    onPageChange (page) {
      this.filter.page = page
      this.loadAsyncDeviceReports()
    },
    onSort (field, order) {
      this.filter.sortField = field
      this.filter.sortOrder = order
      this.loadAsyncDeviceReports()
    }
  },
  created () {
    this.loadAsyncDevice()
      .then(() => {
        this.onFilter()
      })
  }
}
</script>
