<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <advance-filter
      :is-loading="isLoading"
      @onStart="onFilter"
      @onReset="onReset"
    >
      <div class="columns is-multiline">
        <div class="column field is-2">
          <label class="label is-small">Start Date</label>
          <div class="control is-expanded has-icons-left">
            <input
              class="input"
              type="date"
              placeholder="Enter Start Time"
              v-model="filter.start"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-calendar"></i>
            </span>
          </div>
        </div>
        <div class="column field is-2">
          <label class="label is-small">End Date</label>
          <div class="control is-expanded has-icons-left">
            <input
              class="input"
              type="date"
              placeholder="Enter End Time"
              v-model="filter.end"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-calendar"></i>
            </span>
          </div>
        </div>
        <b-field
          class="column is-2"
          label="Agent"
          custom-class="is-small"
        >
          <b-autocomplete
            icon-pack="fas"
            icon="search"
            placeholder="Type agent details"
            field="name"
            :keep-first="true"
            :data="autocomplete.agent.items"
            v-model="autocomplete.agent.value"
            :loading="autocomplete.agent.isLoading"
            @input="loadAgentsSuggestion"
            @select="option => filter.agent_id = option.id"
          >
            <template slot="empty">No agent found</template>
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  {{ props.option.name }}
                  <br>
                  <small>
                    {{ props.option.email }},
                  </small>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
        <b-field
          class="column is-2"
          label="Distributor"
          custom-class="is-small"
        >
          <b-autocomplete
            icon-pack="fas"
            icon="search"
            placeholder="Type distributor details"
            field="name"
            :keep-first="true"
            :data="autocomplete.distributor.items"
            v-model="autocomplete.distributor.value"
            :loading="autocomplete.distributor.isLoading"
            @input="loadDistributorsSuggestion"
            @select="option => filter.distributor_id = option.id"
          >
            <template slot="empty">No distributor found</template>
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  {{ props.option.name }}
                  <br>
                  <small>
                    {{ props.option.email }},
                  </small>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
        <b-field
          class="column is-2"
          label="Channel"
          custom-class="is-small"
        >
          <b-autocomplete
            icon-pack="fas"
            icon="search"
            placeholder="Type channel details"
            field="name"
            :keep-first="true"
            :data="autocomplete.channel.items"
            v-model="autocomplete.channel.value"
            :loading="autocomplete.channel.isLoading"
            @input="loadChannelsSuggestion"
            @select="option => filter.channel_id = option.id"
          >
            <template slot="empty">No channel found</template>
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  {{ props.option.name }}
                  <br>
                  <small>
                    {{ props.option.email }},
                  </small>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
      </div>
    </advance-filter>
    <div class="tile is-ancestor mt-1">
      <div class="tile is-parent">
        <article class="tile is-child notification is-info">
          <inner-loading v-show="isLoading"></inner-loading>
          <p class="is-size-5">{{ report.working_seconds | humanizeDuration | titleize }}</p>
          <p class="is-size-6 has-text-weight-bold">Working Time</p>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child notification is-info">
          <inner-loading v-show="isLoading"></inner-loading>
          <p class="is-size-5">{{ report.rsrtc_run_time | humanizeDuration | titleize }}</p>
          <p class="is-size-6 has-text-weight-bold">RSRTC Run Time</p>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child notification is-info">
          <inner-loading v-show="isLoading"></inner-loading>
          <p class="is-size-5">{{ report.ads_sseconds | humanizeDuration | titleize }}</p>
          <p class="is-size-6 has-text-weight-bold">Ads Time</p>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child notification is-danger">
          <inner-loading v-show="isLoading"></inner-loading>
          <p class="is-size-5">{{ report.ads_fseconds | humanizeDuration | titleize }}</p>
          <p class="is-size-6 has-text-weight-bold">Ads Time</p>
        </article>
      </div>
    </div>
    <div class="card card-section">
      <div class="card-content">
        <b-field
          grouped
          group-multiline
        >
          <b-select
            v-model="filter.size"
            :disabled="!hasItems"
            @input="onFilter"
          >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
            <option value="1000">1000 per page</option>
            <option value="5000">5000 per page</option>
          </b-select>
          <b-button
            @click="downloadExl('exportTableData', 'operation-report')"
            type="is-danger"
            icon-left="fas fa-file-download"
          >Export</b-button>
        </b-field>
        <div id="exportTableData">
          <b-table
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :hoverable="true"
            :mobile-cards="true"
            :loading="isLoading"
            paginated
            backend-pagination
            :per-page="filter.size"
            :total="meta.total"
            @page-change="onPageChange"
            backend-sorting
            default-sort-direction="desc"
            :default-sort="[filter.sortField, filter.sortOrder]"
            @sort="onSort"
            :data="items"
          >
            <b-table-column
              label="S.No."
              width="40"
              numeric
              v-slot="props"
            >{{ meta.from + props.index }}</b-table-column>
            <b-table-column
              field="working_date"
              label="Working Date"
              sortable
              v-slot="props"
            >{{ props.row.working_date }}</b-table-column>
            <b-table-column
              field="department"
              label="Department"
              v-slot="props"
            >{{ props.row.department }}</b-table-column>
            <b-table-column
              field="bus_stand"
              label="Bus Stand"
              v-slot="props"
            >{{ props.row.bus_stand }}</b-table-column>
            <b-table-column
              field="working_seconds"
              label="SXM Duration"
              v-slot="props"
            >{{ props.row.working_seconds | humanizeDuration | titleize }}</b-table-column>
            <b-table-column
              field="rsrtc_run_time"
              label="RSRTC Duration"
              v-slot="props"
            >{{ props.row.rsrtc_run_time | humanizeDuration | titleize }}</b-table-column>
            <b-table-column
              field="ads_sseconds"
              label="Ads Durations (Success)"
              v-slot="props"
            >{{ props.row.ads_sseconds | humanizeDuration | titleize }}</b-table-column>
            <b-table-column
              field="ads_fseconds"
              label="Ads Durations (Failed)"
              v-slot="props"
            >{{ props.row.ads_fseconds | humanizeDuration | titleize  }}</b-table-column>
            <b-table-column
              field="last_working_time"
              label="Last Working Time"
              sortable
              v-slot="props"
            >{{ props.row.last_working_time }}</b-table-column>
            <b-table-column
              field="status"
              label="Status"
              sortable
              v-slot="props"
            >{{ props.row.status }}</b-table-column>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon
                      icon="emoticon-sad"
                      size="is-large"
                    >
                    </b-icon>
                  </p>
                  <p>Nothing here.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import { mapGetters, mapActions } from 'vuex'
import { REPORT_OPERATION, REPORT_AGGREGATE_OPERATION } from '@/store/actions/reportActions'
import { AGENT_LIST, DISTRIBUTOR_LIST, CHANNEL_LIST } from '@/store/actions/userActions'

export default {
  name: 'DeviceShow',
  components: { Breadcrumb, AdvanceFilter },
  data () {
    return {
      pageTitle: 'Report Operation Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' }
        ],
        current: { icon: 'fas fa-chart-pie', name: 'Operation Report' }
      },
      report: {
        working_seconds: 0,
        rsrtc_run_time: 0,
        ads_sseconds: 0,
        ads_fseconds: 0
      },
      filter: {
        start: null,
        end: null,
        size: 10,
        agent_id: null,
        distributer_id: null,
        channel_id: null
      },
      autocomplete: {
        agent: {
          value: '',
          items: [],
          isLoading: false,
          timeout: null
        },
        distributor: {
          value: '',
          items: [],
          isLoading: false,
          timeout: null
        },
        channel: {
          value: '',
          items: [],
          isLoading: false,
          timeout: null
        }
      },
      items: [],
      meta: {}
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    ...mapGetters('Reports', ['isLoading', 'isSuccess', 'isError']),
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    }
  },
  methods: {
    ...mapActions('Reports', { loadReports: REPORT_OPERATION, loadAggregateReports: REPORT_AGGREGATE_OPERATION }),
    ...mapActions('Users', { loadAgents: AGENT_LIST, loadDistributors: DISTRIBUTOR_LIST, loadChannels: CHANNEL_LIST }),
    loadAsyncReports () {
      return new Promise((resolve, reject) => {
        this.loadReports(this.filter)
          .then((response) => {
            this.items = response.data
            this.meta = response.meta
            resolve()
          })
          .catch((error) => {
            this.$notify({
              title: 'Report Loading Failed',
              text: error.message,
              type: 'is-danger'
            })
            reject(error)
          })
      })
    },
    loadAsyncAggregateReports () {
      return new Promise((resolve, reject) => {
        this.loadAggregateReports(this.filter)
          .then((response) => {
            this.report = {
              ...this.report,
              ...response
            }
            resolve()
          })
          .catch((error) => {
            this.$notify({
              title: 'Report Loading Failed',
              text: error.message,
              type: 'is-danger'
            })
            reject(error)
          })
      })
    },
    onFilter () {
      this.loadAsyncAggregateReports()
      this.loadAsyncReports()
    },
    onReset () {
      this.filter = {
        start: null,
        end: null,
        size: 10,
        agent_id: null,
        distributer_id: null,
        channel_id: null
      }
      this.onFilter()
    },
    onPageChange (page) {
      this.filter.page = page
      this.loadAsyncReports()
    },
    onSort (field, order) {
      this.filter.sortField = field
      this.filter.sortOrder = order
      this.loadAsyncReports()
    },
    loadAgentsSuggestion () {
      clearTimeout(this.autocomplete.agent.timeout)
      this.autocomplete.agent.timeout = setTimeout(() => {
        const data = {
          query: this.autocomplete.agent.value
        }
        if (data.query) {
          this.autocomplete.agent.isLoading = true
          this.loadAgents(data)
            .then((response) => {
              this.autocomplete.agent.items = response.data
              this.autocomplete.agent.isLoading = false
            })
            .catch((error) => {
              this.autocomplete.agent.isLoading = false
              this.$notify({
                title: 'Agent Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    },
    loadDistributorsSuggestion () {
      clearTimeout(this.autocomplete.distributor.timeout)
      this.autocomplete.distributor.timeout = setTimeout(() => {
        const data = {
          query: this.autocomplete.distributor.value
        }
        if (data.query) {
          this.autocomplete.distributor.isLoading = true
          this.loadDistributors(data)
            .then((response) => {
              this.autocomplete.distributor.items = response.data
              this.autocomplete.distributor.isLoading = false
            })
            .catch((error) => {
              this.autocomplete.distributor.isLoading = false
              this.$notify({
                title: 'Distributor Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    },
    loadChannelsSuggestion () {
      clearTimeout(this.autocomplete.channel.timeout)
      this.autocomplete.channel.timeout = setTimeout(() => {
        const data = {
          query: this.autocomplete.channel.value
        }
        if (data.query) {
          this.autocomplete.channel.isLoading = true
          this.loadChannels(data)
            .then((response) => {
              this.autocomplete.channel.items = response.data
              this.autocomplete.channel.isLoading = false
            })
            .catch((error) => {
              this.autocomplete.channel.isLoading = false
              this.$notify({
                title: 'Channel Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    }
  },
  created () {
    this.onFilter()
  }
}
</script>
