<template>
  <div class="columns">
    <div class="column">
      <div class="field">
        <label class="label">UUID</label>
        <div class="control">
          <input
            v-model="device.uuid"
            class="input"
            type="text"
            placeholder="Enter Device UUID"
            :class="{'is-danger' : errors.uuid}"
            disabled
          />
        </div>
        <p
          v-if="errors.uuid"
          class="help is-danger"
        >{{ errors.uuid | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Name</label>
        <div class="control">
          <input
            v-model="device.name"
            class="input"
            type="text"
            placeholder="Enter Device Name"
            :class="{'is-danger' : errors.name}"
          />
        </div>
        <p
          v-if="errors.name"
          class="help is-danger"
        >{{ errors.name | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Time Share</label>
        <div class="control">
          <input
            v-model="device.time_share"
            class="input"
            type="number"
            placeholder="Enter SXM Time Share"
            :class="{'is-danger' : errors.time_share}"
            disabled
          />
        </div>
        <p
          v-if="errors.time_share"
          class="help is-danger"
        >{{ errors.time_share | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Footfall</label>
        <div class="control">
          <input
            v-model="device.footfall"
            class="input"
            type="number"
            placeholder="Enter Device Footfall"
            :class="{'is-danger' : errors.footfall}"
            disabled
          />
        </div>
        <p
          v-if="errors.footfall"
          class="help is-danger"
        >{{ errors.footfall | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Address</label>
        <div class="control">
          <textarea
            v-model="device.address"
            class="textarea"
            placeholder="Enter Device address"
            :class="{'is-danger' : errors.address}"
          ></textarea>
        </div>
        <p
          v-if="errors.address"
          class="help is-danger"
        >{{ errors.address | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Latitude</label>
        <div class="control">
          <input
            v-model="device.latitude"
            class="input"
            type="text"
            placeholder="Enter Device Latitude"
            :class="{'is-danger' : errors.latitude}"
          />
        </div>
        <p
          v-if="errors.latitude"
          class="help is-danger"
        >{{ errors.latitude | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Longitude</label>
        <div class="control">
          <input
            v-model="device.longitude"
            class="input"
            type="text"
            placeholder="Enter Device Longitude"
            :class="{'is-danger' : errors.longitude}"
          />
        </div>
        <p
          v-if="errors.longitude"
          class="help is-danger"
        >{{ errors.longitude | implode }}</p>
      </div>
      <div class="field is-deviceed action-buttons">
        <div class="control">
          <button
            type="button"
            :disabled="isFormUploading"
            class="button is-primary is-rounded"
            @click="formSubmit"
            :class="{ 'is-loading': isFormUploading }"
          >Update Device</button>
        </div>
      </div>
    </div>
    <div class="column is-two-fifths">
      <div class="card card-section">
        <header class="card-header">
          <p class="card-header-title has-background-primary has-text-white">
            Ads
          </p>
        </header>
        <div class="card-image">
          <figure class="image is-4by3">
            <img
              v-if="!device.ads"
              src="/img/placeholders/image-placeholder.png"
              alt="Device Thumbnail"
            >
            <img
              v-if="device.ads"
              :src="device.ads.thumbnail"
              alt="Device Ads Thumbnail"
            >
          </figure>
        </div>
        <div class="card-content">
          <div class="file has-name is-fullwidth is-primary">
            <label class="file-label">
              <input
                class="file-input"
                type="file"
                name="adsFile"
                ref="adsFile"
                @change="onAdsChange"
                :disabled="isAdsLoading"
              />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">
                  Choose a file…
                </span>
              </span>
              <span class="file-name">
                {{ adsName }}
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="card card-section">
        <header class="card-header">
          <p class="card-header-title has-background-primary has-text-white">
            Thumbnail
          </p>
        </header>
        <div class="card-image">
          <figure class="image is-4by3">
            <img
              v-if="!device.thumbnails"
              src="/img/placeholders/image-placeholder.png"
              alt="Device Thumbnail"
            >
            <img
              v-if="device.thumbnails"
              :src="device.thumbnails.large"
              alt="Device Thumbnail"
            >
          </figure>
        </div>
        <div class="card-content">
          <div class="file has-name is-fullwidth is-primary">
            <label class="file-label">
              <input
                class="file-input"
                type="file"
                name="thumbnailFile"
                ref="thumbnailFile"
                @change="onThumbnailChange"
                :disabled="isThumbnailLoading"
              />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">
                  Choose a file…
                </span>
              </span>
              <span class="file-name">
                {{ thumbnailName }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { DEVICE_UPDATE, DEVICE_THUMBNAIL, DEVICE_ADS } from '@/store/actions/myActions'

export default {
  name: 'MyDeviceGeneral',
  props: {
    userView: {
      required: true
    },
    device: {
      required: true
    }
  },
  data () {
    return {
      adsName: null,
      thumbnailName: null,
      status_options: ['IDEAL', 'ENABLE', 'DISABLE'],
      errors: {},
      deviceUpdateStatus: '',
      deviceThumbnailUpdateStatus: '',
      adsStatus: ''
    }
  },
  computed: {
    isFormUploading () {
      return this.deviceUpdateStatus === 'DEVICE_UPDATE_UPLOADING'
    },
    isThumbnailLoading () {
      return this.deviceThumbnailUpdateStatus === 'DEVICE_THUMBNAIL_UPLOADING'
    },
    isAdsLoading () {
      return this.adsStatus === 'ADS_UPLOADING'
    }
  },
  methods: {
    ...mapActions('MyActions', { updateDevice: DEVICE_UPDATE, uploadThumbnail: DEVICE_THUMBNAIL, uploadAds: DEVICE_ADS }),
    onThumbnailChange () {
      const thumbnailFiles = this.$refs.thumbnailFile
      if (!thumbnailFiles || !thumbnailFiles.files.length) {
        return
      }
      this.thumbnailName = thumbnailFiles.files[0].name
      this.createImage(thumbnailFiles.files[0])
      this.uploadDeviceThumbnail(thumbnailFiles.files[0])
    },
    createImage (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.device.thumbnails = { large: e.target.result }
      }
      reader.readAsDataURL(file)
    },
    uploadDeviceThumbnail (thumbnail) {
      const thumbnailFormData = new FormData()
      thumbnailFormData.set('thumbnail', thumbnail)
      const data = {
        userView: this.userView,
        deviceId: this.device.id,
        formData: thumbnailFormData
      }
      this.deviceThumbnailUpdateStatus = 'DEVICE_THUMBNAIL_UPLOADING'
      this.uploadThumbnail(data)
        .then((response) => {
          this.$notify({
            title: 'Device Thumbnail',
            text: response.message,
            type: 'is-success'
          })
          this.deviceThumbnailUpdateStatus = 'DEVICE_THUMBNAIL_UPLOADED'
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Device Thumbnail Form',
            text: error.message,
            type: 'is-danger'
          })
          this.deviceThumbnailUpdateStatus = 'DEVICE_THUMBNAIL_ERROR'
        })
    },
    onAdsChange () {
      const adsFiles = this.$refs.adsFile
      if (!adsFiles || !adsFiles.files.length) {
        return
      }
      this.adsName = adsFiles.files[0].name
      this.uploadDeviceAds(adsFiles.files[0])
    },
    uploadDeviceAds (ads) {
      const adsFormData = new FormData()
      adsFormData.set('ads', ads)
      const data = {
        userView: this.userView,
        deviceId: this.device.id,
        formData: adsFormData
      }
      this.adsStatus = 'ADS_UPLOADING'
      this.uploadAds(data)
        .then((response) => {
          this.device.ads = response.data
          this.$notify({
            title: 'Device Ads',
            text: response.message,
            type: 'is-success'
          })
          this.adsStatus = 'ADS_UPLOADED'
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Device Thumbnail Form',
            text: error.message,
            type: 'is-danger'
          })
          this.adsStatus = 'ADS_ERROR'
        })
    },
    formSubmit () {
      this.errors = {}
      const data = {
        userView: this.userView,
        deviceId: this.device.id,
        formData: this.device
      }
      this.updateDevice(data)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.uuid}) Device is updated successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Device',
            onAction: () => {
              this.$router.push({
                name: 'MyDeviceList'
              })
            }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Device Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
