import debounce from 'lodash/debounce'

export default {
  props: ['value', 'propertyName'],
  data () {
    return {
      field: 'name',
      type: 'agent',
      result: null,
      items: [],
      queryStr: null,
      isLoading: false,
      timeoutInterval: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal) {
        this.selectFromProps(newVal)
      }
    }
  },
  methods: {
    selectFromProps (newVal) {
      if (newVal) {
        this.queryStr = newVal[this.field]
      } else {
        this.queryStr = null
      }
    },
    loadUsers (data) {
      return new Promise((resolve) => {
        resolve({ data: [] })
      })
    },
    getAsyncData: debounce(function (queryStr) {
      this.loadUsersSuggestion(queryStr)
    }, 500),
    loadUsersSuggestion () {
      clearTimeout(this.timeoutInterval)
      this.timeoutInterval = setTimeout(() => {
        const data = {
          query: this.queryStr
        }
        if (data.query) {
          this.isLoading = true
          this.loadUsers(data)
            .then((response) => {
              this.items = response.data
              this.isLoading = false
            })
            .catch((error) => {
              this.isLoading = false
              this.$notify({
                title: 'Agent Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    },
    onUseSelection (option) {
      this.result = null
      if (option !== undefined && option !== null) {
        if (this.propertyName !== undefined && this.propertyName !== null) {
          this.result = option[this.propertyName]
        } else {
          this.result = option
        }
      }
      this.$emit('input', this.result)
    }
  }
}
