<template>
  <div>
    <!-- <loading v-show="isLoading"></loading> -->
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <advance-filter
      :is-loading="isLoading"
      @onStart="onFilter"
      @onReset="reset"
    >
      <div class="columns is-multiline">
        <div class="column field is-3">
          <label class="label is-small">Query</label>
          <p class="control has-icons-left">
            <input
              class="input"
              type="text"
              placeholder="search"
              v-model="filter.query"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-search"></i>
            </span>
          </p>
        </div>
        <location-tree
          class="column is-3"
          v-model="filter.locations"
        />
        <category-tree
          class="column is-3"
          v-model="filter.places"
        />
        <tag-tree
          class="column is-3"
          v-model="filter.tags"
        />
        <agent-search-input
          class="column is-3"
          v-model="filter.agent"
          property-name="id"
        />
        <div class="column field is-3">
          <label class="label is-small">Campaign Types</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              multiple
              v-model="filter.types"
              :options="type_options"
              placeholder="Pick types for filter"
              class="is-small"
            >
            </v-select>
          </div>
        </div>
        <div class="column field is-3">
          <label class="label is-small">Campaign Status</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              multiple
              v-model="filter.statuses"
              :options="status_options"
              placeholder="Pick statuses for filter"
              class="is-small"
            >
            </v-select>
          </div>
        </div>
      </div>
    </advance-filter>

    <div class="card card-section">
      <div class="card-content">
        <b-field
          grouped
          group-multiline
        >
          <b-select
            v-model="filter.size"
            :disabled="!hasItems"
            @input="onFilter"
          >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </b-select>
        </b-field>
        <b-table
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :mobile-cards="true"
          :loading="isLoading"
          paginated
          backend-pagination
          :per-page="filter.size"
          :total="meta.total"
          @page-change="onPageChange"
          backend-sorting
          default-sort-direction="desc"
          :default-sort="[filter.sortField, filter.sortOrder]"
          @sort="onSort"
          :data="items"
        >
          <b-table-column
            label=""
            width="100"
            v-slot="props"
          >
            <figure class="image is-128x128">
              <img
                v-if="props.row.thumbnails"
                :src="props.row.thumbnails.small"
              />
              <img
                v-else
                src="/img/placeholders/image-placeholder.png"
              />
            </figure>
          </b-table-column>
          <b-table-column
            label="S.No."
            width="40"
            numeric
            v-slot="props"
          >
            {{ meta.from + props.index }}
          </b-table-column>
          <b-table-column
            field="name"
            label="Name"
            sortable
            v-slot="props"
          >
            <div>{{ props.row.name }}</div>
            <div>Type: <small><strong>{{ props.row.type }}</strong></small></div>
          </b-table-column>
          <b-table-column
            field="agent_id"
            label="Agent"
            v-slot="props"
          >
            <div v-if="props.row.agent">
              <div>{{ props.row.agent.name }}</div>
              <div><strong><small>{{ props.row.agent.email }}</small></strong></div>
            </div>
          </b-table-column>
          <b-table-column
            field="status"
            label="Status"
            sortable
            v-slot="props"
          >
            {{ props.row.status }}
          </b-table-column>
          <b-table-column
            label=""
            v-slot="props"
          >
            <b-dropdown position="is-bottom-left">
              <button
                class="button is-primary"
                slot="trigger"
              >
                <span>Actions</span>
                <b-icon
                  icon="chevron-down"
                  size="is-small"
                ></b-icon>
              </button>
              <b-dropdown-item
                has-link
                v-if="hasAnyScope('CAMPAIGN-START') && props.row.status === 'ENABLE'"
              >
                <router-link :to="{ name: 'CampaignStart', params: { campaignId: props.row.id }}">
                  <span class="icon">
                    <i class="fas fa-play"></i>
                  </span>
                  <span>Apply</span>
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item
                has-link
                v-if="hasAnyScope('CAMPAIGN-SHOW')"
              >
                <router-link :to="{ name: 'CampaignShow', params: { campaignId: props.row.id }}">
                  <span class="icon">
                    <i class="fas fa-eye"></i>
                  </span>
                  <span>DETAILS</span>
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item
                has-link
                v-if="hasAnyScope('CAMPAIGN-UPDATE')"
              >
                <router-link :to="{ name: 'CampaignEdit', params: { campaignId: props.row.id }}">
                  <span class="icon">
                    <i class="fas fa-edit"></i>
                  </span>
                  <span>EDIT</span>
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item
                has-link
                v-if="hasAnyScope('CAMPAIGN-DELETE')"
              >
                <a @click.prevent="confirmDeleteCampaign(props.row.id)">
                  <span class="icon">
                    <i class="fas fa-trash"></i>
                  </span>
                  <span>DELETE</span>
                </a>
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="emoticon-sad"
                    size="is-large"
                  > </b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import { mapGetters, mapActions } from 'vuex'
import { LocationTree, CategoryTree, TagTree } from '@/lib/tree'
import { AgentSearchInput } from '@/lib/autocompletes'
import { CAMPAIGN_LIST, CAMPAIGN_DELETE } from '@/store/actions/campaignActions'
import { AGENT_LIST } from '@/store/actions/userActions'

export default {
  name: 'CampaignList',
  components: {
    Breadcrumb,
    AdvanceFilter,
    LocationTree,
    CategoryTree,
    TagTree,
    AgentSearchInput
  },
  data () {
    return {
      pageTitle: 'Manage Campaigns',
      breadcrumb: {
        list: [{ icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' }],
        current: { icon: 'fas fa-desktop', name: 'Campaigns' },
        actions: [{ icon: 'fas fa-plus', routeName: 'CampaignCreate', name: 'New Campaign' }]
      },
      status_options: ['IDEAL', 'ENABLE', 'DISABLE'],
      live_options: ['YES', 'NO'],
      type_options: ['SINGLE-SCREEN-KIOSK', 'SCREEN-WITH-RIGHT-SIDE-KIOSK', 'INTERACTIVE-KIOSK', 'CUSTOM-KIOSK'],
      filter: {},
      items: [],
      meta: {},
      autocomplete: {
        agent: {
          value: '',
          items: [],
          isLoading: false,
          timeout: null
        }
      }
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    ...mapGetters('Campaigns', ['isLoading', 'isSuccess', 'isError']),
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    }
  },
  methods: {
    ...mapActions('Campaigns', { loadList: CAMPAIGN_LIST, sendDeleteCampaignRequest: CAMPAIGN_DELETE }),
    ...mapActions('Users', { loadAgents: AGENT_LIST }),
    onFilter () {
      this.loadAsyncData()
    },
    onPageChange (page) {
      this.filter.page = page
      this.loadAsyncData()
    },
    onSort (field, order) {
      this.filter.sortField = field
      this.filter.sortOrder = order
      this.loadAsyncData()
    },
    confirmDeleteCampaign (campaignId) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Campaign',
        message: 'Are you sure you want to <b>delete</b> this campaign? This action cannot be undone.',
        confirmText: 'Delete Campaign',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deleteCampaign(campaignId)
      })
    },
    deleteCampaign (campaignId) {
      this.sendDeleteCampaignRequest(campaignId)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.onFilter()
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncData () {
      if (!this.isLoading) {
        const data = this.filter
        this.loadList(data)
          .then((response) => {
            this.items = response.data
            this.meta = response.meta
          })
          .catch((error) => {
            this.$notify({
              title: 'Campaign Loading Failed',
              text: error.message,
              type: 'is-danger'
            })
          })
      }
    },
    loadAgentsSuggestion () {
      clearTimeout(this.autocomplete.agent.timeout)
      this.autocomplete.agent.timeout = setTimeout(() => {
        const data = {
          query: this.autocomplete.agent.value
        }
        if (data.query) {
          this.autocomplete.agent.isLoading = true
          this.loadAgents(data)
            .then((response) => {
              this.autocomplete.agent.items = response.data
              this.autocomplete.agent.isLoading = false
            })
            .catch((error) => {
              this.autocomplete.agent.isLoading = false
              this.$notify({
                title: 'Agent Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    },
    reset () {
      this.filter = {
        locations: [],
        places: [],
        tags: [],
        agent: null,
        statuses: [],
        query: '',
        page: 1,
        size: 10,
        sortField: 'updated_at',
        sortOrder: 'desc',
        types: null,
        live: null
      }
      this.items = []
      this.meta = {
        total: 0
      }
      this.onFilter()
    }
  },
  mounted () {
    this.reset()
  }
}
</script>
