<template>
  <div>
    <loading v-show="isAdsLoading"></loading>
    <loading v-show="apiTransactionProcess"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="card card-section"
      v-if="advertisement"
    >
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                <input
                  v-model="advertisement.name"
                  class="input"
                  type="text"
                  placeholder="Enter Advertisement Name"
                  :class="{'is-danger' : errors.name}"
                />
              </div>
              <p
                v-if="errors.name"
                class="help is-danger"
              >{{ errors.name | implode }}</p>
            </div>
            <div class="field">
              <label class="label">Ads Duration (Secs)</label>
              <div class="control">
                <input
                  v-model="advertisement.duration"
                  class="input"
                  type="text"
                  placeholder="Enter Advertisement Duration"
                  :class="{'is-danger' : errors.duration}"
                />
              </div>
              <p
                v-if="errors.duration"
                class="help is-danger"
              >{{ errors.duration | implode }}</p>
            </div>
            <div class="field">
              <label class="label">Desc</label>
              <div class="control">
                <textarea
                  v-model="advertisement.desc"
                  class="textarea"
                  placeholder="Enter Advertisement Desc"
                  :class="{'is-danger' : errors.desc}"
                ></textarea>
              </div>
              <p
                v-if="errors.desc"
                class="help is-danger"
              >{{ errors.desc | implode }}</p>
            </div>
            <div class="field">
              <label class="label">Product Tags</label>
              <div class="control">
                <treeselect
                  placeholder="Select product tags for the advertisement..."
                  value-consists-of="ALL_WITH_INDETERMINATE"
                  v-model="advertisementTags"
                  :multiple="true"
                  :options="tagTree"
                  :normalizer="normalizer"
                ></treeselect>
              </div>
            </div>
            <div class="field is-grouped action-buttons">
              <div class="control">
                <button
                  type="button"
                  :disabled="apiTransactionProcess"
                  class="button is-primary is-rounded"
                  @click="formSubmit"
                  :class="{ 'is-loading': apiTransactionProcess }"
                >Update Advertisement</button>
              </div>
            </div>
          </div>
          <div class="column is-two-fifths">
            <div class="card card-section">
              <header class="card-header">
                <p class="card-header-title has-background-primary has-text-white">
                  Ads
                </p>
              </header>
              <div class="card-image">
                <b-loading
                  :is-full-page="false"
                  v-model="isVideoUploading"
                  :can-cancel="false"
                >
                  <b-icon
                    pack="fas"
                    icon="sync-alt"
                    size="is-large"
                    custom-class="fa-spin"
                  ></b-icon>
                </b-loading>
                <figure
                  v-if="!advertisement.ads"
                  class="image is-4by3"
                >
                  <img
                    src="/img/placeholders/image-placeholder.png"
                    alt="Advertisement Video"
                  >
                </figure>
                <a
                  v-if="this.advertisement.ads"
                  :href="advertisement.ads.path"
                  target="_blank"
                >
                  <figure class="image is-4by3">
                    <img
                      v-if="advertisement.ads"
                      :src="advertisement.ads.thumbnail"
                      alt="Advertisement Ads Video"
                    >
                  </figure>
                </a>
              </div>
              <div class="card-content">
                <div class="file has-name is-fullwidth is-primary">
                  <label class="file-label">
                    <input
                      class="file-input"
                      type="file"
                      name="adsFile"
                      ref="adsFile"
                      @change="onAdsChange"
                      :disabled="isAdsLoading"
                    />
                    <span class="file-cta">
                      <span class="file-icon">
                        <i class="fas fa-upload"></i>
                      </span>
                      <span class="file-label">
                        Choose a file…
                      </span>
                    </span>
                    <span class="file-name">
                      {{ adsName }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="card card-section">
              <header class="card-header">
                <p class="card-header-title has-background-primary has-text-white">
                  Thumbnail
                </p>
              </header>
              <div class="card-image">
                <figure class="image is-4by3">
                  <img
                    v-if="!advertisement.thumbnails"
                    src="/img/placeholders/image-placeholder.png"
                    alt="Advertisement Thumbnail"
                  >
                  <img
                    v-if="advertisement.thumbnails"
                    :src="advertisement.thumbnails.large"
                    alt="Advertisement Thumbnail"
                  >
                </figure>
              </div>
              <div class="card-content">
                <div class="file has-name is-fullwidth is-primary">
                  <label class="file-label">
                    <input
                      class="file-input"
                      type="file"
                      name="thumbnailFile"
                      ref="thumbnailFile"
                      @change="onThumbnailChange"
                      :disabled="isAdsLoading"
                    />
                    <span class="file-cta">
                      <span class="file-icon">
                        <i class="fas fa-upload"></i>
                      </span>
                      <span class="file-label">
                        Choose a file…
                      </span>
                    </span>
                    <span class="file-name">
                      {{ thumbnailName }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { ADVERTISEMENT_SHOW, ADVERTISEMENT_UPDATE, ADVERTISEMENT_THUMBNAIL, ADVERTISEMENT_ADS } from '@/store/actions/myActions'
import { TAG_TREE_SHOW } from '@/store/actions/tagActions'

export default {
  name: 'MyAdvertisementEdit',
  components: {
    Breadcrumb, Treeselect
  },
  props: {
    advertisementId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncAdvertisement'
  },
  data () {
    return {
      pageTitle: 'Edit My Ads Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fab fa-adversal', routeName: 'MyAdvertisementList', name: 'My Ads Listing' }
        ],
        current: { icon: 'fas fa-edit', name: 'Edit My Ads' },
        actions: [
          { icon: 'fab fa-adversal', routeName: 'MyAdvertisementList', name: 'My Ads Listing' },
          {
            icon: 'fas fa-eye', routeName: 'MyAdvertisementShow', params: { advertisementId: this.advertisementId }, name: 'Display Ads'
          }
        ]
      },
      advertisement: null,
      errors: {},
      tagTree: [],
      advertisementTags: [],
      adsStatus: '',
      LOADING_ADS: 'LOADING_ADS',
      SUCCESS_ADS: 'SUCCESS_ADS',
      ERROR_ADS: 'ERROR_ADS',
      adsName: null,
      thumbnailName: null,
      apiTransactionProcess: false,
      isVideoUploading: false
    }
  },
  computed: {
    isAdsLoading () {
      return this.adsStatus === this.LOADING_ADS
    },
    isAdsSuccess () {
      return this.adsStatus === this.SUCCESS_ADS
    },
    isAdsError () {
      return this.adsStatus === this.ERROR_ADS
    }
  },
  methods: {
    ...mapActions('MyActions', {
      loadAdvertisement: ADVERTISEMENT_SHOW,
      updateAdvertisement: ADVERTISEMENT_UPDATE,
      uploadThumbnail: ADVERTISEMENT_THUMBNAIL,
      uploadAds: ADVERTISEMENT_ADS
    }),
    ...mapActions('Tags', { loadTagTree: TAG_TREE_SHOW }),
    normalizer (node) {
      return {
        id: node.id,
        label: node.name,
        children: node.children
      }
    },
    onThumbnailChange () {
      const thumbnailFiles = this.$refs.thumbnailFile
      if (!thumbnailFiles || !thumbnailFiles.files.length) {
        return
      }
      this.thumbnailName = thumbnailFiles.files[0].name
      this.createImage(thumbnailFiles.files[0])
      this.uploadAdvertisementThumbnail(thumbnailFiles.files[0])
    },
    createImage (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.advertisement.thumbnails = { large: e.target.result }
      }
      reader.readAsDataURL(file)
    },
    uploadAdvertisementThumbnail (thumbnail) {
      this.adsStatus = this.LOADING_ADS
      const thumbnailFormData = new FormData()
      thumbnailFormData.set('thumbnail', thumbnail)
      const data = {
        advertisementId: this.advertisement.id,
        formData: thumbnailFormData
      }
      this.uploadThumbnail(data)
        .then((response) => {
          this.adsStatus = this.SUCCESS_ADS
          this.$notify({
            title: 'Advertisement Thumbnail',
            text: response.message,
            type: 'is-success'
          })
        })
        .catch((error) => {
          this.adsStatus = this.ERROR_ADS
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Advertisement Thumbnail Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onAdsChange () {
      const adsFiles = this.$refs.adsFile
      if (!adsFiles || !adsFiles.files.length) {
        return
      }
      this.adsName = adsFiles.files[0].name
      this.uploadAdvertisementAds(adsFiles.files[0])
    },
    uploadAdvertisementAds (ads) {
      this.isVideoUploading = true
      this.adsStatus = this.LOADING_ADS
      const adsFormData = new FormData()
      adsFormData.set('ads', ads)
      const data = {
        advertisementId: this.advertisement.id,
        formData: adsFormData
      }
      this.uploadAds(data)
        .then((response) => {
          this.advertisement.ads = response.data
          this.adsStatus = this.SUCCESS_ADS
          this.$notify({
            title: 'Advertisement Ads',
            text: response.message,
            type: 'is-success'
          })
        })
        .catch((error) => {
          this.adsStatus = this.ERROR_ADS
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Advertisement Video Form',
            text: error.message,
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isVideoUploading = false
        })
    },
    formSubmit () {
      this.errors = {}
      this.advertisement.tags = this.advertisementTags
      const data = {
        advertisementId: this.advertisement.id,
        formData: this.advertisement
      }
      this.updateAdvertisement(data)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.name}) Advertisement is updated successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Advertisement',
            onAction: () => {
              this.$router.push({
                name: 'MyAdvertisementList'
              })
            }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Advertisement Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncTagTree () {
      this.loadTagTree()
        .then((response) => {
          this.tagTree = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Tag Tree Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncAdvertisement () {
      this.adsStatus = this.LOADING_ADS
      this.advertisement = null
      this.loadAdvertisement(this.advertisementId)
        .then((response) => {
          this.adsStatus = this.SUCCESS_ADS
          this.advertisement = {
            id: response.data.id,
            name: response.data.name,
            desc: response.data.desc,
            duration: response.data.duration,
            ads: response.data.ads,
            thumbnails: response.data.thumbnails,
            tags: response.data.tags
          }
          this.advertisementTags = this.advertisement.tags.map((tag) => tag.id)
        })
        .catch((error) => {
          this.adsStatus = this.ERROR_ADS
          this.$buefy.dialog.alert({
            title: 'Error (Advertisement Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'MyAdvertisementList' }),
            onConfirm: () => this.$router.replace({ name: 'MyAdvertisementList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncAdvertisement()
    this.loadAsyncTagTree()
  }
}
</script>
