<template>
  <div>
    <!-- <loading v-show="isLoading"></loading> -->
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <advance-filter
      :is-loading="isLoading"
      @onStart="onFilter"
    >
      <div class="columns is-multiline">
        <div class="column field is-4">
          <label class="label is-small">Start At</label>
          <div class="field has-addons">
            <div class="control">
              <b-datepicker
                v-model="start.date"
                icon="calendar-alt"
                :readonly="false"
                placeholder="Select Date..."
              ></b-datepicker>
            </div>
            <div class="control">
              <b-timepicker
                v-model="start.time"
                icon="clock"
                :readonly="false"
                placeholder="Select time..."
              ></b-timepicker>
            </div>
          </div>
        </div>
        <div class="column field is-4">
          <label class="label is-small">End At</label>
          <div class="field has-addons">
            <div class="control">
              <b-datepicker
                v-model="end.date"
                icon="calendar-alt"
                :readonly="false"
                placeholder="Select Date..."
              ></b-datepicker>
            </div>
            <div class="control">
              <b-timepicker
                v-model="end.time"
                icon="clock"
                :readonly="false"
                placeholder="Select time..."
              ></b-timepicker>
            </div>
          </div>
        </div>
      </div>
    </advance-filter>

    <div class="card card-section">
      <div class="card-content">
        <b-field
          grouped
          group-multiline
        >
          <b-select
            v-model="filter.size"
            :disabled="!hasItems"
            @input="onFilter"
          >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </b-select>
        </b-field>
        <b-table
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :mobile-cards="true"
          :loading="isLoading"
          paginated
          backend-pagination
          :per-page="filter.size"
          :total="meta.total"
          @page-change="onPageChange"
          backend-sorting
          default-sort-direction="desc"
          :default-sort="[filter.sortField, filter.sortOrder]"
          @sort="onSort"
          :data="items"
        >
          <b-table-column
            label="S.No."
            width="40"
            numeric
            v-slot="props"
          >
            {{ meta.from + props.index }}
          </b-table-column>
          <b-table-column
            field="website_name"
            label="Website Name"
            v-slot="props"
          >
            {{ props.row.website_name }}
          </b-table-column>
          <b-table-column
            field="website_url"
            label="Website URL"
            v-slot="props"
          >
            {{ props.row.website_url }}
          </b-table-column>
          <b-table-column
            field="created_at"
            label="Visited At"
            sortable
            v-slot="props"
          >
            {{ props.row.created_at }}
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="emoticon-sad"
                    size="is-large"
                  > </b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import { mapActions } from 'vuex'
import { DEVICE_WEBSITE_USER_LOG_LIST } from '@/store/actions/deviceActions'

export default {
  name: 'DeviceWebsiteUserLogs',
  components: {
    Breadcrumb,
    AdvanceFilter
  },
  props: {
    deviceId: {
      required: true
    }
  },
  data () {
    return {
      pageTitle: 'Device Website User Log List',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-desktop', routeName: 'DeviceList', name: 'Devices' },
          {
            icon: 'fas fa-tablet', routeName: 'DeviceShow', params: { deviceId: this.deviceId }, name: 'Device Details'
          }
        ],
        current: { icon: 'fas fa-running', name: 'Website User Log List' }
      },
      filter: {},
      items: [],
      meta: {},
      actionStatus: '',
      start: {
        date: null,
        time: null
      },
      end: {
        date: null,
        time: null
      }
    }
  },
  computed: {
    getDeviceId () {
      return this.deviceId
    },
    isLoading () {
      return this.actionStatus === 'LOADING'
    },
    isSuccess () {
      return this.actionStatus === 'SUCCESS'
    },
    isError () {
      return this.actionStatus === 'ERROR'
    },
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    }
  },
  methods: {
    ...mapActions('Devices', { loadList: DEVICE_WEBSITE_USER_LOG_LIST }),
    onFilter () {
      if (!this.isLoading) {
        this.loadAsyncData()
      }
    },
    onPageChange (page) {
      if (!this.isLoading) {
        this.filter.page = page
        this.loadAsyncData()
      }
    },
    onSort (field, order) {
      if (!this.isLoading) {
        this.filter.sortField = field
        this.filter.sortOrder = order
        this.loadAsyncData()
      }
    },
    getDate (type) {
      let tempDate = null
      let tempTime = null
      if (type === 'start') {
        if (this.start.date !== undefined && this.start.date !== null) {
          tempDate = moment(this.start.date).format('YYYY-MM-DD')
        } else if (this.start.time !== undefined && this.start.time !== null) {
          tempDate = moment(new Date()).format('YYYY-MM-DD')
        }
        if (this.start.time !== undefined && this.start.time !== null) {
          tempTime = moment(this.start.time).format('HH:mm:ss')
        } else if (this.start.date !== undefined && this.start.date !== null) {
          tempTime = '00:00:00'
        }
      } else {
        if (this.end.date !== undefined && this.end.date !== null) {
          tempDate = moment(this.end.date).format('YYYY-MM-DD')
        } else if (this.end.time !== undefined && this.end.time !== null) {
          tempDate = moment(new Date()).format('YYYY-MM-DD')
        }
        if (this.end.time !== undefined && this.end.time !== null) {
          tempTime = moment(this.end.time).format('HH:mm:ss')
        } else if (this.end.date !== undefined && this.end.date !== null) {
          tempTime = '23:59:59'
        }
      }
      let result = null
      if (tempDate !== null) {
        result = `${tempDate} ${tempTime}`
      }
      return result
    },
    loadAsyncData () {
      this.filter.start_date = this.getDate('start')
      this.filter.end_date = this.getDate('end')
      const data = {
        deviceId: this.deviceId,
        formData: this.filter
      }
      this.actionStatus = 'LOADING'
      this.loadList(data)
        .then((response) => {
          this.actionStatus = 'SUCCESS'
          this.items = response.data
          this.meta = response.meta
        })
        .catch((error) => {
          this.actionStatus = 'ERROR'
          this.$notify({
            title: 'Device Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    reset () {
      this.filter = {
        start_date: null,
        end_date: null,
        page: 1,
        size: 10,
        sortField: 'updated_at',
        sortOrder: 'desc'
      }
      this.start = {
        date: null,
        time: null
      }
      this.end = {
        date: null,
        time: null
      }
      this.items = []
      this.meta = {
        total: 0
      }
      this.onFilter()
    }
  },
  created () {
    this.reset()
  }
}
</script>
