<template>
  <div class="field">
    <label class="label is-small">Duration</label>
    <div class="control is-expanded has-icons-left">
      <v-select
        @input="onChanged"
        v-model="duration"
        :options="durations"
        placeholder="Pick duration for filter"
        class="is-small"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DurationPicker',
  props: ['value'],
  data () {
    return {
      duration: null,
      durations: ['Today', 'Yesterday', 'Last 7 Days', 'Current Month', 'Previous Month', 'Current Year', 'Previous Year', 'Custom Date Range']
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal) {
        this.duration = newVal
        this.onChanged(newVal)
      }
    }
  },

  methods: {
    onChanged (val) {
      let customTime = false
      let sTime = null
      let eTime = null
      if (val === 'Today') {
        sTime = moment().startOf('day')
        eTime = moment().endOf('day')
      } else if (val === 'Yesterday') {
        sTime = moment().subtract(1, 'days').startOf('day')
        eTime = moment().subtract(1, 'days').endOf('day')
      } else if (val === 'Last 7 Days') {
        sTime = moment().subtract(7, 'days').startOf('day')
        eTime = moment().endOf('day')
      } else if (val === 'Current Month') {
        sTime = moment().startOf('month')
        eTime = moment().endOf('month')
      } else if (val === 'Previous Month') {
        sTime = moment().subtract(1, 'months').startOf('month')
        eTime = moment().subtract(1, 'months').endOf('month')
      } else if (val === 'Current Year') {
        sTime = moment().startOf('year')
        eTime = moment().endOf('year')
      } else if (val === 'Previous Year') {
        sTime = moment().subtract(1, 'years').startOf('year')
        eTime = moment().subtract(1, 'years').endOf('year')
      } else if (val === 'Custom Date Range') {
        customTime = true
      } else {
        sTime = null
        eTime = null
      }
      if (sTime !== null) {
        sTime = sTime.toDate()
      }
      if (eTime !== null) {
        eTime = eTime.toDate()
      }
      this.$emit('input', val)
      this.$emit('onDurationChanged', {
        customTime: customTime,
        sTime: sTime,
        eTime: eTime
      })
    }
  }
}
</script>
