<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="page"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-3">{{ page.title }}</p>
                    <figure
                      v-if="page.thumbnails"
                      class="image is-pulled-right is-placed-right"
                    >
                      <img
                        :src="page.thumbnails.large"
                        alt="Page Thumbnail"
                      />
                      <div
                        class="is-size-7 has-text-justified has-text-weight-semibold"
                        v-if="page.thumbnail_caption"
                      >
                        Caption: {{ page.thumbnail_caption }}
                      </div>
                    </figure>
                    <div
                      class="has-text-justified"
                      v-html="page.body"
                    ></div>
                  </div>
                </div>
                <div class="content">
                  <table class="table is-striped">
                    <tbody>
                      <tr>
                        <th>Status</th>
                        <td>{{ page.status }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="card">
                    <div class="card-content">
                      <div class="field is-grouped">
                        <p class="control">
                          <router-link
                            :to="{ name: 'PageList'}"
                            class="button is-primary"
                          >
                            <span class="icon">
                              <i class="fas fa-th-list"></i>
                            </span>
                            <span>LIST</span>
                          </router-link>
                        </p>
                        <p class="control">
                          <router-link
                            :to="{ name: 'PageEdit', params: { pageId: page.id }}"
                            class="button is-warning"
                          >
                            <span class="icon">
                              <i class="fas fa-edit"></i>
                            </span>
                            <span>EDIT</span>
                          </router-link>
                        </p>
                        <p class="control">
                          <a
                            class="button is-danger"
                            @click.prevent="confirmDeletePage"
                          >
                            <span class="icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span>DELETE</span>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { PAGE_DETAILS, PAGE_DELETE } from '@/store/actions/pageActions'

export default {
  name: 'PageShow',
  components: { Breadcrumb },
  props: {
    pageId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncPage'
  },
  data () {
    return {
      pageTitle: 'Page Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-file-alt', routeName: 'PageList', name: 'Manage Pages' }
        ],
        current: { icon: 'fas fa-eye', name: 'Page Details' },
        actions: [{ icon: 'fas fa-file-alt', routeName: 'PageList', name: 'Manage Pages' }]
      },
      page: null
    }
  },
  computed: {
    ...mapGetters('Pages', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Pages', { loadPage: PAGE_DETAILS, sendDeletePageRequest: PAGE_DELETE }),
    confirmDeletePage () {
      this.$buefy.dialog.confirm({
        title: 'Deleting Page',
        message: 'Are you sure you want to <b>delete</b> this page? This action cannot be undone.',
        confirmText: 'Delete Page',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deletePage()
      })
    },
    deletePage () {
      this.sendDeletePageRequest(this.page.id)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.$router.replace({ name: 'PageList' })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncPage () {
      this.page = null
      this.loadPage(this.pageId)
        .then((response) => {
          this.page = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Page Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'PageList' }),
            onConfirm: () => this.$router.replace({ name: 'PageList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncPage()
  }
}
</script>
