<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="nav"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ nav.name }}</p>
                  </div>
                </div>
                <div class="content">
                  <table class="table is-striped">
                    <tbody>
                      <tr>
                        <th>Icon</th>
                        <td><i
                            v-if="nav.icon"
                            :class="nav.icon"
                          ></i></td>
                      </tr>
                      <tr>
                        <th>Link</th>
                        <td>{{ nav.link }}</td>
                      </tr>
                      <tr>
                        <th>Target</th>
                        <td>{{ nav.target }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="field is-grouped">
                    <p class="control">
                      <router-link
                        :to="{ name: 'NavList'}"
                        class="button is-primary"
                      >
                        <span class="icon">
                          <i class="fas fa-th-list"></i>
                        </span>
                        <span>LIST</span>
                      </router-link>
                    </p>
                    <p class="control">
                      <router-link
                        :to="{ name: 'NavEdit', params: { navId: nav.id }}"
                        class="button is-warning"
                      >
                        <span class="icon">
                          <i class="fas fa-edit"></i>
                        </span>
                        <span>EDIT</span>
                      </router-link>
                    </p>
                    <p class="control">
                      <a
                        class="button is-danger"
                        @click.prevent="confirmDeleteNav"
                      >
                        <span class="icon">
                          <i class="fas fa-trash"></i>
                        </span>
                        <span>DELETE</span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-image">
                <figure class="image is-4by3">
                  <img
                    v-if="!nav.thumbnails"
                    src="/img/placeholders/image-placeholder.png"
                    alt="Nav Image"
                  >
                  <img
                    v-if="nav.thumbnails"
                    :src="nav.thumbnails.large"
                    alt="Nav Image"
                  >
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { NAV_SHOW, NAV_DELETE } from '@/store/actions/navActions'

export default {
  name: 'NavShow',
  components: {
    Breadcrumb
  },
  props: {
    navId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncNav'
  },
  data () {
    return {
      pageTitle: 'Nav Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-road', routeName: 'NavList', name: 'Manage Navs' }
        ],
        current: { icon: 'fas fa-nav-tie', name: 'Nav Details' },
        actions: [{ icon: 'fas fa-road', routeName: 'NavList', name: 'Manage Navs' }]
      },
      nav: null
    }
  },
  computed: {
    ...mapGetters('Navs', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Navs', { loadNav: NAV_SHOW, sendDeleteNavRequest: NAV_DELETE }),
    confirmDeleteNav () {
      this.$buefy.dialog.confirm({
        title: 'Deleting Nav',
        message: 'Are you sure you want to <b>delete</b> this nav? This action cannot be undone.',
        confirmText: 'Delete Nav',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deleteNav()
      })
    },
    deleteNav () {
      this.sendDeleteNavRequest(this.nav.id)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.$router.replace({ name: 'NavList' })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncNav () {
      this.nav = null
      this.loadNav(this.navId)
        .then((response) => {
          this.nav = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Nav Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'NavList' }),
            onConfirm: () => this.$router.replace({ name: 'NavList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncNav()
  }
}
</script>
