<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="category"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ category.name }}</p>
                  </div>
                </div>
                <div class="content">
                  <table class="table is-striped">
                    <tbody>
                      <tr>
                        <th>Description</th>
                        <td>{{ category.description }}</td>
                      </tr>
                      <tr>
                        <th>Parent</th>
                        <td>{{ category.parent ? category.parent.name : '' }}</td>
                      </tr>
                      <tr>
                        <th>Status</th>
                        <td>{{ category.status }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="field is-grouped">
                    <p class="control">
                      <router-link
                        :to="{ name: 'CategoryList'}"
                        class="button is-primary"
                      >
                        <span class="icon">
                          <i class="fas fa-th-list"></i>
                        </span>
                        <span>LIST</span>
                      </router-link>
                    </p>
                    <p class="control">
                      <router-link
                        :to="{ name: 'CategoryEdit', params: { categoryId: category.id }}"
                        class="button is-warning"
                      >
                        <span class="icon">
                          <i class="fas fa-edit"></i>
                        </span>
                        <span>EDIT</span>
                      </router-link>
                    </p>
                    <p class="control">
                      <a
                        class="button is-danger"
                        @click.prevent="confirmDeleteCategory"
                      >
                        <span class="icon">
                          <i class="fas fa-trash"></i>
                        </span>
                        <span>DELETE</span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-image">
                <figure class="image is-4by3">
                  <img
                    v-if="!category.thumbnails"
                    src="/img/placeholders/image-placeholder.png"
                    alt="Place Category Image"
                  >
                  <img
                    v-if="category.thumbnails"
                    :src="category.thumbnails.large"
                    alt="Place Category Image"
                  >
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { CATEGORY_SHOW, CATEGORY_DELETE } from '@/store/actions/categoryActions'

export default {
  name: 'CategoryShow',
  components: {
    Breadcrumb
  },
  props: {
    categoryId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncCategory'
  },
  data () {
    return {
      pageTitle: 'Place Category Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-location-arrow', routeName: 'CategoryList', name: 'Manage Place Categories' }
        ],
        current: { icon: 'fas fa-eye', name: 'Place Category Details' },
        actions: [{ icon: 'fas fa-location-arrow', routeName: 'CategoryList', name: 'Manage Place Categories' }]
      },
      category: null
    }
  },
  computed: {
    ...mapGetters('Categories', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Categories', { loadCategory: CATEGORY_SHOW, sendDeleteCategoryRequest: CATEGORY_DELETE }),
    confirmDeleteCategory () {
      this.$buefy.dialog.confirm({
        title: 'Deleting Place Category',
        message: 'Are you sure you want to <b>delete</b> this category? This action cannot be undone.',
        confirmText: 'Delete Place Category',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deleteCategory()
      })
    },
    deleteCategory () {
      this.sendDeleteCategoryRequest(this.category.id)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.$router.replace({ name: 'CategoryList' })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncCategory () {
      this.category = null
      this.loadCategory(this.categoryId)
        .then((response) => {
          this.category = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Place Category Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'CategoryList' }),
            onConfirm: () => this.$router.replace({ name: 'CategoryList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncCategory()
  }
}
</script>
