<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="media"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ media.title }}</p>
                  </div>
                </div>

                <div class="content">
                  <div class="columns">
                    <div class="column">
                      <table class="table is-striped">
                        <tbody>
                          <tr>
                            <th>Description</th>
                            <td>{{ media.description }}</td>
                          </tr>
                          <tr>
                            <th>Type</th>
                            <td>{{ media.type }}</td>
                          </tr>
                          <tr>
                            <th>Anchor Link</th>
                            <td>{{ media.href }}</td>
                          </tr>
                          <tr>
                            <th>Status</th>
                            <td>{{ media.status }}</td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="field is-grouped">
                        <p class="control">
                          <router-link
                            :to="{ name: 'MediaList'}"
                            class="button is-primary"
                          >
                            <span class="icon">
                              <i class="fas fa-th-list"></i>
                            </span>
                            <span>LIST</span>
                          </router-link>
                        </p>
                        <p class="control">
                          <router-link
                            :to="{ name: 'MediaEdit', params: { mediaId: media.id }}"
                            class="button is-warning"
                          >
                            <span class="icon">
                              <i class="fas fa-edit"></i>
                            </span>
                            <span>EDIT</span>
                          </router-link>
                        </p>
                        <p class="control">
                          <a
                            class="button is-danger"
                            @click.prevent="confirmDeleteMedia"
                          >
                            <span class="icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span>DELETE</span>
                          </a>
                        </p>
                      </div>
                    </div>
                    <div class="column is-two-fifths">
                      <div class="card card-section">
                        <div class="card-content">
                          <div class="card">
                            <div class="card-image">
                              <figure class="image is-4by3">
                                <img
                                  v-if="!media.thumbnail"
                                  src="/img/placeholders/image-placeholder.png"
                                  alt="Media Thumbnail"
                                >
                                <img
                                  v-if="media.thumbnail"
                                  :src="media.thumbnail"
                                  alt="Media Thumbnail"
                                >
                              </figure>
                            </div>
                            <div class="card-content">
                              <a
                                target="_blank"
                                :href="media.path"
                              >Location Original File</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { MEDIA_SHOW, MEDIA_DELETE } from '@/store/actions/mediaActions'

export default {
  name: 'MediaShow',
  components: { Breadcrumb },
  props: {
    mediaId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncMedia'
  },
  data () {
    return {
      pageTitle: 'Media Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-users-cog', routeName: 'MediaList', name: 'Manage Media Library' }
        ],
        current: { icon: 'fas fa-ribbon', name: 'Media Details' },
        actions: [{ icon: 'fas fa-users-cog', routeName: 'MediaList', name: 'Manage Media Library' }]
      },
      media: null
    }
  },
  computed: {
    ...mapGetters('Media', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Media', { loadMedia: MEDIA_SHOW, sendDeleteMediaRequest: MEDIA_DELETE }),
    confirmDeleteMedia () {
      this.$buefy.dialog.confirm({
        title: 'Deleting Media',
        message: 'Are you sure you want to <b>delete</b> this media? This action cannot be undone.',
        confirmText: 'Delete Media',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deleteMedia()
      })
    },
    deleteMedia () {
      this.sendDeleteMediaRequest(this.media.id)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.$router.replace({ name: 'MediaList' })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncMedia () {
      this.media = null
      this.loadMedia(this.mediaId)
        .then((response) => {
          this.media = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Media Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'MediaList' }),
            onConfirm: () => this.$router.replace({ name: 'MediaList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncMedia()
  }
}
</script>
