export const DEVICE_LIST = 'DEVICE_LIST'
export const DEVICE_SHOW = 'DEVICE_SHOW'
export const DEVICE_SHOW_DETAILS = 'DEVICE_SHOW_DETAILS'
export const DEVICE_TEXT_TO_ADS = 'DEVICE_TEXT_TO_ADS'
export const DEVICE_UPDATE = 'DEVICE_UPDATE'
export const DEVICE_THUMBNAIL = 'DEVICE_THUMBNAIL'
export const DEVICE_ADS = 'DEVICE_ADS'
export const DEVICE_GALLERY_CREATE = 'GALLERY_CREATE'
export const DEVICE_GALLERY_DELETE = 'GALLERY_DELETE'
export const DEVICE_PING_LIST = 'DEVICE_PING_LIST'
export const DEVICE_ADS_HISTORY_LIST = 'DEVICE_ADS_HISTORY_LIST'
export const DEVICE_REPORT_REVENUE = 'DEVICE_REPORT_REVENUE'
export const DEVICE_REPORT_AGGREGATE_REVENUE = 'DEVICE_REPORT_AGGREGATE_REVENUE'

export const AGENT_LIST = 'AGENT_LIST'
export const AGENT_SHOW = 'AGENT_SHOW'
export const AGENT_WALLET_INFO = 'AGENT_WALLET_INFO'
export const AGENT_WALLET_TRANSACTION_LIST = 'AGENT_WALLET_TRANSACTION_LIST'

export const ADVERTISEMENT_SUGGESTION = 'ADVERTISEMENT_SUGGESTION'
export const ADVERTISEMENT_LIST = 'ADVERTISEMENT_LIST'
export const ADVERTISEMENT_CREATE = 'ADVERTISEMENT_CREATE'
export const ADVERTISEMENT_UPDATE = 'ADVERTISEMENT_UPDATE'
export const ADVERTISEMENT_SHOW = 'ADVERTISEMENT_SHOW'
export const ADVERTISEMENT_DELETE = 'ADVERTISEMENT_DELETE'
export const ADVERTISEMENT_THUMBNAIL = 'ADVERTISEMENT_THUMBNAIL'
export const ADVERTISEMENT_ADS = 'ADVERTISEMENT_ADS'

export const TICKET_LIST = 'TICKET_LIST'
export const TICKET_CREATE = 'TICKET_CREATE'
export const TICKET_CANCELLED = 'TICKET_CANCELLED'
