<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="deviceWindow"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ deviceWindow.name }}</p>
                  </div>
                </div>
                <div class="content">
                  <table class="table is-striped">
                    <tbody>
                      <tr>
                        <th>UUID</th>
                        <td>{{ deviceWindow.uuid }}</td>
                      </tr>
                      <tr>
                        <th>Start Time</th>
                        <td>{{ deviceWindow.start_time }}</td>
                      </tr>
                      <tr>
                        <th>End Time</th>
                        <td>{{ deviceWindow.end_time }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="field is-grouped">
                    <p class="control">
                      <router-link
                        :to="{ name: 'DeviceWindowList'}"
                        class="button is-primary"
                      >
                        <span class="icon">
                          <i class="fas fa-th-list"></i>
                        </span>
                        <span>LIST</span>
                      </router-link>
                    </p>
                    <p class="control">
                      <router-link
                        :to="{ name: 'DeviceWindowEdit', params: { deviceWindowId: deviceWindow.id }}"
                        class="button is-warning"
                      >
                        <span class="icon">
                          <i class="fas fa-edit"></i>
                        </span>
                        <span>EDIT</span>
                      </router-link>
                    </p>
                    <p class="control">
                      <a
                        class="button is-danger"
                        @click.prevent="confirmDeleteDeviceWindow"
                      >
                        <span class="icon">
                          <i class="fas fa-trash"></i>
                        </span>
                        <span>DELETE</span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { DEVICE_WINDOW_SHOW, DEVICE_WINDOW_DELETE } from '@/store/actions/deviceWindowActions'

export default {
  name: 'DeviceWindowShow',
  components: {
    Breadcrumb
  },
  props: {
    deviceWindowId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncDeviceWindow'
  },
  data () {
    return {
      pageTitle: 'Window Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-clock', routeName: 'DeviceWindowList', name: 'Manage Windows' }
        ],
        current: { icon: 'fas fa-eye', name: 'Window Details' },
        actions: [{ icon: 'fas fa-clock', routeName: 'DeviceWindowList', name: 'Manage Windows' }]
      },
      deviceWindow: null
    }
  },
  computed: {
    ...mapGetters('DeviceWindows', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('DeviceWindows', { loadDeviceWindow: DEVICE_WINDOW_SHOW, sendDeleteDeviceWindowRequest: DEVICE_WINDOW_DELETE }),
    confirmDeleteDeviceWindow () {
      this.$buefy.dialog.confirm({
        title: 'Deleting Window',
        message: 'Are you sure you want to <b>delete</b> this window? This action cannot be undone.',
        confirmText: 'Delete Window',
        type: 'is-danger',
        hasIcon: true,
        icon: 'skull',
        iconPack: 'fas',
        onConfirm: () => this.deleteDeviceWindow()
      })
    },
    deleteDeviceWindow () {
      this.sendDeleteDeviceWindowRequest(this.deviceWindow.id)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.$router.replace({ name: 'DeviceWindowList' })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncDeviceWindow () {
      this.deviceWindow = null
      this.loadDeviceWindow(this.deviceWindowId)
        .then((response) => {
          this.deviceWindow = response.data
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Window Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'DeviceWindowList' }),
            onConfirm: () => this.$router.replace({ name: 'DeviceWindowList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncDeviceWindow()
  }
}
</script>
