<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="card card-section"
      v-if="deviceWindow"
    >
      <div class="card-content">
        <form @submit.prevent="onStart">
          <div class="field">
            <label class="label">UUID</label>
            <div class="control">
              <input
                v-model="deviceWindow.uuid"
                class="input"
                type="text"
                placeholder="Enter Device UUID"
                :class="{'is-danger' : errors.uuid}"
              />
            </div>
            <p
              v-if="errors.uuid"
              class="help is-danger"
            >{{ errors.uuid | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Name</label>
            <div class="control">
              <input
                v-model="deviceWindow.name"
                class="input"
                :class="{'is-danger' : errors.name}"
                type="text"
                placeholder="Enter Window Name"
              />
            </div>
            <p
              v-if="errors.name"
              class="help is-danger"
            >{{ errors.name | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Start Time</label>
            <div class="control">
              <b-timepicker
                v-model="start_time"
                inline
                :class="{'is-danger' : errors.start_time}"
              ></b-timepicker>
            </div>
            <p
              v-if="errors.start_time"
              class="help is-danger"
            >{{ errors.start_time | implode }}</p>
          </div>
          <div class="field">
            <label class="label">End Time</label>
            <div class="control">
              <b-timepicker
                v-model="end_time"
                inline
                :class="{'is-danger' : errors.end_time}"
              ></b-timepicker>
            </div>
            <p
              v-if="errors.end_time"
              class="help is-danger"
            >{{ errors.end_time | implode }}</p>
          </div>
          <div class="field is-grouped action-buttons">
            <div class="control">
              <button
                type="submit"
                :disabled="isLoading"
                class="button is-primary is-rounded"
                :class="{ 'is-loading': isLoading }"
              >Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { DEVICE_WINDOW_SHOW, DEVICE_WINDOW_UPDATE } from '@/store/actions/deviceWindowActions'

export default {
  name: 'DeviceWindowEdit',
  components: {
    Breadcrumb
  },
  props: {
    deviceWindowId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncDeviceWindow'
  },
  data () {
    return {
      pageTitle: 'Edit Window Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-clock', routeName: 'DeviceWindowList', name: 'Manage Windows' }
        ],
        current: { icon: 'fas fa-edit', name: 'Edit Window' },
        actions: [
          { icon: 'fas fa-clock', routeName: 'DeviceWindowList', name: 'Manage Windows' },
          {
            icon: 'fas fa-eye', routeName: 'DeviceWindowShow', params: { deviceWindowId: this.deviceWindowId }, name: 'Display Window'
          }
        ]
      },
      deviceWindow: null,
      start_time: new Date(),
      end_time: new Date(),
      errors: {}
    }
  },
  computed: {
    ...mapGetters('DeviceWindows', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('DeviceWindows', { loadDeviceWindow: DEVICE_WINDOW_SHOW, updateDeviceWindow: DEVICE_WINDOW_UPDATE }),
    getTime (t) {
      let hours = t.getHours()
      if (hours < 10) {
        hours = `0${hours}`
      }
      let minutes = t.getMinutes()
      if (minutes < 10) {
        minutes = `0${minutes}`
      }
      return `${hours}:${minutes}`
    },
    onStart () {
      this.errors = {}
      this.deviceWindow.start_time = this.getTime(this.start_time)
      this.deviceWindow.end_time = this.getTime(this.end_time)
      const data = {
        deviceWindowId: this.deviceWindowId,
        formData: this.deviceWindow
      }
      this.updateDeviceWindow(data)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.name}) Window is updated successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Window',
            onAction: () => {
              this.$router.push({
                name: 'DeviceWindowList'
              })
            }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Window Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncDeviceWindow () {
      this.deviceWindow = null
      this.loadDeviceWindow(this.deviceWindowId)
        .then((response) => {
          this.deviceWindow = {
            id: response.data.id,
            name: response.data.name,
            uuid: response.data.uuid,
            start_time: response.data.start_time,
            end_time: response.data.end_time
          }
          const startTimes = response.data.start_time.split(':')
          const endTimes = response.data.end_time.split(':')
          this.start_time.setHours(parseInt(startTimes[0]), parseInt(startTimes[1]))
          this.end_time.setHours(parseInt(endTimes[0]), parseInt(endTimes[1]))
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Window Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'DeviceWindowList' }),
            onConfirm: () => this.$router.replace({ name: 'DeviceWindowList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncDeviceWindow()
  }
}
</script>
