<template>
  <div>
    <loading v-show="isDetailLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="columns"
      v-if="device"
    >
      <div class="column">
        <div class="card card-section">
          <div class="card-content">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ device.name }} / ({{ device.uuid }})</p>
                  </div>
                </div>
                <div class="content">
                  <div class="columns">
                    <div class="column">
                      <table class="table is-striped">
                        <tbody>
                          <tr>
                            <th>Address</th>
                            <td>{{ device.address }}</td>
                          </tr>
                          <tr>
                            <th>Status</th>
                            <td>{{ device.status }}</td>
                          </tr>
                          <tr>
                            <th>Latitude</th>
                            <td>{{ device.latitude }}</td>
                          </tr>
                          <tr>
                            <th>Longitude</th>
                            <td>{{ device.longitude }}</td>
                          </tr>
                          <tr>
                            <th>Agent</th>
                            <td>
                              <div v-if="device.agent">
                                <div v-if="device.agent.name">{{ device.agent.name }}</div>
                                <div v-if="device.agent.email">{{ device.agent.email }}</div>
                                <div v-if="device.agent.mobile">{{ device.agent.mobile }}</div>
                                <div v-if="device.agent.address">{{ device.agent.address }}</div>
                                <div v-if="device.agent.status">{{ device.agent.status }}</div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>Distributor</th>
                            <td>
                              <div v-if="device.distributor">
                                <div v-if="device.distributor.name">{{ device.distributor.name }}</div>
                                <div v-if="device.distributor.email">{{ device.distributor.email }}</div>
                                <div v-if="device.distributor.mobile">{{ device.distributor.mobile }}</div>
                                <div v-if="device.distributor.address">{{ device.distributor.address }}</div>
                                <div v-if="device.distributor.status">{{ device.distributor.status }}</div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="card">
                        <div class="card-content">
                          <div class="field is-grouped">
                            <p class="control">
                              <router-link
                                :to="{ name: 'MyDeviceList'}"
                                class="button is-primary"
                              >
                                <span class="icon">
                                  <i class="fas fa-th-list"></i>
                                </span>
                                <span>LIST</span>
                              </router-link>
                            </p>
                            <p
                              class="control"
                              v-if="isAgentView && device.agent.id === auth_id"
                            >
                              <router-link
                                :to="{ name: 'MyDeviceEdit', params: { deviceId: device.id }}"
                                class="button is-warning"
                              >
                                <span class="icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                                <span>EDIT</span>
                              </router-link>
                            </p>
                            <p class="control">
                              <router-link
                                :to="{ name: 'MyDevicePingList', params: { deviceId: device.id }}"
                                class="button is-info"
                              >
                                <span class="icon">
                                  <i class="fas fa-heartbeat"></i>
                                </span>
                                <span>HEARTBEATS</span>
                              </router-link>
                            </p>
                            <p class="control">
                              <router-link
                                :to="{ name: 'MyDeviceAdsHistoryList', params: { deviceId: device.id }}"
                                class="button is-dark"
                              >
                                <span class="icon">
                                  <i class="fab fa-adversal"></i>
                                </span>
                                <span>ADS HINSTORY</span>
                              </router-link>
                            </p>
                            <p class="control">
                              <router-link
                                :to="{ name: 'MyDeviceRevenueReport', params: { deviceId: device.id }}"
                                class="button is-primary"
                              >
                                <span class="icon">
                                  <i class="fas fa-chart-pie"></i>
                                </span>
                                <span>Revenue Report</span>
                              </router-link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-two-fifths">
                      <div
                        class="card card-section"
                        v-if="device.ads"
                      >
                        <header class="card-header">
                          <p class="card-header-title has-background-primary has-text-white">
                            Ads
                          </p>
                        </header>
                        <div class="card-image">
                          <a
                            :href="device.ads.path"
                            target="_blank"
                          >
                            <figure class="image is-4by3">
                              <img
                                v-if="!device.ads"
                                src="/img/placeholders/image-placeholder.png"
                                alt="Device Ads"
                              >
                              <img
                                v-if="device.ads"
                                :src="device.ads.thumbnail"
                                alt="Device Ads"
                              >
                            </figure>
                          </a>
                        </div>
                      </div>
                      <div class="card card-section">
                        <header class="card-header">
                          <p class="card-header-title has-background-primary has-text-white">
                            Thumbnail
                          </p>
                        </header>
                        <div class="card-image">
                          <figure class="image is-4by3">
                            <img
                              v-if="!device.thumbnails"
                              src="/img/placeholders/image-placeholder.png"
                              alt="Device Thumbnail"
                            >
                            <img
                              v-if="device.thumbnails"
                              :src="device.thumbnails.large"
                              alt="Device Thumbnail"
                            >
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <picture-gallery
                    title="Picture Gallery"
                    :gallery="device.gallery"
                  ></picture-gallery>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import PictureGallery from '@/pages/layouts/secure/components/PictureGallery'
import { mapGetters, mapActions } from 'vuex'
import { DEVICE_SHOW_DETAILS } from '@/store/actions/myActions'

export default {
  name: 'DeviceShow',
  components: { Breadcrumb, PictureGallery },
  props: {
    userView: {
      required: true
    },
    deviceId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncDevice'
  },
  data () {
    return {
      pageTitle: 'Device Details',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-desktop', routeName: 'MyDeviceList', name: 'Manage Devices' }
        ],
        current: { icon: 'fas fa-eye', name: 'Device Details' },
        actions: [{ icon: 'fas fa-desktop', routeName: 'MyDeviceList', name: 'Manage Devices' }]
      },
      device: null,
      fetchDetailsStatus: ''
    }
  },
  computed: {
    ...mapGetters('Profile', { auth_id: 'getAuthId' }),
    isDetailLoading () {
      return this.fetchDetailsStatus === 'DEVICE_LOADING'
    },
    isAgentView () {
      return this.userView === 'agent'
    }
  },
  methods: {
    ...mapActions('MyActions', { loadDevice: DEVICE_SHOW_DETAILS }),
    loadAsyncDevice () {
      this.device = null
      this.fetchDetailsStatus = 'DEVICE_LOADING'
      const data = { userView: this.userView, deviceId: this.deviceId }
      this.loadDevice(data)
        .then((response) => {
          this.device = response.data
          this.fetchDetailsStatus = 'DEVICE_SUCCESS'
        })
        .catch((error) => {
          this.fetchDetailsStatus = 'DEVICE_ERROR'
          this.$notify({
            title: 'Device Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    this.loadAsyncDevice()
  }
}
</script>
