<template>
  <card
    title="Account Balances"
    :tag-text="wallet.alltime_balance"
    tag-icon="fas fa-rupee-sign"
  >
    <div class="tile is-ancestor has-text-centered">
      <div class="tile is-parent">
        <div
          class="tile is-child relative-position"
          :class="{ 'element is-loading' : isInfoLoading}"
        >
          <article class="tile is-child notification is-primary top-radius">
            <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.allTime.deposit }}</p>
            <p class="subtitle is-5">Deposits</p>
          </article>
          <article class="tile is-child notification is-success bottom-radius">
            <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.allTime.withdrawal }}</p>
            <p class="subtitle is-5">Withdrawals</p>
          </article>
          <span class="tag is-link top-right">ALL TIME</span>
        </div>
      </div>
      <div class="tile is-parent">
        <div
          class="tile is-child relative-position"
          :class="{ 'element is-loading' : isInfoLoading}"
        >
          <article class="tile is-child notification is-primary top-radius">
            <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.today.deposit }}</p>
            <p class="subtitle is-5">Deposits</p>
          </article>
          <article class="tile is-child notification is-success bottom-radius">
            <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.today.withdrawal }}</p>
            <p class="subtitle is-5">Withdrawals</p>
          </article>
          <span class="tag is-link top-right">TODAY</span>
        </div>
      </div>
      <div class="tile is-parent">
        <div
          class="tile is-child relative-position"
          :class="{ 'element is-loading' : isInfoLoading}"
        >
          <article class="tile is-child notification is-primary top-radius">
            <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.yesterday.deposit }}</p>
            <p class="subtitle is-5">Deposits</p>
          </article>
          <article class="tile is-child notification is-success bottom-radius">
            <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.yesterday.withdrawal }}</p>
            <p class="subtitle is-5">Withdrawals</p>
          </article>
          <span class="tag is-link top-right">YESTERDAY</span>
        </div>
      </div>
      <div class="tile is-parent">
        <div
          class="tile is-child relative-position"
          :class="{ 'element is-loading' : isInfoLoading}"
        >
          <article class="tile is-child notification is-primary top-radius">
            <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.currentMonth.deposit }}</p>
            <p class="subtitle is-5">Deposits</p>
          </article>
          <article class="tile is-child notification is-success bottom-radius">
            <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.currentMonth.withdrawal }}</p>
            <p class="subtitle is-5">Withdrawals</p>
          </article>
          <span class="tag is-link top-right">CURRENT MONTH</span>
        </div>
      </div>
      <div class="tile is-parent">
        <div
          class="tile is-child relative-position"
          :class="{ 'element is-loading' : isInfoLoading}"
        >
          <article class="tile is-child notification is-primary top-radius">
            <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.lastMonth.deposit }}</p>
            <p class="subtitle is-5">Deposits</p>
          </article>
          <article class="tile is-child notification is-success bottom-radius">
            <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ wallet.lastMonth.withdrawal }}</p>
            <p class="subtitle is-5">Withdrawals</p>
          </article>
          <span class="tag is-link top-right">LAST MONTH</span>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { mapActions } from 'vuex'
import Card from '@/lib/Card'
import { WALLET_INFO } from '@/store/actions/myWalletActions'

export default {
  name: 'MyWalletBalance',
  components: {
    Card
  },
  data () {
    return {
      isInfoLoading: false,
      wallet: {
        alltime_balance: 0,
        allTime: {
          deposit: 0,
          withdrawal: 0
        },
        currentMonth: {
          deposit: 0,
          withdrawal: 0
        },
        lastMonth: {
          deposit: 0,
          withdrawal: 0
        },
        currentWeek: {
          deposit: 0,
          withdrawal: 0
        },
        lastWeek: {
          deposit: 0,
          withdrawal: 0
        },
        yesterday: {
          deposit: 0,
          withdrawal: 0
        },
        today: {
          deposit: 0,
          withdrawal: 0
        }
      }
    }
  },
  methods: {
    ...mapActions('MyWallets', { loadWalletInfo: WALLET_INFO }),
    loadAsyncUserWallet () {
      this.isInfoLoading = true
      this.loadWalletInfo()
        .then((response) => {
          this.isInfoLoading = false
          this.wallet.alltime_balance = response.wallet_balance
          this.wallet.allTime = { deposit: response.all_time.DEPOSIT, withdrawal: response.all_time.WITHDRAWAL }
          this.wallet.currentMonth = { deposit: response.current_month.DEPOSIT, withdrawal: response.current_month.WITHDRAWAL }
          this.wallet.lastMonth = { deposit: response.last_month.DEPOSIT, withdrawal: response.last_month.WITHDRAWAL }
          this.wallet.currentWeek = { deposit: response.current_week.DEPOSIT, withdrawal: response.current_week.WITHDRAWAL }
          this.wallet.lastWeek = { deposit: response.last_week.DEPOSIT, withdrawal: response.last_week.WITHDRAWAL }
          this.wallet.yesterday = { deposit: response.yesterday.DEPOSIT, withdrawal: response.yesterday.WITHDRAWAL }
          this.wallet.today = { deposit: response.today.DEPOSIT, withdrawal: response.today.WITHDRAWAL }
        })
        .catch((error) => {
          this.isInfoLoading = false
          this.$notify({
            title: 'Wallet Info',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    this.loadAsyncUserWallet()
  }
}
</script>
