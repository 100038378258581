import { SETTING_LIST, SETTING_CREATE, SETTING_SHOW, SETTING_UPDATE, SETTING_DELETE } from '../../actions/settingActions'
import { LOADING, SUCCESS, ERROR } from '../../actions/requestStatus'
import settingApi from '../../../api/settingApi'

/* , SETTING_CREATE, SETTING_UPDATE, SETTING_SHOW, SETTING_DELETE */

export default {
  namespaced: true,

  state: {
    status: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR
  },

  actions: {
    [SETTING_SHOW]: ({ commit }, settingId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        settingApi
          .show(settingId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [SETTING_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        settingApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [SETTING_CREATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        settingApi
          .create(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [SETTING_UPDATE]: ({ commit }, { settingId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        settingApi
          .update(settingId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [SETTING_DELETE]: ({ commit }, settingId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        settingApi
          .delete(settingId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    }
  }
}
