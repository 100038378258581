<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="card card-section">
      <div class="card-content">
        <form
          @submit.prevent="onStart"
          v-if="form"
        >
          <div class="field">
            <label class="label">Name</label>
            <div class="control">
              <input
                v-model="form.name"
                class="input"
                :class="{'is-danger' : errors.name}"
                type="text"
                placeholder="Enter Website Name"
              />
            </div>
            <p
              v-if="errors.name"
              class="help is-danger"
            >{{ errors.name | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Description</label>
            <div class="control">
              <textarea
                v-model="form.description"
                class="input"
                :class="{'is-danger' : errors.description}"
                type="text"
                placeholder="Enter Website Description"
              ></textarea>
            </div>
            <p
              v-if="errors.description"
              class="help is-danger"
            >{{ errors.description | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Website HTTP Link</label>
            <div class="control">
              <input
                v-model="form.link"
                class="input"
                :class="{'is-danger' : errors.link}"
                type="text"
                placeholder="Enter Website Name"
              />
            </div>
            <p
              v-if="errors.link"
              class="help is-danger"
            >{{ errors.link | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Whitelist URLs</label>
            <div class="control">
              <textarea
                v-model="form.whitelist_urls"
                class="input"
                :class="{'is-danger' : errors.whitelist_urls}"
                type="text"
                placeholder="Enter Website Whitelist URLs"
              ></textarea>
            </div>
            <p
              v-if="errors.whitelist_urls"
              class="help is-danger"
            >{{ errors.whitelist_urls | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Blacklist URLs</label>
            <div class="control">
              <textarea
                v-model="form.blacklist_urls"
                class="input"
                :class="{'is-danger' : errors.blacklist_urls}"
                type="text"
                placeholder="Enter Website Blacklist URLs"
              ></textarea>
            </div>
            <p
              v-if="errors.blacklist_urls"
              class="help is-danger"
            >{{ errors.blacklist_urls | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Order No.</label>
            <div class="control">
              <input
                v-model="form.order_no"
                class="input"
                type="number"
                :class="{'is-danger' : errors.order_no}"
                placeholder="Enter Website Order No."
              />
            </div>
            <p
              v-if="errors.order_no"
              class="help is-danger"
            >{{ errors.order_no | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Status</label>
            <div class="control">
              <v-select
                v-model="form.status"
                :options="status_options"
                :class="{'is-danger' : errors.status}"
                placeholder="Pick status for website"
                class="is-small"
              ></v-select>
            </div>
            <p
              v-if="errors.status"
              class="help is-danger"
            >{{ errors.status | implode }}</p>
          </div>
          <div class="field is-grouped action-buttons">
            <div class="control">
              <button
                type="submit"
                :disabled="isLoading"
                class="button is-primary is-rounded"
                :class="{ 'is-loading': isLoading }"
              >Submit</button>
            </div>
            <div class="control">
              <button
                type="button"
                @click="onReset"
                class="button is-rounded"
              >Reset</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { WEBSITE_CREATE, WEBSITE_LIST } from '@/store/actions/websiteActions'

export default {
  name: 'WebsiteCreate',
  components: {
    Breadcrumb
  },
  data () {
    return {
      pageTitle: 'New Website Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-globe', routeName: 'WebsiteList', name: 'Manage Websites' }
        ],
        current: { icon: 'fas fa-plus', name: 'New Website' },
        actions: [{ icon: 'fas fa-globe', routeName: 'WebsiteList', name: 'Manage Websites' }]
      },
      status_options: ['ENABLE', 'DISABLE'],
      form: {
        name: null,
        description: null,
        link: null,
        whitelist_urls: null,
        blacklist_urls: null,
        order_no: 0,
        status: 'ENABLE'
      },
      errors: {}
    }
  },
  computed: {
    ...mapGetters('Websites', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Websites', { createWebsite: WEBSITE_CREATE, loadParents: WEBSITE_LIST }),
    onStart () {
      this.errors = {}
      this.createWebsite(this.form)
        .then((response) => {
          this.onReset()
          this.$notify({
            title: 'Website Saved',
            text: `(${response.data.name}) Website is created successfully`,
            type: 'is-success'
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Website Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onReset () {
      this.errors = {}
      this.form.name = null
      this.form.description = null
      this.form.link = null
      this.form.whitelist_urls = null
      this.form.blacklist_urls = null
      this.form.order_no = 0
      this.form.status = 'ENABLE'
    }
  },
  created () {
    this.onReset()
  }
}
</script>
