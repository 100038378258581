<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="card card-section"
      v-if="advertisement"
    >
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                <input
                  v-model="advertisement.name"
                  class="input"
                  type="text"
                  placeholder="Enter Advertisement Name"
                  :class="{'is-danger' : errors.name}"
                />
              </div>
              <p
                v-if="errors.name"
                class="help is-danger"
              >{{ errors.name | implode }}</p>
            </div>
            <div class="field">
              <label class="label">Ads Text</label>
              <div class="control">
                <textarea
                  v-model="advertisement.ads_text"
                  class="textarea"
                  placeholder="Enter Advertisement Ads Text"
                  :class="{'is-danger' : errors.ads_text}"
                ></textarea>
              </div>
              <p
                v-if="errors.ads_text"
                class="help is-danger"
              >{{ errors.ads_text | implode }}</p>
            </div>
            <div class="field">
              <label class="label">Duration</label>
              <div class="control">
                <input
                  v-model="advertisement.duration"
                  class="input"
                  type="text"
                  placeholder="Enter Advertisement Duration"
                  :class="{'is-danger' : errors.duration}"
                />
              </div>
              <p
                v-if="errors.duration"
                class="help is-danger"
              >{{ errors.duration | implode }}</p>
            </div>
            <b-field label="Advertiser">
              <b-autocomplete
                placeholder="Type advertiser to search and select"
                field="name"
                :keep-first="true"
                :data="autocomplete.advertiser.items"
                v-model="autocomplete.advertiser.value"
                :loading="autocomplete.advertiser.isLoading"
                @input="loadAdvertisersSuggestion"
                @select="option => advertisement.advertiser = option"
              >
                <template slot="empty">No advertiser found</template>
                <template slot-scope="props">
                  <div class="media">
                    <div class="media-content">
                      {{ props.option.name }}
                      <br>
                      <small>
                        {{ props.option.email }},
                      </small>
                    </div>
                  </div>
                </template>
              </b-autocomplete>
              <p
                v-if="errors.advertiser"
                class="help is-danger"
              >{{ errors.advertiser | implode }}</p>
            </b-field>
            <div class="field">
              <label class="label">Product Tags</label>
              <div class="control">
                <treeselect
                  placeholder="Select product tags for the advertisement..."
                  value-consists-of="ALL_WITH_INDETERMINATE"
                  v-model="advertisementTags"
                  :multiple="true"
                  :options="tagTree"
                  :normalizer="normalizer"
                ></treeselect>
              </div>
            </div>
            <div class="field">
              <label class="label">Status</label>
              <div class="control">
                <v-select
                  v-model="advertisement.status"
                  :options="status_options"
                  :class="{'is-danger' : errors.status}"
                  placeholder="Pick status for advertisement"
                  class="is-small"
                ></v-select>
              </div>
              <p
                v-if="errors.status"
                class="help is-danger"
              >{{ errors.status | implode }}</p>
            </div>
            <div class="field is-grouped action-buttons">
              <div class="control">
                <button
                  type="button"
                  :disabled="isLoading"
                  class="button is-primary is-rounded"
                  @click="formSubmit"
                  :class="{ 'is-loading': isLoading }"
                >Update Text Advertisement</button>
              </div>
            </div>
          </div>
          <div class="column is-two-fifths">
            <div class="card card-section">
              <header class="card-header">
                <p class="card-header-title has-background-primary has-text-white">
                  Thumbnail
                </p>
              </header>
              <div class="card-image">
                <figure class="image is-4by3">
                  <img
                    v-if="!advertisement.thumbnails"
                    src="/img/placeholders/image-placeholder.png"
                    alt="Advertisement Thumbnail"
                  >
                  <img
                    v-if="advertisement.thumbnails"
                    :src="advertisement.thumbnails.large"
                    alt="Advertisement Thumbnail"
                  >
                </figure>
              </div>
              <div class="card-content">
                <div class="file has-name is-fullwidth is-primary">
                  <label class="file-label">
                    <input
                      class="file-input"
                      type="file"
                      name="thumbnailFile"
                      ref="thumbnailFile"
                      @change="onThumbnailChange"
                      :disabled="isThumbnailLoading"
                    />
                    <span class="file-cta">
                      <span class="file-icon">
                        <i class="fas fa-upload"></i>
                      </span>
                      <span class="file-label">
                        Choose a file…
                      </span>
                    </span>
                    <span class="file-name">
                      {{ thumbnailName }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { TEXT_ADVERTISEMENT_SHOW, TEXT_ADVERTISEMENT_UPDATE, TEXT_ADVERTISEMENT_THUMBNAIL } from '@/store/actions/textAdvertisementActions'
import { ADVERTISER_LIST } from '@/store/actions/userActions'
import { TAG_TREE_SHOW } from '@/store/actions/tagActions'

export default {
  name: 'TextAdvertisementEdit',
  components: {
    Breadcrumb, Treeselect
  },
  props: {
    advertisementId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncAdvertisement'
  },
  data () {
    return {
      pageTitle: 'Edit Text Advertisement Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fab fa-adversal', routeName: 'TextAdvertisementList', name: 'Manage Text Advertisements' }
        ],
        current: { icon: 'fas fa-edit', name: 'Edit Text Advertisement' },
        actions: [
          { icon: 'fab fa-adversal', routeName: 'TextAdvertisementList', name: 'Manage Text Advertisements' },
          {
            icon: 'fas fa-eye', routeName: 'TextAdvertisementShow', params: { advertisementId: this.advertisementId }, name: 'Display Text Advertisement'
          }
        ]
      },
      status_options: ['IDEAL', 'ENABLE', 'DISABLE'],
      autocomplete: {
        advertiser: {
          value: null,
          items: [],
          isLoading: false,
          timeout: null
        }
      },
      advertisement: null,
      errors: {},
      tagTree: [],
      advertisementTags: [],
      thumbnailName: null
    }
  },
  computed: {
    ...mapGetters('TextAdvertisements', ['isLoading', 'isSuccess', 'isError', 'isThumbnailLoading', 'isThumbnailSuccess', 'isThumbnailError'])
  },
  methods: {
    ...mapActions('TextAdvertisements', {
      loadAdvertisement: TEXT_ADVERTISEMENT_SHOW,
      updateAdvertisement: TEXT_ADVERTISEMENT_UPDATE,
      uploadThumbnail: TEXT_ADVERTISEMENT_THUMBNAIL
    }),
    ...mapActions('Users', { loadAdvertisers: ADVERTISER_LIST }),
    ...mapActions('Tags', { loadTagTree: TAG_TREE_SHOW }),
    normalizer (node) {
      return {
        id: node.id,
        label: node.name,
        children: node.children
      }
    },
    onThumbnailChange () {
      const thumbnailFiles = this.$refs.thumbnailFile
      if (!thumbnailFiles || !thumbnailFiles.files.length) {
        return
      }
      this.thumbnailName = thumbnailFiles.files[0].name
      this.createImage(thumbnailFiles.files[0])
      this.uploadAdvertisementThumbnail(thumbnailFiles.files[0])
    },
    createImage (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.advertisement.thumbnails = { large: e.target.result }
      }
      reader.readAsDataURL(file)
    },
    uploadAdvertisementThumbnail (thumbnail) {
      const thumbnailFormData = new FormData()
      thumbnailFormData.set('thumbnail', thumbnail)
      const data = {
        advertisementId: this.advertisement.id,
        formData: thumbnailFormData
      }
      this.uploadThumbnail(data)
        .then((response) => {
          this.$notify({
            title: 'Advertisement Thumbnail',
            text: response.message,
            type: 'is-success'
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Advertisement Thumbnail Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    formSubmit () {
      this.errors = {}
      this.advertisement.tags = this.advertisementTags
      const data = {
        advertisementId: this.advertisement.id,
        formData: this.advertisement
      }
      this.updateAdvertisement(data)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.name}) Advertisement is updated successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Text Advertisements',
            onAction: () => {
              this.$router.push({
                name: 'TextAdvertisementList'
              })
            }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Text Advertisement Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAdvertisersSuggestion () {
      clearTimeout(this.autocomplete.advertiser.timeout)
      this.autocomplete.advertiser.timeout = setTimeout(() => {
        const data = {
          query: this.autocomplete.advertiser.value
        }
        if (data.query) {
          this.autocomplete.advertiser.isLoading = true
          this.loadAdvertisers(data)
            .then((response) => {
              this.autocomplete.advertiser.items = response.data
              this.autocomplete.advertiser.isLoading = false
            })
            .catch((error) => {
              this.autocomplete.advertiser.isLoading = false
              this.$notify({
                title: 'Advertiser Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    },
    loadAsyncTagTree () {
      this.loadTagTree()
        .then((response) => {
          this.tagTree = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Tag Tree Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncAdvertisement () {
      this.advertisement = null
      this.loadAdvertisement(this.advertisementId)
        .then((response) => {
          this.advertisement = {
            id: response.data.id,
            name: response.data.name,
            ads_text: response.data.ads_text,
            duration: response.data.duration,
            status: response.data.status,
            advertiser: response.data.advertiser,
            thumbnails: response.data.thumbnails,
            tags: response.data.tags
          }
          this.advertisementTags = this.advertisement.tags.map((tag) => tag.id)
          this.autocomplete.advertiser.value = this.advertisement.advertiser ? this.advertisement.advertiser.name : null
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (Advertisement Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'TextAdvertisementList' }),
            onConfirm: () => this.$router.replace({ name: 'TextAdvertisementList' })
          })
        })
    }
  },
  created () {
    this.loadAsyncAdvertisement()
    this.loadAsyncTagTree()
  }
}
</script>
