import { axios } from './axios'

export default {
  uploadThumbnail (campaignId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/campaigns/${campaignId}/thumbnail`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list (filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/campaigns', {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  show (campaignId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/campaigns/${campaignId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  showDetails (campaignId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/campaigns/${campaignId}/details`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  create (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/campaigns', formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  updateCampaignRelation (campaignId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/campaigns/${campaignId}/relations`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  update (campaignId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/campaigns/${campaignId}`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  delete (campaignId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/campaigns/${campaignId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },

  uploadSpaces (campaignId, spaces) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/campaigns/${campaignId}/spaces`, spaces)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  uploadAds (campaignId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/campaigns/${campaignId}/ads`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  start (campaignId, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/campaigns/${campaignId}/start`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
