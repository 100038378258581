<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="card card-section">
      <div class="card-content">
        <form @submit.prevent="onStart">
          <div class="field">
            <label class="label">Name</label>
            <div class="control">
              <input
                v-model="form.name"
                class="input"
                :class="{'is-danger' : errors.name}"
                type="text"
                placeholder="Enter Role Name"
              />
            </div>
            <p
              v-if="errors.name"
              class="help is-danger"
            >{{ errors.name | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Status</label>
            <div class="control">
              <!-- <div class="control is-expanded has-icons-left"> -->
              <v-select
                v-model="form.status"
                :options="status_options"
                :class="{'is-danger' : errors.status}"
                placeholder="Pick status for role"
                class="is-small"
              ></v-select>
            </div>
            <p
              v-if="errors.status"
              class="help is-danger"
            >{{ errors.status | implode }}</p>
          </div>
          <table class="table is-bordered is-striped is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th>Module Name</th>
                <th class="has-text-centered">LIST</th>
                <th class="has-text-centered">SHOW</th>
                <th class="has-text-centered">CREATE</th>
                <th class="has-text-centered">UPDATE</th>
                <th class="has-text-centered">DELETE</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="module in permissionModules"
                :key="module"
              >
                <td>{{ module }}</td>
                <td class="has-text-centered">
                  <b-checkbox
                    v-model="form.permissions"
                    :native-value="`${module}-LIST`"
                  ></b-checkbox>
                </td>
                <td class="has-text-centered">
                  <b-checkbox
                    v-model="form.permissions"
                    :native-value="`${module}-SHOW`"
                  ></b-checkbox>
                </td>
                <td class="has-text-centered">
                  <b-checkbox
                    v-model="form.permissions"
                    :native-value="`${module}-CREATE`"
                  ></b-checkbox>
                </td>
                <td class="has-text-centered">
                  <b-checkbox
                    v-model="form.permissions"
                    :native-value="`${module}-UPDATE`"
                  ></b-checkbox>
                </td>
                <td class="has-text-centered">
                  <b-checkbox
                    v-model="form.permissions"
                    :native-value="`${module}-DELETE`"
                  ></b-checkbox>
                </td>
              </tr>
              <tr>
                <td>Singular Permissions</td>
                <td colspan="5">
                  <div class="tags">
                    <span
                      class="tag"
                      v-for="permission in permissions"
                      :key="permission"
                    >
                      <b-checkbox
                        v-model="form.permissions"
                        :native-value="permission"
                      >{{ permission }}</b-checkbox>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="field is-grouped action-buttons">
            <div class="control">
              <button
                type="submit"
                :disabled="isLoading"
                class="button is-primary is-rounded"
                :class="{ 'is-loading': isLoading }"
              >Submit</button>
            </div>
            <div class="control">
              <button
                type="button"
                @click="onReset"
                class="button is-rounded"
              >Reset</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { ROLE_CREATE, PERMISSION_LIST } from '@/store/actions/roleActions'

export default {
  name: 'RoleCreate',
  components: { Breadcrumb },
  data () {
    return {
      pageTitle: 'New Role Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-users-cog', routeName: 'RoleList', name: 'Manage Roles' }
        ],
        current: { icon: 'fas fa-ribbon', name: 'New Role' },
        actions: [{ icon: 'fas fa-users-cog', routeName: 'RoleList', name: 'Manage Roles' }]
      },
      status_options: ['ENABLE', 'DISABLE'],
      form: {
        name: null,
        status: 'ENABLE',
        permissions: []
      },
      permissionModules: null,
      permissions: null,
      errors: {}
    }
  },
  computed: {
    ...mapGetters('Roles', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Roles', { createRole: ROLE_CREATE, loadPermissionList: PERMISSION_LIST }),
    onStart () {
      this.errors = {}
      this.createRole(this.form)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.name}) Role is created successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Role',
            onAction: () => {
              this.$router.push({
                name: 'RoleList'
              })
            }
          })
          this.onReset()
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Role Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onReset () {
      this.errors = {}
      this.form = {
        name: '',
        status: 'ENABLE',
        permissions: []
      }
      this.$notify({
        title: 'Reset Form',
        text: 'Role form is reset with default value, please continue...',
        type: 'is-info'
      })
    },
    loadAsyncPermissions () {
      this.permissionModules = null
      this.permissions = null
      this.loadPermissionList()
        .then((response) => {
          this.permissionModules = response.data.MODULES
          this.permissions = response.data.OTHERS
        })
        .catch((error) => {
          this.$notify({
            title: 'Error (Permission Loading Failed)',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    this.onReset()
    this.loadAsyncPermissions()
  }
}
</script>
