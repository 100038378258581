<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="card card-section">
      <div class="card-content">
        <form
          @submit.prevent="onStart"
          v-if="form"
        >
          <div class="field">
            <label class="label">Name</label>
            <div class="control">
              <input
                v-model="form.name"
                class="input"
                :class="{'is-danger' : errors.name}"
                type="text"
                placeholder="Enter Nav Name"
              />
            </div>
            <p
              v-if="errors.name"
              class="help is-danger"
            >{{ errors.name | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Icon</label>
            <div class="control">
              <input
                v-model="form.icon"
                class="input"
                :class="{'is-danger' : errors.icon}"
                type="text"
                placeholder="Enter Nav Icon"
              />
            </div>
            <p
              v-if="errors.icon"
              class="help is-danger"
            >{{ errors.icon | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Link</label>
            <div class="control">
              <input
                v-model="form.link"
                class="input"
                :class="{'is-danger' : errors.link}"
                type="text"
                placeholder="Enter Nav Link"
              />
            </div>
            <p
              v-if="errors.link"
              class="help is-danger"
            >{{ errors.link | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Target</label>
            <div class="control">
              <!-- <div class="control is-expanded has-icons-left"> -->
              <v-select
                v-model="form.target"
                :options="target_options"
                :class="{'is-danger' : errors.target}"
                placeholder="Pick target for nav"
                class="is-small"
              ></v-select>
            </div>
            <p
              v-if="errors.target"
              class="help is-danger"
            >{{ errors.target | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Parent Nav</label>
            <div class="control">
              <v-select
                v-model="form.parent"
                :options="parents"
                label="name"
                :class="{'is-danger' : errors.parent}"
                placeholder="Pick parent for nav"
                class="is-small"
              ></v-select>
            </div>
            <p
              v-if="errors.parent"
              class="help is-danger"
            >{{ errors.parent | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Group</label>
            <div class="control">
              <v-select
                v-model="form.group"
                :options="group_options"
                :class="{'is-danger' : errors.group}"
                placeholder="Pick group for nav"
                class="is-small"
              ></v-select>
            </div>
            <p
              v-if="errors.group"
              class="help is-danger"
            >{{ errors.group | implode }}</p>
          </div>
          <div class="field is-grouped action-buttons">
            <div class="control">
              <button
                type="submit"
                :disabled="isLoading"
                class="button is-primary is-rounded"
                :class="{ 'is-loading': isLoading }"
              >Submit</button>
            </div>
            <div class="control">
              <button
                type="button"
                @click="onReset"
                class="button is-rounded"
              >Reset</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { NAV_CREATE, NAV_LIST } from '@/store/actions/navActions'

export default {
  name: 'NavCreate',
  components: {
    Breadcrumb
  },
  data () {
    return {
      pageTitle: 'New Nav Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-road', routeName: 'NavList', name: 'Manage Navs' }
        ],
        current: { icon: 'fas fa-plus', name: 'New Nav' },
        actions: [{ icon: 'fas fa-road', routeName: 'NavList', name: 'Manage Navs' }]
      },
      target_options: ['_blank', '_self', '_parent', '_top'],
      group_options: ['Top Nav', 'Left Main Nav', 'Right Main Nav', 'Footer Nav'],
      form: null,
      errors: {},
      parents: []
    }
  },
  computed: {
    ...mapGetters('Navs', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Navs', { createNav: NAV_CREATE, loadParents: NAV_LIST }),
    onStart () {
      this.errors = {}
      this.createNav(this.form)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.name}) Nav is created successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Nav',
            onAction: () => {
              this.$router.push({
                name: 'NavList'
              })
            }
          })
          this.onReset()
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Nav Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onReset () {
      this.errors = {}
      this.form = {
        name: null,
        icon: null,
        link: '#',
        target: '_self',
        parent: null,
        group: 'Top Nav'
      }
      this.$notify({
        title: 'Reset Form',
        text: 'Nav form is reset with default value, please continue...',
        type: 'is-info'
      })
    },
    loadAsyncParents () {
      this.loadParents()
        .then((response) => {
          this.parents = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Parent Navs Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    this.onReset()
    this.loadAsyncParents()
  }
}
</script>
