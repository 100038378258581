<template>
  <div
    class="columns"
    v-if="campaign"
  >
    <div class="column">
      <div class="field">
        <label class="label">Name</label>
        <div class="control">
          <input
            v-model="campaign.name"
            class="input"
            type="text"
            placeholder="Enter Campaign Name"
            :class="{'is-danger' : errors.name}"
          />
        </div>
        <p
          v-if="errors.name"
          class="help is-danger"
        >{{ errors.name | implode }}</p>
      </div>
      <b-field label="Agent">
        <b-autocomplete
          placeholder="Type agent to search and select"
          field="name"
          :keep-first="true"
          :data="autocomplete.agent.items"
          v-model="autocomplete.agent.value"
          :loading="autocomplete.agent.isLoading"
          @input="loadAgentsSuggestion"
          @select="option => campaign.agent = option"
        >
          <template slot="empty">No agent found</template>
          <template slot-scope="props">
            <div class="media">
              <div class="media-content">
                {{ props.option.name }}
                <br>
                <small>
                  {{ props.option.email }},
                </small>
              </div>
            </div>
          </template>
        </b-autocomplete>
        <p
          v-if="errors.agent"
          class="help is-danger"
        >{{ errors.agent | implode }}</p>
      </b-field>
      <div class="field">
        <label class="label">Campaign Type</label>
        <div class="control">
          <v-select
            v-model="campaign.type"
            :options="types"
            :class="{'is-danger' : errors.type}"
            placeholder="Pick type of campaign"
            class="is-small"
          ></v-select>
        </div>
        <p
          v-if="errors.type"
          class="help is-danger"
        >{{ errors.type | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Status</label>
        <div class="control">
          <v-select
            v-model="campaign.status"
            :options="availableStatusOptions"
            :class="{'is-danger' : errors.status}"
            placeholder="Pick status for campaign"
            class="is-small"
          ></v-select>
        </div>
        <p
          v-if="errors.status"
          class="help is-danger"
        >{{ errors.status | implode }}</p>
      </div>
      <div class="field is-campaigned action-buttons">
        <div class="control">
          <button
            type="button"
            :disabled="isLoading"
            class="button is-primary is-rounded"
            @click="formSubmit"
            :class="{ 'is-loading': isLoading }"
          >Update Campaign</button>
        </div>
      </div>
    </div>
    <div class="column is-two-fifths">
      <div
        class="card card-section"
        v-if="hasAnyScope(['CAMPAIGN-UPDATE-THUMBNAIL'])"
      >
        <header class="card-header">
          <p class="card-header-title has-background-primary has-text-white">
            Thumbnail
          </p>
        </header>
        <div class="card-image">
          <figure class="image is-4by3">
            <img
              v-if="!campaign.thumbnails"
              src="/img/placeholders/image-placeholder.png"
              alt="Campaign Thumbnail"
            >
            <img
              v-if="campaign.thumbnails"
              :src="campaign.thumbnails.large"
              alt="Campaign Thumbnail"
            >
          </figure>
        </div>
        <div class="card-content">
          <div class="file has-name is-fullwidth is-primary">
            <label class="file-label">
              <input
                class="file-input"
                type="file"
                name="thumbnailFile"
                ref="thumbnailFile"
                @change="onThumbnailChange"
                :disabled="isThumbnailLoading"
              />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">
                  Choose a file…
                </span>
              </span>
              <span class="file-name">
                {{ thumbnailName }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { CAMPAIGN_UPDATE, CAMPAIGN_THUMBNAIL } from '@/store/actions/campaignActions'
import { AGENT_LIST } from '@/store/actions/userActions'

export default {
  name: 'CampaignGeneral',
  props: {
    campaign: {
      required: true
    }
  },
  data () {
    return {
      thumbnailName: null,
      status_options: ['IDEAL', 'ENABLE', 'DISABLE'],
      types: ['SINGLE-SCREEN-KIOSK', 'SCREEN-WITH-RIGHT-SIDE-KIOSK', 'INTERACTIVE-KIOSK', 'CUSTOM-KIOSK'],
      errors: {},
      autocomplete: {
        agent: {
          value: this.campaign.agent ? this.campaign.agent.name : null,
          items: [],
          isLoading: false,
          timeout: null
        }
      },
      LOADING_ADS: 'LOADING_ADS',
      SUCCESS_ADS: 'SUCCESS_ADS',
      ERROR_ADS: 'ERROR_ADS'
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    ...mapGetters('Campaigns', ['isLoading', 'isSuccess', 'isError', 'isThumbnailLoading', 'isThumbnailSuccess', 'isThumbnailError']),
    availableStatusOptions () {
      if (this.hasAnyScope(['CAMPAIGN-STATUS-APPROVAL'])) {
        return this.status_options
      } else {
        if (this.campaign.status === 'IDEAL') {
          return this.status_options.filter((value, index, arr) => value === 'IDEAL')
        } else {
          return this.status_options.filter((value, index, arr) => value !== 'IDEAL')
        }
      }
    }
  },
  methods: {
    ...mapActions('Campaigns', { updateCampaign: CAMPAIGN_UPDATE, uploadThumbnail: CAMPAIGN_THUMBNAIL }),
    ...mapActions('Users', { loadAgents: AGENT_LIST }),
    onThumbnailChange () {
      const thumbnailFiles = this.$refs.thumbnailFile
      if (!thumbnailFiles || !thumbnailFiles.files.length) {
        return
      }
      this.thumbnailName = thumbnailFiles.files[0].name
      this.createImage(thumbnailFiles.files[0])
      this.uploadCampaignThumbnail(thumbnailFiles.files[0])
    },
    createImage (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.campaign.thumbnails = { large: e.target.result }
      }
      reader.readAsDataURL(file)
    },
    uploadCampaignThumbnail (thumbnail) {
      const thumbnailFormData = new FormData()
      thumbnailFormData.set('thumbnail', thumbnail)
      const data = {
        campaignId: this.campaign.id,
        formData: thumbnailFormData
      }
      this.uploadThumbnail(data)
        .then((response) => {
          this.$notify({
            title: 'Campaign Thumbnail',
            text: response.message,
            type: 'is-success'
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Campaign Thumbnail Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    formSubmit () {
      this.errors = {}
      const data = {
        campaignId: this.campaign.id,
        formData: this.campaign
      }
      this.updateCampaign(data)
        .then((response) => {
          this.$emit('on-campaign-update')
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.uuid}) Campaign is updated successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Campaign',
            onAction: () => {
              this.$router.push({
                name: 'CampaignList'
              })
            }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Campaign Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAgentsSuggestion () {
      clearTimeout(this.autocomplete.agent.timeout)
      this.autocomplete.agent.timeout = setTimeout(() => {
        const data = {
          query: this.autocomplete.agent.value
        }
        if (data.query) {
          this.autocomplete.agent.isLoading = true
          this.loadAgents(data)
            .then((response) => {
              this.autocomplete.agent.items = response.data
              this.autocomplete.agent.isLoading = false
            })
            .catch((error) => {
              this.autocomplete.agent.isLoading = false
              this.$notify({
                title: 'Agent Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    }
  }
}
</script>
