import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import Vue from 'vue'
import VueMoment from 'vue-moment'
import Buefy from 'buefy'
import Notifications from 'vue-notification'
import VueSelect from 'vue-select'
import vClickOutside from 'v-click-outside'
import App from './App.vue'
import router from './router'
import store from './store'
import Loading from './lib/loading.vue'
import ExportPlugin from './lib/exportPlugin.js'
import InnerLoading from './lib/InnerLoading.vue'
import Card from '@/lib/Card'

import './filters'

window.Vue = Vue

Vue.use(VueMoment)
Vue.use(ExportPlugin)
Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultDateFormatter: (date) => date.toLocaleDateString('en-GB')
})
Vue.use(Notifications)
Vue.use(vClickOutside)
Vue.component('loading', Loading)
Vue.component('inner-loading', InnerLoading)
Vue.component('v-select', VueSelect)
Vue.component('card', Card)

Vue.prototype.$eventHub = new Vue()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
