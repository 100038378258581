import {
  TAG_LIST,
  TAG_CREATE,
  TAG_SHOW,
  TAG_THUMBNAIL,
  TAG_UPDATE,
  TAG_DELETE,
  TAG_TREE_SHOW,
  TAG_TREE_UPDATE
} from '../../actions/tagActions'

import { LOADING, SUCCESS, ERROR, LOADING_THUMBNAIL, SUCCESS_THUMBNAIL, ERROR_THUMBNAIL } from '../../actions/requestStatus'
import tagApi from '../../../api/tagApi'

export default {
  namespaced: true,

  state: {
    status: '',
    thumbnailStatus: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR,
    isThumbnailLoading: (state) => state.thumbnailStatus === LOADING_THUMBNAIL,
    isThumbnailSuccess: (state) => state.thumbnailStatus === SUCCESS_THUMBNAIL,
    isThumbnailError: (state) => state.thumbnailStatus === ERROR_THUMBNAIL
  },

  actions: {
    [TAG_THUMBNAIL]: ({ commit }, { tagId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING_THUMBNAIL)
        tagApi
          .uploadThumbnail(tagId, formData)
          .then((response) => {
            commit(SUCCESS_THUMBNAIL)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR_THUMBNAIL)
            reject(error)
          })
      }),

    [TAG_SHOW]: ({ commit }, tagId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        tagApi
          .show(tagId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [TAG_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        tagApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [TAG_CREATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        tagApi
          .create(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [TAG_UPDATE]: ({ commit }, { tagId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        tagApi
          .update(tagId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [TAG_DELETE]: ({ commit }, tagId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        tagApi
          .delete(tagId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [TAG_TREE_SHOW]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        tagApi
          .tree()
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [TAG_TREE_UPDATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        tagApi
          .treeUpdate(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    },
    [LOADING_THUMBNAIL]: (state) => {
      state.thumbnailStatus = LOADING
    },
    [SUCCESS_THUMBNAIL]: (state) => {
      state.thumbnailStatus = SUCCESS
    },
    [ERROR_THUMBNAIL]: (state) => {
      state.thumbnailStatus = ERROR
    }
  }
}
