import {
  USER_LIST,
  USER_CREATE,
  USER_SHOW,
  USER_UPDATE,
  USER_DELETE,
  CHANNEL_LIST,
  AGENT_LIST,
  DISTRIBUTOR_LIST,
  ADVERTISER_LIST,
  USER_SUGGENTIONS
} from '../../actions/userActions'
import { LOADING, SUCCESS, ERROR } from '../../actions/requestStatus'
import userApi from '../../../api/userApi'

export default {
  namespaced: true,

  state: {
    status: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR
  },

  actions: {
    [USER_SHOW]: ({ commit }, userId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        userApi
          .show(userId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [CHANNEL_LIST]: (context, filterData) =>
      new Promise((resolve, reject) => {
        userApi
          .listChannels(filterData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [AGENT_LIST]: (context, filterData) =>
      new Promise((resolve, reject) => {
        userApi
          .listAgents(filterData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DISTRIBUTOR_LIST]: (context, filterData) =>
      new Promise((resolve, reject) => {
        userApi
          .listDistributors(filterData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [USER_SUGGENTIONS]: (context, filterData) =>
      new Promise((resolve, reject) => {
        userApi
          .listUserSuggestions(filterData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [ADVERTISER_LIST]: (context, filterData) =>
      new Promise((resolve, reject) => {
        userApi
          .listAdvertisers(filterData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [USER_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        userApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [USER_CREATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        userApi
          .create(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [USER_UPDATE]: ({ commit }, { userId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        userApi
          .update(userId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [USER_DELETE]: ({ commit }, userId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        userApi
          .delete(userId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    }
  }
}
