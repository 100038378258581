<template>
  <div
    class="card reservation-card"
    v-if="item"
    @click="itemClicked"
    :class="{ 'is-selected' : isItemSelected, 'd-online': item.device.live, 'd-offline': !item.device.live }"
  >
    <div class="card-image">
      <figure class="image is-4by3">
        <img
          v-if="!item.device.thumbnails"
          src="/img/placeholders/image-placeholder.png"
          alt="Placeholder image"
        >
        <img
          v-if="item.device.thumbnails"
          :src="item.device.thumbnails.large"
          alt="Device Image"
        >
      </figure>
    </div>
    <div class="card-content card-content-padding">
      <div class="media">
        <div class="media-left">
          <figure class="image is-32x32">
            <img
              class="is-rounded has-background-primary"
              :src="item.device.agent.avatar['extra-small']"
              alt="Agent Image"
            >
          </figure>
        </div>
        <div
          class="media-content"
          v-if="item.device.agent"
        >
          <p class="title is-6">{{ item.device.agent.name }} @Agent</p>
          <p class="subtitle tags is-7">{{ item.device.uuid }} @UUID</p>
        </div>
      </div>
      <div class="content">
        <div class="field is-grouped is-grouped-multiline">
          <div class="control">
            <div class="tags has-addons">
              <span class="tag is-dark">Viewers</span>
              <span class="tag is-info">{{ item.device.footfall }}</span>
            </div>
          </div>
          <div
            class="control"
            v-if="item.device.screen_size"
          >
            <div class="tags has-addons">
              <span class="tag is-dark">Size</span>
              <span class="tag is-success">{{ item.device.screen_size }} &Prime;</span>
            </div>
          </div>
          <div
            class="control"
            v-if="item.device.screen_type"
          >
            <div class="tags has-addons">
              <span class="tag is-dark">Screen</span>
              <span class="tag is-success">{{ item.device.screen_type }}</span>
            </div>
          </div>
        </div>
        <div
          class="control card-style"
          v-if="item.device.prices"
        >
          <h6 class="stick-header">Paisa/Sec</h6>
          <div
            class="tags has-addons"
            v-for="price in item.device.prices"
            :key="`price-${price.id}`"
          >
            <span class="tag is-dark">{{ price.amount }}</span>
            <span class="tag is-success">{{ price.interval }}</span>
          </div>
        </div>
        <p><strong>Address: </strong>{{ item.device.address }}</p>
        <div class="control card-style">
          <h6 class="stick-header">Locations</h6>
          <div class="tags-text">
            <span
              v-for="location in item.device.locations"
              :key="`location-${location.id}`"
            >{{ location.name }}</span>
          </div>
        </div>
        <div class="control card-style">
          <h6 class="stick-header">Places</h6>
          <div class="tags-text">
            <span
              v-for="place in item.device.categories"
              :key="`place-${place.id}`"
            >{{ place.name }}</span>
          </div>
        </div>
        <div class="control card-style">
          <h6 class="stick-header">Ads Tagged</h6>
          <div class="tags-text">
            <span
              class="has-text-success"
              v-for="wtag in item.device.tags.whitelist"
              :key="`wtag-${wtag.id}`"
            >{{ wtag.name }}</span>
            <span
              class="has-text-danger"
              v-for="btag in item.device.tags.blacklist"
              :key="`btag-${btag.id}`"
            >{{ btag.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BookingReservation',
  props: {
    item: {
      required: true
    },
    bookingIds: {
      required: true
    }
  },
  computed: {
    isItemSelected () {
      return this.bookingIds.includes(this.item.device.id)
    }
  },
  methods: {
    itemClicked () {
      this.$emit('selected', this.item.device.id)
    }
  }
}
</script>

<style lang="sass" scoped>
.control.card-style
  background-color: #dadada
  padding: 20px 10px 10px 10px
  margin-bottom: 5px
  .stick-header
    position: absolute
    left: 0px
    top: 0px
    background-color: #0882b1
    color: #fff
    padding: 5px
    font-size: 10px
    border-radius: 0px 0px 6px 0px
  .tags
    display: inline-flex
    margin-right: 10px !important
    margin-bottom: 0px !important
.tags-text
  font-size: 12px
  font-weight: 700
.tags-text > span::before
  content: ', '
.tags-text > span:first-child::before
  content: ''
.card-content-padding
  padding: 0.5rem
</style>
