<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">Places</label>
          <div class="control">
            <treeselect
              placeholder="Select places for the campaign..."
              value-consists-of="ALL_WITH_INDETERMINATE"
              v-model="categories"
              :multiple="true"
              :options="categoryTree"
              :normalizer="normalizer"
            ></treeselect>
          </div>
        </div>
        <div class="field">
          <label class="label">Categories</label>
          <div class="control">
            <treeselect
              placeholder="Select categories for the campaign..."
              value-consists-of="ALL_WITH_INDETERMINATE"
              v-model="tags"
              :multiple="true"
              :options="tagTree"
              :normalizer="normalizer"
            ></treeselect>
          </div>
        </div>
      </div>
      <div class="column is-two-fifths">
        <div class="field">
          <label class="label">Locations</label>
          <div class="control">
            <treeselect
              placeholder="Select locations for the campaign..."
              value-consists-of="ALL_WITH_INDETERMINATE"
              v-model="locations"
              :multiple="true"
              :options="locationTree"
              :normalizer="normalizer"
            ></treeselect>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-campaigned action-buttons">
      <div class="control">
        <button
          type="button"
          :disabled="isLoading"
          class="button is-primary is-rounded"
          @click="formSubmit"
          :class="{ 'is-loading': isLoading }"
        >Update Relationships</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { CAMPAIGN_RELATION_UPDATE } from '@/store/actions/campaignActions'

export default {
  name: 'CampaignLinks',
  components: { Treeselect },
  props: {
    campaign: {
      required: true
    },
    categoryTree: {
      required: true
    },
    locationTree: {
      required: true
    },
    tagTree: {
      required: true
    }
  },
  data () {
    return {
      locations: this.campaign.locations.map((location) => location.id),
      categories: this.campaign.categories.map((category) => category.id),
      tags: this.campaign.tags.map((tag) => tag.id),
      errors: {}
    }
  },
  computed: {
    ...mapGetters('Campaigns', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Campaigns', { updateCampaignRelationship: CAMPAIGN_RELATION_UPDATE }),
    normalizer (node) {
      return {
        id: node.id,
        label: node.name,
        children: node.children
      }
    },
    formSubmit () {
      this.errors = {}
      const formData = {
        categories: this.categories,
        locations: this.locations,
        tags: this.tags
      }
      const data = {
        campaignId: this.campaign.id,
        formData
      }
      this.updateCampaignRelationship(data)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.uuid}) Campaign relationship is updated successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Campaign',
            onAction: () => {
              this.$router.push({
                name: 'CampaignList'
              })
            }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Campaign Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
