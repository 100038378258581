<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="card card-section">
      <div class="card-content">
        <div v-if="items">
          <div class="field is-grouped action-buttons">
            <div class="control">
              <b-select
                v-model="groupTree"
                @input="onGroupChange"
              >
                <option
                  v-for="groupOption in group_options"
                  :key="`group-${groupOption}`"
                  :value="groupOption"
                >{{ groupOption }}</option>
              </b-select>
            </div>
            <div class="control">
              <button
                type="button"
                class="button is-primary is-rounded"
                @click="onTreeUpdate"
                :disabled="isLoading"
                :class="{ 'is-loading': isLoading }"
              >Save Nav Tree</button>
            </div>
          </div>
          <c-tree
            :data="items"
            draggable="draggable"
            cross-tree="cross-tree"
          >
            <div
              slot-scope="{data, store}"
              :class="[ (data.status === 'DISABLE') ? 'disable-item' : '']"
            >
              <template>
                <b
                  v-if="data.children &amp;&amp; data.children.length"
                  @click="store.toggleOpen(data)"
                >{{data.open ? '-' : '+'}}&nbsp;</b>
                <span>{{data.name}}</span>
              </template>
            </div>
          </c-tree>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { DraggableTree } from 'vue-draggable-nested-tree'
import { mapGetters, mapActions } from 'vuex'
import { NAV_TREE_SHOW, NAV_TREE_UPDATE } from '@/store/actions/navActions'

export default {
  name: 'NavTree',
  components: {
    Breadcrumb,
    'c-tree': DraggableTree
  },
  data () {
    return {
      pageTitle: 'Manage Navs Tree',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-road', routeName: 'NavList', name: 'Manage Navs' }
        ],
        current: { icon: 'fas fa-tree', name: 'Navs Tree' },
        actions: [{ icon: 'fas fa-road', routeName: 'NavList', name: 'Manage Navs' }]
      },
      group_options: ['Top Nav', 'Left Main Nav', 'Right Main Nav', 'Footer Nav'],
      groupTree: 'Top Nav',
      items: []
    }
  },
  computed: {
    ...mapGetters('Navs', ['isLoading', 'isSuccess', 'isError']),
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    }
  },
  methods: {
    ...mapActions('Navs', { loadTree: NAV_TREE_SHOW, updateTree: NAV_TREE_UPDATE }),
    loadAsyncData () {
      const group = this.groupTree
      this.loadTree(group)
        .then((response) => {
          this.items = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Navs Tree Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    getTree (items) {
      return items.map((item) => (
        {
          id: item.id,
          name: item.name,
          subnavs: item.children ? this.getTree(item.children) : []
        }
      ))
    },
    onTreeUpdate () {
      const itemsTree = this.getTree(this.items)
      this.updateTree(itemsTree)
        .then(() => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: 'Nav Tree is updated successfully',
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Nav',
            onAction: () => {
              this.$router.push({
                name: 'NavList'
              })
            }
          })
        })
        .catch((error) => {
          this.$notify({
            title: 'Invalid Nav Tree',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    onGroupChange () {
      this.loadAsyncData()
    }
  },
  created () {
    this.loadAsyncData()
  }
}
</script>

<style lang="css">
.tree-node-inner {
  padding: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.draggable-placeholder-inner {
  border: 1px dashed #0088f8;
  box-sizing: border-box;
  background: rgba(0, 136, 249, 0.09);
  color: #0088f9;
  text-align: center;
  padding: 0;
  display: flex;
  align-items: center;
}
.tree-node-inner .disable-item {
  background-color: #ffcfcf;
}
</style>
