<template>
  <div>
    <div class="box is-hidden-tablet">
      <b-field>
        <b-radio
          v-model="filter.type"
          native-value="VIDEO"
          v-show="!isOrderAdsProvided"
        >VIDEO ADS</b-radio>
        <b-radio
          v-model="filter.type"
          native-value="TEXT"
          v-show="!isOrderAdsProvided"
        >TEXT ADS</b-radio>
        <b-radio
          v-if="isOrderAdsProvided"
          v-model="filter.type"
          native-value="VIDEO"
        >ORDER VIDEO ADS</b-radio>
      </b-field>
      <b-field>
        <b-input
          v-model="filter.query"
          placeholder="Type to search"
          :disabled="isOrderAdsProvided"
        ></b-input>
      </b-field>
      <span class="tag is-info">Select your ads to continue</span>
    </div>
    <div class="box is-hidden-mobile">
      <div class="field is-grouped">
        <p class="control is-expanded">
          <b-input
            v-model="filter.query"
            placeholder="Type to search"
            :disabled="isOrderAdsProvided"
          ></b-input>
        </p>
        <p class="control">
          <b-radio
            v-model="filter.type"
            native-value="VIDEO"
            v-show="!isOrderAdsProvided"
          >VIDEO ADS</b-radio>
          <b-radio
            v-model="filter.type"
            native-value="TEXT"
            v-show="!isOrderAdsProvided"
          >TEXT ADS</b-radio>
          <b-radio
            v-if="isOrderAdsProvided"
            v-model="filter.type"
            native-value="VIDEO"
          >ORDER VIDEO ADS</b-radio>
        </p>
      </div>
      <span class="tag is-info">Select your ads to continue</span>
    </div>
    <back-to-top
      visibleoffset="170"
      class="offsetforms"
      style="bottom: none; right: none;"
    >
      <div class="box is-hidden-tablet">
        <b-field>
          <b-radio
            v-model="filter.type"
            native-value="VIDEO"
            v-show="!isOrderAdsProvided"
          >VIDEO ADS</b-radio>
          <b-radio
            v-model="filter.type"
            native-value="TEXT"
            v-show="!isOrderAdsProvided"
          >TEXT ADS</b-radio>
          <b-radio
            v-if="isOrderAdsProvided"
            v-model="filter.type"
            native-value="VIDEO"
          >ORDER VIDEO ADS</b-radio>
        </b-field>
        <b-field>
          <b-input
            v-model="filter.query"
            placeholder="Type to search"
            :disabled="isOrderAdsProvided"
          ></b-input>
        </b-field>
        <span class="tag is-info">Select your ads to continue</span>
      </div>
      <div class="box is-hidden-mobile">
        <div class="field is-grouped">
          <p class="control is-expanded">
            <b-input
              v-model="filter.query"
              placeholder="Type to search"
              :disabled="isOrderAdsProvided"
            ></b-input>
          </p>
          <p class="control">
            <b-radio
              v-model="filter.type"
              native-value="VIDEO"
              v-show="!isOrderAdsProvided"
            >VIDEO ADS</b-radio>
            <b-radio
              v-model="filter.type"
              native-value="TEXT"
              v-show="!isOrderAdsProvided"
            >TEXT ADS</b-radio>
            <b-radio
              v-if="isOrderAdsProvided"
              v-model="filter.type"
              native-value="VIDEO"
            >ORDER VIDEO ADS</b-radio>
          </p>
        </div>
        <span class="tag is-info">Select your ads to continue</span>
      </div>
    </back-to-top>
    <div class="box">
      <div
        class="columns is-multiline is-mobile"
        v-if="items"
      >
        <div
          class="column is-full-mobile is-one-third-tablet is-one-quarter-desktop"
          v-for="item in items"
          :key="`item-${item.id}`"
        >
          <booking-video-advertisement
            v-if="item.type === 'Video Ads'"
            :ads="ads"
            :item="item"
            @selected="onAdvertisementSelect"
          ></booking-video-advertisement>
          <booking-text-advertisement
            v-if="item.type === 'Text Ads'"
            :ads="ads"
            :item="item"
            @selected="onAdvertisementSelect"
          ></booking-text-advertisement>
        </div>
      </div>
      <infinite-loading
        :identifier="infiniteId"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <h1
          slot="no-more"
          class="title is-3 has-text-centered"
        >There is no more advertisement found!</h1>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import BackToTop from 'vue-backtotop'
import BookingVideoAdvertisement from './BookingVideoAdvertisement'
import BookingTextAdvertisement from './BookingTextAdvertisement'
import { SEARCH_ADVERTISEMENTS } from '@/store/actions/bookingActions'

export default {
  name: 'BookingAdsPicker',
  components: { InfiniteLoading, BookingVideoAdvertisement, BookingTextAdvertisement, BackToTop },
  props: {
    orderAds: {
      required: true,
      default: null
    },
    waitForOrder: {
      required: true,
      default: () => true
    }
  },
  data () {
    return {
      filter: {
        query: '',
        type: 'VIDEO',
        ads_id: null
      },
      filterTimout: null,
      nextUrl: null,
      meta: null,
      items: [],
      ads: null,
      infiniteId: +new Date()
    }
  },
  watch: {
    filter: {
      handler: 'onFilterChanged',
      deep: true
    },
    waitForOrder: {
      handler: 'updateAdsIdToFilter'
    }
  },
  computed: {
    isOrderAdsProvided () {
      return this.orderAds !== null
    }
  },
  methods: {
    ...mapActions('Bookings', { searchAdvertisements: SEARCH_ADVERTISEMENTS }),
    onAdvertisementSelect (item) {
      const data = {
        type: this.filter.type,
        ads: item
      }
      this.ads = item
      this.$emit('ads-picked', data)
    },
    updateAdsIdToFilter () {
      if (this.isOrderAdsProvided) {
        this.filter.ads_id = this.orderAds.id
      }
    },
    onFilterChanged () {
      if (!this.waitForOrder) {
        clearTimeout(this.filterTimout)
        this.filterTimout = setTimeout(() => {
          this.nextUrl = null
          this.items = []
          this.$nextTick(() => {
            this.infiniteId += 1
          })
        }, 1000)
      }
    },
    onSearch () {
      return new Promise((resolve, reject) => {
        const formData = {
          type: this.filter.type,
          filterData: this.filter,
          nextUrl: this.nextUrl
        }
        this.searchAdvertisements(formData)
          .then((response) => {
            resolve({ meta: response.meta, data: response.data, links: response.links })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    infiniteHandler ($state) {
      if (!this.waitForOrder) {
        this.onSearch().then(({ meta, data, links }) => {
          this.meta = meta
          data.forEach((ads) => {
            this.items.push(ads)
          })
          $state.loaded()
          if (links.next === null) {
            $state.complete()
          } else {
            this.nextUrl = links.next
          }
          if (this.isOrderAdsProvided && this.items.length > 0) {
            this.onAdvertisementSelect(this.items[0])
          }
        })
      }
    }
  }
}
</script>
