import { axios } from './axios'

export default {
  advertisementSearch (type, filterData, nextUrl) {
    return new Promise((resolve, reject) => {
      let url = `/api/booking/advertisement/search/${type}`
      if (nextUrl !== null) {
        url = nextUrl
      }
      axios
        .post(url, filterData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  listAdvertisement (type, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/booking/advertisements/${type}`, {
          params: filterData
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },

  loadCategoryTree () {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/booking/categories/tree')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  loadLocationTree () {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/booking/locations/tree')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  loadTagTree () {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/booking/tags/tree')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  reservationSearch (filterData) {
    return new Promise((resolve, reject) => {
      let url = '/api/booking/reservation/search'
      if (filterData.next !== null) {
        url = filterData.next
      }
      axios
        .post(url, filterData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  processBookingReservation (type, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/booking/reservation/booking/${type.toLowerCase()}`, filterData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  }
}
