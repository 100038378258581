<template>
  <div class="card modal-card">
    <header class="card-header">
      <p class="card-header-title">Recharge Wallet Manually</p>
    </header>
    <div class="card-content">
      <form @submit.prevent="onRechargeSubmit">
        <div class="field">
          <label class="label">Recharge Amount</label>
          <div class="control">
            <input
              class="input"
              type="number"
              step="any"
              placeholder="Enter Recharge Amount"
              v-model="form.amount"
              :class="{'is-danger' : errors.name}"
            />
          </div>
          <p
            v-if="errors.amount"
            class="help is-danger"
          >{{ errors.amount | implode }}</p>
        </div>
        <div class="field">
          <label class="label">Transaction By</label>
          <div class="control">
            <v-select
              v-model="form.transaction_method"
              :options="transaction_method_options"
              :class="{'is-danger' : errors.transaction_method}"
              placeholder="Pick transaction method"
              class="is-small"
            ></v-select>
          </div>
          <p
            v-if="errors.transaction_method"
            class="help is-danger"
          >{{ errors.transaction_method | implode }}</p>
        </div>
        <div
          class="field"
          v-if="!isTransactionByCash"
        >
          <label class="label">Transaction ID</label>
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="Enter Transaction ID"
              v-model="form.transaction_id"
              :class="{'is-danger' : errors.transaction_id}"
            />
          </div>
          <p
            v-if="errors.transaction_id"
            class="help is-danger"
          >{{ errors.transaction_id | implode }}</p>
        </div>
        <div
          class="field"
          v-if="isBankTransaction"
        >
          <label class="label">Bank Name</label>
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="Enter Bank Name"
              v-model="form.transaction_bank"
              :class="{'is-danger' : errors.transaction_bank}"
            />
          </div>
          <p
            v-if="errors.transaction_bank"
            class="help is-danger"
          >{{ errors.transaction_bank | implode }}</p>
        </div>
        <div class="field">
          <label class="label">Remarks</label>
          <div class="control">
            <textarea
              class="textarea"
              placeholder="Enter Remarks"
              v-model="form.remarks"
              :class="{'is-danger' : errors.remarks}"
            ></textarea>
          </div>
          <p
            v-if="errors.remarks"
            class="help is-danger"
          >{{ errors.remarks | implode }}</p>
        </div>
        <div class="field is-grouped action-buttons">
          <div class="control">
            <button
              type="submit"
              class="button is-primary is-rounded"
              :disabled="isRechargeLoading"
              :class="{ 'is-loading': isRechargeLoading }"
            >Submit Recharge</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { WALLET_RECHARGE } from '@/store/actions/walletActions'

export default {
  name: 'WalletRechargeForm',
  props: {
    user: {
      required: true
    },
    type: {
      required: true
    }
  },
  data () {
    return {
      form: {
        amount: 0,
        transaction_method: 'CASH',
        transaction_id: '',
        transaction_bank: '',
        remarks: ''
      },
      errors: {},
      transaction_method_options: ['CASH', 'CHEQUE', 'DEBIT CARD', 'CREDIT CARD', 'NEFT/IMPS/RTGS', 'PAYTM']
    }
  },
  computed: {
    ...mapGetters('Wallets', ['isRechargeLoading', 'isRechargeSuccess', 'isRechargeError']),
    isBankTransaction () {
      return this.form.transaction_method === 'DEBIT CARD' ||
        this.form.transaction_method === 'CREDIT CARD' ||
        this.form.transaction_method === 'CHEQUE' ||
        this.form.transaction_method === 'NEFT/IMPS/RTGS'
    },
    isTransactionByCash () {
      return this.form.transaction_method === 'CASH'
    }
  },
  methods: {
    ...mapActions('Wallets', { rechargeWallet: WALLET_RECHARGE }),
    onRechargeSubmit () {
      if (this.form.amount <= 0 || this.form.transaction_method === null || this.form.transaction_method === '') {
        this.$notify({
          title: 'Invalid Wallet Recharge Form',
          text: 'Wrong amount or transaction type...',
          type: 'is-danger'
        })
      } else if (!this.isTransactionByCash && this.form.transaction_id === '') {
        this.$notify({
          title: 'Invalid Wallet Recharge Form',
          text: 'Enter valid transaction number...',
          type: 'is-danger'
        })
      } else if ((this.isBankTransaction) && this.form.transaction_bank === '') {
        this.$notify({
          title: 'Invalid Wallet Recharge Form',
          text: 'Enter valid bank name...',
          type: 'is-danger'
        })
      } else {
        const data = {
          userId: this.user.id,
          formData: {
            ...this.form,
            type: this.type
          }
        }
        this.rechargeWallet(data)
          .then((response) => {
            this.$notify({
              title: 'User Wallet Recharged',
              text: response.message,
              type: 'is-success'
            })
            this.$parent.$emit('on-update')
            this.$parent.close()
          })
          .catch((error) => {
            this.errors = error.errors
            this.$notify({
              title: 'Invalid Recharge Inputs',
              text: error.message,
              type: 'is-danger'
            })
          })
      }
    }
  }
}
</script>
