import {
  SLIDER_LIST,
  SLIDER_CREATE,
  SLIDER_SHOW,
  SLIDER_UPDATE,
  SLIDER_DELETE,
  SLIDE_CREATE,
  SLIDE_DELETE,
  SLIDE_UPDATE
} from '../../actions/sliderActions'

import { LOADING, SUCCESS, ERROR } from '../../actions/requestStatus'
import sliderApi from '../../../api/sliderApi'

export default {
  namespaced: true,

  state: {
    status: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR
  },

  actions: {
    [SLIDE_CREATE]: (context, { sliderId, formData }) =>
      new Promise((resolve, reject) => {
        sliderApi
          .uploadSlide(sliderId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [SLIDE_UPDATE]: (context, { sliderId, slideId, formData }) =>
      new Promise((resolve, reject) => {
        sliderApi
          .updateSlide(sliderId, slideId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [SLIDE_DELETE]: (context, { sliderId, slideId }) =>
      new Promise((resolve, reject) => {
        sliderApi
          .deleteSlide(sliderId, slideId)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [SLIDER_SHOW]: ({ commit }, sliderId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        sliderApi
          .show(sliderId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [SLIDER_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        sliderApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [SLIDER_CREATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        sliderApi
          .create(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [SLIDER_UPDATE]: ({ commit }, { sliderId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        sliderApi
          .update(sliderId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [SLIDER_DELETE]: ({ commit }, sliderId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        sliderApi
          .delete(sliderId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    }
  }
}
