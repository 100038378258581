<template>
  <b-field
    label="Places (Category)"
    custom-class="is-small"
  >
    <treeselect
      placeholder="Pick Places..."
      value-consists-of="BRANCH_PRIORITY"
      v-model="values"
      :multiple="true"
      :options="options"
      :normalizer="normalizer"
      @input="onValueChange"
    />
  </b-field>
</template>

<script>
import { mapActions } from 'vuex'
import treekMixins from './tree-mixin'
import { CATEGORY_TREE } from '@/store/actions/bookingActions'

export default {
  name: 'PlaceTree',
  mixins: [treekMixins],
  data () {
    return {
      type: 'Places'
    }
  },
  methods: {
    ...mapActions('Bookings', { loadOptions: CATEGORY_TREE })
  }
}
</script>
