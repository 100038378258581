<template>
  <b-field
    :label="fieldName"
    custom-class="is-small"
  >
    <treeselect
      :placeholder="`Pick ${fieldName}`"
      value-consists-of="BRANCH_PRIORITY"
      v-model="values"
      :multiple="true"
      :options="options"
      :normalizer="normalizer"
      @input="onValueChange"
    />
  </b-field>
</template>

<script>
import { mapActions } from 'vuex'
import treekMixins from './tree-mixin'
import { TAG_TREE } from '@/store/actions/bookingActions'

export default {
  name: 'TagTree',
  props: {
    fieldName: {
      default: 'Categories'
    }
  },
  mixins: [treekMixins],
  data () {
    return {
      type: 'Tags'
    }
  },
  methods: {
    ...mapActions('Bookings', { loadOptions: TAG_TREE })
  }
}
</script>
