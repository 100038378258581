import {
  DEVICE_LIST,
  DEVICE_CREATE,
  DEVICE_SHOW,
  DEVICE_SHOW_DETAILS,
  DEVICE_UPDATE,
  DEVICE_RELATION_UPDATE,
  DEVICE_PRICE_UPDATE,
  DEVICE_DELETE,
  DEVICE_THUMBNAIL,
  DEVICE_ADS,
  DEVICE_TEXT_TO_ADS,
  GALLERY_CREATE,
  GALLERY_DELETE,
  DEVICE_WEBSITE_USER_LOG_LIST,
  DEVICE_PING_LIST,
  DEVICE_PING_CREATE,
  DEVICE_REPORT_OPERATION,
  DEVICE_REPORT_REVENUE,
  DEVICE_REPORT_AGGREGATE_OPERATION,
  DEVICE_REPORT_AGGREGATE_REVENUE
} from '../../actions/deviceActions'

import { LOADING, SUCCESS, ERROR, LOADING_THUMBNAIL, SUCCESS_THUMBNAIL, ERROR_THUMBNAIL } from '../../actions/requestStatus'
import deviceApi from '../../../api/deviceApi'

export default {
  namespaced: true,

  state: {
    status: '',
    thumbnailStatus: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR,
    isThumbnailLoading: (state) => state.thumbnailStatus === LOADING_THUMBNAIL,
    isThumbnailSuccess: (state) => state.thumbnailStatus === SUCCESS_THUMBNAIL,
    isThumbnailError: (state) => state.thumbnailStatus === ERROR_THUMBNAIL
  },

  actions: {
    [GALLERY_CREATE]: (context, { deviceId, formData }) =>
      new Promise((resolve, reject) => {
        deviceApi
          .uploadGallery(deviceId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [GALLERY_DELETE]: (context, { deviceId, galleryId }) =>
      new Promise((resolve, reject) => {
        deviceApi
          .deleteGallery(deviceId, galleryId)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_THUMBNAIL]: ({ commit }, { deviceId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING_THUMBNAIL)
        deviceApi
          .uploadThumbnail(deviceId, formData)
          .then((response) => {
            commit(SUCCESS_THUMBNAIL)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR_THUMBNAIL)
            reject(error)
          })
      }),

    [DEVICE_ADS]: (context, { deviceId, formData }) =>
      new Promise((resolve, reject) => {
        deviceApi
          .uploadAds(deviceId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_SHOW]: ({ commit }, deviceId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceApi
          .show(deviceId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_REPORT_OPERATION]: ({ commit }, { deviceId, filterData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceApi
          .reportOperation(deviceId, filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_REPORT_REVENUE]: ({ commit }, { deviceId, filterData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceApi
          .reportRevenue(deviceId, filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_REPORT_AGGREGATE_OPERATION]: ({ commit }, { deviceId, filterData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceApi
          .reportAggregateOperation(deviceId, filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_REPORT_AGGREGATE_REVENUE]: ({ commit }, { deviceId, filterData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceApi
          .reportAggregateRevenue(deviceId, filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_SHOW_DETAILS]: ({ commit }, deviceId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceApi
          .showDetails(deviceId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_LIST]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceApi
          .list(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_CREATE]: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceApi
          .create(formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_UPDATE]: ({ commit }, { deviceId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceApi
          .update(deviceId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_TEXT_TO_ADS]: (context, { deviceId, formData }) =>
      new Promise((resolve, reject) => {
        deviceApi
          .uploadAdsByText(deviceId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_PRICE_UPDATE]: ({ commit }, { deviceId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceApi
          .updateDevicePrices(deviceId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_RELATION_UPDATE]: ({ commit }, { deviceId, formData }) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceApi
          .updateDeviceRelation(deviceId, formData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_DELETE]: ({ commit }, deviceId) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        deviceApi
          .delete(deviceId)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      }),

    [DEVICE_WEBSITE_USER_LOG_LIST]: (context, { deviceId, formData }) =>
      new Promise((resolve, reject) => {
        deviceApi
          .listWebsiteUserLogs(deviceId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_PING_LIST]: (context, { deviceId, formData }) =>
      new Promise((resolve, reject) => {
        deviceApi
          .listPings(deviceId, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [DEVICE_PING_CREATE]: (context, { deviceId }) =>
      new Promise((resolve, reject) => {
        deviceApi
          .createPings(deviceId)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    },
    [LOADING_THUMBNAIL]: (state) => {
      state.thumbnailStatus = LOADING_THUMBNAIL
    },
    [SUCCESS_THUMBNAIL]: (state) => {
      state.thumbnailStatus = SUCCESS_THUMBNAIL
    },
    [ERROR_THUMBNAIL]: (state) => {
      state.thumbnailStatus = ERROR_THUMBNAIL
    }
  }
}
