import Vue from 'vue'
import humanizeDuration from 'humanize-duration'

Vue.filter('implode', (items) => {
  let result = items
  if (typeof items === 'object') {
    result = items.join(', ')
  }
  return result
})

Vue.filter('yesOrNo', (value) => (value ? 'YES' : 'NO'))

Vue.filter('implodeWithBreak', (items) => {
  let result = items
  if (typeof items === 'object') {
    result = items.join('<br/>')
  }
  return result
})

Vue.filter('money', (amount, count = 2) => {
  return parseFloat(Number(amount).toFixed(2))
})

Vue.filter('humanizeDuration', (seconds) => {
  return humanizeDuration(seconds * 1000, { units: ['h', 'm', 's'] })
})

Vue.filter('lowercase', (value) => {
  return value.toLowerCase()
})

Vue.filter('titleize', (value) => {
  return value.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())
})

Vue.filter('nounderscore', (value) => {
  return value.replace(/_/g, ' ')
})
