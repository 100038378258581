<template>
  <div>
    <!-- <loading v-show="isListLoading"></loading> -->
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <advance-filter
      :is-loading="isListLoading"
      @onStart="onFilter"
    >
      <div class="columns is-multiline">
        <div class="column field is-3">
          <label class="label is-small">Query</label>
          <p class="control has-icons-left">
            <input
              class="input"
              type="text"
              placeholder="search"
              v-model="filter.query"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-search"></i>
            </span>
          </p>
        </div>
        <div class="column field is-3">
          <label class="label is-small">Device Status</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              multiple
              v-model="filter.statuses"
              :options="status_options"
              placeholder="Pick statuses for filter"
              class="is-small"
            ></v-select>
          </div>
        </div>
        <div class="column field is-3">
          <label class="label is-small">Is Live</label>
          <div class="control is-expanded has-icons-left">
            <v-select v-model="filter.live" :options="live_options" placeholder="Pick live status for filter" class="is-small">
            </v-select>
          </div>
        </div>
      </div>
    </advance-filter>

    <div class="card card-section">
      <div class="card-content">
        <b-field
          grouped
          group-multiline
        >
          <b-select
            v-model="filter.size"
            :disabled="!hasItems"
            @input="onFilter"
          >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </b-select>
        </b-field>
        <b-table
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :mobile-cards="true"
          :loading="isListLoading"
          paginated
          backend-pagination
          :per-page="filter.size"
          :total="meta.total"
          @page-change="onPageChange"
          backend-sorting
          default-sort-direction="desc"
          :default-sort="[filter.sortField, filter.sortOrder]"
          @sort="onSort"
          :data="items"
        >
          <b-table-column
            label="S.No."
            width="40"
            numeric
            v-slot="props"
          >
            {{ meta.from + props.index }}
          </b-table-column>
          <b-table-column
            field="uuid"
            label="UUID"
            sortable
            v-slot="props"
          >
            {{ props.row.uuid }}
          </b-table-column>
          <b-table-column
            field="name"
            label="Name"
            sortable
            v-slot="props"
          >
            {{ props.row.name }}
          </b-table-column>
          <b-table-column
            field="agent_id"
            label="Agent"
            v-slot="props"
          >
            <div v-if="props.row.agent">
              <strong>{{ props.row.agent.name }}</strong><br />
              <span>{{ props.row.agent.email }}</span>
            </div>
          </b-table-column>
          <b-table-column
            field="distributor_id"
            label="Distributor"
            v-slot="props"
          >
            <div v-if="props.row.distributor">
              <strong>{{ props.row.distributor.name }}</strong><br />
              <span>{{ props.row.distributor.email }}</span>
            </div>
          </b-table-column>
          <b-table-column field="status" label="Status" sortable v-slot="props">
            {{ props.row.status }}
          </b-table-column>
          <b-table-column field="live" width="275" label="Is Online?" sortable v-slot="props">
            <div>
              <span class="tag is-normal" :class="props.row.live ? 'is-success' : 'is-danger'">{{ props.row.live ? 'Online' :
                'Offline' }}</span>
            </div>
            <div>
              <strong>Ping At:</strong>&nbsp;<span>{{ props.row.live_at ? props.row.live_at : 'Not Activated' }}</span>
            </div>
          </b-table-column>
          <b-table-column
            label=""
            v-slot="props"
          >
            <div class="buttons">
              <router-link
                :to="{ name: 'MyDeviceAds', params: { deviceId: props.row.id }}"
                class="button is-small is-success"
                v-if="isAgentView && props.row.agent.id === auth_id"
              >
                <span class="icon">
                  <i class="fas fa-cloud-upload-alt"></i>
                </span>
                <span>DEFAULT ADS</span>
              </router-link>
              <!-- <router-link :to="{ name: 'MyDeviceShow', params: { deviceId: props.row.id }}"
                class="button is-small is-success">
                <span class="icon">
                  <i class="fas fa-eye"></i>
                </span>
                <span>DETAILS</span>
              </router-link> -->
              <router-link
                :to="{ name: 'MyDeviceEdit', params: { deviceId: props.row.id, openTab: 1 }}"
                class="button is-small is-success"
                v-if="isAgentView && props.row.agent.id === auth_id"
              >
                <span class="icon">
                  <i class="fas fa-edit"></i>
                </span>
                <span>GALLERY IMAGES</span>
              </router-link>
              <!-- <router-link :to="{ name: 'MyDeviceEdit', params: { deviceId: props.row.id }}"
                class="button is-small is-success" v-if="props.row.agent.id === auth_id">
                <span class="icon">
                  <i class="fas fa-edit"></i>
                </span>
                <span>EDIT</span>
              </router-link> -->
              <router-link
                :to="{ name: 'MyDevicePingList', params: { deviceId: props.row.id }}"
                class="button is-small is-success"
              >
                <span class="icon">
                  <i class="fas fa-heartbeat"></i>
                </span>
                <span>HEARTBEATS</span>
              </router-link>
              <router-link
                :to="{ name: 'MyDeviceAdsHistoryList', params: { deviceId: props.row.id }}"
                class="button is-small is-success"
              >
                <span class="icon">
                  <i class="fab fa-adversal"></i>
                </span>
                <span>ADS Hinstory</span>
              </router-link>
              <router-link
                :to="{ name: 'MyDeviceRevenueReport', params: { deviceId: props.row.id }}"
                class="button is-small is-primary"
              >
                <span class="icon">
                  <i class="fas fa-chart-pie"></i>
                </span>
                <span>Revenue Report</span>
              </router-link>
            </div>
            <!-- <b-dropdown position="is-bottom-left">
              <button class="button is-small is-primary" slot="trigger">
                <span>Actions</span>
                <b-icon icon="chevron-down" size="is-small"></b-icon>
              </button>

              <b-dropdown-item has-link v-if="props.row.agent.id === auth_id">
                <router-link :to="{ name: 'MyDeviceAds', params: { deviceId: props.row.id }}">
                  <span class="icon">
                    <i class="fas fa-cloud-upload-alt"></i>
                  </span>
                  <span>DEFAULT ADS</span>
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item has-link>
                <router-link :to="{ name: 'MyDeviceShow', params: { deviceId: props.row.id }}">
                  <span class="icon">
                    <i class="fas fa-eye"></i>
                  </span>
                  <span>DETAILS</span>
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item has-link v-if="props.row.agent.id === auth_id">
                <router-link :to="{ name: 'MyDeviceEdit', params: { deviceId: props.row.id, openTab: 1 }}">
                  <span class="icon">
                    <i class="fas fa-edit"></i>
                  </span>
                  <span>GALLERY IMAGES</span>
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item has-link v-if="props.row.agent.id === auth_id">
                <router-link :to="{ name: 'MyDeviceEdit', params: { deviceId: props.row.id }}">
                  <span class="icon">
                    <i class="fas fa-edit"></i>
                  </span>
                  <span>EDIT</span>
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item has-link>
                <router-link :to="{ name: 'MyDevicePingList', params: { deviceId: props.row.id }}">
                  <span class="icon">
                    <i class="fas fa-heartbeat"></i>
                  </span>
                  <span>HEARTBEATS</span>
                </router-link>
              </b-dropdown-item>
            </b-dropdown> -->
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="emoticon-sad"
                    size="is-large"
                  > </b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import { mapGetters, mapActions } from 'vuex'
import { DEVICE_LIST } from '@/store/actions/myActions'

export default {
  name: 'MyDeviceList',
  components: {
    Breadcrumb,
    AdvanceFilter
  },
  props: {
    userView: {
      required: true
    }
  },
  data () {
    return {
      pageTitle: 'Manage Devices',
      breadcrumb: {
        list: [{ icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' }],
        current: { icon: 'fas fa-desktop', name: 'Devices' }
      },
      status_options: ['IDEAL', 'ENABLE', 'DISABLE'],
      live_options: ['YES', 'NO'],
      filter: {},
      items: [],
      meta: {},
      fetchListStatus: ''
    }
  },
  computed: {
    ...mapGetters('Profile', { auth_id: 'getAuthId' }),
    isListLoading () {
      return this.fetchListStatus === 'DEVICE_LOADING'
    },
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    },
    isAgentView () {
      return this.userView === 'agent'
    }
  },
  methods: {
    ...mapActions('MyActions', { loadDeviceList: DEVICE_LIST }),
    onFilter () {
      this.loadAsyncData()
    },
    onPageChange (page) {
      this.filter.page = page
      this.loadAsyncData()
    },
    onSort (field, order) {
      this.filter.sortField = field
      this.filter.sortOrder = order
      this.loadAsyncData()
    },
    loadAsyncData () {
      if (!this.isListLoading) {
        this.fetchListStatus = 'DEVICE_LOADING'
        const data = { userView: this.userView, filterData: this.filter }
        this.loadDeviceList(data)
          .then((response) => {
            this.items = response.data
            this.meta = response.meta
            this.fetchListStatus = 'DEVICE_SUCCESS'
          })
          .catch((error) => {
            this.fetchListStatus = 'DEVICE_ERROR'
            this.$notify({
              title: 'Device Loading Failed',
              text: error.message,
              type: 'is-danger'
            })
          })
      }
    },
    reset () {
      this.filter = {
        statuses: [],
        query: '',
        page: 1,
        size: 10,
        sortField: 'updated_at',
        sortOrder: 'desc',
        live: null
      }
      this.items = []
      this.meta = {
        total: 0
      }
      this.onFilter()
    }
  },
  created () {
    this.reset()
  }
}
</script>
