/* eslint-disable */
import XLSX from 'xlsx'
import FileSaver from 'file-saver'

function s2ab (s) {
  if (typeof ArrayBuffer !== 'undefind') {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
    return buf
  } else {
    const buf = new Array(s.length)
    for (let i = 0; i !== s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF
    return buf
  }
}

function install (Vue) {
  Vue.prototype.downloadExl = (exportTableId, exportFileName) => {
    let wb = XLSX.utils.table_to_book(document.getElementById(exportTableId))
    let wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' }
    let wbout = XLSX.write(wb, wopts)
    FileSaver.saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream;charset=utf-8' }), `${exportFileName}.xlsx`)
  }
}

export default install

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(install)
  if (install.installed) {
    install.installed = false
  }
}
