<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div class="card card-section">
      <div class="card-content">
        <b-tabs
          v-model="activeTab"
          v-if="form"
        >
          <b-tab-item label="General Properties">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">UUID</label>
                  <div class="control">
                    <input
                      v-model="form.uuid"
                      class="input"
                      type="text"
                      placeholder="Enter UUID"
                      :class="{'is-danger' : errors.uuid}"
                    />
                  </div>
                  <p
                    v-if="errors.uuid"
                    class="help is-danger"
                  >{{ errors.uuid | implode }}</p>
                </div>
                <div class="field">
                  <label class="label">Name</label>
                  <div class="control">
                    <input
                      v-model="form.name"
                      class="input"
                      type="text"
                      placeholder="Enter Name"
                      :class="{'is-danger' : errors.name}"
                    />
                  </div>
                  <p
                    v-if="errors.name"
                    class="help is-danger"
                  >{{ errors.name | implode }}</p>
                </div>
                <div class="field">
                  <label class="label">SXM Time Share</label>
                  <div class="control">
                    <input
                      v-model="form.time_share"
                      class="input"
                      type="number"
                      placeholder="Enter SXM Time Share"
                      :class="{'is-danger' : errors.time_share}"
                    />
                  </div>
                  <p
                    v-if="errors.time_share"
                    class="help is-danger"
                  >{{ errors.time_share | implode }}</p>
                </div>
                <div class="field">
                  <label class="label">Footfall</label>
                  <div class="control">
                    <input
                      v-model="form.footfall"
                      class="input"
                      type="number"
                      placeholder="Enter Device Footfall"
                      :class="{'is-danger' : errors.footfall}"
                    />
                  </div>
                  <p
                    v-if="errors.footfall"
                    class="help is-danger"
                  >{{ errors.footfall | implode }}</p>
                </div>
                <div class="field">
                  <label class="label">Address</label>
                  <div class="control">
                    <textarea
                      v-model="form.address"
                      class="textarea"
                      placeholder="Enter Address"
                      :class="{'is-danger' : errors.address}"
                    ></textarea>
                  </div>
                  <p
                    v-if="errors.address"
                    class="help is-danger"
                  >{{ errors.address | implode }}</p>
                </div>
                <agent-search-input
                  v-model="form.agent"
                />
                <distributor-search-input
                  v-model="form.distributer"
                />
                <channel-search-input
                  v-model="form.channel"
                />
                <div class="field">
                  <label class="label">Status</label>
                  <div class="control">
                    <v-select
                      v-model="form.status"
                      :options="status_options"
                      :class="{'is-danger' : errors.status}"
                      placeholder="Pick Status"
                      class="is-small"
                    ></v-select>
                  </div>
                  <p
                    v-if="errors.status"
                    class="help is-danger"
                  >{{ errors.status | implode }}</p>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Type</label>
                  <div class="control">
                    <v-select
                      v-model="form.type"
                      :options="types"
                      :class="{'is-danger' : errors.type}"
                      placeholder="Pick Type"
                      class="is-small"
                    ></v-select>
                  </div>
                  <p
                    v-if="errors.type"
                    class="help is-danger"
                  >{{ errors.type | implode }}</p>
                </div>
                <div
                  class="field"
                  v-if="form.type === 'RSRTC_TIMETABLE'"
                >
                  <label class="label">RSRTC Stop Code</label>
                  <div class="control">
                    <input
                      v-model="form.stop_code"
                      class="input"
                      type="text"
                      placeholder="Enter RSRTC Stop Code"
                      :class="{'is-danger' : errors.stop_code}"
                    />
                  </div>
                  <p
                    v-if="errors.stop_code"
                    class="help is-danger"
                  >{{ errors.stop_code | implode }}</p>
                </div>
                <div
                  class="field"
                  v-if="form.type === 'RSRTC_TIMETABLE'"
                >
                  <label class="label">RSRTC Stop Name</label>
                  <div class="control">
                    <input
                      v-model="form.stop_name"
                      class="input"
                      type="text"
                      placeholder="Enter RSRTC Stop Name"
                      :class="{'is-danger' : errors.stop_name}"
                    />
                  </div>
                  <p
                    v-if="errors.stop_name"
                    class="help is-danger"
                  >{{ errors.stop_name | implode }}</p>
                </div>
                <div
                  class="field"
                  v-if="form.type === 'RSRTC_TIMETABLE'"
                >
                  <label class="label">RSRTC Installation Location</label>
                  <div class="control">
                    <input
                      v-model="form.installation_location"
                      class="input"
                      type="text"
                      placeholder="Enter RSRTC Installation Location"
                      :class="{'is-danger' : errors.installation_location}"
                    />
                  </div>
                  <p
                    v-if="errors.installation_location"
                    class="help is-danger"
                  >{{ errors.installation_location | implode }}</p>
                </div>
                <div
                  class="field"
                  v-if="form.type === 'RSRTC_TIMETABLE'"
                >
                  <label class="label">RSRTC Department</label>
                  <div class="control">
                    <input
                      v-model="form.department"
                      class="input"
                      type="text"
                      placeholder="Enter RSRTC Department"
                      :class="{'is-danger' : errors.department}"
                    />
                  </div>
                  <p
                    v-if="errors.department"
                    class="help is-danger"
                  >{{ errors.department | implode }}</p>
                </div>
                <div class="field">
                  <label class="label">Orientation</label>
                  <div class="control">
                    <v-select
                      v-model="form.orientation"
                      :options="orientations"
                      :class="{'is-danger' : errors.orientation}"
                      placeholder="Pick Orientation"
                      class="is-small"
                    ></v-select>
                  </div>
                  <p
                    v-if="errors.orientation"
                    class="help is-danger"
                  >{{ errors.orientation | implode }}</p>
                </div>
                <div class="field">
                  <label class="label">Screen Flip</label>
                  <div class="control">
                    <b-switch
                      :value="true"
                      v-model="form.screen_flip"
                      type="is-info"
                    >
                      {{ form.screen_flip ? 'Screen Fliped' : 'Screen No Flip' }}
                    </b-switch>
                  </div>
                  <p
                    v-if="errors.screen_flip"
                    class="help is-danger"
                  >{{ errors.screen_flip | implode }}</p>
                </div>
                <div class="field">
                  <label class="label">Allowed External</label>
                  <div class="control">
                    <v-select
                      v-model="form.allowed_external"
                      :options="allowed_externals"
                      :class="{'is-danger' : errors.allowed_external}"
                      placeholder="Pick Allowed External"
                      class="is-small"
                    ></v-select>
                  </div>
                  <p
                    v-if="errors.allowed_external"
                    class="help is-danger"
                  >{{ errors.allowed_external | implode }}</p>
                </div>
                <div class="field">
                  <label class="label">Screen Type</label>
                  <div class="control">
                    <v-select
                      v-model="form.screen_type"
                      :options="screen_types"
                      :class="{'is-danger' : errors.screen_type}"
                      placeholder="Pick Screen Type"
                      class="is-small"
                    ></v-select>
                  </div>
                  <p
                    v-if="errors.screen_type"
                    class="help is-danger"
                  >{{ errors.screen_type | implode }}</p>
                </div>
                <div class="field">
                  <label class="label">Screen Size</label>
                  <div class="control">
                    <v-select
                      v-model="form.screen_size"
                      :options="screen_sizes"
                      :class="{'is-danger' : errors.screen_size}"
                      placeholder="Pick Screen Size"
                      class="is-small"
                    ></v-select>
                  </div>
                  <p
                    v-if="errors.screen_size"
                    class="help is-danger"
                  >{{ errors.screen_size | implode }}</p>
                </div>
                <div class="field">
                  <label class="label">WiFi-SSID</label>
                  <div class="control">
                    <input
                      v-model="form.wifissid"
                      class="input"
                      type="text"
                      placeholder="Enter Device WiFi SSID"
                      :class="{'is-danger' : errors.wifissid}"
                    />
                  </div>
                  <p
                    v-if="errors.wifissid"
                    class="help is-danger"
                  >{{ errors.wifissid | implode }}</p>
                </div>
                <div class="field">
                  <label class="label">WiFi-Password</label>
                  <div class="control">
                    <input
                      v-model="form.wifipassword"
                      class="input"
                      type="text"
                      placeholder="Enter Device WiFi Password"
                      :class="{'is-danger' : errors.wifipassword}"
                    />
                  </div>
                  <p
                    v-if="errors.wifipassword"
                    class="help is-danger"
                  >{{ errors.wifipassword | implode }}</p>
                </div>
                <div class="field">
                  <label class="label">WIFI Mobile Number</label>
                  <div class="control">
                    <input
                      v-model="form.mobile"
                      class="input"
                      type="number"
                      placeholder="Enter Device Mobile Number"
                      :class="{'is-danger' : errors.mobile}"
                    />
                  </div>
                  <p
                    v-if="errors.mobile"
                    class="help is-danger"
                  >{{ errors.mobile | implode }}</p>
                </div>
                <div class="field">
                  <label class="label">WIFI Provider</label>
                  <div class="control">
                    <input
                      v-model="form.provider"
                      class="input"
                      type="text"
                      placeholder="Enter Device Mobile Provider"
                      :class="{'is-danger' : errors.provider}"
                    />
                  </div>
                  <p
                    v-if="errors.provider"
                    class="help is-danger"
                  >{{ errors.provider | implode }}</p>
                </div>
                <b-field label="WIFI Plan Expire Date">
                  <b-datepicker
                    v-model="expire_at"
                    :first-day-of-week="1"
                    icon="calendar-alt"
                    placeholder="Click to select expire date..."
                  >
                    <button
                      type="button"
                      class="button is-primary"
                      @click="expire_at = new Date()"
                    >
                      <b-icon icon="calendar-check"></b-icon>
                      <span>Today</span>
                    </button>

                    <button
                      type="button"
                      class="button is-danger"
                      @click="expire_at = null"
                    >
                      <b-icon icon="window-close"></b-icon>
                      <span>Clear</span>
                    </button>
                  </b-datepicker>
                </b-field>
                <div class="field">
                  <label class="label">Latitude</label>
                  <div class="control">
                    <input
                      v-model="form.latitude"
                      class="input"
                      type="text"
                      placeholder="Enter Latitude"
                      :class="{'is-danger' : errors.latitude}"
                    />
                  </div>
                  <p
                    v-if="errors.latitude"
                    class="help is-danger"
                  >{{ errors.latitude | implode }}</p>
                </div>
                <div class="field">
                  <label class="label">Longitude</label>
                  <div class="control">
                    <input
                      v-model="form.longitude"
                      class="input"
                      type="text"
                      placeholder="Enter Longitude"
                      :class="{'is-danger' : errors.longitude}"
                    />
                  </div>
                  <p
                    v-if="errors.longitude"
                    class="help is-danger"
                  >{{ errors.longitude | implode }}</p>
                </div>
                <div class="field">
                  <label class="label">Live Web</label>
                  <div class="control">
                    <input
                      v-model="form.live_web"
                      class="input"
                      type="text"
                      placeholder="Enter Live Web Address"
                      :class="{'is-danger' : errors.live_web}"
                    />
                  </div>
                  <p
                    v-if="errors.live_web"
                    class="help is-danger"
                  >{{ errors.live_web | implode }}</p>
                </div>
              </div>
            </div>
            <div class="field is-deviceed action-buttons">
              <div class="control">
                <button
                  type="button"
                  :disabled="isLoading"
                  class="button is-primary is-rounded"
                  @click="formSubmit"
                  :class="{ 'is-loading': isLoading }"
                >Create</button>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item
            label="Picture Gallery"
            :disabled="true"
          ></b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { AgentSearchInput, DistributorSearchInput, ChannelSearchInput } from '@/lib/autocompletes'
import { DEVICE_CREATE } from '@/store/actions/deviceActions'
import { AGENT_LIST, DISTRIBUTOR_LIST, CHANNEL_LIST } from '@/store/actions/userActions'

export default {
  name: 'DeviceCreate',
  components: {
    Breadcrumb, AgentSearchInput, DistributorSearchInput, ChannelSearchInput
  },
  data () {
    return {
      activeTab: 0,
      isSlugEditable: false,
      pageTitle: 'New Device Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-desktop', routeName: 'DeviceList', name: 'Manage Devices' }
        ],
        current: { icon: 'fas fa-plus', name: 'New Device' },
        actions: [{ icon: 'fas fa-desktop', routeName: 'DeviceList', name: 'Manage Devices' }]
      },
      status_options: ['IDEAL', 'ENABLE', 'DISABLE'],
      screen_types: ['CRT', 'LCD', 'LED', 'PLASMA', 'OLED'],
      screen_sizes: ['32', '40', '43', '50', '55', '60', '65', '70', '75'],
      types: ['ADVERTISING_DEVICE', 'RSRTC_TIMETABLE'],
      orientations: ['PORTRAIT', 'LANDSCAPE'],
      allowed_externals: ['SD CARD'],
      form: null,
      expire_at: null,
      errors: {},
      autocomplete: {
        agent: {
          value: null,
          items: [],
          isLoading: false,
          timeout: null
        },
        distributor: {
          value: null,
          items: [],
          isLoading: false,
          timeout: null
        },
        channel: {
          value: null,
          items: [],
          isLoading: false,
          timeout: null
        }
      }
    }
  },
  computed: {
    ...mapGetters('Devices', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Devices', { createDevice: DEVICE_CREATE }),
    ...mapActions('Users', { loadAgents: AGENT_LIST, loadDistributors: DISTRIBUTOR_LIST, loadChannels: CHANNEL_LIST }),
    formSubmit () {
      this.errors = {}
      let expireDate = null
      if (this.expire_at !== undefined && this.expire_at !== null) {
        expireDate = moment(this.expire_at).format('YYYY-MM-DD')
        this.form.expire_at = expireDate
      }
      this.createDevice(this.form)
        .then((response) => {
          this.$notify({
            title: 'Device Saved as Draft',
            text: `(${response.data.uuid}) Device is created and saved as ${response.data.status} successfully`,
            type: 'is-success'
          })
          this.$router.push({
            name: 'DeviceEdit',
            params: { deviceId: response.data.id }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Device Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAgentsSuggestion () {
      clearTimeout(this.autocomplete.agent.timeout)
      this.autocomplete.agent.timeout = setTimeout(() => {
        const data = {
          query: this.autocomplete.agent.value
        }
        if (data.query) {
          this.autocomplete.agent.isLoading = true
          this.loadAgents(data)
            .then((response) => {
              this.autocomplete.agent.items = response.data
              this.autocomplete.agent.isLoading = false
            })
            .catch((error) => {
              this.autocomplete.agent.isLoading = false
              this.$notify({
                title: 'Agent Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    },
    loadDistributorsSuggestion () {
      clearTimeout(this.autocomplete.distributor.timeout)
      this.autocomplete.distributor.timeout = setTimeout(() => {
        const data = {
          query: this.autocomplete.distributor.value
        }
        if (data.query) {
          this.autocomplete.distributor.isLoading = true
          this.loadDistributors(data)
            .then((response) => {
              this.autocomplete.distributor.items = response.data
              this.autocomplete.distributor.isLoading = false
            })
            .catch((error) => {
              this.autocomplete.distributor.isLoading = false
              this.$notify({
                title: 'Distributor Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    },
    loadChannelsSuggestion () {
      clearTimeout(this.autocomplete.channel.timeout)
      this.autocomplete.channel.timeout = setTimeout(() => {
        const data = {
          query: this.autocomplete.channel.value
        }
        if (data.query) {
          this.autocomplete.channel.isLoading = true
          this.loadChannels(data)
            .then((response) => {
              this.autocomplete.channel.items = response.data
              this.autocomplete.channel.isLoading = false
            })
            .catch((error) => {
              this.autocomplete.channel.isLoading = false
              this.$notify({
                title: 'Channel Owner Loading Failed',
                text: error.message,
                type: 'is-danger'
              })
            })
        }
      }, 500)
    },
    onReset () {
      this.errors = {}
      this.form = {
        uuid: '',
        name: '',
        time_share: 100,
        address: '',
        wifissid: '',
        wifipassword: '',
        latitude: '',
        longitude: '',
        mobile: '',
        provider: '',
        expire_at: null,
        status: 'IDEAL',
        agent: null,
        distributor: null,
        channel: null,
        footfall: 0,
        screen_size: null,
        screen_type: null,
        orientation: 'PORTRAIT',
        screen_flip: false,
        allowed_external: null,
        live_web: null,
        type: 'ADVERTISING_DEVICE',
        stop_code: null,
        stop_name: null,
        installation_location: null
      }
      this.$notify({
        title: 'Reset Form',
        text: 'Device form is reset with default value, please continue...',
        type: 'is-info'
      })
    }
  },
  created () {
    this.onReset()
  }
}
</script>
