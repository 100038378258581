<template>
  <div>
    <!-- <loading v-show="isLoading"></loading> -->
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <advance-filter
      :is-loading="isLoading"
      @onStart="onFilter"
    >
      <div class="columns is-multiline">
        <div class="column field is-3">
          <label class="label is-small">Query</label>
          <p class="control has-icons-left">
            <input
              class="input"
              type="text"
              placeholder="search"
              v-model="filter.query"
            >
            <span class="icon is-small is-left">
              <i class="fas fa-search"></i>
            </span>
          </p>
        </div>
        <div class="column field is-4">
          <label class="label is-small">Type</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              multiple
              v-model="filter_types"
              :options="type_options"
              placeholder="Pick types for filter"
              class="is-small"
            ></v-select>
          </div>
        </div>
        <div class="column field is-4">
          <label class="label is-small">Status</label>
          <div class="control is-expanded has-icons-left">
            <v-select
              multiple
              v-model="filter.statuses"
              :options="status_options"
              placeholder="Pick statuses for filter"
              class="is-small"
            ></v-select>
          </div>
        </div>
      </div>
    </advance-filter>

    <div class="card card-section">
      <div class="card-content">
        <b-field
          grouped
          group-multiline
        >
          <b-select
            v-model="filter.size"
            :disabled="!hasItems"
            @input="onFilter"
          >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </b-select>
        </b-field>
        <b-table
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :mobile-cards="true"
          :loading="isLoading"
          paginated
          backend-pagination
          :per-page="filter.size"
          :total="meta.total"
          @page-change="onPageChange"
          backend-sorting
          default-sort-direction="desc"
          :default-sort="[filter.sortField, filter.sortOrder]"
          @sort="onSort"
          :data="items"
          detailed
          detail-key="id"
        >
          <b-table-column
            label="S.No."
            width="40"
            numeric
            v-slot="props"
          >
            {{ meta.from + props.index }}
          </b-table-column>
          <b-table-column
            field="type"
            label="Type"
            v-slot="props"
          >
            {{ getTicketType(props.row.type) }}
          </b-table-column>
          <b-table-column
            field="user_id"
            label="User"
            v-slot="props"
          >
            {{ props.row.user.name }}<br>{{ props.row.user.email }}
          </b-table-column>
          <b-table-column
            label="Amount"
            width="40"
            numeric
            v-slot="props"
          >
            {{ props.row.amount }}
          </b-table-column>
          <b-table-column
            field="status"
            label="Status"
            sortable
            v-slot="props"
          >
            {{ props.row.status }}
          </b-table-column>
          <b-table-column
            field="updated_at"
            label="Last Modified"
            sortable
            v-slot="props"
          >
            {{ props.row.updated_at }}
          </b-table-column>
          <b-table-column
            label=""
            v-slot="props"
          >
            <b-dropdown position="is-bottom-left">
              <button
                class="button is-primary"
                slot="trigger"
              >
                <span>Actions</span>
                <b-icon
                  icon="chevron-down"
                  size="is-small"
                ></b-icon>
              </button>
              <b-dropdown-item has-link>
                <a @click.prevent="confirmProcessedTicket(props.row.id)">
                  <span class="icon">
                    <i class="fas fa-trash"></i>
                  </span>
                  <span>MARK PROCESSED</span>
                </a>
              </b-dropdown-item>
              <b-dropdown-item has-link>
                <a @click.prevent="confirmCancelledTicket(props.row.id)">
                  <span class="icon">
                    <i class="fas fa-trash"></i>
                  </span>
                  <span>MARK CANCELLED</span>
                </a>
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>
          <template
            slot="detail"
            slot-scope="props"
          >
            <nav class="panel">
              <a class="panel-block"><span class="panel-icon"><i
                    class="fas fa-book"
                    aria-hidden="true"
                  ></i></span> <label>User</label>{{ props.row.user.name }} ({{ props.row.user.email }})</a>
              <a class="panel-block"><span class="panel-icon"><i
                    class="fas fa-book"
                    aria-hidden="true"
                  ></i></span> <label>Type</label>{{ getTicketType(props.row.type) }}</a>
              <a class="panel-block"><span class="panel-icon"><i
                    class="fas fa-book"
                    aria-hidden="true"
                  ></i></span> <label>Amount</label>{{ props.row.amount }} {{ props.row.currency }}</a>
              <a class="panel-block"><span class="panel-icon"><i
                    class="fas fa-book"
                    aria-hidden="true"
                  ></i></span> <label>Remarks</label>{{ props.row.remarks }}</a>
              <a class="panel-block"><span class="panel-icon"><i
                    class="fas fa-book"
                    aria-hidden="true"
                  ></i></span> <label>Requested By</label>{{ props.row.requested_by.name }} ({{ props.row.requested_by.email }})</a>
              <a class="panel-block"><span class="panel-icon"><i
                    class="fas fa-book"
                    aria-hidden="true"
                  ></i></span> <label>Created On</label>{{ props.row.created_at }}</a>
              <a class="panel-block"><span class="panel-icon"><i
                    class="fas fa-book"
                    aria-hidden="true"
                  ></i></span> <label>Last Modified On</label>{{ props.row.updated_at }}</a>
            </nav>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="emoticon-sad"
                    size="is-large"
                  > </b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import { mapGetters, mapActions } from 'vuex'
import { TICKET_LIST, TICKET_PROCESSED, TICKET_DELETE } from '@/store/actions/ticketActions'

export default {
  name: 'TicketList',
  components: {
    Breadcrumb,
    AdvanceFilter
  },
  data () {
    return {
      pageTitle: 'Manage User Tickets',
      breadcrumb: {
        list: [{ icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' }],
        current: { icon: 'fab fa-stack-exchange', name: 'User Tickets' },
        actions: [{ icon: 'fas fa-ribbon', routeName: 'TicketCreate', name: 'New User Ticket' }]
      },
      status_options: ['WAITING', 'PROCESSED', 'CANCELLED'],
      type_options: [
        { label: 'REDEEM WALLET MONEY', value: 1 }
      ],
      filter_types: null,
      filter: {},
      items: [],
      meta: {}
    }
  },
  watch: {
    filter_types: 'onFilterTicketTypeChange'
  },
  computed: {
    ...mapGetters('Tickets', ['isLoading', 'isSuccess', 'isError']),
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    }
  },
  methods: {
    ...mapActions('Tickets', { loadList: TICKET_LIST, sendProcessedTicketRequest: TICKET_PROCESSED, sendCancelledTicketRequest: TICKET_DELETE }),
    onFilterTicketTypeChange () {
      this.filter.types = []
      if (this.filter_types !== undefined && this.filter_types !== null) {
        this.filter_types.forEach((type) => this.filter.types.push(type.value))
      }
    },
    getTicketType (typeValue) {
      let result = ''
      if (typeValue === 1) {
        result = 'REDEEM WALLET MONEY'
      }
      return result
    },
    onFilter () {
      this.loadAsyncData()
    },
    onPageChange (page) {
      this.filter.page = page
      this.loadAsyncData()
    },
    onSort (field, order) {
      this.filter.sortField = field
      this.filter.sortOrder = order
      this.loadAsyncData()
    },
    confirmProcessedTicket (ticketId) {
      this.$buefy.dialog.prompt({
        message: 'User Ticket Processed',
        inputAttrs: {
          placeholder: 'Your Remarks',
          maxlength: 512
        },
        onConfirm: (remarks) => this.processedTicket(ticketId, remarks)
      })
    },
    processedTicket (ticketId, remarks) {
      const data = {
        ticketId,
        formData: {
          remarks
        }
      }
      this.sendProcessedTicketRequest(data)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.onFilter()
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    confirmCancelledTicket (ticketId) {
      this.$buefy.dialog.prompt({
        message: 'Deleting User Ticket',
        inputAttrs: {
          placeholder: 'Your Remarks',
          maxlength: 512
        },
        onConfirm: (remarks) => this.cancelTicket(ticketId, remarks)
      })
    },
    cancelTicket (ticketId, remarks) {
      const data = {
        ticketId,
        formData: {
          remarks
        }
      }
      this.sendCancelledTicketRequest(data)
        .then((response) => {
          this.$notify({
            title: 'Success',
            text: response.message,
            type: 'is-success'
          })
          this.onFilter()
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncData () {
      const data = this.filter
      this.loadList(data)
        .then((response) => {
          this.items = response.data
          this.meta = response.meta
        })
        .catch((error) => {
          this.$notify({
            title: 'User Request Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    reset () {
      this.filter = {
        statuses: [],
        types: [],
        query: '',
        page: 1,
        size: 10,
        sortField: 'updated_at',
        sortOrder: 'desc'
      }
      this.items = []
      this.meta = {
        total: 0
      }
      this.onFilter()
    }
  },
  created () {
    this.reset()
  }
}
</script>
