<template>
  <div>
    <!-- <loading v-show="isDataLoading"></loading> -->
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <advance-filter
      :is-loading="isDataLoading"
      @onStart="onFilter"
      :is-hide="false"
    >
      <div class="columns is-multiline">
        <advertiser-select-box
          v-if="isAdministrator"
          class="column is-3"
          v-model="filter.advertiser"
          property-name="id"
        />
        <video-advertisement-search-input
          class="column is-3"
          v-model="filter.advertisement"
          property-name="id"
        />
        <duration-picker
          class="column is-3"
          v-model="duration"
          @onDurationChanged="onDurationChanged"
          />
      </div>
      <div
        class="columns is-multiline"
        v-show="editableDuration"
      >
        <date-time-picker
          label="Start Date & Time"
          class="column is-3"
          v-model="filter.start"
          :max-date="filter.end" />
        <date-time-picker
          label="End Date & Time"
          class="column is-3"
          v-model="filter.end"
          :min-date="filter.start" />
      </div>
    </advance-filter>
    <card
      title="Summary"
      class="mt-2 min-shadow"
      :init-view="true"
    >
      <div class="tile is-ancestor has-text-centered">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              <article
              class="tile is-child notification is-primary"
              :class="{'element is-loading' : isFinanceLoading }"
            >
              <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ summary.totalAmount }}</p>
              <p class="subtitle is-5">Total Amount</p>
              <span class="tag is-link top-right">{{ timeFilterType }}</span>
            </article>
            </div>
            <div class="tile is-parent">
              <article
                class="tile is-child notification is-danger"
                :class="{'element is-loading' : isFinanceLoading}"
              >
                <p class="title is-4"><i class="fas fa-rupee-sign"></i> {{ summary.totalRefund }}</p>
                <p class="subtitle is-5">Total Refund</p>
                <span class="tag is-link top-right">{{ timeFilterType }}</span>
              </article>
            </div>
          </div>
          <div class="tile" v-if="summary.slots">
            <div class="tile is-parent" v-for="(slot, index) in summary.slots" :key="`slot-${index}`">
              <article
                class="tile is-child notification is-primary"
                :class="{
                  'element is-loading' : isSlotsLoading,
                  'is-info' : slot.status === 'IN_PROCESS',
                  'is-success' : slot.status === 'PROCESSED',
                  'is-warning' : slot.status === 'REFUNDED',
                  'is-danger' : slot.status === 'CANCELLED'
                }"
              >
                <p class="title is-4">{{ slot.count }}</p>
                <p class="subtitle is-5">Slots ({{ slot.status.toLowerCase() | lowercase | nounderscore | titleize }})</p>
                <span class="tag is-link top-right">{{ timeFilterType }}</span>
              </article>
            </div>
          </div>
        </div>
      </div>
    </card>
    <div class="card card-section">
      <div class="card-content">
        <b-field
          grouped
          group-multiline
        >
          <b-select
            v-model="filter.size"
            :disabled="!hasItems"
            @input="onFilter"
          >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </b-select>
        </b-field>
        <b-table
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :mobile-cards="true"
          :loading="isDataLoading"
          paginated
          backend-pagination
          :per-page="filter.size"
          :total="meta.total"
          @page-change="onPageChange"
          backend-sorting
          default-sort-direction="desc"
          :default-sort="[filter.sortField, filter.sortOrder]"
          @sort="onSort"
          :data="items"
        >
          <b-table-column
            label="S.No."
            width="40"
            numeric
            v-slot="props"
          >
            {{ meta.from + props.index }}
          </b-table-column>
          <b-table-column
            field="thumbnail"
            label="Thumbnail"
            v-slot="props"
          >
            <img
              v-if="props.row.advertisement.thumbnails"
              :src="props.row.advertisement.thumbnails.small"
              style="width: 100px;"
            />
          </b-table-column>
          <b-table-column
            field="start"
            label="Details"
            sortable
            v-slot="props"
          >
            <strong>Booked At: </strong>{{ props.row.booked_at }}<br />
            <strong>Ads Duration: </strong>{{ props.row.advertisement.duration }} Secs.<br />
            <strong>Budget Alloted: </strong>{{ props.row.budget_alloted }}<br />

            <strong>Location Selected: </strong>{{ props.row.locations }}<br />
            <strong>Places Selected: </strong>{{ props.row.places }}<br />
            <strong>Ads Categories: </strong>{{ props.row.tags }}<br />
          </b-table-column>
          <b-table-column
            field="reservation_count"
            label="Slots"
            v-slot="props"
          >
            {{ props.row.slots }}
          </b-table-column>
          <b-table-column
            field="amount"
            label="Amount"
            sortable
            v-slot="props"
          >
            {{ props.row.amount | money }}
          </b-table-column>
          <b-table-column
            field="refund"
            label="Refund"
            sortable
            v-slot="props"
          >
            {{ props.row.refund }}
          </b-table-column>
          <b-table-column
            label="Relations"
            v-slot="props"
          >
            <div
              class="list-items"
              v-if="props.row.advertiser"
            >
              <div class="list-li-head">Advertiser:</div>
              <div class="list-li-desc">{{ props.row.advertiser.name }}</div>
              <div class="list-li-desc">{{ props.row.advertiser.email }}</div>
            </div>
            <div
              class="list-items"
              v-if="props.row.bookedBy"
            >
              <div class="list-li-head">Booked By:</div>
              <div class="list-li-desc">{{ props.row.bookedBy.name }}</div>
              <div class="list-li-desc">{{ props.row.bookedBy.email }}</div>
            </div>
          </b-table-column>
          <b-table-column
            field="status"
            label="Status"
            sortable
            v-slot="props"
          >
            {{ props.row.status }}
          </b-table-column>
          <b-table-column
            label=""
            v-slot="props"
          >
            <b-dropdown position="is-bottom-left">
              <button
                class="button is-primary"
                slot="trigger"
              >
                <span>Actions</span>
                <b-icon
                  icon="chevron-down"
                  size="is-small"
                ></b-icon>
              </button>
              <b-dropdown-item
                has-link
                v-if="hasAnyScope('ORDER-SHOW')"
              >
                <router-link :to="{ name: 'OrderShow', params: { orderId: props.row.id }}">
                  <span class="icon">
                    <i class="fas fa-eye"></i>
                  </span>
                  <span>DETAILS</span>
                </router-link>
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="emoticon-sad"
                    size="is-large"
                  > </b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import AdvanceFilter from '@/pages/layouts/secure/components/AdvanceFilter'
import { Card, DurationPicker, DateTimePicker } from '@/lib'
import { VideoAdvertisementSearchInput } from '@/lib/autocompletes'
import { AdvertiserSelectBox } from '@/lib/selectboxes'
import { ORDER_LIST, SUMMARY_SLOTS, SUMMARY_FINANCIAL } from '@/store/actions/orderActions'

export default {
  name: 'OrderList',
  components: {
    Breadcrumb,
    AdvanceFilter,
    Card,
    DurationPicker,
    DateTimePicker,
    AdvertiserSelectBox,
    VideoAdvertisementSearchInput
  },
  data () {
    return {
      pageTitle: 'Manage Orders',
      breadcrumb: {
        list: [{ icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' }],
        current: { icon: 'fas fa-file-invoice-dollar', name: 'Orders' }
      },
      filter: {},
      items: [],
      meta: {},
      summary: {
        totalAmount: 0,
        totalRefund: 0,
        slots: []
      },
      editableDuration: false,
      duration: 'Today',
      filterDuration: null,
      isDataLoading: false,
      isFinanceLoading: false,
      isSlotsLoading: false
    }
  },
  computed: {
    ...mapGetters('Profile', ['hasAnyScope', 'isAdvertiser', 'isAdministrator']),
    timeFilterType () {
      return this.editableDuration ? 'Custom Time' : this.filterDuration
    },
    hasItems () {
      let result = false
      if (this.items && this.items.length > 0) result = true
      return result
    }
  },
  methods: {
    ...mapActions('Orders', { loadList: ORDER_LIST, loadSlotsDetails: SUMMARY_SLOTS, loadFinancialDetails: SUMMARY_FINANCIAL }),
    onDurationChanged (duration) {
      this.editableDuration = duration.customTime
      if (!this.editableDuration) {
        this.filter.start = duration.sTime
        this.filter.end = duration.eTime
      }
    },
    onFilter () {
      this.loadAsyncData()
      this.loadAsyncSlotsDetails()
      this.loadAsyncFinancialDetails()
    },
    onPageChange (page) {
      this.filter.page = page
      this.onFilter()
    },
    onSort (field, order) {
      this.filter.sortField = field
      this.filter.sortOrder = order
      this.onFilter()
    },
    loadAsyncData () {
      if (!this.isDataLoading) {
        this.isDataLoading = true
        const data = this.getFilterData()
        this.filterDuration = this.duration
        this.loadList(data)
          .then((response) => {
            this.items = response.data
            this.meta = response.meta
          })
          .catch((error) => {
            this.$notify({
              title: 'Order Loading Failed',
              text: error.message,
              type: 'is-danger'
            })
          })
          .finally(() => {
            this.isDataLoading = false
          })
      }
    },
    loadAsyncFinancialDetails () {
      this.isFinanceLoading = true
      const data = this.getFilterData()
      this.filterDuration = this.duration
      this.loadFinancialDetails(data)
        .then((response) => {
          this.summary.totalAmount = response.amount
          this.summary.totalRefund = response.refund
          this.isFinanceLoading = false
        })
        .catch((error) => {
          this.$notify({
            title: 'Order Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
          this.isFinanceLoading = false
        })
    },
    loadAsyncSlotsDetails () {
      const data = this.getFilterData()
      this.isSlotsLoading = true
      this.filterDuration = this.duration
      this.loadSlotsDetails(data)
        .then((response) => {
          this.summary.slots = response
        })
        .catch((error) => {
          this.$notify({
            title: 'Order Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isSlotsLoading = false
        })
    },
    getFilterData () {
      const data = {
        ...this.filter
      }
      if (data.start !== null) {
        data.start = moment(data.start).format('YYYY-MM-DD HH:mm:ss')
      }
      if (data.end !== null) {
        data.end = moment(data.end).format('YYYY-MM-DD HH:mm:ss')
      }
      return data
    },
    onReset () {
      this.reset()
      setTimeout(() => this.onFilter(), 1000)
    },
    reset () {
      this.filter = {
        advertiser: null,
        advertisement: null,
        start: null,
        end: null,
        page: 1,
        size: 10,
        sortField: 'updated_at',
        sortOrder: 'desc'
      }
      this.items = []
      this.totalAmount = 0
      this.totalRefund = 0
      this.meta = {
        total: 0
      }
    }
  },
  created () {
    this.onReset()
  }
}
</script>
