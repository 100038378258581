import { mapActions } from 'vuex'
import { CAMPAIGN_ADS, CAMPAIGN_UPLOAD_SPACES } from '@/store/actions/campaignActions'
import KioskSpace from '@/pages/secure/campaigns/kiosks/KioskSpace'

export default {
  props: {
    campaign: {
      required: true
    },
    viewOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: { KioskSpace },
  data () {
    return {
      spaces: [],
      showUploadButton: false,
      needEdit: true,
      blankSpace: {
        status: '',
        adsName: 'Select Ads',
        thumbnail: '/img/placeholders/image-placeholder.png',
        path: null
      },
      loadingNotification: null
    }
  },
  computed: {
    canAdsUpload () {
      return this.showUploadButton && !this.viewOnly
    }
  },
  methods: {
    ...mapActions('Campaigns', { uploadAds: CAMPAIGN_ADS, uploadSpaces: CAMPAIGN_UPLOAD_SPACES }),
    addColumn (rIndex) {
      this.spaces[rIndex].push(this.blankSpace)
    },
    removeColumn (rIndex) {
      if (this.spaces[rIndex].length === 1) {
        if (this.spaces.length > 1) {
          this.spaces.splice(rIndex, 1)
        }
      } else {
        this.spaces[rIndex].pop()
      }
    },
    addRow () {
      this.createSpace(this.spaces.length + 1, 1)
    },
    removeRow () {
      if (this.spaces.length > 1) {
        this.spaces.pop()
      }
    },
    getSpace (row, col) {
      if (this.spaces.length >= row & this.spaces[row].length >= col) {
        return this.spaces[row][col]
      } else {
        return this.createSpace(row, col)
      }
    },
    createSpace (row, col) {
      const missingRows = row - this.spaces.length
      for (let index = 0; index < missingRows; index++) {
        const rSpace = []
        rSpace.push(this.blankSpace)
        this.spaces.push(rSpace)
      }
      const rSpace = this.spaces[row - 1]
      const missingCols = col - rSpace.length
      for (let index = 0; index < missingCols; index++) {
        rSpace.push(this.blankSpace)
      }
    },
    uploadSpaceAds (adsFormData, rIndex, cIndex) {
      adsFormData.set('row', rIndex + 1)
      adsFormData.set('col', cIndex + 1)
      const data = {
        campaignId: this.campaign.id,
        formData: adsFormData
      }
      this.loadingNotification = this.$buefy.loading.open({
        container: null
      })
      this.uploadAds(data)
        .then((response) => {
          const rowSpace = this.spaces[rIndex]
          rowSpace[cIndex] = {
            ...rowSpace[cIndex],
            ...response.data.ads
          }
          this.$set(this.spaces, rIndex, rowSpace)
          this.$notify({
            title: 'Space Ads',
            text: response.message,
            type: 'is-success'
          })
          this.$emit('on-display-update')
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Space Form',
            text: error.message,
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.loadingNotification.close()
        })
    },
    saveDisplay () {
      const spaceData = []
      let row = 1
      this.spaces.forEach(spaceRow => {
        let col = 1
        spaceRow.forEach(space => {
          spaceData.push({
            id: space.id,
            col: col,
            row: row,
            thumbnail: space.thumbnail
          })
          col = col + 1
        })
        row = row + 1
      })
      const data = {
        campaignId: this.campaign.id,
        formData: {
          type: this.campaign.type,
          spaces: spaceData
        }
      }
      this.uploadSpaces(data)
        .then((response) => {
          const remoteSpaces = response.data
          this.updateByRemoteSpaces(remoteSpaces)
          this.$notify({
            title: 'Space Ads',
            text: 'Campaign display saved successfully',
            type: 'is-success'
          })
          this.needEdit = false
          this.showUploadButton = true
          this.$emit('on-display-update')
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Space Form',
            text: error.message,
            type: 'is-danger'
          })
          this.needEdit = true
          this.showUploadButton = false
        })
    },
    editDisplay () {
      this.showUploadButton = false
      this.needEdit = true
    },
    updateByRemoteSpaces (spacesData) {
      const localSpaces = []
      spacesData.forEach(spaceData => {
        const spaceId = spaceData.id
        const rCol = spaceData.col
        const rRow = spaceData.row
        const spaceThumbnail = spaceData.ads !== undefined && spaceData.ads !== null && spaceData.ads.thumbnail !== undefined && spaceData.ads.thumbnail !== null ? spaceData.ads.thumbnail : this.blankSpace.thumbnail
        while (localSpaces.length < rRow) {
          const columns = []
          columns.push({})
          localSpaces.push(columns)
        }
        const rowSpace = localSpaces[rRow - 1]
        while (rowSpace.length < rCol) {
          rowSpace.push({})
        }
        rowSpace[rCol - 1] = {
          ...this.blankSpace,
          ...rowSpace[rCol - 1],
          id: spaceId,
          ...spaceData.ads,
          thumbnail: spaceThumbnail
        }
      })
      this.spaces = localSpaces
    }
  },
  created () {
    this.updateByRemoteSpaces(this.campaign.spaces)
    if (this.campaign.type === 'SINGLE-SCREEN-KIOSK' || this.campaign.type === 'INTERACTIVE-KIOSK') {
      this.createSpace(1, 1)
      this.spaces = [[this.spaces[0][0]]]
      this.showUploadButton = true
      this.needEdit = false
    } else if (this.campaign.type === 'SCREEN-WITH-RIGHT-SIDE-KIOSK') {
      this.createSpace(1, 1)
      this.createSpace(1, 2)
      this.spaces = [[this.spaces[0][0], this.spaces[0][1]]]
      this.showUploadButton = true
      this.needEdit = false
    } else {
      this.createSpace(1, 1)
      this.showUploadButton = false
      this.needEdit = true
    }
  }
}
