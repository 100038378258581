<template>
  <div class="columns is-mobile is-multiline">
    <div class="column is-12">
      <div class="file has-name is-fullwidth is-primary">
        <label class="file-label">
          <input
            class="file-input"
            multiple
            type="file"
            name="galleryFiles"
            ref="galleryFiles"
            @change="uploadGalleryFiles"
          />
          <span class="file-cta">
            <span class="file-icon">
              <i class="fas fa-upload"></i>
            </span>
            <span class="file-label">
              Choose files to upload…
            </span>
          </span>
          <span class="file-name">

          </span>
        </label>
      </div>
    </div>
    <div
      class="column is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd"
      v-for="queue in uploadQueues"
      :key="queue.id"
    >
      <div class="card">
        <div class="card-image">
          <figure class="image">
            <img
              :src="queue.image"
              alt="Queue Image"
            >
          </figure>
          <div
            v-show="queue.status === 'uploading'"
            class="card-image-text-ontop has-background-primary"
          >
            <i class="fas fa-cloud-upload-alt"></i> Uploading...<i class="fas fa-spinner fa-spin"></i>
          </div>
          <div
            v-show="queue.status === 'success'"
            class="card-image-text-ontop has-background-success"
          >
            <i class="fas fa-cloud-upload-alt"></i> Uploaded to server...
          </div>
          <div
            v-show="queue.status === 'error'"
            class="card-image-text-ontop has-background-danger"
          >
            <i class="fas fa-cloud-upload-alt"></i> Failed to upload on server...
          </div>
        </div>
        <footer class="card-footer">
          <button
            :disabled="queue.deleteInProcess"
            v-show="queue.status === 'success'"
            class="card-footer-item has-background-danger has-text-white"
            @click="deleteDeviceGallery(queue, 'queue')"
          >
            <i
              class="fas fa-trash-alt"
              :class="{ 'fa-spin' : queue.deleteInProcess }"
            ></i> <span class="delete-text">Remove From Server</span>
          </button>
        </footer>
      </div>
    </div>
    <div
      class="column is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd"
      v-for="galleryImage in gallery"
      :key="galleryImage.id"
    >
      <div class="card">
        <div class="card-image">
          <figure class="image">
            <img
              :src="galleryImage.large"
              alt="Gallery Image"
            >
          </figure>
        </div>
        <footer class="card-footer">
          <button
            class="card-footer-item has-background-danger has-text-white"
            :disabled="galleryImage.deleteInProcess"
            @click="deleteDeviceGallery(galleryImage, 'gallery')"
          >
            <i
              class="fas fa-trash-alt"
              :class="{ 'fa-spin' : galleryImage.deleteInProcess }"
            ></i> <span class="delete-text">Remove From Server</span>
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { GALLERY_CREATE, GALLERY_DELETE } from '@/store/actions/deviceActions'

export default {
  name: 'DeviceGallery',
  props: {
    device: {
      required: true
    }
  },
  data () {
    return {
      gallery: this.device.gallery ? this.device.gallery : [],
      uploadQueues: []
    }
  },
  methods: {
    ...mapActions('Devices', { uploadGallery: GALLERY_CREATE, deleteGallery: GALLERY_DELETE }),
    uploadGalleryFiles () {
      const { galleryFiles } = this.$refs
      if (!galleryFiles || !galleryFiles.files.length) {
        return
      }
      Array.from(galleryFiles.files).forEach((galleryFile) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          const galleryItem = {
            id: this.generateUniqueId(),
            name: galleryFile.name,
            image: e.target.result,
            file: galleryFile,
            status: 'uploading',
            deleteInProcess: false
          }
          this.uploadQueues.unshift(galleryItem)
          this.uploadDeviceGallery(galleryItem)
        }
        reader.readAsDataURL(galleryFile)
      })
    },
    chr4 () {
      return Math.random().toString(16).slice(-4)
    },
    generateUniqueId () {
      return (`${Date.now().toString(36)}-${this.chr4()}-${this.chr4()}-${this.chr4()}`).toLowerCase()
    },
    deleteDeviceGallery (galleryItem, queueName) {
      const tempGalleryItem = galleryItem
      const data = {
        deviceId: this.device.id,
        galleryId: tempGalleryItem.id
      }
      if (queueName === 'gallery') {
        this.$set(tempGalleryItem, 'deleteInProcess', true)
      } else {
        tempGalleryItem.deleteInProcess = true
      }
      this.deleteGallery(data)
        .then(() => {
          if (queueName === 'gallery') {
            const galleryItemIndex = this.gallery.findIndex((item) => item.id === galleryItem.id)
            this.gallery.splice(galleryItemIndex, 1)
          } else {
            const galleryItemIndex = this.uploadQueues.findIndex((item) => item.id === galleryItem.id)
            this.uploadQueues.splice(galleryItemIndex, 1)
          }
          this.$notify({
            title: 'Gallery Image Removed',
            text: 'Gallery image is removed from server successfully',
            type: 'is-success'
          })
        })
        .catch((error) => {
          this.$notify({
            title: 'Error Device Gallery Removal',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    uploadDeviceGallery (galleryItem) {
      const tempGalleryItem = galleryItem
      const galleryFormData = new FormData()
      galleryFormData.set('gallery', tempGalleryItem.file)
      galleryFormData.set('id', tempGalleryItem.id)
      const data = {
        deviceId: this.device.id,
        formData: galleryFormData
      }
      this.uploadGallery(data)
        .then(() => {
          tempGalleryItem.status = 'success'
          this.$notify({
            title: 'Gallery Image',
            text: `${tempGalleryItem.name} is uploaded to server successfully`,
            type: 'is-success'
          })
        })
        .catch((error) => {
          tempGalleryItem.status = 'error'
          this.$notify({
            title: 'Invalid Device Gallery Image',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
