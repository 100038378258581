import {
  CATEGORY_TREE,
  LOCATION_TREE,
  TAG_TREE,
  RESERVATION_ADVERTISEMENTS,
  RESERVATION_SEARCH,
  PROCESS_BOOKING_RESERVATION,
  SEARCH_ADVERTISEMENTS
} from '../../actions/bookingActions'
import { LOADING, SUCCESS, ERROR } from '../../actions/requestStatus'
import bookingApi from '../../../api/bookingApi'

export default {
  namespaced: true,

  state: {
    status: ''
  },

  getters: {
    isLoading: (state) => state.status === LOADING,
    isSuccess: (state) => state.status === SUCCESS,
    isError: (state) => state.status === ERROR
  },

  actions: {
    [PROCESS_BOOKING_RESERVATION]: (context, { type, formData }) =>
      new Promise((resolve, reject) => {
        bookingApi
          .processBookingReservation(type, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [SEARCH_ADVERTISEMENTS]: (context, { type, filterData, nextUrl }) =>
      new Promise((resolve, reject) => {
        bookingApi
          .advertisementSearch(type, filterData, nextUrl)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [RESERVATION_ADVERTISEMENTS]: (context, { type, filterData }) =>
      new Promise((resolve, reject) => {
        bookingApi
          .listAdvertisement(type, filterData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [CATEGORY_TREE]: () =>
      new Promise((resolve, reject) => {
        bookingApi
          .loadCategoryTree()
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [LOCATION_TREE]: () =>
      new Promise((resolve, reject) => {
        bookingApi
          .loadLocationTree()
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [TAG_TREE]: () =>
      new Promise((resolve, reject) => {
        bookingApi
          .loadTagTree()
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }),

    [RESERVATION_SEARCH]: ({ commit }, filterData) =>
      new Promise((resolve, reject) => {
        commit(LOADING)
        bookingApi
          .reservationSearch(filterData)
          .then((response) => {
            commit(SUCCESS)
            resolve(response)
          })
          .catch((error) => {
            commit(ERROR)
            reject(error)
          })
      })
  },

  mutations: {
    [LOADING]: (state) => {
      state.status = LOADING
    },
    [SUCCESS]: (state) => {
      state.status = SUCCESS
    },
    [ERROR]: (state) => {
      state.status = ERROR
    }
  }
}
