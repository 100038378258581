<template>
  <div
    class="columns"
    v-if="device"
  >
    <div class="column">
      <div class="field">
        <label class="label">UUID</label>
        <div class="control">
          <input
            v-model="device.uuid"
            class="input"
            type="text"
            placeholder="Enter UUID"
            :class="{'is-danger' : errors.uuid}"
          />
        </div>
        <p
          v-if="errors.uuid"
          class="help is-danger"
        >{{ errors.uuid | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Name</label>
        <div class="control">
          <input
            v-model="device.name"
            class="input"
            type="text"
            placeholder="Enter Name"
            :class="{'is-danger' : errors.name}"
          />
        </div>
        <p
          v-if="errors.name"
          class="help is-danger"
        >{{ errors.name | implode }}</p>
      </div>
      <div class="field">
        <label class="label">SXM Time Share</label>
        <div class="control">
          <input
            v-model="device.time_share"
            class="input"
            type="number"
            placeholder="Enter SXM Time Share"
            :class="{'is-danger' : errors.time_share}"
          />
        </div>
        <p
          v-if="errors.time_share"
          class="help is-danger"
        >{{ errors.time_share | implode }}</p>
      </div>
      <div class="field">
        <label class="label">WiFi-SSID</label>
        <div class="control">
          <input
            v-model="device.wifissid"
            class="input"
            type="text"
            placeholder="Enter Device WiFi SSID"
            :class="{'is-danger' : errors.wifissid}"
          />
        </div>
        <p
          v-if="errors.wifissid"
          class="help is-danger"
        >{{ errors.wifissid | implode }}</p>
      </div>
      <div class="field">
        <label class="label">WiFi-Password</label>
        <div class="control">
          <input
            v-model="device.wifipassword"
            class="input"
            type="text"
            placeholder="Enter Device WiFi Password"
            :class="{'is-danger' : errors.wifipassword}"
          />
        </div>
        <p
          v-if="errors.wifipassword"
          class="help is-danger"
        >{{ errors.wifipassword | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Footfall</label>
        <div class="control">
          <input
            v-model="device.footfall"
            class="input"
            type="number"
            placeholder="Enter Device Footfall"
            :class="{'is-danger' : errors.footfall}"
          />
        </div>
        <p
          v-if="errors.footfall"
          class="help is-danger"
        >{{ errors.footfall | implode }}</p>
      </div>
      <div class="field">
        <label class="label">WIFI Mobile Number</label>
        <div class="control">
          <input
            v-model="device.mobile"
            class="input"
            type="number"
            placeholder="Enter Device Mobile Number"
            :class="{'is-danger' : errors.mobile}"
          />
        </div>
        <p
          v-if="errors.mobile"
          class="help is-danger"
        >{{ errors.mobile | implode }}</p>
      </div>
      <div class="field">
        <label class="label">WIFI Provider</label>
        <div class="control">
          <input
            v-model="device.provider"
            class="input"
            type="text"
            placeholder="Enter Device Mobile Provider"
            :class="{'is-danger' : errors.provider}"
          />
        </div>
        <p
          v-if="errors.provider"
          class="help is-danger"
        >{{ errors.provider | implode }}</p>
      </div>
      <b-field label="WIFI Plan Expire Date">
        <b-datepicker
          v-model="expire_at"
          :first-day-of-week="1"
          icon="calendar-alt"
          placeholder="Click to select expire date..."
        >
          <button
            type="button"
            class="button is-primary"
            @click="expire_at = new Date()"
          >
            <b-icon icon="calendar-check"></b-icon>
            <span>Today</span>
          </button>

          <button
            type="button"
            class="button is-danger"
            @click="expire_at = null"
          >
            <b-icon icon="window-close"></b-icon>
            <span>Clear</span>
          </button>
        </b-datepicker>
      </b-field>
      <div class="field">
        <label class="label">Address</label>
        <div class="control">
          <textarea
            v-model="device.address"
            class="textarea"
            placeholder="Enter Address"
            :class="{'is-danger' : errors.address}"
          ></textarea>
        </div>
        <p
          v-if="errors.address"
          class="help is-danger"
        >{{ errors.address | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Orientation</label>
        <div class="control">
          <v-select
            v-model="device.orientation"
            :options="orientations"
            :class="{'is-danger' : errors.orientation}"
            placeholder="Pick Orientation"
            class="is-small"
          ></v-select>
        </div>
        <p
          v-if="errors.orientation"
          class="help is-danger"
        >{{ errors.orientation | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Screen Flip</label>
        <div class="control">
          <b-switch
            :value="true"
            v-model="device.screen_flip"
            type="is-info"
          >
            {{ device.screen_flip ? 'Screen Fliped' : 'Screen No Flip' }}
          </b-switch>
        </div>
        <p
          v-if="errors.screen_flip"
          class="help is-danger"
        >{{ errors.screen_flip | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Allowed External</label>
        <div class="control">
          <v-select
            v-model="device.allowed_external"
            :options="allowed_externals"
            :class="{'is-danger' : errors.allowed_external}"
            placeholder="Pick Allowed External"
            class="is-small"
          ></v-select>
        </div>
        <p
          v-if="errors.allowed_external"
          class="help is-danger"
        >{{ errors.allowed_external | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Screen Type</label>
        <div class="control">
          <v-select
            v-model="device.screen_type"
            :options="screen_types"
            :class="{'is-danger' : errors.screen_type}"
            placeholder="Pick Screen Type"
            class="is-small"
          ></v-select>
        </div>
        <p
          v-if="errors.screen_type"
          class="help is-danger"
        >{{ errors.screen_type | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Screen Size</label>
        <div class="control">
          <v-select
            v-model="device.screen_size"
            :options="screen_sizes"
            :class="{'is-danger' : errors.screen_size}"
            placeholder="Pick Screen Size"
            class="is-small"
          ></v-select>
        </div>
        <p
          v-if="errors.screen_size"
          class="help is-danger"
        >{{ errors.screen_size | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Latitude</label>
        <div class="control">
          <input
            v-model="device.latitude"
            class="input"
            type="text"
            placeholder="Enter Latitude"
            :class="{'is-danger' : errors.latitude}"
          />
        </div>
        <p
          v-if="errors.latitude"
          class="help is-danger"
        >{{ errors.latitude | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Longitude</label>
        <div class="control">
          <input
            v-model="device.longitude"
            class="input"
            type="text"
            placeholder="Enter Longitude"
            :class="{'is-danger' : errors.longitude}"
          />
        </div>
        <p
          v-if="errors.longitude"
          class="help is-danger"
        >{{ errors.longitude | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Live Web</label>
        <div class="control">
          <input
            v-model="device.live_web"
            class="input"
            type="text"
            placeholder="Enter Live Web Address"
            :class="{'is-danger' : errors.live_web}"
          />
        </div>
        <p
          v-if="errors.live_web"
          class="help is-danger"
        >{{ errors.live_web | implode }}</p>
      </div>
      <agent-search-input
        v-model="device.agent"
      />
      <distributor-search-input
        v-model="device.distributor"
      />
      <channel-search-input
        v-model="device.channel"
      />
      <div
        class="field"
        v-if="device.type === 'RSRTC_TIMETABLE'"
      >
        <label class="label">RSRTC Stop Code</label>
        <div class="control">
          <input
            v-model="device.stop_code"
            class="input"
            type="text"
            placeholder="Enter RSRTC Stop Code"
            :class="{'is-danger' : errors.stop_code}"
          />
        </div>
        <p
          v-if="errors.stop_code"
          class="help is-danger"
        >{{ errors.stop_code | implode }}</p>
      </div>
      <div
        class="field"
        v-if="device.type === 'RSRTC_TIMETABLE'"
      >
        <label class="label">RSRTC Stop Name</label>
        <div class="control">
          <input
            v-model="device.stop_name"
            class="input"
            type="text"
            placeholder="Enter RSRTC Stop Name"
            :class="{'is-danger' : errors.stop_name}"
          />
        </div>
        <p
          v-if="errors.stop_name"
          class="help is-danger"
        >{{ errors.stop_name | implode }}</p>
      </div>
      <div
        class="field"
        v-if="device.type === 'RSRTC_TIMETABLE'"
      >
        <label class="label">RSRTC Installation Location</label>
        <div class="control">
          <input
            v-model="device.installation_location"
            class="input"
            type="text"
            placeholder="Enter RSRTC Installation Location"
            :class="{'is-danger' : errors.installation_location}"
          />
        </div>
        <p
          v-if="errors.installation_location"
          class="help is-danger"
        >{{ errors.installation_location | implode }}</p>
      </div>
      <div
        class="field"
        v-if="device.type === 'RSRTC_TIMETABLE'"
      >
        <label class="label">RSRTC Department</label>
        <div class="control">
          <input
            v-model="device.department"
            class="input"
            type="text"
            placeholder="Enter RSRTC Department"
            :class="{'is-danger' : errors.department}"
          />
        </div>
        <p
          v-if="errors.department"
          class="help is-danger"
        >{{ errors.department | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Type</label>
        <div class="control">
          <v-select
            v-model="device.type"
            :options="types"
            :class="{'is-danger' : errors.type}"
            placeholder="Pick Type"
            class="is-small"
          ></v-select>
        </div>
        <p
          v-if="errors.type"
          class="help is-danger"
        >{{ errors.type | implode }}</p>
      </div>
      <div class="field">
        <label class="label">Status</label>
        <div class="control">
          <v-select
            v-model="device.status"
            :options="status_options"
            :class="{'is-danger' : errors.status}"
            placeholder="Pick Status"
            class="is-small"
          ></v-select>
        </div>
        <p
          v-if="errors.status"
          class="help is-danger"
        >{{ errors.status | implode }}</p>
      </div>
      <div class="field is-deviceed action-buttons">
        <div class="control">
          <button
            type="button"
            :disabled="isLoading"
            class="button is-primary is-rounded"
            @click="formSubmit"
            :class="{ 'is-loading': isLoading }"
          >Update Device</button>
        </div>
      </div>
    </div>
    <div class="column is-two-fifths">
      <div
        class="card card-section"
        v-if="hasAnyScope(['DEVICE-UPDATE-ADS'])"
      >
        <header class="card-header">
          <p class="card-header-title has-background-primary has-text-white">
            Ads
          </p>
        </header>
        <div class="card-image">
          <figure class="image is-4by3">
            <img
              v-if="!device.ads"
              src="/img/placeholders/image-placeholder.png"
              alt="Device Thumbnail"
            >
            <img
              v-if="device.ads"
              :src="device.ads.thumbnail"
              alt="Device Ads Thumbnail"
            >
          </figure>
        </div>
        <div class="card-content">
          <div class="file has-name is-fullwidth is-primary">
            <label class="file-label">
              <input
                class="file-input"
                type="file"
                name="adsFile"
                ref="adsFile"
                @change="onAdsChange"
                :disabled="isAdsLoading"
              />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">
                  Choose a file…
                </span>
              </span>
              <span class="file-name">
                {{ adsName }}
              </span>
            </label>
          </div>
        </div>
        <b-loading :is-full-page="false" v-model="isAdsLoading" :can-cancel="false"></b-loading>
      </div>
      <div
        class="card card-section"
        v-if="hasAnyScope(['DEVICE-UPDATE-THUMBNAIL'])"
      >
        <header class="card-header">
          <p class="card-header-title has-background-primary has-text-white">
            Thumbnail
          </p>
        </header>
        <div class="card-image">
          <figure class="image is-4by3">
            <img
              v-if="!device.thumbnails"
              src="/img/placeholders/image-placeholder.png"
              alt="Device Thumbnail"
            >
            <img
              v-if="device.thumbnails"
              :src="device.thumbnails.large"
              alt="Device Thumbnail"
            >
          </figure>
        </div>
        <div class="card-content">
          <div class="file has-name is-fullwidth is-primary">
            <label class="file-label">
              <input
                class="file-input"
                type="file"
                name="thumbnailFile"
                ref="thumbnailFile"
                @change="onThumbnailChange"
                :disabled="isThumbnailLoading"
              />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">
                  Choose a file…
                </span>
              </span>
              <span class="file-name">
                {{ thumbnailName }}
              </span>
            </label>
          </div>
        </div>
        <b-loading :is-full-page="false" v-model="isThumbnailLoading" :can-cancel="false"></b-loading>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import { AgentSearchInput, DistributorSearchInput, ChannelSearchInput } from '@/lib/autocompletes'
import { DEVICE_UPDATE, DEVICE_THUMBNAIL, DEVICE_ADS } from '@/store/actions/deviceActions'
import { CHANNEL_LIST, AGENT_LIST, DISTRIBUTOR_LIST } from '@/store/actions/userActions'

export default {
  name: 'DeviceGeneral',
  props: {
    device: {
      required: true
    }
  },
  components: { AgentSearchInput, DistributorSearchInput, ChannelSearchInput },
  data () {
    return {
      adsName: null,
      thumbnailName: null,
      status_options: ['IDEAL', 'ENABLE', 'DISABLE'],
      screen_types: ['CRT', 'LCD', 'LED', 'PLASMA', 'OLED'],
      screen_sizes: ['32', '40', '43', '50', '55', '60', '65', '70', '75'],
      types: ['ADVERTISING_DEVICE', 'RSRTC_TIMETABLE'],
      orientations: ['PORTRAIT', 'LANDSCAPE'],
      allowed_externals: ['SD CARD'],
      errors: {},
      adsStatus: '',
      expire_at: (this.device.expire_at !== null) ? moment(this.device.expire_at).toDate() : null,
      LOADING_ADS: 'LOADING_ADS',
      SUCCESS_ADS: 'SUCCESS_ADS',
      ERROR_ADS: 'ERROR_ADS',
      isThumbnailLoading: false,
      isAdsLoading: false
    }
  },
  computed: {
    ...mapGetters('Profile', { hasAnyScope: 'hasAnyScope' }),
    ...mapGetters('Devices', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Devices', { updateDevice: DEVICE_UPDATE, uploadThumbnail: DEVICE_THUMBNAIL, uploadAds: DEVICE_ADS }),
    ...mapActions('Users', { loadAgents: AGENT_LIST, loadDistributors: DISTRIBUTOR_LIST, loadChannels: CHANNEL_LIST }),
    onThumbnailChange () {
      const thumbnailFiles = this.$refs.thumbnailFile
      if (!thumbnailFiles || !thumbnailFiles.files.length) {
        return
      }
      this.thumbnailName = thumbnailFiles.files[0].name
      this.createImage(thumbnailFiles.files[0])
      this.uploadDeviceThumbnail(thumbnailFiles.files[0])
    },
    createImage (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.device.thumbnails = { large: e.target.result }
      }
      reader.readAsDataURL(file)
    },
    uploadDeviceThumbnail (thumbnail) {
      this.isThumbnailLoading = true
      const thumbnailFormData = new FormData()
      thumbnailFormData.set('thumbnail', thumbnail)
      const data = {
        deviceId: this.device.id,
        formData: thumbnailFormData
      }
      this.uploadThumbnail(data)
        .then((response) => {
          this.$notify({
            title: 'Device Thumbnail',
            text: response.message,
            type: 'is-success'
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Device Thumbnail Form',
            text: error.message,
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isThumbnailLoading = false
        })
    },
    onAdsChange () {
      const adsFiles = this.$refs.adsFile
      if (!adsFiles || !adsFiles.files.length) {
        return
      }
      this.adsName = adsFiles.files[0].name
      this.uploadDeviceAds(adsFiles.files[0])
    },
    uploadDeviceAds (ads) {
      this.isAdsLoading = true
      const adsFormData = new FormData()
      adsFormData.set('ads', ads)
      const data = {
        deviceId: this.device.id,
        formData: adsFormData
      }
      this.uploadAds(data)
        .then((response) => {
          this.device.ads = response.data
          this.$notify({
            title: 'Device Ads',
            text: response.message,
            type: 'is-success'
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Device Thumbnail Form',
            text: error.message,
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isAdsLoading = false
        })
    },
    formSubmit () {
      this.errors = {}
      const data = {
        deviceId: this.device.id,
        formData: this.device
      }
      let expireDate = null
      if (this.expire_at !== undefined && this.expire_at !== null) {
        expireDate = moment(this.expire_at).format('YYYY-MM-DD')
        data.formData.expire_at = expireDate
      }
      this.updateDevice(data)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.uuid}) Device is updated successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage Device',
            onAction: () => {
              this.$router.push({
                name: 'DeviceList'
              })
            }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid Device Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
