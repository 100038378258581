<template>
  <div>
    <loading v-show="isLoading"></loading>
    <breadcrumb
      :title="pageTitle"
      :breadcrumb="breadcrumb"
    ></breadcrumb>
    <div
      class="card card-section"
      v-if="user"
    >
      <div class="card-content">
        <form @submit.prevent="onStart">
          <div class="field">
            <label class="label">Name</label>
            <div class="control">
              <input
                v-model="user.name"
                class="input"
                :class="{'is-danger' : errors.name}"
                type="text"
                placeholder="Enter User Name"
              />
            </div>
            <p
              v-if="errors.name"
              class="help is-danger"
            >{{ errors.name | implode }}</p>
          </div>

          <div class="field">
            <label class="label">E-Mail</label>
            <div class="control has-icons-left">
              <input
                v-model="user.email"
                class="input"
                :class="{'is-danger' : errors.email}"
                type="email"
                placeholder="Enter User E-Mail"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
            </div>
            <p
              v-if="errors.email"
              class="help is-danger"
            >{{ errors.email | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Password</label>
            <div class="control has-icons-left">
              <input
                v-model="user.password"
                class="input"
                :class="{'is-danger' : errors.password}"
                type="password"
                placeholder="Enter User Password"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-key"></i>
              </span>
            </div>
            <p
              v-if="errors.password"
              class="help is-danger"
            >{{ errors.password | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Confirm Password</label>
            <div class="control has-icons-left">
              <input
                v-model="user.password_confirmation"
                class="input"
                :class="{'is-danger' : errors.password_confirmation}"
                type="text"
                placeholder="Enter Password Again"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-key"></i>
              </span>
            </div>
            <p
              v-if="errors.password_confirmation"
              class="help is-danger"
            >{{ errors.password_confirmation | implode }}</p>
          </div>
          <div class="field">
            <label class="label">Mobile</label>
            <div class="control has-icons-left">
              <input
                v-model="user.mobile"
                class="input"
                :class="{'is-danger' : errors.mobile}"
                type="text"
                placeholder="Enter User Mobile"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-phone"></i>
              </span>
            </div>
            <p
              v-if="errors.mobile"
              class="help is-danger"
            >{{ errors.mobile | implode }}</p>
          </div>
          <div class="field">
            <b-switch
              true-value="MALE"
              false-value="FEMALE"
              v-model="user.profiles.gender"
              type="is-success"
            >
              {{ user.profiles.gender }}
            </b-switch>
          </div>
          <div class="field">
            <label class="label">Address</label>
            <div class="control">
              <textarea
                v-model="user.profiles.address"
                class="textarea"
                :class="{'is-danger' : errors.address}"
                placeholder="Enter User Address"
              ></textarea>
            </div>
            <p
              v-if="errors.address"
              class="help is-danger"
            >{{ errors.address | implode }}</p>
          </div>
          <b-field label="Expire Date">
            <b-datepicker
              v-model="user.expire_date"
              :first-day-of-week="1"
              icon="calendar-alt"
              :min-date="minDate"
              placeholder="Click to select user expire date..."
            >

              <button
                type="button"
                class="button is-primary"
                @click="user.expire_date = new Date()"
              >
                <b-icon icon="calendar-check"></b-icon>
                <span>Today</span>
              </button>

              <button
                type="button"
                class="button is-danger"
                @click="user.expire_date = null"
              >
                <b-icon icon="window-close"></b-icon>
                <span>Clear</span>
              </button>
            </b-datepicker>
          </b-field>
          <div class="field">
            <label class="label">Status</label>
            <div class="control">
              <!-- <div class="control is-expanded has-icons-left"> -->
              <v-select
                v-model="user.status"
                :options="status_options"
                :class="{'is-danger' : errors.status}"
                placeholder="Pick status for user"
                class="is-small"
              ></v-select>
            </div>
            <p
              v-if="errors.status"
              class="help is-danger"
            >{{ errors.status | implode }}</p>
          </div>
          <div class="field">
            <b-switch
              :value="true"
              v-model="user.is_administrator"
              type="is-info"
            >
              {{ user.is_administrator ? 'Administrator' : 'Not An Administrator' }}
            </b-switch>
          </div>
          <div class="field">
            <b-switch
              :value="true"
              v-model="user.is_customer"
              type="is-success"
            >
              {{ user.is_customer ? 'Customer' : 'Not A Customer' }}
            </b-switch>
          </div>
          <div class="field">
            <b-switch
              :value="true"
              v-model="user.is_advertiser"
              type="is-success"
            >
              {{ user.is_advertiser ? 'Advertiser' : 'Not A Advertiser' }}
            </b-switch>
          </div>
          <div class="field">
            <b-switch
              :value="true"
              v-model="user.is_channel"
              type="is-success"
            >
              {{ user.is_channel ? 'Channel Owner' : 'Not A Channel Owner' }}
            </b-switch>
          </div>
          <div class="field">
            <b-switch
              :value="true"
              v-model="user.is_distributor"
              type="is-success"
            >
              {{ user.is_distributor ? 'Distributor' : 'Not A distributor' }}
            </b-switch>
          </div>
          <div class="field">
            <b-switch
              :value="true"
              v-model="user.is_agent"
              type="is-success"
            >
              {{ user.is_agent ? 'Agent' : 'Not A Agent' }}
            </b-switch>
          </div>
          <div class="field">
            <b-switch
              :value="true"
              v-model="user.e_active"
              type="is-info"
            >
              {{ user.e_active ? 'E-Mail Login Allowed' : 'E-Mail Login Not Allowed' }}
            </b-switch>
          </div>
          <div class="field">
            <b-switch
              :value="true"
              v-model="user.m_active"
              type="is-success"
            >
              {{ user.m_active ? 'Mobile Login Allowed' : 'Mobile Login Not Allowed' }}
            </b-switch>
          </div>
          <div
            class="field"
            v-show="user.is_administrator"
          >
            <label class="label">Role</label>
            <div class="control">
              <v-select
                v-model="user.role"
                :options="roles"
                label="name"
                :class="{'is-danger' : errors.role}"
                placeholder="Pick role for user"
                class="is-small"
              ></v-select>
            </div>
            <p
              v-if="errors.role"
              class="help is-danger"
            >{{ errors.role | implode }}</p>
          </div>
          <div class="field is-grouped action-buttons">
            <div class="control">
              <button
                type="submit"
                :disabled="isLoading"
                class="button is-primary is-rounded"
                :class="{ 'is-loading': isLoading }"
              >Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Breadcrumb from '@/pages/layouts/secure/components/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'
import { USER_SHOW, USER_UPDATE } from '@/store/actions/userActions'
import { ROLE_LIST } from '@/store/actions/roleActions'

export default {
  name: 'UserEdit',
  components: {
    Breadcrumb
  },
  props: {
    userId: {
      required: true
    }
  },
  watch: {
    $route: 'loadAsyncUser'
  },
  data () {
    return {
      pageTitle: 'Edit User Form',
      breadcrumb: {
        list: [
          { icon: 'fas fa-home', routeName: 'Dashboard', name: 'Dashboard' },
          { icon: 'fas fa-user-shield', routeName: 'UserList', name: 'Manage Users' }
        ],
        current: { icon: 'fas fa-user-plus', name: 'Edit User' },
        actions: [
          {
            icon: 'fas fa-user-shield', routeName: 'UserList', name: 'Manage Users', scope: 'USER-LIST'
          },
          {
            icon: 'fas fa-user-tie', routeName: 'UserShow', params: { userId: this.userId }, name: 'Display User', scope: 'USER-SHOW'
          }
        ]
      },
      status_options: ['ACTIVE', 'BANNED', 'EXPIRED'],
      minDate: new Date(),
      user: null,
      errors: {},
      roles: []
    }
  },
  computed: {
    ...mapGetters('Users', ['isLoading', 'isSuccess', 'isError'])
  },
  methods: {
    ...mapActions('Users', { updateUser: USER_UPDATE, loadUser: USER_SHOW }),
    ...mapActions('Roles', { loadRoles: ROLE_LIST }),
    onStart () {
      this.errors = {}
      let expireDate = null
      if (this.user.expire_date !== undefined && this.user.expire_date !== null) {
        expireDate = moment(this.user.expire_date).format('YYYY-MM-DD HH:mm:ss ZZ')
      }
      const formData = {
        id: this.user.id,
        name: this.user.name,
        email: this.user.email,
        password: this.user.password,
        password_confirmation: this.user.password_confirmation,
        status: this.user.status,
        is_advertiser: this.user.is_advertiser,
        is_distributor: this.user.is_distributor,
        is_agent: this.user.is_agent,
        is_customer: this.user.is_customer,
        is_administrator: this.user.is_administrator,
        is_channel: this.user.is_channel,
        e_active: this.user.e_active,
        m_active: this.user.m_active,
        mobile: this.user.mobile,
        expire_date: expireDate,
        profiles: this.user.profiles,
        role: this.user.role
      }

      const data = {
        userId: this.userId,
        formData
      }
      this.updateUser(data)
        .then((response) => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `(${response.data.name}) User is updated successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'Go to Manage User',
            onAction: () => {
              this.$router.push({
                name: 'UserList'
              })
            }
          })
        })
        .catch((error) => {
          this.errors = error.errors
          this.$notify({
            title: 'Invalid User Form',
            text: error.message,
            type: 'is-danger'
          })
        })
    },
    loadAsyncUser () {
      this.user = null
      this.loadUser(this.userId)
        .then((response) => {
          this.user = {
            id: response.data.id,
            name: response.data.name,
            email: response.data.email,
            password: null,
            password_confirmation: null,
            status: response.data.status,
            is_advertiser: response.data.types.includes('ADVERTISER'),
            is_distributor: response.data.types.includes('DISTRIBUTOR'),
            is_agent: response.data.types.includes('AGENT'),
            is_customer: response.data.types.includes('CUSTOMER'),
            is_administrator: response.data.types.includes('ADMINISTRATOR'),
            is_channel: response.data.types.includes('CHANNEL OWNER'),
            e_active: response.data.logins.includes('E-MAIL'),
            m_active: response.data.logins.includes('MOBILE'),
            mobile: response.data.mobile,
            expire_date: moment(response.data.expire_date).toDate(),
            profiles: response.data.profiles,
            role: response.data.role
          }
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: 'Error (User Loading Failed)',
            message: error.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'skull',
            iconPack: 'fas',
            onCancel: () => this.$router.replace({ name: 'UserList' }),
            onConfirm: () => this.$router.replace({ name: 'UserList' })
          })
        })
    },
    loadAsyncRoles () {
      this.loadRoles()
        .then((response) => {
          this.roles = response.data
        })
        .catch((error) => {
          this.$notify({
            title: 'Role Loading Failed',
            text: error.message,
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    this.loadAsyncRoles()
    this.loadAsyncUser()
  }
}
</script>
